import { EntryCollection } from 'contentful';

import client from '@/api-contentful/contentful-request';
import { ITournamentRulesFields } from '@/interfaces/contentful';

export const getContentfulActiveTournamentsRules = async (): Promise<
  EntryCollection<ITournamentRulesFields>
> => {
  const response = await client.getEntries<ITournamentRulesFields>({
    content_type: 'tournamentRules',
    'fields.activeTournament': true,
  });

  return response;
};

export const getContentfulTournamentRules = async ({
  tournamentSlug,
}: {
  tournamentSlug: string;
}): Promise<EntryCollection<ITournamentRulesFields>> => {
  const response = await client.getEntries<ITournamentRulesFields>({
    content_type: 'tournamentRules',
    'fields.slug': tournamentSlug,
  });

  return response;
};
