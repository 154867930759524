/* eslint-disable camelcase */
import { DraftPoolForSlate, DraftPoolForSlateResponse } from '@/interfaces/draft-pools';

export const draftPoolForSlateAdapter = (
  draftPool: DraftPoolForSlateResponse
): DraftPoolForSlate => {
  const {
    id,
    contest_style_id,
    description,
    draft_entry_style_id,
    entry_count,
    entry_role,
    entry_style_id,
    image_url,
    max_entries,
    rules_url,
    slate_id,
    status,
    title,
    user_draft_pool_entry_count,
    user_draft_pool_entry_payouts,
  } = draftPool;

  return {
    id,
    contestStyleId: contest_style_id,
    description,
    draftEntryStyleId: draft_entry_style_id,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    imageUrl: image_url,
    maxEntries: max_entries,
    rulesUrl: rules_url,
    slateId: slate_id,
    status,
    title,
    userDraftPoolEntryCount: user_draft_pool_entry_count,
    userDraftPoolEntryPayouts: user_draft_pool_entry_payouts,
  };
};
