/* eslint-disable camelcase */
import { pickEmRivalLineConstructor } from '@/store/constructors/pick-em-rival-appearance-constructor';

import {
  ConstructedPickEmRivalLine,
  PickEmRival,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { PlayerResponse } from '@/interfaces/constructed-interfaces/constructed-slates';
import { DraftingConfig, StateConfig } from '@/interfaces/drafting-config';
import {
  FavoritePlayersResponse,
  FeaturedLobbyResponse,
  RivalLine,
  RivalLinesResponse,
} from '@/interfaces/pick-em';
import { Role } from '@/interfaces/user';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import appearanceAdapter from '../../constructed-slates/adapters/appearance';
import playerAdapter from '../../constructed-slates/adapters/player';
import matchAdapter from '../../matches/adapters/match';
import {
  addFavoritePickEmPlayer,
  REMOVE_PICK_EM_RIVAL_LINE,
  removeFavoritePickEmPlayer,
  removePickEmRivalLine,
  SET_PICK_EM_FEATURED_LOBBY,
  SET_PICK_EM_RIVAL_LINES,
  setPickEmFeaturedLobby,
  setPickEmRivalLines,
} from '../actions';
import featuredLobbyAdapter from '../adapters/featured-lobby';
import rivalLineAdapter from '../adapters/rival-line';
import soloGameAdapter from '../adapters/solo-game';

import { convertToObjects, removeDuplicateAppearances } from './helpers/over-under-lines';

type State = PickEmRival;

type PickEmRivalActions = ReturnType<
  | typeof setPickEmRivalLines
  | typeof removePickEmRivalLine
  | typeof addFavoritePickEmPlayer
  | typeof removeFavoritePickEmPlayer
  | typeof setPickEmFeaturedLobby
>;

const initialState: PickEmRival = {
  rivalLines: null,
  appearances: null,
  games: null,
  players: null,
  soloGames: null,
  constructedPickEmRivals: null,
  featuredLobby: {
    airdrops: null,
    appearances: null,
    overUnderLines: null,
    constructedAppearances: null,
    constructedPromoAppearances: null,
    constructedRivalLines: null,
    sections: null,
    playerGroups: null,
    pickemPacks: null,
    powerUps: null,
    rivalLines: null,
    soloGames: null,
    games: null,
    players: null,
    favoritePlayers: null,
  },
};

const set = (
  state: PickEmRival,
  {
    data,
    favoritePlayers,
    draftingConfig,
    pickEmStateConfig,
    roles,
  }: {
    data: RivalLinesResponse;
    favoritePlayers: FavoritePlayersResponse['favorite_players'];
    draftingConfig: DraftingConfig;
    pickEmStateConfig: StateConfig['pickEm'];
    roles: Role[];
  }
): PickEmRival => {
  const {
    rival_lines,
    appearances: appearancesResponse,
    games: gamesResponse,
    players: playersResponse,
    solo_games,
  } = data;
  const isAdmin = roles.includes('admin');

  const favoritePlayerIds = favoritePlayers.map((fPlayer) => fPlayer.id);

  const rivalLines = rival_lines.map(rivalLineAdapter).filter((rL) => {
    if (isAdmin) return true;
    if (!pickEmStateConfig.visibleStats) return true;
    return pickEmStateConfig.visibleStats.includes(rL.options[0].appearanceStat.stat);
  });
  const appearances = appearancesResponse.map(appearanceAdapter);
  const games = arrayToObjectIdKeys(gamesResponse.map(matchAdapter));
  const soloGames = arrayToObjectIdKeys(solo_games.map(soloGameAdapter));
  const players = arrayToObjectIdKeys(
    playersResponse.map((player) => {
      const adaptedPlayer = playerAdapter(player);
      if (!favoritePlayerIds?.length) {
        return adaptedPlayer;
      }
      if (favoritePlayerIds.includes(player.id)) {
        return {
          ...adaptedPlayer,
          favorite: true,
        };
      }
      return adaptedPlayer;
    })
  );

  const { teams, lineupStatuses, sports } = draftingConfig;

  let constructedPickEmRivals = appearances
    ? pickEmRivalLineConstructor({
        appearances,
        games,
        lineupStatuses,
        rivalLines,
        players,
        soloGames,
        sports,
        teams,
      })
    : null;

  if (constructedPickEmRivals && !isAdmin && pickEmStateConfig.visibleSports) {
    constructedPickEmRivals = constructedPickEmRivals.filter(
      (cPERL) =>
        pickEmStateConfig.visibleSports.includes(cPERL.appearanceOptions[0].match?.sportId) ||
        pickEmStateConfig.visibleSports.includes(cPERL.appearanceOptions[0].soloGame?.sportId)
    );
  }

  return {
    ...state,
    rivalLines,
    appearances,
    games,
    players,
    soloGames,
    constructedPickEmRivals,
  };
};

const remove = (state: PickEmRival, { data }: { data: string[] }): PickEmRival => {
  const newRivalLines: RivalLine[] = state.rivalLines.map((rL: RivalLine) => {
    if (data.includes(rL.id)) {
      return {
        ...rL,
        status: 'removed',
      };
    }
    return rL;
  });

  const newCPERs: ConstructedPickEmRivalLine[] = state.constructedPickEmRivals.map(
    (cPER: ConstructedPickEmRivalLine) => {
      if (data.includes(cPER.id)) {
        return {
          ...cPER,
          rival: {
            ...cPER.rival,
            status: 'removed',
          },
          status: 'removed',
        };
      }
      return cPER;
    }
  );

  return {
    ...state,
    rivalLines: newRivalLines,
    appearances: state.appearances,
    games: state.games,
    players: state.players,
    soloGames: state.soloGames,
    constructedPickEmRivals: newCPERs,
  };
};

const setPickEmFeaturedLobbyAction = (
  state: PickEmRival,
  {
    data,
    pickEmStateConfig,
    draftingConfig,
    roles,
  }: {
    data: FeaturedLobbyResponse;
    pickEmStateConfig: StateConfig['pickEm'];
    draftingConfig: DraftingConfig;
    roles: Role[];
  }
): PickEmRival => {
  const isAdmin = roles.includes('admin');

  const {
    appearances: appearancesResponse,
    games: gamesResponse,
    players: playersResponse,
    solo_games: soloGamesResponse,
    rival_lines: rivalLinesResponse,
  } = data;

  const uniqueAppearances = removeDuplicateAppearances(appearancesResponse.map(appearanceAdapter));

  const { games, soloGames, players } = convertToObjects(
    gamesResponse.map(matchAdapter),
    soloGamesResponse.map(soloGameAdapter),
    playersResponse.map((player: PlayerResponse) => playerAdapter(player))
  );

  const rivalLines = rivalLinesResponse.map(rivalLineAdapter).filter((rL) => {
    if (isAdmin) return true;
    if (!pickEmStateConfig.visibleStats) return true;
    return pickEmStateConfig.visibleStats.includes(rL.options[0].appearanceStat.stat);
  });

  const { teams, lineupStatuses, sports } = draftingConfig;

  const constructedPickEmRivals = uniqueAppearances
    ? pickEmRivalLineConstructor({
        appearances: uniqueAppearances,
        games,
        lineupStatuses,
        rivalLines,
        players,
        soloGames,
        sports,
        teams,
      })
    : null;

  const featuredLobbyContent = featuredLobbyAdapter(data, rivalLinesResponse);

  return {
    ...state,
    featuredLobby: {
      ...featuredLobbyContent,
      constructedRivalLines: constructedPickEmRivals,
    },
  };
};

export const pickEmRivalLineReducer = (
  state: State = initialState,
  action: PickEmRivalActions = {} as PickEmRivalActions
): State => {
  switch (action.type) {
    case SET_PICK_EM_RIVAL_LINES:
      return set(state, action.payload);
    case REMOVE_PICK_EM_RIVAL_LINE:
      return remove(state, action.payload);
    case SET_PICK_EM_FEATURED_LOBBY:
      return setPickEmFeaturedLobbyAction(state, action.payload);
    default:
      return state;
  }
};
