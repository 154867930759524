import React from 'react';

import Button from '@/components/atoms/button';
import Icon from '@/components/atoms/icon';
import Loader from '@/components/atoms/loader';
import useGetPromoCards from '@/utilities/hooks/use-get-promo-cards';

import EmptyState from './components/empty-state';
import ErrorState from './components/error-state';

import styles from './styles.scss';

interface InventoryListProps {
  handleCloseModal: () => void;
  isError: boolean;
  isLoading: boolean;
}

const InventoryList = (props: InventoryListProps) => {
  const { handleCloseModal, isError, isLoading } = props;
  const promoCards = useGetPromoCards({ handleCloseModal, location: 'rewards hub' });

  let contentEl: React.ReactNode = null;
  if (isError) {
    contentEl = <ErrorState />;
  } else if (isLoading) {
    contentEl = <Loader />;
  } else if (promoCards.length) {
    contentEl = <div className={styles.cardContainer}>{promoCards}</div>;
  } else {
    contentEl = <EmptyState />;
  }

  return (
    <div className={styles.inventoryList}>
      <header className={styles.headerSection}>
        <h1 className={styles.header}>Promos</h1>
        <button className={styles.closeButton} onClick={handleCloseModal}>
          <Icon name="clear" className={styles.icon} />
        </button>
      </header>
      <div className={styles.scrollableSection}>{contentEl}</div>
      <div className={styles.buttonWrapper}>
        <Button
          classNames={{ button: styles.button }}
          color="grey"
          onClick={handleCloseModal}
          size="small"
          text="Close"
          type="text"
          width="intrinsic"
        />
      </div>
    </div>
  );
};

export default InventoryList;
