/* eslint-disable camelcase */
import { SettledSlate, SettledSlateResponse } from '@/interfaces/results';

const settledSlatesAdapter = (slates: SettledSlateResponse[]): SettledSlate[] =>
  slates.map((slate) => {
    const {
      contest_style_ids,
      cutoff_at,
      description,
      draft_count,
      draft_pool_draft_count,
      fees,
      game_count,
      id,
      lobby_hidden,
      payouts,
      sport_id,
      start_at,
      title,
      tournament_draft_count,
      weekly_winner_draft_count,
    } = slate;

    return {
      contestStyleIds: contest_style_ids,
      cutoffAt: cutoff_at,
      description,
      draftCount: draft_count,
      draftPoolDraftCount: draft_pool_draft_count,
      fees,
      gameCount: game_count,
      id,
      lobbyHidden: lobby_hidden,
      payouts,
      sportId: sport_id,
      startAt: start_at,
      title,
      tournamentDraftCount: tournament_draft_count,
      weeklyWinnerDraftCount: weekly_winner_draft_count,
    };
  });

export default settledSlatesAdapter;
