// IMPORTANT: All services that are statically imported here will be included
// in _any_ code path that imports this module.
// For 3rd party services, in most cases, you will want to want to register
// services that are created by factories, whereby the factory will then
// dynamically import the service. This way 3rd party services are only loaded
// by code paths that actually use them.
// For 1st party services, it may make sense to statically import them here
// if they are an ambient service and are used in many places throughout the app.
// However, if they are only used in a few places, it may make sense to
// dynamically import them via a factory.

import { ServiceContainer, type ServiceMap } from '@lib/service-container/container';

export type AppServiceContainer = typeof appServiceContainer;

export type AppServiceMap = ServiceMap<typeof appServiceContainer>;

// note: as we transition to service-oriented architecture, we eventually
// won't reference environment variables here. Instead, we will use a
// configuration service that can be referenced when creating services.
// The configuration service will be responsible for resolving the
// config values - whether they be from environment variables, a config
// file, or some other source.

export const appServiceContainer = new ServiceContainer()
  .registerFactory({
    name: 'braze',
    factory: async () => {
      const { createBrazeService } = await import(
        /* webpackChunkName: "service.braze" */
        '../../services/braze/service'
      );

      return createBrazeService({
        apiKey: process.env.BRAZE_API_KEY,
        baseUrl: process.env.BRAZE_SDK_ENDPOINT,
        safariWebsitePushId: process.env.BRAZE_SAFARI_DOMAIN,
      });
    },
  })
  .registerFactory({
    name: 'pciProxy',
    factory: async () => {
      const { createPciProxyService } = await import(
        /* webpackChunkName: "service.pci-proxy" */
        '../../services/pci-proxy/service'
      );
      return createPciProxyService({
        merchantId: process.env.SECURE_FIELDS_INIT_KEY,
        scriptUrl: process.env.PCI_PROXY_URL,
      });
    },
  });
// additional services can be registered by chaining `.registerFactory` calls
// and/or `registerInstance` calls on the call to `registerFactory` above.
// .registerFactory({
//   name: 'paysafe',
//   factory: async () => {
//     const { createPaysafeService } = await import(
//       /* webpackChunkName: "service.paysafe" */
//       '../../paysafe/service'
//     );
//     return createPaysafeService({
//       apiKey: process.env.PAYSAFE_API_KEY,
//       scriptUrl: process.env.PAYSAFE_SCRIPT_URL,
//     });
//   },
// }).registerInstance({
//   name: 'some-service',
//   instance: someServiceInstance,
// });
