/* eslint-disable camelcase */
import {
  TournamentForTournamentDraftRound,
  TournamentForTournamentDraftRoundResponse,
} from '@/interfaces/tournament-rounds';

export const tournamentForTournamentRoundAdapter = (
  tournament: TournamentForTournamentDraftRoundResponse
): TournamentForTournamentDraftRound => {
  const {
    id,
    additional_info,
    contest_style_id,
    cutoff_at,
    description,
    entry_count,
    entry_role,
    entry_style_id,
    image_url,
    max_entries,
    rules_url,
    status,
    title,
    total_rounds,
  } = tournament;

  return {
    id,
    additionalInfo: additional_info,
    contestStyleId: contest_style_id,
    cutoffAt: cutoff_at,
    description,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    imageUrl: image_url,
    maxEntries: max_entries,
    rulesUrl: rules_url,
    status,
    title,
    totalRounds: total_rounds,
  };
};
