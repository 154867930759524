import { LiveSlatesForDailySport, LiveSlatesForSportResponse } from '@/interfaces/live';

import { SET_LIVE_SLATES_FOR_DAILY_SPORT, setLiveSlatesForDailySport } from '../actions';
import liveSlateOverviewsAdapter from '../adapters/live-slate-overviews';
import liveSlatesAdapter from '../adapters/live-slates';

type State = LiveSlatesForDailySport | null;

type LiveSlatesForSportActions = ReturnType<typeof setLiveSlatesForDailySport>;

const initialState: LiveSlatesForDailySport = {};

const set = (
  state: LiveSlatesForDailySport,
  { data, sportId }: { data: LiveSlatesForSportResponse; sportId: string }
): LiveSlatesForDailySport => {
  const slates = liveSlatesAdapter(data.slates);
  const slateOverviews = data.slate_overviews && liveSlateOverviewsAdapter(data.slate_overviews);

  return {
    ...state,
    [sportId]: {
      slates,
      slateOverviews,
    },
  };
};

export const liveSlatesForDailySportReducer = (
  state: State = initialState,
  action: LiveSlatesForSportActions = {} as LiveSlatesForSportActions
): State => {
  switch (action.type) {
    case SET_LIVE_SLATES_FOR_DAILY_SPORT:
      return set(state, action.payload);
    default:
      return state;
  }
};
