/* eslint-disable camelcase */
import {
  TournamentRoundsForSlate,
  TournamentRoundsForSlateResponse,
} from '@/interfaces/tournament-rounds';

import { SET_TOURNAMENT_ROUNDS_FOR_SLATE, setTournamentRoundsForSlate } from '../actions';
import { tournamentRoundAdapter } from '../adapters/tournament-rounds';

type State = TournamentRoundsForSlate | null;

type TournamentRoundsActions = ReturnType<typeof setTournamentRoundsForSlate>;

const initialState: TournamentRoundsForSlate = {};

const set = (
  state: TournamentRoundsForSlate,
  data: TournamentRoundsForSlateResponse
): TournamentRoundsForSlate => {
  const slateId = data.tournament_rounds?.[0].slate_id;
  const tournamentRounds =
    data.tournament_rounds && data.tournament_rounds.map(tournamentRoundAdapter);
  return {
    ...state,
    [slateId]: tournamentRounds,
  };
};

export const tournamentRoundsForSlateReducer = (
  state: State = initialState,
  action: TournamentRoundsActions = {} as TournamentRoundsActions
): State => {
  switch (action.type) {
    case SET_TOURNAMENT_ROUNDS_FOR_SLATE:
      return set(state, action.payload);
    default:
      return state;
  }
};
