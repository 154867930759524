import { SET_OBJECT_ID, setSelectedObjectId as setSelectedObjectIdAction } from '../actions';

type State = { objectId: string };

type SearchActions = ReturnType<typeof setSelectedObjectIdAction>;

const initialState: State = { objectId: '' };

const setSelectedObjectId = ({ objectId }: { objectId: string }): { objectId: string } => {
  return { objectId };
};

export const searchReducer = (
  state: State = initialState,
  action: SearchActions = {} as SearchActions
): State => {
  switch (action.type) {
    case SET_OBJECT_ID:
      return setSelectedObjectId(action.payload);
    default:
      return state;
  }
};
