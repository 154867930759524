import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/exposure';

import { getExposure, setExposure } from '../actions';

export const getExposureSaga = function* getExposureSaga(
  action: ReturnType<typeof getExposure>
): SagaIterator {
  try {
    const response = yield call(api.getExposure, action.payload);

    yield put(setExposure(response.data));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
