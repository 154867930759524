/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';

import request from '@request';
import {
  DraftEntriesForDraftResponse,
  DraftsForTournamentRoundResponse,
  TournamentRoundsForSlateResponse,
  TournamentRoundsLeaderboardResponse,
  UserDraftEntriesForDraftResponse,
} from '@/interfaces/tournament-rounds';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getTournamentRoundsForSlate = async ({
  slateId,
}: {
  slateId: string;
}): Promise<AxiosResponse<TournamentRoundsForSlateResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/slates/${slateId}/tournament_rounds`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUserDraftsForTournamentRound = async ({
  tournamentRoundId,
  page,
}: {
  tournamentRoundId: string;
  page?: number;
}): Promise<AxiosResponse<DraftsForTournamentRoundResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/tournament_rounds/${tournamentRoundId}/drafts`,
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getPaginatedDraftEntriesForDraft = async ({
  draftId,
  page,
}: {
  draftId: string;
  page?: number;
}): Promise<AxiosResponse<DraftEntriesForDraftResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/tournaments/drafts/${draftId}`,
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUserPaginatedDraftEntriesForDraft = async ({
  draftId,
  page,
}: {
  draftId: string;
  page?: number;
}): Promise<AxiosResponse<UserDraftEntriesForDraftResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/tournaments/drafts/${draftId}/draft_entries`,
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getLeaderboardForTournamentRound = async ({
  tournamentId,
  page,
}: {
  tournamentId: string;
  page?: number;
}): Promise<AxiosResponse<TournamentRoundsLeaderboardResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/tournament_rounds/${tournamentId}/leaderboard`,
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
