import request from '@request';

export const getUserFeatures = async (): Promise<any> => {
  const response = await request({
    method: 'GET',
    underdogAPIVersion: 'v2',
    url: '/user/features',
    requiresLatLong: true,
  });

  return response;
};

export const getGlobalFeatures = async (): Promise<any> => {
  const response = await request({
    method: 'GET',
    url: '/features',
  });

  return response;
};
