import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { RootState } from '@/store';

import LogoModalHeader from '@/components/atoms/logo-modal-header';
/* eslint-disable-next-line import/no-cycle */
import RegisterForm from '@/components/molecules/register-form';
import { User } from '@/interfaces/user';

import styles from './styles.scss';

export interface RegisterModalProps {
  handleCloseModal: () => void;
  user: User;
  prefilledPromo?: string;
}

const RegisterModal = (props: RegisterModalProps) => {
  const { handleCloseModal, prefilledPromo, user } = props;

  useEffect(() => {
    if (user.id) {
      handleCloseModal();
    }
  }, [user, handleCloseModal]);

  return (
    <div className={styles.modal}>
      <LogoModalHeader handleCloseModal={handleCloseModal} />
      <div className={styles.content}>
        <RegisterForm isModal prefilledPromo={prefilledPromo} />
      </div>
    </div>
  );
};

export default connect((state: RootState) => ({
  user: state.user,
}))(RegisterModal);
