import { EntryCollection } from 'contentful';

import { IPromoInfoFields } from '@/interfaces/contentful';

export const GET_CONTENTFUL_PROMO_INFO = 'contentful-promo-info/GET_CONTENTFUL_PROMO_INFO' as const;
export const SET_CONTENTFUL_PROMO_INFO = 'contentful-promo-info/SET_CONTENTFUL_PROMO_INFO' as const;

export const getContentfulPromoInfo = (payload: { contentfulInfoId: string }) => ({
  type: GET_CONTENTFUL_PROMO_INFO,
  payload,
});

export const setContentfulPromoInfo = (payload: {
  promoInfoResponse: EntryCollection<IPromoInfoFields>;
  contentfulInfoId: string;
}) => ({ type: SET_CONTENTFUL_PROMO_INFO, payload });
