import {
  DraftResponse,
  DraftWeeklyScoresResponse,
  GetDraftRequest,
  NewDraftEntryResponse,
  PickMadeResponse,
  RenameDraftEntry,
} from '@/interfaces/drafts';
import { EntryScoringUpdateResponse, PicksScoringUpdateResponse } from '@/interfaces/live';

export const GET_DRAFT = 'drafts/GET_DRAFT' as const; // main saga
export const SET_DRAFT = 'drafts/SET_DRAFT' as const;
export const UPDATE_DRAFT = 'drafts/UPDATE_DRAFT' as const;
export const SET_NEW_DRAFT_ENTRY = 'drafts/SET_NEW_DRAFT_ENTRY' as const;
export const UPDATE_PICK = 'drafts/UPDATE_PICK' as const;
export const CLEAR_DRAFT = 'drafts/CLEAR_DRAFT' as const;
export const GET_DRAFT_WEEKLY_SCORES = 'drafts/GET_DRAFT_WEEKLY_SCORES' as const;
export const SET_DRAFT_WEEKLY_SCORES = 'drafts/SET_DRAFT_WEEKLY_SCORES' as const;
export const RENAME_DRAFT_ENTRY = 'drafts/RENAME_DRAFT_ENTRY' as const;
export const UPDATE_DRAFT_ENTRIES_SCORING = 'drafts/UPDATE_DRAFT_ENTRIES_SCORING' as const;
export const UPDATE_PICKS_SCORING = 'drafts/UPDATE_PICKS_SCORING' as const;

export const getDraft = (payload: GetDraftRequest) => ({ type: GET_DRAFT, payload });

export const setDraft = (payload: DraftResponse) => ({ type: SET_DRAFT, payload });

export const updateDraft = (payload: DraftResponse) => ({ type: UPDATE_DRAFT, payload });

export const getDraftWeeklyScores = (payload: { draftId: string }) => ({
  type: GET_DRAFT_WEEKLY_SCORES,
  payload,
});

export const setDraftWeeklyScores = (payload: {
  data: DraftWeeklyScoresResponse;
  draftId: string;
}) => ({ type: SET_DRAFT_WEEKLY_SCORES, payload });

export const setNewDraftEntry = (payload: NewDraftEntryResponse) => ({
  type: SET_NEW_DRAFT_ENTRY,
  payload,
});

export const updatePick = (payload: { updatedPick: PickMadeResponse }) => ({
  type: UPDATE_PICK,
  payload,
});

export const clearDraft = (payload: GetDraftRequest) => ({ type: CLEAR_DRAFT, payload });

export const renameDraftEntry = (payload: RenameDraftEntry) => ({
  type: RENAME_DRAFT_ENTRY,
  payload,
});

// Draft live scoring pusher actions
export const updateDraftEntriesScoring = (payload: {
  data: EntryScoringUpdateResponse[];
  draftId: string;
  slateId: string;
}) => ({ type: UPDATE_DRAFT_ENTRIES_SCORING, payload });

export const updatePicksScoring = (payload: {
  data: PicksScoringUpdateResponse;
  draftId: string;
}) => ({ type: UPDATE_PICKS_SCORING, payload });
