/* eslint-disable camelcase */
import { UserPromotion, UserPromotionResponse } from '@/interfaces/promotions';

const userPromotionAdapter = (userPromotion: UserPromotionResponse): UserPromotion => {
  if (!userPromotion) return null;
  const { expires_at, promotion } = userPromotion;

  const { amount, description, id, title } = promotion;

  return {
    expiresAt: expires_at,
    promotion: {
      amount,
      ctaText: promotion.cta_text,
      default: promotion.default, // Property shorthand syntax throws an error for keyword default
      description,
      dismissible: promotion.dismissible,
      id,
      percentMatch: promotion.percent_match,
      title,
    },
  };
};

export default userPromotionAdapter;
