import {
  AppearanceStatsByIdRequest,
  AppearanceStatsRequest,
  AppearanceStatsResponse,
  WeeklyAppearanceStatsRequest,
} from '@/interfaces/appearance-stats';

export const GET_APPEARANCE_STATS = 'drafts/GET_APPEARANCE_STATS' as const;
export const GET_APPEARANCE_STATS_BY_ID = 'drafts/GET_APPEARANCE_STATS_BY_ID' as const;
export const SET_APPEARANCE_STATS = 'drafts/SET_APPEARANCE_STATS' as const;
export const GET_WEEKLY_APPEARANCE_STATS = 'drafts/GET_WEEKLY_APPEARANCE_STATS' as const;
export const SET_WEEKLY_APPEARANCE_STATS = 'drafts/SET_WEEKLY_APPEARANCE_STATS' as const;
export const CLEAR_APPEARANCE_STATS = 'drafts/CLEAR_APPEARANCE_STATS' as const;

export const getAppearanceStats = (payload: AppearanceStatsRequest) => ({
  type: GET_APPEARANCE_STATS,
  payload,
});

export const getAppearanceStatsById = (payload: AppearanceStatsByIdRequest) => ({
  type: GET_APPEARANCE_STATS_BY_ID,
  payload,
});

export const setAppearanceStats = (payload: AppearanceStatsResponse) => ({
  type: SET_APPEARANCE_STATS,
  payload,
});

export const getWeeklyAppearanceStats = (payload: WeeklyAppearanceStatsRequest) => ({
  type: GET_WEEKLY_APPEARANCE_STATS,
  payload,
});

export const setWeeklyAppearanceStats = (payload: AppearanceStatsResponse) => ({
  type: SET_WEEKLY_APPEARANCE_STATS,
  payload,
});

export const clearAppearanceStats = () => ({ type: CLEAR_APPEARANCE_STATS });
