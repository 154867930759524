import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api-contentful/promo-info';

import { getContentfulPromoInfo, setContentfulPromoInfo } from '../actions';

export const getContentfulPromoInfoSaga = function* getContentfulPromoInfoSaga(
  action: ReturnType<typeof getContentfulPromoInfo>
): SagaIterator {
  try {
    const promoInfoResponse = yield call(api.getContentfulPromoInfoById, action.payload);

    yield put(
      setContentfulPromoInfo({
        promoInfoResponse,
        contentfulInfoId: action.payload.contentfulInfoId,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
