/* eslint-disable camelcase */
import {
  TournamentInfo,
  TournamentResponse,
  TournamentRound,
  TournamentRoundResponse,
  TournamentSlate,
  TournamentSlateResponse,
} from '@/interfaces/tournaments';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

const slatesAdapter = (slates: TournamentSlateResponse[]): TournamentSlate[] => {
  if (!slates || slates.length === 0) return null;
  return slates.map((slate) => {
    const { id, cutoff_at, description, game_count, lobby_hidden, sport_id, start_at, title } =
      slate;

    return {
      id,
      cutoffAt: cutoff_at,
      description,
      gameCount: game_count,
      lobbyHidden: lobby_hidden,
      sportId: sport_id,
      startAt: start_at,
      title,
    };
  });
};

const tournamentRoundsAdapter = (tournamentRounds: TournamentRoundResponse[]): TournamentRound[] =>
  tournamentRounds.map((tournamentRound) => ({
    id: tournamentRound.id,
    abbr: tournamentRound.abbr,
    description: tournamentRound.description,
    draftAt: tournamentRound.draft_at,
    entryStyleId: tournamentRound.entry_style_id,
    finalRound: tournamentRound.final_round,
    leaderboardUrl: tournamentRound.leaderboard_url,
    number: tournamentRound.number,
    paginated: tournamentRound.paginated,
    prizeBreakdownTitle: tournamentRound.prize_breakdown_title,
    status: tournamentRound.status,
    slateId: tournamentRound.slate_id,
    title: tournamentRound.title,
  }));

const tournamentAdapter = (tournamentResponse: TournamentResponse): TournamentInfo => {
  const {
    tournament: {
      id,
      additional_info,
      contest_style_id,
      cutoff_at,
      description,
      entry_count,
      entry_role,
      entry_style_id,
      image_url,
      max_entries,
      rules_url,
      status,
      slates: slatesResponse,
      title,
      total_rounds,
      tournament_rounds,
    },
  } = tournamentResponse;

  const tournamentRounds = tournamentRoundsAdapter(tournament_rounds);
  const slates = arrayToObjectIdKeys(slatesAdapter(slatesResponse));

  return {
    id,
    additionalInfo: additional_info,
    contestStyleId: contest_style_id,
    cutoffAt: cutoff_at,
    description,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    imageUrl: image_url,
    maxEntries: max_entries,
    rulesUrl: rules_url,
    status,
    slates,
    title,
    totalRounds: total_rounds,
    tournamentRounds,
  };
};

export default tournamentAdapter;
