import { MatchUpdateResponse } from '@/interfaces/live';
import { MatchesResponse } from '@/interfaces/matches';
import { SportId } from '@/interfaces/types';

export const GET_MATCHES_FOR_DATE = 'matches/GET_MATCHES_FOR_DATE' as const;
export const SET_MATCHES_FOR_DATE = 'matches/SET_MATCHES_FOR_DATE' as const;
export const UPDATE_MATCH_FOR_DATE = 'matches/UPDATE_MATCH_FOR_DATE' as const;

// Actions
export const getMatchesForDate = (payload: { sportId: SportId; date: string }) => ({
  type: GET_MATCHES_FOR_DATE,
  payload,
});

export const setMatchesForDate = (payload: {
  data: MatchesResponse;
  date: string;
  sportId: SportId;
}) => ({ type: SET_MATCHES_FOR_DATE, payload });

export const updateMatchForDate = (payload: { data: MatchUpdateResponse }) => ({
  type: UPDATE_MATCH_FOR_DATE,
  payload,
});
