/* eslint-disable camelcase */

import {
  ContestStyle,
  ContestStyleResponse,
  PickSlot,
  PickSlotResponse,
} from '@/interfaces/drafting-config';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

export const pickSlotAdapter = (pickSlot: PickSlotResponse): PickSlot => {
  const { id, slot_id } = pickSlot;

  return {
    id,
    slotId: slot_id.toString(),
  };
};

const contestStyleAdapter = (contestStyle: ContestStyleResponse): ContestStyle => {
  const {
    id,
    best_ball,
    display_scoring_guide_ids,
    name,
    pick_slots,
    rounds,
    rules_url,
    scoring_type_id,
    sport_id,
    status,
  } = contestStyle;

  const pickSlots = arrayToObjectIdKeys(pick_slots.map((pickSlot) => pickSlotAdapter(pickSlot)));

  return {
    id,
    bestBall: best_ball,
    displayScoringGuideIds: display_scoring_guide_ids,
    name,
    pickSlots,
    rounds,
    rulesUrl: rules_url,
    scoringTypeId: scoring_type_id,
    sportId: sport_id,
    status,
  };
};

export default contestStyleAdapter;
