/* eslint-disable camelcase */
import { LobbyItem, LobbyItemResponse } from '@/interfaces/lobbies';

export const lobbyItemAdapter = (lobbyItem: LobbyItemResponse): LobbyItem => {
  const { id, item_id, item_type, rank } = lobbyItem;

  return {
    id,
    itemId: item_id,
    itemType: item_type,
    rank,
  };
};
