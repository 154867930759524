/* eslint-disable camelcase */
import { EntrySlipRivalLine, EntrySlipRivalLineResponse } from '@/interfaces/pick-em';

const entrySlipRivalLineAdapter = (rivalLine: EntrySlipRivalLineResponse): EntrySlipRivalLine => {
  const { id, live_event, live_event_stats, rank, rival_id, stat_value, status } = rivalLine;

  return {
    id,
    liveEvent: live_event,
    liveEventStats: live_event_stats,
    rank,
    rivalId: rival_id,
    statValue: stat_value,
    status,
  };
};

export default entrySlipRivalLineAdapter;
