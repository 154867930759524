import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '@/store';
import {
  addFavoritePickEmPlayer as addFavoritePickEmPlayerAction,
  removeFavoritePickEmPlayer as removeFavoritePickEmPlayerAction,
} from '@/store/modules/pick-em/actions';

import {
  addFavoritePickEmPlayer as addFavoritePickEmPlayerApi,
  removeFavoritePickEmPlayer as removeFavoritePickEmPlayerApi,
} from '@/api/pick-em';
import Icon from '@/components/atoms/icon';
import { FavoritePlayer, FeaturedLobbyFavoritePlayer } from '@/interfaces/pick-em';
import errorLogger from '@/utilities/errors/logger';

import styles from './styles.scss';

export interface FavoriteButtonProps {
  favoritePlayers: FeaturedLobbyFavoritePlayer[];
  appearanceId: string;
  player: FavoritePlayer;
  iconClassName?: string;
  numberOfActiveLines?: number;
  addFavoritePickEmPlayer?: (player: FeaturedLobbyFavoritePlayer) => void;
  removeFavoritePickEmPlayer?: (playerId: string) => void;
}

const FavoriteButton = (props: FavoriteButtonProps) => {
  const {
    player,
    appearanceId,
    favoritePlayers,
    iconClassName,
    numberOfActiveLines,
    addFavoritePickEmPlayer,
    removeFavoritePickEmPlayer,
  } = props;

  const isPlayerInFavorites = useMemo(
    () => favoritePlayers?.some((favoritePlayer) => favoritePlayer.id === player.id),
    [favoritePlayers, player.id]
  );

  const handleOnFavoriteClick =
    addFavoritePickEmPlayer && removeFavoritePickEmPlayer
      ? (thisPlayer: FavoritePlayer) => {
          if (!thisPlayer) {
            errorLogger(true, 'handleOnFavoriteClick: thisPlayer could not be favorited');
          }
          if (isPlayerInFavorites) {
            removeFavoritePickEmPlayerApi(thisPlayer.id)
              .catch((e) => {
                // this will hardly ever fire because there's no checks on the api side
                errorLogger(true, `removeFavoritePickEmPlayer: ${e}`);
              })
              .finally(() => {
                removeFavoritePickEmPlayer(thisPlayer.id);
              });
          }
          if (!isPlayerInFavorites) {
            addFavoritePickEmPlayerApi(thisPlayer.id)
              .catch((e) => {
                // this will hardly ever fire because there's no checks on the api side
                errorLogger(true, `addFavoritePickEmPlayer: ${e}`);
              })
              .finally(() => {
                addFavoritePickEmPlayer({
                  id: thisPlayer.id,
                  appearanceIds: [appearanceId],
                  adaptedPlayer: thisPlayer,
                  numberOfActiveLines: numberOfActiveLines || 0,
                });
              });
          }
        }
      : null;

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        handleOnFavoriteClick(player);
      }}
      aria-label={isPlayerInFavorites ? 'Remove favorite' : 'Add favorite'}
    >
      <Icon
        name={isPlayerInFavorites ? 'favorite' : 'favoriteOutline'}
        className={clsx(styles.favoriteIcon, iconClassName || {})}
      />
    </button>
  );
};

export default connect(
  (state: RootState) => ({
    favoritePlayers: state.pickEmOverUnder.featuredLobby.favoritePlayers,
  }),
  (dispatch) => ({
    addFavoritePickEmPlayer: (player: FeaturedLobbyFavoritePlayer) =>
      dispatch(addFavoritePickEmPlayerAction({ player })),
    removeFavoritePickEmPlayer: (playerId: string) =>
      dispatch(removeFavoritePickEmPlayerAction({ playerId })),
  })
)(FavoriteButton);
