/* eslint-disable camelcase */
import { LobbyDraftPool, LobbyDraftPoolResponse } from '@/interfaces/lobbies';

import lobbySlateAdapter from './lobby-slate';

const lobbyDraftPoolAdapter = (lobbyDraftPool: LobbyDraftPoolResponse): LobbyDraftPool => {
  const {
    id,
    contest_style_id,
    cutoff_at,
    description,
    draft_entry_style_id,
    entry_count,
    entry_role,
    entry_style_id,
    image_url,
    max_entries,
    rules_url,
    slate_id,
    slate: slateResponse,
    status,
    title,
  } = lobbyDraftPool;

  const slate = slateResponse && lobbySlateAdapter(slateResponse);

  return {
    id,
    contestStyleId: contest_style_id,
    cutoffAt: cutoff_at,
    description,
    draftEntryStyleId: draft_entry_style_id,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    imageUrl: image_url,
    maxEntries: max_entries,
    rulesUrl: rules_url,
    slateId: slate_id,
    slate,
    status,
    title,
  };
};

export default lobbyDraftPoolAdapter;
