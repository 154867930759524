import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import {
  getDraft,
  getDraftWeeklyScores,
  setDraft,
  setDraftWeeklyScores,
} from '@/store/modules/drafts/actions';
import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/drafts';
import { DraftResponse } from '@/interfaces/drafts';
import errorLogger from '@/utilities/errors/logger';

export const getDraftSaga = function* getDraftSaga(
  action: ReturnType<typeof getDraft>
): SagaIterator {
  try {
    const response = yield call(api.getDraft, action.payload);
    const { data }: { data: DraftResponse } = response;

    yield put(setDraft(data));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
    errorLogger(true, `getDraftSaga: ${e}`);
  }
};

export const getDraftWeeklyScoresSaga = function* getDraftWeeklyScoresSaga(
  action: ReturnType<typeof getDraftWeeklyScores>
): SagaIterator {
  try {
    const response = yield call(api.getDraftWeeklyScores, action.payload);

    yield put(setDraftWeeklyScores({ data: response.data, draftId: action.payload.draftId }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
