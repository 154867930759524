import React from 'react';
import clsx from 'clsx';

import styles from './styles.scss';

export interface YourPrivacyChoicesProps {
  className?: string;
  label: string | JSX.Element;
}

const YourPrivacyChoicesLink = (props: YourPrivacyChoicesProps) => {
  const { className, label } = props;

  const onClick = () => {
    if (window.DG_BANNER_API) {
      window.DG_BANNER_API.showConsentBanner();
    }
  };

  return (
    <button className={clsx(styles.ypcButton, className)} onClick={onClick}>
      {label}
    </button>
  );
};

export default YourPrivacyChoicesLink;
