import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/completed';

import { setCompletedSlates } from '../actions';

export const getCompletedSlatesSaga = function* getCompletedSlatesSaga(): SagaIterator {
  try {
    const response = yield call(api.getCompletedSlates);

    yield put(setCompletedSlates(response.data));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
