import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { ServiceContainerProvider } from '@lib/service-container/context';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { updateDraftEntryAutoPickStatus as updateDraftEntryAutoPickStatusAction } from '@/store/modules/active-drafts/actions';
import { getGlobalFeatureFlags as getGlobalFeatureFlagsAction } from '@/store/modules/feature-flags/actions';
import { getStateConfigurations as getStateConfigurationsAction } from '@/store/modules/state-configuration/actions';
import {
  getUserBonusWallet as getUserBonusWalletAction,
  getUserStateConfig as getUserStateConfigAction,
  setUserRegistrationSource as setUserRegistrationSourceAction,
  updateUserBonusCash as updateUserBonusCashAction,
  updateUserIdStatus as updateUserIdStatusAction,
  updateUserWallet as updateUserWalletAction,
} from '@/store/modules/user/actions';

import geoService from '@/utilities/location/geocomply';

import { getIntercomHash } from './api/intercom';
import { type HandleCloseModal, useModal } from './components/atoms/modal';
import { useBranchContext } from './components/contexts/branch';
import { initPusher, subscribeToPrivateChannels } from './components/contexts/pusher-events';
import { useThemeContext } from './components/contexts/theme';
import DialogModal from './components/molecules/dialog-modal';
import LocationModal from './components/molecules/location-modal';
import type { AutoPickToggleResponse } from './interfaces/drafts';
import type { FeatureFlags } from './interfaces/feature-flags';
import type { AllStateConfigurations } from './interfaces/state-configuration';
import type {
  BonusBalanceUpdateResponse,
  IdentificationStatusChangeResponse,
  RegistrationSourceResponse,
  User,
  WalletBalanceUpdateResponse,
} from './interfaces/user';
import { appServiceContainer } from './service-containers/app/container';
import { useLocation, useNavigate } from './services/router/service';
import { getFeatureFlagFromLocalStorage } from './store/modules/feature-flags/utils';
import { identifyAmplitudeUser, loadAmplitude } from './utilities/amplitude';
import { experiment } from './utilities/amplitude/experiment';
import { UD_DEVICE_THEME, UD_LOCATION_TOKEN } from './utilities/constants';
import errorLogger from './utilities/errors/logger';
import {
  checkLocationPermissionsGranted,
  watchLocationPermissionChanges,
} from './utilities/location';
import type { RootState } from './store';

loadAmplitude();

if (window.Intercom) {
  window.Intercom('boot', {
    app_id: process.env.INTERCOM_APP_ID,
  });
}

watchLocationPermissionChanges();

export type AppServicesProps = React.PropsWithChildren<{
  featureFlags: FeatureFlags;
  getGlobalFeatureFlags: () => void;
  getStateConfigurations: () => void;
  getUserBonusWallet: () => void;
  getUserStateConfig: () => void;
  setUserRegistrationSource: (data: RegistrationSourceResponse) => void;
  stateConfigurations: AllStateConfigurations;
  updateUserIdStatus: (data: IdentificationStatusChangeResponse) => void;
  updateUserWallet: (data: WalletBalanceUpdateResponse) => void;
  updateUserBonusCash: (data: BonusBalanceUpdateResponse) => void;
  updateDraftEntryAutoPickStatus: (data: AutoPickToggleResponse) => void;
  user: User;
}>;

const AppServices = ({
  children,
  featureFlags,
  getGlobalFeatureFlags,
  getStateConfigurations,
  getUserBonusWallet,
  getUserStateConfig,
  setUserRegistrationSource,
  stateConfigurations,
  updateUserIdStatus,
  updateUserWallet,
  updateUserBonusCash,
  updateDraftEntryAutoPickStatus,
  user,
}: AppServicesProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const branch = useBranchContext();
  const openModal = useModal();
  const theme = useThemeContext();
  const { updateTheme } = theme;

  const isOnDraftPage = /^\/draft\/([a-zA-Z]?[0-9]?-?)*/.test(location.pathname);
  const isFeatureFlagsLoaded = Object.keys(featureFlags).length > 0;
  const isMobilePage = /^\/m\//.test(location.pathname) || /^\/splash\/m\//.test(location.pathname);

  // note: this function is a candidate for moving somewhere else, only relevant for drafts
  const handleAutoAutoPilotModal = useCallback((data: AutoPickToggleResponse) => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <DialogModal
        handleCloseModal={handleCloseModal}
        title="Autopilot notice"
        content={
          <p>
            Heads up! Autopilot has been enabled because you&apos;ve missed two consecutive picks.
            You can turn it off at any time from inside the draft room.
          </p>
        }
        confirmText="Go to draft"
        confirmAction={() => navigate(`active/${data.draft_id}`)}
        dismissText="Ok"
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this effect needs `window.Intercom` to be initialized
  // note: candidate for extraction elsewhere
  useEffect(() => {
    if (window.Intercom) {
      if (
        isMobilePage ||
        location.pathname === '/' ||
        location.pathname === '/legality-underdog-contests' ||
        location.pathname === '/guarddog' ||
        location.pathname === '/splash/jack' ||
        location.pathname.startsWith('/draft') ||
        location.pathname.startsWith('/active')
      ) {
        window.Intercom('update', { hide_default_launcher: true });
      } else {
        window.Intercom('update', { hide_default_launcher: false });
      }
    }
  }, [isMobilePage, location.pathname]);

  useEffect(() => {
    const isSiftFeatureEnabled = getFeatureFlagFromLocalStorage('sift');
    if (isSiftFeatureEnabled && window._sift) {
      const siftBeacon = process.env.APP_ENV === 'production' ? 'a91e4ccf57' : '266433fe52';
      window._sift.push(['_setUserId', '']);
      window._sift.push(['_setAccount', siftBeacon]);
      window._sift.push(['_setSessionId', uuidv4()]);
    }
  }, []);

  useEffect(() => {
    const userId = user?.id;
    const registrationSource = user?.registrationSource;

    identifyAmplitudeUser({ userId, registrationSource });
  }, [user.id, user.registrationSource]);

  useEffect(() => {
    experiment.fetch();
  }, [user.id]);

  useEffect(() => {
    if (user?.id) {
      const { secondaryPusherEnv, tertiaryPusherEnv } = featureFlags;
      const pusherEnvs = {
        secondary: secondaryPusherEnv,
        tertiary: tertiaryPusherEnv,
      };
      initPusher(pusherEnvs);
      const privateChannel = subscribeToPrivateChannels(user.id);
      privateChannel.bind(
        'identification_status_change',
        (data: IdentificationStatusChangeResponse) => {
          updateUserIdStatus(data);
        }
      );
      privateChannel.bind('balance_update', (data: WalletBalanceUpdateResponse) => {
        updateUserWallet(data);
      });
      privateChannel.bind(
        'bonus_wallet_balance_update',
        async (data: BonusBalanceUpdateResponse) => {
          updateUserBonusCash(data);
          getUserBonusWallet();
        }
      );
      privateChannel.bind('auto_pick_enabled', (data: AutoPickToggleResponse) => {
        updateDraftEntryAutoPickStatus(data);
        if (data.auto_pick === 'system') {
          handleAutoAutoPilotModal(data);
        }
      });
      privateChannel.bind('registration_acquisition', (data: RegistrationSourceResponse) => {
        setUserRegistrationSource(data);
      });
    }
  }, [
    featureFlags,
    getUserBonusWallet,
    handleAutoAutoPilotModal,
    setUserRegistrationSource,
    updateDraftEntryAutoPickStatus,
    updateUserBonusCash,
    updateUserIdStatus,
    updateUserWallet,
    user?.id,
  ]); // needs to be user?.id with the ?

  useEffect(() => {
    if (user?.id) {
      // Note: this direct import and usage of the service container is temporary.
      // Eventually, we'll move all braze service initialization and updates to the
      // service container. No more useEffects like this one.
      // But we need to wait until we move user resolving to a non-react-bound auth service
      // that can be used in the service container.
      appServiceContainer.getServiceAsync('braze').then((brazeService) => {
        brazeService.changeUser(user.id);
        brazeService.openSession();
      });

      if (datadogRum) {
        datadogRum.setUserProperty('id', user.id);
      }

      if (featureFlags.sift && window._sift) {
        window._sift.push(['_setUserId', user.id]);
      }
    }
  }, [featureFlags.sift, user?.id]);

  useEffect(() => {
    if (user?.id && window.Intercom) {
      getIntercomHash()
        .then((response) => {
          window.Intercom('update', {
            user_id: user.id,
            username: user.username,
            email: user.email,
            user_hash: response.data.hashed_id,
          });
        })
        .catch((err) => {
          errorLogger(true, err);
        });
    }
  }, [user?.id, user?.username, user?.email]);

  useEffect(() => {
    const enableLocalLogging = false; // toggle this to send local errors to datadog

    if (process.env.APP_ENV === 'production' || enableLocalLogging) {
      datadogRum.init({
        beforeSend: (event) => {
          if (event?.type === 'error') {
            const message = event?.error?.message;
            if (message?.includes('/v1/usernames')) return false;
            if (message?.includes('/v1/promotions/promo_code')) return false;
            if (message?.includes('/v1/promotions/user_referral')) return false;
            return true;
          }
          return true;
        },
        applicationId: process.env.DATADOG_APPLICATION_ID,
        clientToken: 'pubd3c63a4b58fa5f1c43894daefa6a606c',
        site: 'datadoghq.com',
        service: 'web-app',
        env: enableLocalLogging ? 'dev' : 'production', // always 'dev' or 'production'
        version: process.env.CLIENT_VERSION, // needs to match sourcemap upload
        sessionSampleRate: 100, // monitor 100% user sessions
        sessionReplaySampleRate: 1, // collect 1% replay sessions
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input', // only hide user input
      });

      if (process.env.APP_ENV === 'production') {
        datadogRum.startSessionReplayRecording();
      }
    }
  }, []);

  useEffect(() => {
    const enableLocalLogging = false; // toggle this to send local info logs to datadog

    datadogLogs.init({
      clientToken: process.env.DATADOG_BROWSER_LOG_API_KEY,
      forwardErrorsToLogs: false, // don't send errors, rely on RUM for that
      sessionSampleRate: 100,
      env: process.env.APP_ENV === 'production' ? 'production' : 'staging',
      version: process.env.CLIENT_VERSION || 'development',
      service: 'web-app',
      site: 'datadoghq.com',
      beforeSend: (event: any) => {
        // only send messages to datadog if not in a 'local' environment.
        // and if in local, log the message to the console for visibility.
        // IMPORTANT: do _not_ use `console.error` or `console.warn` in this function,
        // doing so will cause an infinite loop because the datadog client is configured
        // to forward console errors/warnings to datadog. So if you use console.error/warn
        // here, it will be captured by the logger and run the `beforeSend` function ad infinitum.
        if (process.env.APP_ENV !== 'production' && !enableLocalLogging) {
          // eslint-disable-next-line no-console
          console.log('not sending log message to datadog because env is local', event);

          return false;
        }
        return true;
      },
    });
  }, []);

  useEffect(() => {
    if (user?.id) {
      checkLocationPermissionsGranted().then((permissionGranted) => {
        // if it's a mobile page and we have the location cookie, we don't need to do this
        // if the user sits here too long though, it will error
        if (!(isMobilePage && Cookies.get(UD_LOCATION_TOKEN))) {
          if (permissionGranted) {
            geoService.init({ userId: user.id });
          } else {
            openModal(({ handleCloseModal }: HandleCloseModal) => (
              <LocationModal
                handleCloseModal={handleCloseModal}
                confirmAction={async () => geoService.init({ userId: user.id })}
                errorContext={{ location: 'app.tsx' }}
              />
            ));

            if ('permissions' in navigator) {
              navigator.permissions.query({ name: 'geolocation' }).then((geolocationPerm) => {
                // eslint-disable-next-line no-param-reassign
                geolocationPerm.onchange = () => {
                  if (geolocationPerm.state === 'granted') {
                    window.location.reload();
                  }
                };
              });
            }
          }
        }
      });
    }
  }, [isMobilePage, openModal, user?.id]);

  useEffect(() => {
    if (!isFeatureFlagsLoaded) {
      getGlobalFeatureFlags();
    }
  }, [getGlobalFeatureFlags, isFeatureFlagsLoaded]);

  // TODO [FAN-1146] Currently we aren't refetching the state config without a refresh if the user disables
  // and then reenables location, so this will be handled in a later ticket (see ticket for more details)
  useEffect(() => {
    if (user?.id && !user?.stateConfig) {
      getUserStateConfig();
    }
  }, [getUserStateConfig, user?.id, user?.stateConfig]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (branch?.branchData?.data?.route === 'draft' && !isOnDraftPage && user?.id) {
      const objectId = branch?.branchData?.data?.objectId;
      // clear the route prop so that this doesn't accidentally force a loop
      branch.setBranchData({
        data: {
          ...branch.branchData?.data,
          route: null,
        },
        error: branch.branchData?.error,
      });
      if (objectId) {
        navigate(`/draft/${objectId}`);
      }
    }

    if (branch?.branchData?.data?.route === 'entry_slip' && user?.id) {
      const objectId = branch?.branchData?.data?.objectId;
      // clear the route prop so that this doesn't accidentally force a loop
      branch.setBranchData({
        data: {
          ...branch.branchData?.data,
          route: null,
        },
        error: branch.branchData?.error,
      });
      if (objectId) {
        navigate(`/pick-em/higher-lower?entrySlipShareId=${objectId}`);
      }
    }
  }, [branch, navigate, isOnDraftPage, user?.id]);

  useEffect(() => {
    if (window.Socure) {
      // this invalidates the temporary socure token (kills status events in network tab)
      // important in case use leaves the doc upload flow in middle of process
      window.Socure.cleanup();
      delete window.Socure;
    }

    if (window._sift) {
      window._sift.push(['_trackPageview']);
    }
  }, [location.pathname]);

  useEffect(() => {
    const isStateConfigEmpty = Object.keys(stateConfigurations).length > 0;
    if (!isStateConfigEmpty) {
      getStateConfigurations();
    }
  }, [getStateConfigurations, stateConfigurations]);

  const handleDeviceSettingsUpdate = useCallback(
    (event: MediaQueryListEvent) => {
      const deviceTheme = localStorage.getItem(UD_DEVICE_THEME);
      if (deviceTheme === 'system') {
        updateTheme(event.matches ? 'dark' : 'light');
      }
    },
    [updateTheme]
  );

  useEffect(() => {
    const browserTheme = window.matchMedia('(prefers-color-scheme: dark)');
    browserTheme.addEventListener('change', handleDeviceSettingsUpdate);
    return () => browserTheme.removeEventListener('change', handleDeviceSettingsUpdate);
  }, [handleDeviceSettingsUpdate]);

  return (
    <ServiceContainerProvider container={appServiceContainer}>{children}</ServiceContainerProvider>
  );
};

export default connect(
  (state: RootState) => ({
    featureFlags: state.featureFlags,
    stateConfigurations: state.stateConfigurations,
    user: state.user,
  }),
  (dispatch) => ({
    getGlobalFeatureFlags: () => dispatch(getGlobalFeatureFlagsAction()),
    getStateConfigurations: () => dispatch(getStateConfigurationsAction()),
    getUserBonusWallet: () => dispatch(getUserBonusWalletAction()),
    getUserStateConfig: () => dispatch(getUserStateConfigAction()),
    setUserRegistrationSource: (data: RegistrationSourceResponse) =>
      dispatch(setUserRegistrationSourceAction(data)),
    updateUserIdStatus: (data: IdentificationStatusChangeResponse) =>
      dispatch(updateUserIdStatusAction(data)),
    updateUserWallet: (data: WalletBalanceUpdateResponse) => dispatch(updateUserWalletAction(data)),
    updateUserBonusCash: (data: BonusBalanceUpdateResponse) =>
      dispatch(updateUserBonusCashAction(data)),
    updateDraftEntryAutoPickStatus: (data: AutoPickToggleResponse) =>
      dispatch(updateDraftEntryAutoPickStatusAction(data)),
  })
)(AppServices);
