import { EntryCollection } from 'contentful';

import { ICareersFaqFields } from '@/interfaces/contentful';
import { UnderdogCareersFaq } from '@/interfaces/underdog-careers-faqs';

import { SET_UNDERDOG_CAREERS_FAQS, setUnderdogCareersFaqs } from '../actions';
import careersFaqAdapter from '../adapters/careers-faq';

type State = UnderdogCareersFaq[] | null;

type UnderdogCareersFaqsActions = ReturnType<typeof setUnderdogCareersFaqs>;

const initialState: UnderdogCareersFaq[] = null;

const set = ({
  contentfulCareersFaqs,
}: {
  contentfulCareersFaqs: EntryCollection<ICareersFaqFields>;
}): UnderdogCareersFaq[] => {
  const faqs = contentfulCareersFaqs.items.map(careersFaqAdapter);

  return faqs;
};

export const underdogCareersFaqReducer = (
  state: State = initialState,
  action: UnderdogCareersFaqsActions = {} as UnderdogCareersFaqsActions
): State => {
  switch (action.type) {
    case SET_UNDERDOG_CAREERS_FAQS:
      return set(action.payload);
    default:
      return state;
  }
};
