/* eslint-disable camelcase */
import {
  PayoutOption,
  PoolV1Style,
  PoolV1StyleOption,
  PoolV1StyleOptionResponse,
  PoolV1StyleResponse,
} from '@/interfaces/pick-em';

const poolV1StyleAdapter = (poolV1StyleResponse: PoolV1StyleResponse): PoolV1Style => {
  const { id, title, active, fees, options, prize_percentage } = poolV1StyleResponse;

  const adaptedOptions = options.map((option: PoolV1StyleOptionResponse) => {
    const {
      entry_count,
      fee,
      insured_payout_option,
      max_entries,
      payout_option,
      prizes,
      prize_percentage: optionPrizePercentage,
      selection_count,
    } = option;

    let adaptedInsuredPayoutOption: PayoutOption = null;
    if (insured_payout_option) {
      adaptedInsuredPayoutOption = {
        id: insured_payout_option.id,
        maxFee: insured_payout_option.max_fee,
        minMultiplier: insured_payout_option.min_multiplier,
        multiplier: insured_payout_option.multiplier,
        selectionCount: insured_payout_option.selection_count,
      };
    }

    let adaptedPayoutOption: PayoutOption = null;
    if (payout_option) {
      adaptedPayoutOption = {
        id: payout_option.id,
        maxFee: payout_option.max_fee,
        minMultiplier: payout_option.min_multiplier,
        multiplier: payout_option.multiplier,
        selectionCount: payout_option.selection_count,
      };
    }

    const adaptedPrizes: PoolV1StyleOption['prizes'] = {};
    if (prizes) {
      const prizeKeys = Object.keys(prizes);
      prizeKeys.forEach((key: string) => {
        adaptedPrizes[key] = {
          default: {
            maxPayout: prizes[key].default.max_payout,
            avgPayout: prizes[key].default.avg_payout,
          },
          insured: {
            maxPayout: prizes[key]?.insured?.max_payout,
            avgPayout: prizes[key]?.insured?.avg_payout,
          },
        };
      });
    }

    return {
      id: option.id,
      entryCount: entry_count,
      fee,
      insuredPayoutOption: adaptedInsuredPayoutOption,
      maxEntries: max_entries,
      payoutOption: adaptedPayoutOption,
      prizes: adaptedPrizes,
      prizePercentage: optionPrizePercentage,
      selectionCount: selection_count,
    };
  });

  return {
    id,
    active,
    default: poolV1StyleResponse.default,
    fees,
    options: adaptedOptions,
    prizePercentage: prize_percentage,
    title,
  };
};

export default poolV1StyleAdapter;
