/* eslint-disable camelcase */
import { LobbyTournamentRound, LobbyTournamentRoundResponse } from '@/interfaces/lobbies';

const tournamentRoundAdapter = (
  lobbyTournamentRound: LobbyTournamentRoundResponse
): LobbyTournamentRound => {
  const {
    abbr,
    description,
    draft_at,
    entry_style_id,
    id,
    number,
    slate_id,
    prize_breakdown_title,
    status,
    title,
  } = lobbyTournamentRound;

  return {
    abbr,
    description,
    draftAt: draft_at,
    entryStyleId: entry_style_id,
    id,
    number,
    prizeBreakdownTitle: prize_breakdown_title,
    slateId: slate_id,
    status,
    title,
  };
};

export default tournamentRoundAdapter;
