import { EntryCollection } from 'contentful';

import { IStateConfigsFields } from '@/interfaces/contentful';

export const GET_LANDING_PAGE_CONFIGS = 'config/GET_LANDING_PAGE_CONFIGS' as const;
export const SET_LANDING_PAGE_CONFIGS = 'config/SET_LANDING_PAGE_CONFIGS' as const;

export const getLandingPageConfigs = (payload: { title: string }) => ({
  type: GET_LANDING_PAGE_CONFIGS,
  payload,
});

export const setLandingPageConfigs = (payload: {
  landingPageConfigs: EntryCollection<IStateConfigsFields> | Record<string, never>;
}) => ({ type: SET_LANDING_PAGE_CONFIGS, payload });
