import {
  EntryCountUpdate,
  LobbyDraftPool,
  MainLobby,
  MainLobbyResponse,
  SlateSitAndGoResponse,
} from '@/interfaces/lobbies';

import {
  REMOVE_LOBBY_DRAFT_POOL,
  REMOVE_LOBBY_TOURNAMENT,
  REMOVE_LOBBY_WEEKLY_WINNER,
  REMOVE_SLATE_SIT_AND_GOS,
  removeLobbyDraftPool,
  removeLobbyTournament,
  removeLobbyWeeklyWinner,
  removeSlateSitAndGos,
  SET_MAIN_LOBBY,
  setMainLobby,
  UPDATE_DRAFT_POOL_COUNT,
  UPDATE_SLATE_SIT_AND_GO_COUNT,
  UPDATE_TOURNAMENT_COUNT,
  UPDATE_WEEKLY_WINNER_COUNT,
  updateDraftPoolCount,
  updateSlateSitAndGoCount,
  updateTournamentCount,
  updateWeeklyWinnerCount,
} from '../actions';
import mainLobbyAdapter from '../adapters/main-lobby';
import slateSitAndGosAdapter from '../adapters/slate-sit-and-gos';

type State = MainLobby;

type LobbiesActions = ReturnType<
  | typeof setMainLobby
  | typeof removeLobbyDraftPool
  | typeof removeSlateSitAndGos
  | typeof removeLobbyTournament
  | typeof removeLobbyWeeklyWinner
  | typeof updateSlateSitAndGoCount
  | typeof updateTournamentCount
  | typeof updateDraftPoolCount
  | typeof updateWeeklyWinnerCount
>;

const initialMainLobbyState: MainLobby = {
  id: null,
  draftPools: [],
  lobbyItems: {},
  slateSitAndGos: [],
  lobbySlates: [],
  tournaments: [],
  weeklyWinners: [],
};

// TODO [FAN-2426]: write quite a few tests for this if I end up using it in production
const set = ({ data }: { data: MainLobbyResponse }): any => mainLobbyAdapter({ lobby: data.lobby });

const removeSlateSngs = (
  state: MainLobby,
  { slateSitAndGos }: { slateSitAndGos: SlateSitAndGoResponse[] }
): MainLobby => {
  const filteredSlateSitAndGos = state.slateSitAndGos
    ? state.slateSitAndGos.filter((stateSsng) => {
        if (!slateSitAndGos || slateSitAndGos.length === 0) return true;
        // eslint-disable-next-line max-len
        return slateSitAndGos.find((slateSitAndGo) => {
          if (!slateSitAndGo.sit_and_go_id) return true;
          return slateSitAndGo.sit_and_go_id !== stateSsng.sitAndGoId;
        });
      })
    : state.slateSitAndGos;

  return {
    ...state,
    slateSitAndGos: filteredSlateSitAndGos,
  };
};

const removeTournament = (
  state: MainLobby,
  { tournamentId }: { tournamentId: string }
): MainLobby => {
  const filteredTournaments = state.tournaments.filter(
    (tournament) => tournament.id !== tournamentId
  );

  return {
    ...state,
    tournaments: filteredTournaments,
  };
};

const removeWeeklyWinner = (
  state: MainLobby,
  { weeklyWinnerId }: { weeklyWinnerId: string }
): MainLobby => {
  const filteredWeeklyWinners = state.weeklyWinners.filter(
    (weeklyWinner) => weeklyWinner.id !== weeklyWinnerId
  );

  return {
    ...state,
    weeklyWinners: filteredWeeklyWinners,
  };
};

const updateEntryCount = (
  state: MainLobby,
  { slateSitAndGos }: { slateSitAndGos: SlateSitAndGoResponse[] }
): MainLobby => {
  const adaptedSitAndGos = slateSitAndGosAdapter(slateSitAndGos);
  const updatedSlateSitAndGos = state.slateSitAndGos
    ? state.slateSitAndGos.map((stateSsng) => {
        const newSlateSitAndGo = adaptedSitAndGos.find(
          (adaptedSitAndGo) =>
            adaptedSitAndGo.sitAndGoId === stateSsng.sitAndGoId &&
            adaptedSitAndGo.slateId === stateSsng.slateId
        );
        if (newSlateSitAndGo) {
          return newSlateSitAndGo;
        }
        return stateSsng;
      })
    : state.slateSitAndGos;

  return {
    ...state,
    slateSitAndGos: updatedSlateSitAndGos,
  };
};

const tournamentCount = (state: MainLobby, payload: EntryCountUpdate): MainLobby => {
  const { tournamentId, entryCount, status } = payload;
  const updatedTournaments = state.tournaments
    .filter((tournament) => !(status === 'filled' && tournament.id === tournamentId))
    .map((tournament) => {
      if (tournament.id === tournamentId) {
        return {
          ...tournament,
          entryCount,
        };
      }
      return tournament;
    });

  return {
    ...state,
    tournaments: updatedTournaments,
  };
};

const draftPoolCount = (state: MainLobby, payload: EntryCountUpdate): MainLobby => {
  const { draftPoolId, entryCount, status } = payload;
  const updatedDraftPools = state.draftPools
    .filter((draftPool) => !(status === 'filled' && draftPool.id === draftPoolId))
    .map((draftPool) => {
      if (draftPool.id === draftPoolId) {
        return {
          ...draftPool,
          entryCount,
        };
      }
      return draftPool;
    });

  return {
    ...state,
    draftPools: updatedDraftPools,
  };
};

const weeklyWinnerCount = (state: MainLobby, payload: EntryCountUpdate): MainLobby => {
  const { weeklyWinnerId, entryCount, status } = payload;
  const updatedWeeklyWinners = state.weeklyWinners
    .filter((weeklyWinner) => !(status === 'filled' && weeklyWinner.id === weeklyWinnerId))
    .map((weeklyWinner) => {
      if (weeklyWinner.id === weeklyWinnerId) {
        return {
          ...weeklyWinner,
          entryCount,
        };
      }
      return weeklyWinner;
    });

  return {
    ...state,
    weeklyWinners: updatedWeeklyWinners,
  };
};

const removeDraftPool = (
  state: MainLobby,
  {
    draftPoolId,
  }: {
    draftPoolId: string;
  }
): MainLobby => {
  const filteredDraftPools = state.draftPools.filter(
    (draftPool: LobbyDraftPool) => draftPool.id !== draftPoolId
  );

  return {
    ...state,
    draftPools: filteredDraftPools,
  };
};

export const mainLobbyReducer = (
  state: State = initialMainLobbyState,
  action: LobbiesActions = {} as LobbiesActions
): State => {
  switch (action.type) {
    case SET_MAIN_LOBBY:
      return set(action.payload);
    case REMOVE_LOBBY_DRAFT_POOL:
      return removeDraftPool(state, action.payload);
    case REMOVE_SLATE_SIT_AND_GOS:
      return removeSlateSngs(state, action.payload);
    case REMOVE_LOBBY_TOURNAMENT:
      return removeTournament(state, action.payload);
    case REMOVE_LOBBY_WEEKLY_WINNER:
      return removeWeeklyWinner(state, action.payload);
    case UPDATE_SLATE_SIT_AND_GO_COUNT:
      return updateEntryCount(state, action.payload);
    case UPDATE_TOURNAMENT_COUNT:
      return tournamentCount(state, action.payload);
    case UPDATE_DRAFT_POOL_COUNT:
      return draftPoolCount(state, action.payload);
    case UPDATE_WEEKLY_WINNER_COUNT:
      return weeklyWinnerCount(state, action.payload);
    default:
      return state;
  }
};
