import React, { MouseEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import clsx from 'clsx';

import { RootState } from '@/store';
import { getContentfulPowerUpInfo as getContentfulPowerUpInfoAction } from '@/store/modules/contentful-power-up-infos/actions';
import { closeToast as closeToastAction } from '@/store/modules/toast/actions';

import Button from '@/components/atoms/button';
import Icon from '@/components/atoms/icon';
import Label from '@/components/atoms/label';
import Loader from '@/components/atoms/loader';
import NavLinkWrapper from '@/components/atoms/nav-link-wrapper';
import { useToast } from '@/components/atoms/toast';
import CountdownLabel from '@/components/molecules/countdown-label';
import { ContentfulPowerUpInfos } from '@/interfaces/contentful-power-up-info';
import { AirdropOffer } from '@/interfaces/pick-em';
import { CDN_HOST } from '@/utilities/constants';

import { getOptions } from './options';

import commonStyles from './common-styles.scss';
import styles from './styles.scss';

export interface ContentfulAirdropInfoProps {
  airdrop: AirdropOffer;
  closeToast: () => void;
  contentfulInfoId: string;
  contentfulPowerUpInfos: ContentfulPowerUpInfos;
  getContentfulPowerUpInfoById: ({ contentfulInfoId }: { contentfulInfoId: string }) => void;
  handleCloseModal: () => void;
  onAirdropClaim: (e: MouseEvent<HTMLInputElement>) => void;
}

const ContentfulAirdropInfo = (props: ContentfulAirdropInfoProps) => {
  const {
    airdrop,
    closeToast,
    contentfulInfoId,
    contentfulPowerUpInfos,
    getContentfulPowerUpInfoById,
    onAirdropClaim,
    handleCloseModal,
  } = props;

  const openToast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { daysForClawback, description, expiresAt, title } = airdrop;
  const contentfulPowerUpInfo = contentfulPowerUpInfos?.[contentfulInfoId];

  useEffect(() => {
    if (!contentfulPowerUpInfo) {
      setIsLoading(true);
      getContentfulPowerUpInfoById({
        contentfulInfoId,
      });
    } else {
      setIsLoading(false);
    }
  }, [contentfulPowerUpInfo, getContentfulPowerUpInfoById, contentfulInfoId]);

  if (isLoading || !contentfulPowerUpInfo) {
    return (
      <div className={styles.loadingWrapper}>
        <Loader />
      </div>
    );
  }

  const toastMessage = (
    <p>
      Sorry about that - we&apos;re unable to load the entry details. For more info, check out the{' '}
      <NavLinkWrapper
        to="/rules/promotions"
        classNames={{
          activeNavLink: styles.toastLink,
          navLink: styles.toastLink,
        }}
        element="Promotion rules"
        onClick={closeToast}
      />
      <Icon className={styles.arrowIcon} name="arrowOutward" />
    </p>
  );

  const onAirdropClaimInModal = (e: MouseEvent<HTMLInputElement>) => {
    handleCloseModal();
    onAirdropClaim(e);
  };

  if (!isLoading && !contentfulPowerUpInfo.contentfulInfoId) {
    openToast({
      message: toastMessage,
      open: true,
      showCloseButton: true,
    });
    return null;
  }

  return (
    <>
      <div className={styles.contentfulAirdropInfo}>
        <header className={styles.headerSection}>
          <h1 className={styles.header}>Promos</h1>
          <button className={styles.closeButton} onClick={handleCloseModal}>
            <Icon name="clear" className={styles.icon} />
          </button>
        </header>
        <div className={styles.scrollableSection}>
          <section
            className={clsx(styles.section, styles.heroSection)}
            style={{ backgroundImage: `url(${CDN_HOST}/pickem/bonus-bg.png)` }}
          >
            <img
              className={styles.headerIcon}
              src={`${CDN_HOST}/pickem/airdrop-icon.png`}
              alt="Airdrops icon"
            />
          </section>
          <section className={styles.section}>
            <div className={styles.flexContainer}>
              <h2 className={commonStyles.sectionHeader}>{title}</h2>
              {expiresAt && <CountdownLabel color="light" expiresAt={expiresAt} />}
            </div>
            <p className={commonStyles.sectionText}>{description}</p>
            {daysForClawback && (
              <div className={styles.labelWrapper}>
                <Label
                  classNames={{ label: styles.label }}
                  color="dark"
                  text={`Bonus funds expire ${daysForClawback} days after claiming`}
                  type="neutral"
                />
              </div>
            )}
          </section>
          <section className={styles.section}>
            {documentToReactComponents(contentfulPowerUpInfo.content, getOptions())}
          </section>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          classNames={{
            button: styles.button,
          }}
          color="grey"
          onClick={onAirdropClaimInModal}
          size="small"
          text="Claim bonus"
          type="solid"
          width="full"
        />
      </div>
    </>
  );
};

export default connect(
  (state: RootState) => ({
    contentfulPowerUpInfos: state.contentfulPowerUpInfos,
  }),
  (dispatch) => ({
    closeToast: () => dispatch(closeToastAction()),
    getContentfulPowerUpInfoById: ({ contentfulInfoId }: { contentfulInfoId: string }) =>
      dispatch(getContentfulPowerUpInfoAction({ contentfulInfoId })),
  })
)(ContentfulAirdropInfo);
