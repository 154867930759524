/* eslint-disable camelcase */
import { DraftPool, DraftPoolResponse } from '@/interfaces/draft-pools';

export const draftPoolAdapter = (draftPool: DraftPoolResponse['draft_pool']): DraftPool => {
  const {
    id,
    contest_style_id,
    cutoff_at,
    description,
    draft_entry_style_id,
    entry_count,
    entry_role,
    entry_style_id,
    image_url,
    max_entries,
    rules_url,
    slate_id,
    status,
    title,
  } = draftPool;

  return {
    id,
    contestStyleId: contest_style_id,
    cutoffAt: cutoff_at,
    description,
    draftEntryStyleId: draft_entry_style_id,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    imageUrl: image_url,
    maxEntries: max_entries,
    rulesUrl: rules_url,
    slateId: slate_id,
    status,
    title,
  };
};
