import { ExposureTournament, ExposureTournamentResponse } from '@/interfaces/exposure';

export const exposureTournamentAdapter = (
  tournament: ExposureTournamentResponse
): ExposureTournament => ({
  id: tournament.id,
  contestStyleId: tournament.contest_style_id,
  description: tournament.description,
  entryCount: tournament.entry_count,
  entryStyleId: tournament.entry_style_id,
  imageUrl: tournament.image_url,
  maxEntries: tournament.max_entries,
  rulesUrl: tournament.rules_url,
  title: tournament.title,
});
