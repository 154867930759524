import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import Badge from '@/components/atoms/badge';
import badges from '@/components/atoms/badge/badges';
import Button from '@/components/atoms/button';
import SportBadge from '@/components/atoms/sport-badge';
import TextField, { TextFieldCallback } from '@/components/atoms/text-field';
import {
  ContestStyle,
  EntryStyle,
  Payout,
  RoundPayout,
  ScoringType,
  Slots,
  Sport,
  Teams,
} from '@/interfaces/drafting-config';
import { Source } from '@/interfaces/drafts';
import { Match } from '@/interfaces/matches';
import { TournamentInfo, TournamentRound, TournamentSlates } from '@/interfaces/tournaments';
import { Role } from '@/interfaces/user';
import {
  isBestBall,
  numberToOrdinal,
  numberToTime,
  slotCountsForContest,
} from '@/utilities/helpers';
import langHelper from '@/utilities/lang-helper';

import styles from './styles.scss';

export const EntryInfoSection = ({
  entryStyle,
  currentCount,
}: {
  entryStyle: EntryStyle;
  currentCount: string;
}) => {
  const entryFee = langHelper.formatNumberKM(entryStyle.fee);
  const prize = langHelper.formatNumberKM(
    entryStyle.specialPrize && entryStyle.specialPrizeTotal
      ? entryStyle.specialPrizeTotal
      : entryStyle.prize
  );
  const currentEntrants = currentCount && langHelper.formatNumberKM(currentCount);
  const totalEntrants = langHelper.formatNumber(entryStyle.entryCount);

  return (
    <div className={styles.entryInfoSection}>
      <div className={styles.entryInfo}>
        <p className={styles.entryInfoValue}>${entryFee}</p>
        <p className={styles.entryInfoLabel}>Entry</p>
      </div>
      <div className={styles.entryInfo}>
        <p className={styles.entryInfoValue}>
          {/* only use fraction on sit and gos */}
          {currentEntrants ? `${currentEntrants}/${totalEntrants}` : totalEntrants}
        </p>
        <p className={styles.entryInfoLabel}>Entrants</p>
      </div>
      <div className={styles.entryInfo}>
        <p className={styles.entryInfoValue}>${prize}</p>
        <p className={styles.entryInfoLabel}>Prizes</p>
      </div>
    </div>
  );
};

export const BasicInfoSection = ({
  title,
  children,
  additionalInfo,
}: {
  title: string;
  children: JSX.Element | JSX.Element[];
  additionalInfo?: string;
}) => (
  <div className={styles.basicInfoSection}>
    <h2 className={styles.sectionTitle}>{title}</h2>
    {additionalInfo && <p className={styles.additionalInfo}>{additionalInfo}</p>}
    {children}
  </div>
);

export const InfoUnit = ({ label, value }: { label: string; value: string }) => (
  <div className={styles.info}>
    <p className={styles.infoLabel}>{label}</p>
    <p className={styles.infoValue}>{value}</p>
  </div>
);

export const ScoringSection = ({
  scoringType,
  displayTitle = true,
}: {
  scoringType: ScoringType;
  displayTitle?: boolean;
}) => (
  <div className={styles.scoringSection}>
    {displayTitle && <p className={styles.sectionTitle}>Scoring</p>}
    {scoringType.displayStats.map((displayStat) => {
      if (displayStat.displayGroup) {
        return (
          <React.Fragment key={displayStat.displayGroup}>
            <p className={styles.scoringGroupTitle}>{displayStat.displayGroup}</p>
            {displayStat.stats.map((stat) => (
              <div className={styles.stat} key={stat.name}>
                <p className={styles.statName}>{stat.name}</p>
                <p className={styles.statValue}>{stat.value}</p>
              </div>
            ))}
          </React.Fragment>
        );
      }

      return displayStat.stats.map((stat) => (
        <div className={styles.stat} key={stat.name}>
          <p className={styles.statName}>{stat.name}</p>
          <p className={styles.statValue}>{stat.value}</p>
        </div>
      ));
    })}
  </div>
);

export const MatchesSection = ({
  matches,
  teams,
  contestStyle,
}: {
  matches: Match[];
  teams: Teams;
  contestStyle: ContestStyle;
}) => {
  if (matches?.length && !contestStyle.bestBall && matches[0].type !== 'SoloGame') {
    return (
      <div className={styles.matchesSection}>
        <h2 className={styles.sectionTitle}>Games</h2>
        {matches.map((match) => (
          <div className={styles.match} key={match.id}>
            <p className={styles.matchName}>{langHelper.genericMatchText({ match, teams })}</p>
            <p className={styles.matchValue}>{langHelper.timeToDayOrDate(match?.scheduledAt)}</p>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export const TournamentScheduleSection = ({
  tournamentRounds,
  slates,
}: {
  tournamentRounds: TournamentRound[];
  slates: TournamentSlates;
}) => {
  if (!tournamentRounds || tournamentRounds.length === 0) return null;
  return (
    <div className={styles.tournamentScheduleSection}>
      <h2 className={styles.sectionTitle}>Tournament schedule</h2>
      {tournamentRounds.map((round) => {
        let slateInfo = round.description;
        if (round.slateId && slates[round.slateId] && !slateInfo) {
          slateInfo = slates[round.slateId].description;
        }
        if (!slateInfo) {
          slateInfo = 'TBD';
        }
        return (
          <div className={styles.schedule} key={round.id}>
            <p className={styles.scheduleName}>{round.title}</p>
            <p className={styles.scheduleValue}>{slateInfo}</p>
          </div>
        );
      })}
    </div>
  );
};

export const RosterSection = ({
  contestStyle,
  slots,
}: {
  contestStyle: ContestStyle;
  slots: Slots;
}) => {
  const slotsAndCounts = slotCountsForContest({ contestStyle, slots });
  return (
    <div className={styles.rosterSection}>
      <h2 className={styles.sectionTitle}>Roster</h2>
      {slotsAndCounts.map(({ slot, count }) => (
        <div className={styles.slot} key={slot.id}>
          <p style={{ color: slot.color }} className={styles.slotName}>
            {slot.name}
          </p>
          <p className={styles.slotValue}>{count}</p>
        </div>
      ))}
    </div>
  );
};

const getPrizePlace = (payout: Payout) => {
  if (payout.placeStart === payout.placeEnd) {
    return numberToOrdinal(payout.placeStart);
  }
  return `${payout.placeStart} - ${numberToOrdinal(payout.placeEnd)}`;
};

interface PrizeSectionProps {
  entryStyle: EntryStyle;
  title?: string;
  classNames?: {
    wrapper?: string;
    title?: string;
    prize?: string;
    prizeName?: string;
    prizeValue?: string;
  };
  prizeBreakdownInfo?: string;
}

export const PrizeSection = (props: PrizeSectionProps) => {
  const { classNames, entryStyle, prizeBreakdownInfo, title } = props;
  if (!entryStyle?.payouts || entryStyle.payouts.length === 0) return null;

  return (
    <div className={clsx(styles.prizeSection, classNames?.wrapper)}>
      <h2 className={clsx(styles.sectionTitle, classNames?.title)}>{title || 'Prize breakdown'}</h2>
      {prizeBreakdownInfo && <p className={styles.prizeBreakdownInfo}>{prizeBreakdownInfo}</p>}
      {entryStyle.payouts.map((payout) => (
        <div className={clsx(styles.prize, classNames?.prize)} key={payout.placeStart}>
          <p className={clsx(styles.prizeName, classNames?.prizeName)}>{getPrizePlace(payout)}</p>
          <p className={clsx(styles.prizeValue, classNames?.prizeValue)}>
            {payout.prizeItem?.displayText
              ? `$${langHelper.formatCash(payout.prizeItem.amount)} + ${
                  payout.prizeItem.displayText
                }`
              : `$${langHelper.formatCash(payout.prize)}`}
          </p>
        </div>
      ))}
    </div>
  );
};

export const RoundPrizeSection = ({
  classNames,
  entryStyle,
  tournamentInfo,
}: {
  entryStyle: EntryStyle;
  tournamentInfo: TournamentInfo;
  classNames?: {
    wrapper?: string;
    title?: string;
    prize?: string;
    prizeName?: string;
    prizeValue?: string;
  };
}) => {
  if (!entryStyle?.tournamentRoundPayouts || entryStyle.tournamentRoundPayouts.length === 0) {
    return null;
  }

  const sortedTournamentRoundPayouts =
    entryStyle.tournamentRoundPayouts?.reduce(
      (acc, curr) => {
        if (!acc[curr.round]) {
          acc[curr.round] = [];
        }
        acc[curr.round].push(curr);
        return acc;
      },
      {} as { [key: string]: RoundPayout[] }
    ) || {};

  return (
    <div className={clsx(styles.roundPrizeSection, classNames?.wrapper)}>
      {Object.keys(sortedTournamentRoundPayouts).map((roundNumber) => {
        const roundTitle =
          tournamentInfo?.tournamentRounds?.find(
            (round) => round.number === parseInt(roundNumber, 10)
          )?.prizeBreakdownTitle ?? '';
        return (
          <React.Fragment key={roundNumber}>
            <h2 className={clsx(styles.sectionTitle, classNames?.title)}>
              {roundTitle} prize breakdown
            </h2>
            {sortedTournamentRoundPayouts[roundNumber].map((roundPayout) => (
              <div key={roundPayout.placeStart} className={clsx(styles.prize, classNames?.prize)}>
                <p className={clsx(styles.prizeName, classNames?.prizeName)}>
                  {getPrizePlace(roundPayout)}
                </p>
                <p className={clsx(styles.prizeValue, classNames?.prizeValue)}>
                  ${langHelper.formatCash(roundPayout.prize)}
                </p>
              </div>
            ))}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export const LegendSection = ({
  clock,
  contestStyle,
  entryRole,
  entryStyle,
  maxEntries,
  source,
  sport,
}: {
  clock: number;
  contestStyle: ContestStyle;
  entryRole: Role;
  entryStyle: EntryStyle;
  maxEntries?: number;
  source: Source;
  sport: Sport;
}) => {
  const badgeEls: JSX.Element[] = [];
  if (contestStyle.sportId) {
    badgeEls.push(
      <div className={styles.badgeRow} key="sport">
        <SportBadge sport={sport} />
        <p className={styles.badgeDefinition}>{sport.name}</p>
      </div>
    );
  }

  if (isBestBall(contestStyle)) {
    badgeEls.push(
      <div className={styles.badgeRow} key="bestBall">
        <Badge badge="bestBall" />
        <p className={styles.badgeDefinition}>Best Ball</p>
      </div>
    );
  }

  if (source !== 'sitAndGo') {
    badgeEls.push(
      <div className={styles.badgeRow} key={source}>
        <Badge badge={source} />
        <p className={styles.badgeDefinition}>{badges[source].title}</p>
      </div>
    );
  }

  if (entryStyle.guaranteed) {
    badgeEls.push(
      <div className={styles.badgeRow} key="guaranteed">
        <Badge badge="guaranteed" />
        <p className={styles.badgeDefinition}>Guaranteed</p>
      </div>
    );
  }

  if (maxEntries && maxEntries > 1) {
    badgeEls.push(
      <div className={styles.badgeRow} key="multiEntry">
        <Badge badge="multiEntry" />
        <p className={styles.badgeDefinition}>Multi-entry</p>
      </div>
    );
  }

  if (entryRole) {
    badgeEls.push(
      <div className={styles.badgeRow} key="entryRole">
        <Badge badge={entryRole} />
        <p className={styles.badgeDefinition}>{langHelper.sentenceCase(entryRole)}</p>
      </div>
    );
  }
  if (clock > 600) {
    badgeEls.push(
      <div className={styles.badgeRow} key="slowDraft">
        <Badge badge="slow" />
        <p className={styles.badgeDefinition}>Slow Draft</p>
      </div>
    );
  }
  if (clock === 0) {
    badgeEls.push(
      <div className={styles.badgeRow} key="instantDraft">
        <Badge badge="instant" />
        <p className={styles.badgeDefinition}>Instant</p>
      </div>
    );
  }
  if (badgeEls.length) {
    return (
      <div className={styles.badgeSection}>
        <h2 className={styles.sectionTitle}>Legend</h2>
        {badgeEls}
      </div>
    );
  }

  return null;
};

export const RulesSection = ({ link, path }: { link?: string; path?: string }) => (
  <div className={styles.rulesSection}>
    <a
      href={link || `${process.env.APP_ENDPOINT}${path || '/rules'}`}
      className={styles.rulesLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      All rules
    </a>
  </div>
);

export const DraftName = ({
  defaultDraftName,
  draftName,
  disableSave,
  editable,
  onClear,
  onChange,
  onEdit,
  onSave,
}: {
  defaultDraftName: string;
  draftName: string;
  disableSave: boolean;
  editable: boolean;
  onChange: TextFieldCallback;
  onClear: () => void;
  onEdit?: () => void;
  onSave: () => void;
}) => {
  const clearButton = (
    <Button
      color="grey"
      size="small"
      icon="clear"
      type="text"
      width="intrinsic"
      onClick={onClear}
      classNames={{
        icon: styles.clearIcon,
      }}
    />
  );

  const saveButton = (
    <Button
      color="grey"
      size="small"
      text="save"
      type="text"
      width="intrinsic"
      disabled={disableSave}
      onClick={onSave}
      classNames={{
        button: styles.saveButton,
      }}
    />
  );

  const editButton = (
    <Button
      color="grey"
      size="small"
      type="text"
      width="intrinsic"
      icon="edit"
      onClick={onEdit}
      classNames={{ icon: styles.editIcon }}
    />
  );

  return (
    <div className={styles.draftNameSection}>
      {editable ? (
        <TextField
          name="Draft name"
          type="text"
          onChange={onChange}
          onSubmit={onSave}
          classNames={{ label: styles.infoLabel }}
          label="Draft name"
          value={draftName}
          customLeftElement={clearButton}
          customRightElement={saveButton}
          placeholder={defaultDraftName}
          maxLength={30}
        />
      ) : (
        <>
          <p className={styles.infoLabel}>Draft name</p>
          <div className={styles.draftNameContainer}>
            <p className={styles.infoValue}>{draftName || defaultDraftName}</p>
            {onEdit ? editButton : null}
          </div>
        </>
      )}
    </div>
  );
};
export const ClosesAtInfoUnit = ({
  cutoffAt,
  cutoffAtTimer,
}: {
  cutoffAt: string;
  cutoffAtTimer: number;
}) => {
  if (dayjs().isBefore(cutoffAt)) {
    if (dayjs().add(1, 'hour').isBefore(cutoffAt)) {
      const value = langHelper.timeToDayOrDate(cutoffAt);
      return value ? <InfoUnit label="Closes at" value={value} /> : null;
    }
    const value = numberToTime(cutoffAtTimer);
    return value ? <InfoUnit label="Closes in" value={value} /> : null;
  }

  return null;
};
