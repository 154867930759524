/* eslint-disable camelcase */
import dayjs from 'dayjs';

import { ConstructedUserAchievement } from '@/interfaces/constructed-interfaces/constructed-cosmetics-and-achievements';
import { UserAchievementResponse, UserAchievementsResponse } from '@/interfaces/cosmetics';

export const accessoryAdapter = (
  achievement: UserAchievementResponse
): ConstructedUserAchievement => {
  const { cosmetic, description, opened_at, rarity, user_achievement } = achievement;

  const isNew = dayjs(opened_at).isAfter(dayjs().subtract(3, 'day'));
  const isNewAchievement =
    user_achievement?.achieved_at &&
    dayjs(user_achievement?.achieved_at).isAfter(dayjs().subtract(3, 'day'));

  return {
    cosmetic,
    description,
    openedAt: opened_at,
    rarity,
    isAchievement: true,
    isNew,
    isNewAchievement,
    isLocked: !user_achievement?.achieved_at,
    userAchievement: user_achievement
      ? {
          progress: user_achievement.progress,
          achievedAt: user_achievement.achieved_at,
        }
      : null,
  };
};

export const accessoriesAdapter = (
  achievementsResponse: UserAchievementsResponse['achievements']
): ConstructedUserAchievement[] => {
  const filteredAchievements = achievementsResponse.filter(
    (ach) => ach.cosmetic.style === 'accessory'
  );
  return filteredAchievements.map(accessoryAdapter);
};
