import { Entry } from 'contentful';

import { ISportsRulesFields } from '@/interfaces/contentful';
import { SportRules } from '@/interfaces/sport-rules';

const sportsRules = (sportRule: Entry<ISportsRulesFields>): SportRules => {
  const { id } = sportRule.sys;
  const {
    title,
    slug,
    navLinkText,
    navRank,
    commonContent,
    scoringTables,
    rosterTables,
    textContentBestBall,
    textContentDailyContests,
    textContentPickEm,
    hasTwoTeamRule,
  } = sportRule.fields;

  return {
    id,
    title,
    slug,
    navLinkText,
    navRank,
    commonContent,
    scoringTables,
    rosterTables,
    textContentBestBall,
    textContentDailyContests,
    textContentPickEm,
    hasTwoTeamRule,
  };
};

export default sportsRules;
