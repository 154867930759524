import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clsx } from 'clsx';

import { RootState } from '@/store';
import { setPowerUpOnEntry as setPowerUpOnEntryAction } from '@/store/modules/pick-em/actions';

import { TokenAddedToEntryProperties } from '@/ampli';
import Button from '@/components/atoms/button';
import Icon from '@/components/atoms/icon';
import Label from '@/components/atoms/label';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import { useToast } from '@/components/atoms/toast';
import ContentfulPowerUpInfo from '@/components/molecules/contentful-power-up-info';
import CountdownLabel from '@/components/molecules/countdown-label';
import ModalWrapper from '@/components/molecules/modal-wrapper';
import { RedeemablePowerUp } from '@/interfaces/power-ups';

import commonStyles from '../../common-styles.scss';
import styles from './styles.scss';

const MAX_HIGHLIGHT_LABELS = 3;
interface PromoCardPowerUpProps {
  location: TokenAddedToEntryProperties['location'];
  powerUp: RedeemablePowerUp;
}

const PromoCardPowerUp = (props: PromoCardPowerUpProps) => {
  const { location, powerUp } = props;
  const openModal = useModal();
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const openToast = useToast();

  const selectedPowerUp = useSelector((state: RootState) => state.pickEmEntries.selected.powerUp);

  const onPowerUpApplyNow = () => {
    const action = powerUp.id === selectedPowerUp?.id ? 'remove' : 'add';
    dispatch(setPowerUpOnEntryAction({ action, location, powerUp }));

    if (action === 'add') {
      openToast({
        message: (
          <div className={styles.toastMessage}>
            <Icon name="checkCircle" className={styles.toastIcon} />
            <span>{powerUp.title} promo applied to your entry!</span>
          </div>
        ),
        showCloseButton: true,
        open: true,
        variant: 'success',
      });
    }
  };

  const openInfoModal = () => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <ModalWrapper handleCloseModal={handleCloseModal} hideCloseButton>
        <ContentfulPowerUpInfo
          powerUp={powerUp}
          handleCloseModal={handleCloseModal}
          contentfulInfoId={powerUp.contentfulInfoId}
        />
      </ModalWrapper>
    ));
  };

  const keyboardSelect = (e: React.KeyboardEvent<HTMLElement>) => {
    if (containerRef.current === e.target) {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();
        openInfoModal();
      }
    }
  };

  // only show three labels max
  // if more than three highlights replace third label with [+n]
  let highlightLabels: RedeemablePowerUp['highlights'] = powerUp.highlights;
  if (powerUp.highlights.length > MAX_HIGHLIGHT_LABELS) {
    const firstTwoHighlights = powerUp.highlights.slice(0, MAX_HIGHLIGHT_LABELS - 1);
    const remainingHighlightsCount = powerUp.highlights.length - (MAX_HIGHLIGHT_LABELS - 1);
    highlightLabels = [...firstTwoHighlights, { icon: null, text: `+${remainingHighlightsCount}` }];
  }

  return (
    <div
      ref={containerRef}
      className={clsx(commonStyles.promoCardWrapper, styles.promoCardPowerUp)}
      style={{
        backgroundImage: `url(${powerUp.backgroundImage})`,
        backgroundColor: powerUp.backgroundColor,
      }}
      onClick={openInfoModal}
      onKeyDown={keyboardSelect}
      role="button"
      tabIndex={0}
    >
      {(powerUp.uses.length > 1 || powerUp.expiresAt) && (
        <div className={styles.labelWrapper}>
          {powerUp.uses.length > 1 && (
            <Label color="dark" type="neutral" text={`${powerUp.uses.length.toString()} Left`} />
          )}
          {powerUp.expiresAt && (
            <CountdownLabel expiresAt={powerUp.expiresAt} className={styles.countdownLabel} />
          )}
        </div>
      )}
      <img alt="power-up-icon" className={styles.powerUpIcon} src={powerUp.heroImage} />
      <div className={clsx(commonStyles.infoSection, styles.infoSection)}>
        <h2 className={clsx(commonStyles.header, styles.header)}>
          <span>{powerUp.title}</span>
          <Icon className={styles.infoIcon} name="info" />
        </h2>
        {powerUp.description && (
          <p className={clsx(commonStyles.text, styles.text)}>{powerUp.description}</p>
        )}
        <div className={styles.highlightWrapper}>
          {highlightLabels.map((highlight) => (
            <Label
              key={highlight.text}
              classNames={{ label: styles.highlightLabel }}
              color="dark"
              iconNameLeft={highlight.icon}
              text={highlight.text}
              type="neutral"
            />
          ))}
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            classNames={{
              button: clsx(styles.button, {
                [styles.selected]: powerUp.id === selectedPowerUp?.id,
              }),
            }}
            color="grey"
            onClick={(e) => {
              e.stopPropagation();
              onPowerUpApplyNow();
            }}
            size="regular"
            text={powerUp.id === selectedPowerUp?.id ? 'Remove' : 'Apply'}
            type="solid"
            width="full"
          />
        </div>
      </div>
    </div>
  );
};

export default PromoCardPowerUp;
