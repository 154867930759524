import appearanceStatsAdapter from '@/store/modules/appearance-stats/adapters/appearance-stats';

import { AppearanceStats, AppearanceStatsResponse } from '@/interfaces/appearance-stats';

import { SET_APPEARANCE_STATS_WK_AVG, setAppearanceStatsWkAvg } from '../actions';

type State = AppearanceStats;

type AppearanceStatsActions = ReturnType<typeof setAppearanceStatsWkAvg>;

const initiaAppearanceStatslWkAvgState: AppearanceStats = {};

const set = (
  state: AppearanceStats,
  appearanceStatsResponse: AppearanceStatsResponse
): AppearanceStats => {
  const appearanceStat = appearanceStatsAdapter(appearanceStatsResponse);

  return {
    ...state,
    [appearanceStat.id]: appearanceStat,
  };
};

export const appearanceStatsWkAvgReducer = (
  state: State = initiaAppearanceStatslWkAvgState,
  action: AppearanceStatsActions = {} as AppearanceStatsActions
): State => {
  switch (action.type) {
    case SET_APPEARANCE_STATS_WK_AVG:
      return set(state, action.payload);
    default:
      return state;
  }
};
