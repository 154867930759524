/* eslint-disable camelcase */

import {
  SlateInfo,
  SlateInfoResponse,
} from '@/interfaces/constructed-interfaces/constructed-slates';

const slateInfoAdapter = (slateInfo: SlateInfoResponse): SlateInfo => {
  const {
    slate: {
      id,
      contest_style_ids,
      description,
      game_count,
      lobby_hidden,
      sport_id,
      start_at,
      title,
    },
  } = slateInfo;

  return {
    id,
    contestStyleIds: contest_style_ids,
    description,
    gameCount: game_count,
    lobbyHidden: lobby_hidden,
    sportId: sport_id,
    startAt: start_at,
    title,
  };
};

export default slateInfoAdapter;
