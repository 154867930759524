import { ActiveDraftsResponse } from '@/interfaces/active-drafts';
import {
  AutoPickToggleResponse,
  DraftResponse,
  NewDraftEntryResponse,
  PickMadeResponse,
} from '@/interfaces/drafts';

export const GET_ACTIVE_DRAFTS = 'user/GET_ACTIVE_DRAFTS' as const;
export const SET_ACTIVE_DRAFTS = 'user/SET_ACTIVE_DRAFTS' as const;
export const UPDATE_ACTIVE_DRAFT_STATUS = 'user/UPDATE_ACTIVE_DRAFT_STATUS' as const;
export const UPDATE_ACTIVE_DRAFT_ENTRY_COUNT = 'user/UPDATE_ACTIVE_DRAFT_ENTRY_COUNT' as const;
// eslint-disable-next-line max-len
export const UPDATE_DRAFT_AUTO_PICK_STATUS = 'user/UPDATE_DRAFT_AUTO_PICK_STATUS' as const;
export const UPDATE_ACTIVE_DRAFT_PICK = 'user/UPDATE_ACTIVE_DRAFT_PICK' as const;
export const REMOVE_ACTIVE_DRAFT = 'user/REMOVE_ACTIVE_DRAFT' as const;

export const getActiveDrafts = () => ({ type: GET_ACTIVE_DRAFTS });

export const setActiveDrafts = (payload: ActiveDraftsResponse) => ({
  type: SET_ACTIVE_DRAFTS,
  payload,
});

export const updateActiveDraftStatus = (payload: {
  draft: DraftResponse['draft'];
  userId: string;
}) => ({ type: UPDATE_ACTIVE_DRAFT_STATUS, payload });

export const updateActiveDraftEntryCount = (payload: NewDraftEntryResponse) => ({
  type: UPDATE_ACTIVE_DRAFT_ENTRY_COUNT,
  payload,
});

export const updateDraftEntryAutoPickStatus = (payload: AutoPickToggleResponse) => ({
  type: UPDATE_DRAFT_AUTO_PICK_STATUS,
  payload,
});

export const updateActiveDraftPick = (payload: { updatedPick: PickMadeResponse }) => ({
  type: UPDATE_ACTIVE_DRAFT_PICK,
  payload,
});

export const removeActiveDraft = (payload: { draftId: string }) => ({
  type: REMOVE_ACTIVE_DRAFT,
  payload,
});
