import { EntryCollection } from 'contentful';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api-contentful/tournament-rules';
import { ITournamentRulesFields } from '@/interfaces/contentful';

import { getTournamentRules, setActiveTournamentsRules, setTournamentRules } from '../actions';

export const getTournamentRulesSaga = function* getTournamentRulesSaga(
  action: ReturnType<typeof getTournamentRules>
): SagaIterator {
  try {
    // eslint-disable-next-line max-len
    const tournamentRules: EntryCollection<ITournamentRulesFields> = yield call(
      api.getContentfulTournamentRules,
      action.payload
    );

    yield put(setTournamentRules({ tournamentRules, slug: action.payload.tournamentSlug }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getActiveTournamentsRulesSaga =
  function* getActiveTournamentsRulesSaga(): SagaIterator {
    try {
      // eslint-disable-next-line max-len
      const tournamentsRules: EntryCollection<ITournamentRulesFields> = yield call(
        api.getContentfulActiveTournamentsRules
      );

      yield put(setActiveTournamentsRules({ tournamentsRules }));
    } catch (e) {
      yield put(setError({ ...e, type: 'toast' }));
    }
  };
