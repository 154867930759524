/* eslint-disable camelcase */
import { draftEntryAdapter } from '@/store/modules/drafts/adapters/draft-entry';
import { draftUserAdapter } from '@/store/modules/drafts/adapters/draft-user';
import { pickAdapter } from '@/store/modules/drafts/adapters/pick';

import {
  DraftEntriesForDraftResponse,
  DraftEntriesForDrafts,
} from '@/interfaces/tournament-rounds';
import { User } from '@/interfaces/user';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import {
  SET_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT,
  setPaginatedDraftEntriesForDraft,
} from '../actions';
import { draftForTournamentRoundAdapter } from '../adapters/drafts-for-tournament-rounds';

type State = DraftEntriesForDrafts | null;

type PaginatedDraftEntriesForDraftActions = ReturnType<typeof setPaginatedDraftEntriesForDraft>;

const initialState: DraftEntriesForDrafts = {};

const set = (
  state: DraftEntriesForDrafts,
  { data, user }: { data: DraftEntriesForDraftResponse; user?: User }
): DraftEntriesForDrafts => {
  const draft = draftForTournamentRoundAdapter(data.draft);
  const newDraftEntries = data.draft_entries.map(draftEntryAdapter);
  const newPicks = data.picks.map(pickAdapter);
  const newUsers = arrayToObjectIdKeys(data.users.map(draftUserAdapter));

  const draftEntries = state[draft.id]?.draftEntries
    ? [...state[draft.id].draftEntries, ...newDraftEntries].filter(
        (draftEntry, ind, arr) => arr.findIndex((d) => d.id === draftEntry.id) === ind
      )
    : newDraftEntries;

  const picks = state[draft.id]?.picks
    ? [...state[draft.id].picks, ...newPicks].filter(
        (pick, ind, arr) => arr.findIndex((p) => p.id === pick.id) === ind
      )
    : newPicks;

  const users = state[draft.id]?.users ? { ...state[draft.id]?.users, ...newUsers } : newUsers;

  const usersPlusUser = user
    ? {
        // we need the current user to display their teams, but if they aren't on the first page the api doesn't send it
        [user.id]: user,
        ...users,
      }
    : users;

  return {
    ...state,
    [draft.id]: {
      draft,
      draftEntries,
      picks,
      users: usersPlusUser,
      meta: data.meta,
    },
  };
};

export const paginatedDraftEntriesForDraftReducer = (
  state: State = initialState,
  action: PaginatedDraftEntriesForDraftActions = {} as PaginatedDraftEntriesForDraftActions
): State => {
  switch (action.type) {
    case SET_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT:
      return set(state, action.payload);
    default:
      return state;
  }
};
