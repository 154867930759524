/* eslint-disable camelcase */
import { AirdropOffer, AirdropOffersResponse } from '@/interfaces/pick-em';

const airdropAdapter = (airdrop: AirdropOffersResponse): AirdropOffer => {
  const {
    airdrop_id,
    amount,
    contentful_info_id,
    days_for_clawback,
    description,
    expires_at,
    id,
    starts_at,
    title,
  } = airdrop;

  return {
    airdropId: airdrop_id,
    amount,
    contentfulInfoId: contentful_info_id,
    daysForClawback: days_for_clawback,
    description,
    expiresAt: expires_at,
    id,
    startsAt: starts_at,
    title,
  };
};

export default airdropAdapter;
