import React from 'react';

// be sure to include file extensions
export const assetImages = [
  'detailedEmail.png',
  'detailedError.png',
  'detailedSuccess.png',
  'detailedWarning.png',
] as const;
export type AssetImageName = (typeof assetImages)[number];

export interface AssetImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  imageName: AssetImageName;
}

export function AssetImage({ imageName, ...imgProps }: AssetImageProps) {
  return (
    <img
      src={`/assets/images/${imageName}`}
      alt={imgProps.alt ?? imageName}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...imgProps}
    />
  );
}
