import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api-contentful/power-up-info';

import { getContentfulPowerUpInfo, setContentfulPowerUpInfo } from '../actions';

export const getContentfulPowerUpInfoSaga = function* getContentfulPowerUpInfoSaga(
  action: ReturnType<typeof getContentfulPowerUpInfo>
): SagaIterator {
  try {
    const powerUpInfoResponse = yield call(api.getContentfulPowerUpInfoById, action.payload);

    yield put(
      setContentfulPowerUpInfo({
        powerUpInfoResponse,
        contentfulInfoId: action.payload.contentfulInfoId,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
