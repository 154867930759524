import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import clsx from 'clsx';

import ExternalLink from '@/components/atoms/external-link';

import commonStyles from './common-styles.scss';

export const getOptions = () => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: any, children: React.ReactNode) => (
      <p className={clsx(commonStyles.section, commonStyles.paragraph)}>{children}</p>
    ),
    [BLOCKS.HEADING_2]: (_node: any, children: React.ReactNode) => (
      <h2 className={commonStyles.sectionHeader}>{children}</h2>
    ),
    [INLINES.HYPERLINK]: (node: any, children: React.ReactNode) => (
      <ExternalLink href={node.data.uri} className={commonStyles.link} text={children} showArrow />
    ),
  },
});
