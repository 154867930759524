import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '@/store';

import Icon from '@/components/atoms/icon';
import { ScoringSection } from '@/components/atoms/info-modal-components';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import { useToast } from '@/components/atoms/toast';
import DialogModal from '@/components/molecules/dialog-modal';
import ManualScoringIcon from '@/components/molecules/manual-scoring-icon';
// eslint-disable-next-line import/no-cycle
import PickEmPlayerModal from '@/components/molecules/pick-em-player-modal';
import PickEmInfoModal from '@/components/pages/pick-em/components/review-section/components/pick-em-info-modal';
import { ConstructedPickEmRivalLine } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { LiveStatLines } from '@/interfaces/constructed-interfaces/constructed-slates';
import { ScoringTypes } from '@/interfaces/drafting-config';
import { Matches } from '@/interfaces/matches';
import { Selection } from '@/interfaces/pick-em';
import { AdjustmentDisplay } from '@/interfaces/types';
import langHelper, { LOW_SCORE_WINS_TEXT } from '@/utilities/lang-helper';

import { LiveDividingContainer } from '../rival-dividing-container';

import PickEmRivalLiveResultPlayerCell from './pick-em-rival-live-result-player-cell';

import styles from './styles.scss';

export interface RivalLiveResultCellProps {
  className?: string;
  constructedPickEmRivalLine: ConstructedPickEmRivalLine;
  disableButtons?: boolean; // used to disable buttons for outgoing shared modal
  initialSelectedOptionId?: string; // used for outgoing shared modal
  isPools?: boolean;
  isLive?: boolean;
  liveGames: Matches | null;
  liveStatLines: LiveStatLines | null;
  scoringTypes: ScoringTypes;
}

const RivalLiveResultCell = (props: RivalLiveResultCellProps) => {
  const {
    className,
    constructedPickEmRivalLine: rivalLine,
    disableButtons = false,
    initialSelectedOptionId,
    isPools,
    isLive,
    liveGames,
    liveStatLines,
    scoringTypes,
  } = props;
  const openModal = useModal();
  const openToast = useToast();

  if (!rivalLine) return null;

  const handleScoringModalClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const scoringType = scoringTypes[rivalLine?.rival?.scoringTypeId];
    if (scoringType) {
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <DialogModal
          handleCloseModal={handleCloseModal}
          title="Scoring"
          content={<ScoringSection scoringType={scoringType} displayTitle={false} />}
        />
      ));
    }
  };

  const handleLowScoreWinsModalClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <DialogModal
        handleCloseModal={handleCloseModal}
        title="Lowest score wins"
        content={<p>{LOW_SCORE_WINS_TEXT}</p>}
      />
    ));
  };

  const handlePlayerClick = (appearanceId: string) => {
    const cA = rivalLine.appearanceOptions.find((app) => app.id === appearanceId);
    if (cA.sport?.manual) {
      openToast({
        open: true,
        message: (
          <div className={styles.noPlayerCardToast}>
            <Icon name="recentActors" className={styles.toastIcon} />
            <p className={styles.noPlayerCardText}>Player card not available</p>
          </div>
        ),
      });
    } else {
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <PickEmPlayerModal
          constructedPickEmRivalLine={rivalLine}
          constructedAppearance={cA}
          handleCloseModal={handleCloseModal}
          isResults
        />
      ));
    }
  };

  let [leftCA, rightCA] = rivalLine.appearanceOptions;
  if (initialSelectedOptionId) {
    // we may need to reorder the constructedAppearances
    leftCA = rivalLine.appearanceOptions.find(
      (aO) => aO.rivalOption.id === initialSelectedOptionId
    );
    rightCA = rivalLine.appearanceOptions.find((aO) => aO.id !== leftCA.id);
  }
  const leftStatLine = liveStatLines?.[leftCA.id] || leftCA.statLine; // fallback to cA.statLine if on results page
  const rightStatLine = liveStatLines?.[rightCA.id] || rightCA.statLine; // fallback to cA.statLine if on results page

  // actualStatValue is the stat value once a selection has been settled
  // actualStatValue won't change if there's a stat correction after being settled
  const leftActiveStatValue =
    rivalLine.selection?.actualStatValue ||
    (leftCA.rivalOption.appearanceStat.stat === 'fantasy_points'
      ? leftStatLine?.scores?.find((score) => score.scoringTypeId === rivalLine.rival.scoringTypeId)
          ?.points || '0'
      : leftStatLine?.data?.[leftCA.rivalOption.appearanceStat.stat] || '0');

  // rivalActualStatValue is the stat value of the opponent of a user pick once a selection has been settled
  // rivalActualStatValue won't change if there's a stat correction after being settled
  const rightActiveStatValue =
    rivalLine.selection?.rivalActualStatValue ||
    (rightCA.rivalOption.appearanceStat.stat === 'fantasy_points'
      ? rightStatLine?.scores?.find(
          (score) => score.scoringTypeId === rivalLine.rival.scoringTypeId
        )?.points || '0'
      : rightStatLine?.data?.[rightCA.rivalOption.appearanceStat.stat] || '0');

  const leftSpreadNumber = parseFloat(leftCA.rivalOption?.spread) || 0;
  const rightSpreadNumber = parseFloat(rightCA.rivalOption?.spread) || 0;

  let adjustmentDisplay: AdjustmentDisplay = 'hidden';
  let adjustedPlayer;
  if (rightSpreadNumber > 0) {
    adjustmentDisplay = 'left';
    adjustedPlayer = {
      cA: rightCA,
      actualValue: rightActiveStatValue,
    };
  } else if (leftSpreadNumber > 0) {
    adjustmentDisplay = 'right';
    adjustedPlayer = {
      cA: leftCA,
      actualValue: leftActiveStatValue,
    };
  }

  // this won't exist in results
  const leftMatch = liveGames?.[leftCA?.match?.id];
  const rightMatch = liveGames?.[rightCA?.match?.id];
  const leftSoloGame = leftCA?.soloGame;
  const rightSoloGame = rightCA?.soloGame;

  let result: Selection['result'] | 'live' =
    ((leftMatch?.period > 0 && rightMatch?.period > 0) ||
      (leftSoloGame?.period > 0 && rightSoloGame?.period > 0)) &&
    rivalLine.selection?.result === 'pending'
      ? 'live'
      : rivalLine.selection?.result;

  const leftScore = rivalLine.liveEvent
    ? parseFloat(rivalLine.liveEventStats?.[leftCA.id])
    : langHelper.sumFloats(leftActiveStatValue, leftSpreadNumber); // this is always the selected player
  const rightScore = rivalLine.liveEvent
    ? parseFloat(rivalLine.liveEventStats?.[rightCA.id])
    : langHelper.sumFloats(rightActiveStatValue, rightSpreadNumber);

  if (result === 'live') {
    if (leftCA.rivalOption.appearanceStat?.gradedBy === 'lowScore') {
      if (leftScore < rightScore) {
        result = 'won';
      }
      if (leftScore > rightScore) {
        result = 'lost';
      }
    } else {
      // highScore wins, or gradedBy is null
      if (leftScore > rightScore) {
        result = 'won';
      }
      if (leftScore < rightScore) {
        result = 'lost';
      }
    }
  }

  const openInGameProjectionInfoModal = () => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <DialogModal
        handleCloseModal={handleCloseModal}
        title="In-game projection"
        content={
          <p>
            When you see this icon displayed, that means you&apos;ve picked a stat projection that
            occurred live, in-game.
          </p>
        }
        dismissText="Got it"
        dismissAction={handleCloseModal}
      />
    ));
  };

  const handleClickOnRescuesBadge = () => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <PickEmInfoModal
        handleCloseModal={handleCloseModal}
        contentToShow={[`rescues${isPools ? 'Champions' : 'Standard'}`]}
        title="Pick'em Rescues"
      />
    ));
  };

  const isSportScoredManually =
    isLive &&
    (leftMatch?.manuallyCreated ||
      leftSoloGame?.manuallyCreated ||
      rightMatch?.manuallyCreated ||
      rightSoloGame?.manuallyCreated);

  const warningLabelEl = isSportScoredManually ? <ManualScoringIcon /> : null;

  return (
    <div className={clsx(styles.rivalLiveResult, className, styles[rivalLine.selection?.result])}>
      <div className={styles.header}>
        <div className={styles.stat}>
          <span>{leftCA.rivalOption.appearanceStat.displayStat}</span>
          {rivalLine.rival.scoringTypeId && !disableButtons && (
            <button
              className={styles.scoringModalButton}
              onClick={handleScoringModalClick}
              aria-label="Scoring info"
            >
              <Icon name="info" className={styles.scoringModalIcon} />
            </button>
          )}
          {leftCA.rivalOption?.appearanceStat?.gradedBy === 'lowScore' && !disableButtons && (
            <button
              className={styles.scoringModalButton}
              onClick={handleLowScoreWinsModalClick}
              aria-label="Low score wins info"
            >
              <Icon name="info" className={styles.scoringModalIcon} />
            </button>
          )}
        </div>
        {warningLabelEl}
        {rivalLine.liveEvent && (
          <div className={styles.inGameProjectionWrapper}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                openInGameProjectionInfoModal();
              }}
              aria-label="In-game projection info"
            >
              <Icon name="timer" className={styles.inGameProjectionIcon} />
            </button>
          </div>
        )}
        {rivalLine.selection?.rebooted && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleClickOnRescuesBadge();
            }}
            className={styles.rescuesBadge}
          >
            <Icon name="medicalServices" className={styles.medicalServicesIcon} />
            <span>Rescued</span>
          </button>
        )}
      </div>
      <div className={styles.rivalPlayerLiveResultCellWrapper}>
        <div className={styles.rivalPlayerLiveResultCell}>
          <PickEmRivalLiveResultPlayerCell
            constructedAppearance={leftCA}
            handlePlayerClick={handlePlayerClick}
            disableButtons={disableButtons}
            isSelected="selected"
          />
          <div className={clsx(styles.dividingLine, styles[result])} />
          <PickEmRivalLiveResultPlayerCell
            constructedAppearance={rightCA}
            handlePlayerClick={handlePlayerClick}
            disableButtons={disableButtons}
          />
        </div>
        <LiveDividingContainer
          result={result}
          leftScore={leftScore}
          rightScore={rightScore}
          adjustmentDisplay={adjustmentDisplay}
          adjustedPlayer={adjustedPlayer}
          disableButtons={disableButtons}
        />
      </div>
    </div>
  );
};

export default connect((state: RootState) => ({
  scoringTypes: state.draftingConfig.scoringTypes,
  liveStatLines: state.pickEmEntries.live.liveStatLines,
  liveGames: state.pickEmEntries.live.liveGames,
}))(RivalLiveResultCell);
