import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '@/store';

import Icon from '@/components/atoms/icon';
import PlayerDetailedAvatar from '@/components/molecules/player-detailed-avatar';
import { ConstructedPickEmRivalLineAppearance } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { Teams } from '@/interfaces/drafting-config';
import { Matches } from '@/interfaces/matches';
import langHelper from '@/utilities/lang-helper';

import styles from './styles.scss';

interface PickEmRivalLiveResultPlayerCellProps {
  constructedAppearance: ConstructedPickEmRivalLineAppearance;
  teams: Teams;
  liveGames: Matches;
  isSelected?: string;
  handlePlayerClick: (appearanceId: string) => void;
  disableButtons?: boolean; // used to disable buttons for outgoing shared modal
}

const PickEmRivalLiveResultPlayerCell = (props: PickEmRivalLiveResultPlayerCellProps) => {
  const {
    constructedAppearance: cA,
    teams,
    liveGames,
    isSelected,
    handlePlayerClick,
    disableButtons = false,
  } = props;
  const { soloGame, sport } = cA;

  const match = liveGames?.[cA.match?.id] || cA.match; // fallback to match if on results page

  const isLive =
    match?.status === 'scoring' ||
    match?.status === 'scheduled' ||
    soloGame?.status === 'scoring' ||
    soloGame?.status === 'scheduled';

  const keyboardSelect = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (disableButtons) return;
    if (event.key === 'Enter' || event.key === ' ') {
      handlePlayerClick(cA.id);
    }
  };

  const renderLiveMatchTime = () => {
    // for an in-game projection
    if (match?.period > 0 || soloGame?.period > 0) {
      return (
        <p className={styles.matchInfoText}>
          {langHelper.matchTextWithScore({
            match: cA.match,
            team: cA.team,
            teams,
            soloGame,
            sport: cA.sport,
          })}
        </p>
      );
    }

    // if the game is just scheduled and not yet live
    return (
      <p className={styles.matchInfoText}>
        {langHelper.matchText({
          gameType: sport.gameType,
          teams,
          match,
          team: cA.team,
          soloGame,
        })}{' '}
        -&nbsp;
        {langHelper.timeToDayOrDate(match?.scheduledAt || soloGame?.scheduledAt)}
      </p>
    );
  };

  return (
    <div
      className={clsx(
        styles.pickEmRivalLiveResultPlayerCell,
        isSelected ? styles.showColors : styles.hidden,
        { [styles.noButtons]: disableButtons }
      )}
      onClick={() => {
        if (!disableButtons) {
          handlePlayerClick(cA.id);
        }
      }}
      role="button"
      tabIndex={0}
      onKeyDown={keyboardSelect}
      data-appearance-id={cA.id}
    >
      <div className={clsx(styles.avatarWrapper, { [styles.selected]: isSelected })}>
        <PlayerDetailedAvatar
          team={cA.team}
          player={cA.player}
          sport={cA.sport}
          backgroundStripes={!!isSelected}
          size="regular"
          borderType="none"
        />
      </div>
      <div className={styles.playerInfo}>
        <h1 className={`${styles.playerName} ${isSelected ? styles.bold : styles.unbold}`}>
          {langHelper.getPlayerFullName(cA.player)}
        </h1>
        {isLive ? (
          <div className={styles.matchName}>
            <Icon className={styles.sportIcon} name={sport?.icon} />
            {renderLiveMatchTime()}
          </div>
        ) : (
          <>
            <div className={styles.matchName}>
              <Icon className={styles.sportIcon} name={sport?.icon} />
              <p className={styles.matchInfoText}>
                {langHelper.matchResultText({
                  teams,
                  match,
                  team: cA.team,
                  soloGame,
                })}
              </p>
            </div>
            <div className={styles.matchInfoTextRow}>
              <p className={styles.matchInfoText}>
                {langHelper.timeToDayOrDate(match?.scheduledAt || soloGame?.scheduledAt)}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default connect((state: RootState) => ({
  teams: state.draftingConfig.teams,
  liveGames: state.pickEmEntries.live.liveGames,
}))(PickEmRivalLiveResultPlayerCell);
