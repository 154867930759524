import { MatchesResponse } from '@/interfaces/matches';

export const GET_WEEKLY_MATCHES = 'user/GET_WEEKLY_MATCHES' as const;
export const SET_WEEKLY_MATCHES = 'user/SET_WEEKLY_MATCHES' as const;

interface GetWeeklyMatches {
  type: 'user/GET_WEEKLY_MATCHES';
  payload: { weekId: number };
}

interface SetWeeklyMatches {
  type: 'user/SET_WEEKLY_MATCHES';
  payload: { data: MatchesResponse; weekId: number };
}

export const getWeeklyMatches = (payload: { weekId: number }): GetWeeklyMatches => ({
  type: GET_WEEKLY_MATCHES,
  payload,
});

export const setWeeklyMatches = (payload: {
  data: MatchesResponse;
  weekId: number;
}): SetWeeklyMatches => ({ type: SET_WEEKLY_MATCHES, payload });
