import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import * as api from '@/api/weekly-appearances';
import errorLogger from '@/utilities/errors/logger';

import { getWeeklyAppearances, setWeeklyAppearances } from '../actions';

export const getWeeklyAppearancesSaga = function* getWeeklyAppearancesSaga(
  action: ReturnType<typeof getWeeklyAppearances>
): SagaIterator {
  try {
    const response = yield call(api.getWeeklyAppearances, action.payload);

    yield put(setWeeklyAppearances({ data: response.data, weekId: action.payload.weekId }));
  } catch (e) {
    errorLogger(true, `getWeeklyAppearancesSaga: ${e}`);
  }
};
