/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';

// eslint-disable-next-line import/no-cycle
import request from '@request';
import { GeoComplyLicenseResponse, GeoComplyLocationResponse } from '@/interfaces/geocomply';
import { AppError } from '@/utilities/errors';

export const getLicense = async (): Promise<AxiosResponse<{ data: GeoComplyLicenseResponse }>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/geo_comply/license?web=true',
    });

    return response;
  } catch (e) {
    // This is a safe cast (for now) because we know `request` will only
    // throw type `Error` or `APIError` (which extends `Error`).
    throw new AppError(e as Error);
  }
};

export const checkLocation = async (
  token: string
): Promise<AxiosResponse<GeoComplyLocationResponse>> => {
  try {
    const response = await request({
      method: 'POST',
      url: '/geo_comply/location',
      data: { encoded_response: token },
    });

    return response;
  } catch (e) {
    // This is a safe cast (for now) because we know `request` will only
    // throw type `Error` or `APIError` (which extends `Error`).
    throw new AppError(e as Error);
  }
};
