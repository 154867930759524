import {
  UPDATE_DRAFT_AUTO_PICK_STATUS,
  updateDraftEntryAutoPickStatus,
} from '@/store/modules/active-drafts/actions';

import {
  AutoPickToggleResponse,
  DraftResponse,
  Drafts,
  NewDraftEntryResponse,
  PickMadeResponse,
  RenameDraftEntry,
} from '@/interfaces/drafts';
import { EntryScoringUpdateResponse, PicksScoringUpdateResponse } from '@/interfaces/live';

import {
  CLEAR_DRAFT,
  clearDraft,
  RENAME_DRAFT_ENTRY,
  renameDraftEntry,
  SET_DRAFT,
  SET_NEW_DRAFT_ENTRY,
  setDraft,
  setNewDraftEntry as setNewDraftEntryAction,
  UPDATE_DRAFT,
  UPDATE_DRAFT_ENTRIES_SCORING,
  UPDATE_PICK,
  UPDATE_PICKS_SCORING,
  updateDraft,
  updateDraftEntriesScoring,
  updatePick as updatePickAction,
  updatePicksScoring as updatePicksScoringAction,
} from '../actions';
import draftAdapter from '../adapters/draft';
import { pickMadeAdapter } from '../adapters/pick-made';

type State = Drafts;

type DraftsActions = ReturnType<
  | typeof setDraft
  | typeof updateDraft
  | typeof clearDraft
  | typeof updatePickAction
  | typeof setNewDraftEntryAction
  | typeof updateDraftEntryAutoPickStatus
  | typeof renameDraftEntry
  | typeof updateDraftEntriesScoring
  | typeof updatePicksScoringAction
>;

const initialDraftsState: Drafts = {};

const set = (state: Drafts, data: DraftResponse): Drafts => {
  const draft = draftAdapter(data.draft);
  return {
    ...state,
    [draft.id]: draft,
  };
};

const update = (state: Drafts, draftResponse: DraftResponse): Drafts => {
  const draft = draftAdapter(draftResponse.draft);
  const existingDraft = state[draftResponse.draft.id];
  return {
    ...state,
    [draftResponse.draft.id]: {
      id: draft.id || existingDraft.id,
      autoPickAt: draft.autoPickAt || existingDraft.autoPickAt,
      clock: draft.clock || existingDraft.clock,
      contestStyleId: draft.contestStyleId || existingDraft.contestStyleId,
      creatorId: draft.creatorId || existingDraft?.creatorId,
      draftAt: draft.draftAt || existingDraft.draftAt,
      draftEntries: draft.draftEntries || existingDraft.draftEntries,
      draftType: draft.draftType || existingDraft.draftType,
      entryCount: draft.entryCount || existingDraft.entryCount,
      entryRole: draft.entryRole || existingDraft.entryRole,
      entryStyleId: draft.entryStyleId || existingDraft.entryStyleId,
      picks: draft.picks.length ? draft.picks : existingDraft.picks, // this might be an empty array
      slate: draft.slate || existingDraft.slate,
      slateId: draft.slateId || existingDraft.slateId,
      source: draft.source || existingDraft.source,
      sourceId: draft.sourceId || existingDraft.sourceId,
      status: draft.status || existingDraft.status,
      title: draft.title || existingDraft.title,
      sourceEntryStyleId: draft.sourceEntryStyleId || existingDraft.sourceEntryStyleId,
      users: draft.users || existingDraft.users,
      appearanceIds: draft.appearanceIds || existingDraft.appearanceIds,
    },
  };
};

const setNewDraftEntry = (state: Drafts, newEntryResponse: NewDraftEntryResponse): Drafts => ({
  ...state,
  [newEntryResponse.id]: {
    ...state[newEntryResponse.id],
    status: newEntryResponse.status,
    entryCount: newEntryResponse.entry_count,
  },
});

const updatePick = (state: Drafts, { updatedPick }: { updatedPick: PickMadeResponse }): Drafts => {
  const currentDraft = state[updatedPick.id];
  // If this fails, like there's no current draft, I should have some catch-all
  // safety net that just fetches everything again

  return {
    ...state,
    // return the chosen draft with updated pick
    [updatedPick.id]: {
      ...currentDraft,
      autoPickAt: updatedPick.auto_pick_at,
      picks: [...currentDraft.picks, pickMadeAdapter(updatedPick.pick)],
    },
  };
};

const updateAutoPickStatus = (state: Drafts, data: AutoPickToggleResponse) => {
  const currentDraft = state[data.draft_id];
  if (!currentDraft) return state;
  const draftEntries = currentDraft.draftEntries.map((dE) => {
    if (dE.id === data.draft_entry_id) {
      return {
        ...dE,
        autoPick: data.auto_pick,
      };
    }

    return dE;
  });

  return {
    ...state,
    [data.draft_id]: {
      ...currentDraft,
      draftEntries,
    },
  };
};

const clear = () => ({});

const rename = (state: Drafts, data: RenameDraftEntry) => {
  const currentDraft = state[data.id];
  if (!currentDraft) return state;

  const draftEntriesArray = currentDraft.draftEntries.map((draftEntry) => {
    if (draftEntry.id === data.draftEntryId) {
      return {
        ...draftEntry,
        title: data.draftEntryTitle,
      };
    }
    return draftEntry;
  });

  return {
    ...state,
    [data.id]: {
      ...currentDraft,
      draftEntries: draftEntriesArray,
    },
  };
};

// Live scoring functions
const updateScoring = (
  state: Drafts,
  { data, draftId }: { data: EntryScoringUpdateResponse[]; draftId: string }
): Drafts => {
  if (!state[draftId]) return state;
  const selectedDraft = state[draftId];

  const updatedDraftEntries = selectedDraft.draftEntries.map((draftEntry) => {
    const scoringDraftEntry = data.find((dE) => dE.id === draftEntry.id);
    if (scoringDraftEntry) {
      return {
        ...draftEntry,
        place: scoringDraftEntry.place || draftEntry.place,
        points: scoringDraftEntry.points || draftEntry.points,
        payout: scoringDraftEntry.payout || draftEntry.payout,
        payoutText: scoringDraftEntry.payout_text || draftEntry.payoutText,
      };
    }

    return draftEntry;
  });

  return {
    ...state,
    [draftId]: {
      ...state[draftId],
      draftEntries: updatedDraftEntries,
    },
  };
};

const updatePicksScoring = (
  state: Drafts,
  { data, draftId }: { data: PicksScoringUpdateResponse; draftId: string }
): Drafts => {
  if (!state[draftId]) return state;
  const selectedDraft = state[draftId];

  const updatedPicks = selectedDraft.picks.map((pick) => {
    const updatedPick = data[pick.id];
    if (updatedPick) {
      return {
        ...pick,
        points: updatedPick || pick.points,
      };
    }
    return pick;
  });

  return {
    ...state,
    [draftId]: {
      ...state[draftId],
      picks: updatedPicks,
    },
  };
};

export const draftsReducer = (
  state: State = initialDraftsState,
  action: DraftsActions = {} as DraftsActions
): State => {
  switch (action.type) {
    case SET_DRAFT:
      return set(state, action.payload);
    case UPDATE_DRAFT:
      return update(state, action.payload);
    case UPDATE_PICK:
      return updatePick(state, action.payload);
    case SET_NEW_DRAFT_ENTRY:
      return setNewDraftEntry(state, action.payload);
    case UPDATE_DRAFT_AUTO_PICK_STATUS:
      return updateAutoPickStatus(state, action.payload);
    case UPDATE_DRAFT_ENTRIES_SCORING:
      return updateScoring(state, action.payload);
    case UPDATE_PICKS_SCORING:
      return updatePicksScoring(state, action.payload);
    case CLEAR_DRAFT:
      return clear();
    case RENAME_DRAFT_ENTRY:
      return rename(state, action.payload);
    default:
      return state;
  }
};
