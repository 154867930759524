/* eslint-disable camelcase */
import { draftUserAdapter } from '@/store/modules/drafts/adapters/draft-user';

import { DraftPoolEntry, DraftPoolEntryResponse } from '@/interfaces/draft-pools';

import { pickAdapter } from '../../drafts/adapters/pick';

export const draftPoolEntryAdapter = (entry: DraftPoolEntryResponse): DraftPoolEntry => {
  const {
    id,
    draft_id,
    payout,
    payout_text,
    picks: picksResponse,
    place,
    points,
    tied,
    user: userResponse,
    user_id,
  } = entry;

  const picks = picksResponse && picksResponse.map(pickAdapter);
  const user = userResponse ? draftUserAdapter(userResponse) : undefined;

  return {
    id,
    draftId: draft_id,
    payout,
    payoutText: payout_text,
    picks,
    place,
    points,
    tied,
    user,
    userId: user_id,
  };
};
