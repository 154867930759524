import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import {
  getAppearanceStats,
  getAppearanceStatsById,
  getWeeklyAppearanceStats,
  setAppearanceStats,
  setWeeklyAppearanceStats,
} from '@/store/modules/appearance-stats/actions';

import * as api from '@/api/appearance-stats';
import { AppearanceStatsResponse } from '@/interfaces/appearance-stats';
import errorLogger from '@/utilities/errors/logger';

export const getAppearanceStatsSaga = function* getAppearanceStatsSaga(
  action: ReturnType<typeof getAppearanceStats>
): SagaIterator {
  try {
    const response = yield call(api.getAppearanceStats, action.payload);
    const { data }: { data: AppearanceStatsResponse } = response;

    yield put(setAppearanceStats(data));
  } catch (e) {
    errorLogger(true, `getAppearanceStatsSaga: ${e}`);
  }
};

export const getAppearanceStatsByIdSaga = function* getAppearanceStatsByIdSaga(
  action: ReturnType<typeof getAppearanceStatsById>
): SagaIterator {
  try {
    const response = yield call(api.getAppearanceStatsById, action.payload);
    const { data }: { data: AppearanceStatsResponse } = response;

    yield put(setAppearanceStats(data));
  } catch (e) {
    errorLogger(true, `getAppearanceStatsByIdSaga: ${e}`);
  }
};

export const getWeeklyAppearanceStatsSaga = function* getWeeklyAppearanceStatsSaga(
  action: ReturnType<typeof getWeeklyAppearanceStats>
): SagaIterator {
  try {
    const response = yield call(api.getWeeklyAppearanceStats, action.payload);
    const { data }: { data: AppearanceStatsResponse } = response;

    yield put(setWeeklyAppearanceStats(data));
  } catch (e) {
    errorLogger(true, `getWeeklyAppearanceStatsSaga: ${e}`);
  }
};
