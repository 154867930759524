import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';
import { setAllSitAndGos } from '@/store/modules/sit-and-gos/actions';

import * as lobbyApi from '@/api/lobbies';
import { SitAndGosResponse } from '@/interfaces/lobbies';
import errorLogger from '@/utilities/errors/logger';

export const getAllSitAndGosSaga = function* getAllSitAndGosSaga(): SagaIterator {
  try {
    const response = yield call(lobbyApi.getAllSitAndGos);
    const { data }: { data: SitAndGosResponse } = response;

    yield put(setAllSitAndGos({ data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
    errorLogger(true, `getAllSitAndGosSaga: ${e}`);
  }
};
