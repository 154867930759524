import { EntryCollection } from 'contentful';

import { IStateConfigurationFields } from '@/interfaces/contentful';

export const GET_STATE_CONFIGURATION = 'config/GET_STATE_CONFIGURATION' as const;
export const SET_STATE_CONFIGURATION = 'config/SET_STATE_CONFIGURATION' as const;

export const getStateConfigurations = () => ({ type: GET_STATE_CONFIGURATION });

export const setStateConfigurations = (payload: {
  stateConfigurations: EntryCollection<IStateConfigurationFields> | Record<string, never>;
}) => ({ type: SET_STATE_CONFIGURATION, payload });
