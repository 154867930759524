import { AxiosResponse } from 'axios';

import request from '@request';
import { MatchesResponse } from '@/interfaces/matches';
import { SportId } from '@/interfaces/types';
import { apiErrorTracking, AppError } from '@/utilities/errors';

/*
 * Get matches for a slate
 */
export const getSlateMatches = async ({
  slateId,
}: {
  slateId: string;
}): Promise<AxiosResponse<MatchesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/slates/${slateId}/matches`,
      underdogAPIVersion: 'v2',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get matches for a date
 */
export const getDateMatches = async ({
  sportId,
  date,
}: {
  sportId: SportId;
  date: string;
}): Promise<AxiosResponse<MatchesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/sports/${sportId}/matches`,
      params: {
        date,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get matches for a week
 */
export const getWeeklyMatches = async ({
  weekId,
}: {
  weekId: number;
}): Promise<AxiosResponse<MatchesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/weeks/${weekId.toString()}/matches`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
