import React from 'react';
import clsx from 'clsx';

import styles from './styles.scss';

export interface LoaderProps {
  className?: string;
  message?: string;
}

const Loader = (props: LoaderProps) => {
  const { className, message } = props;
  return (
    <div className={styles.loaderWrapper}>
      <div className={clsx(styles.loadingRing, className)}>
        <div />
        <div />
        <div />
        <div />
      </div>
      {message && <p className={styles.message}>{message}</p>}
    </div>
  );
};

export default Loader;
