import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/pick-em';
import { RedeemablePowerUp } from '@/interfaces/power-ups';
import errorLogger from '@/utilities/errors/logger';

import {
  getAlternateProjections,
  getLivePickEmEntries,
  getPickEm,
  getPickEmPoolLeaderboard,
  getPickEmPoolUserLeaderboard,
  getSettledPickEmEntries,
  getSlipPayoutOutcome,
  setAlternateProjections,
  setEntrySlipLimits,
  setInitialAltSelection,
  setInitialPickEmSelections,
  setLivePickEmEntries,
  setPickEmFeaturedLobby,
  setPickEmOverUnderLines,
  setPickEmPoolLeaderboard,
  setPickEmPoolUserLeaderboard,
  setPickEmRivalLines,
  setPoolV1Styles,
  setPoolV2Styles,
  setSettledPickEmEntries,
  setSlipPayoutOutcome,
} from '../actions';

export const getPayoutOutcomeSaga = function* getPayoutOutcomeSaga(
  action: ReturnType<typeof getSlipPayoutOutcome>
): SagaIterator {
  try {
    const { entryRoles, options, powerUpId } = action.payload;
    const response = yield call(api.getSlipPayoutOutcome, {
      options,
      powerUpId,
    });
    yield put(setSlipPayoutOutcome({ data: response.data, entryRoles }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

// get featured_lobby, over_under, live_over_under, rivals all at once
export const getPickEmSaga = function* getPickEmSaga(
  action: ReturnType<typeof getPickEm>
): SagaIterator {
  try {
    const { isAlternateProjectionsEnabled } = action.payload;

    const [
      entrySlipLimitsResponse,
      overUnderLineResponse,
      liveOverUnderLineResponse,
      rivalLineResponse,
      favoritesResponse,
      featuredLobbyResponse,
      featuredOverUnderLineResponse,
    ] = yield all([
      call(api.getEntrySlipLimits),
      call(api.getOverUnderLines, { isAlternateProjectionsEnabled }),
      call(api.getLiveOverUnderLines),
      call(api.getRivalLines),
      call(api.getFavoritePickEmPlayers),
      call(api.getFeaturedLobby),
      call(api.getFeaturedOverUnderLines),
    ]);

    yield put(
      setEntrySlipLimits({
        limits: entrySlipLimitsResponse.data.limits,
      })
    );

    yield put(
      setPickEmOverUnderLines({
        preGameOverUnderLines: overUnderLineResponse.data,
        liveOverUnderLines: liveOverUnderLineResponse.data,
        featuredOverUnders: featuredOverUnderLineResponse.data,
        draftingConfig: action.payload.draftingConfig,
        featuredLobby: featuredLobbyResponse.data,
        pickEmStateConfig: action.payload.pickEmStateConfig,
        roles: action.payload.roles,
      })
    );

    yield put(
      setPickEmRivalLines({
        data: rivalLineResponse.data,
        favoritePlayers: favoritesResponse?.data?.favorite_players,
        draftingConfig: action.payload.draftingConfig,
        pickEmStateConfig: action.payload.pickEmStateConfig,
        roles: action.payload.roles,
      })
    );

    yield put(
      setPickEmFeaturedLobby({
        data: featuredLobbyResponse.data,
        pickEmStateConfig: action.payload.pickEmStateConfig,
        draftingConfig: action.payload.draftingConfig,
        rivalLines: rivalLineResponse.data.rival_lines,
        roles: action.payload.roles,
      })
    );

    /**
     * On app load, add non-alt selections from local storage into the entry slip, but filter
     * out any lines that are not active anymore. The reason we do this here and not
     * within a component is because we need to know the active lines to determine
     * if the selections from local storage are still valid. However, to pass in that
     * information through a component, we need to take dependencies on
     * state.pickEmOverUnder.overUnderLines which causes several unwanted re-renders
     * of the component. It's also helpful that all the data is loaded into the store
     * here at once.
     */
    const overUnderLines = yield select((state) => state.pickEmOverUnder.overUnderLines);
    yield put(
      // on initial load
      setInitialPickEmSelections({
        overUnderLines,
        rivalLines: rivalLineResponse.data.rival_lines,
        powerUps: featuredLobbyResponse.data.power_ups,
      })
    );
  } catch (e) {
    errorLogger(true, `getPickEmSaga: ${e}`);
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getAlternateProjectionsSaga = function* getAlternateProjectionsSaga(
  action: ReturnType<typeof getAlternateProjections>
): SagaIterator {
  try {
    const response = yield call(api.getAlternateProjections, action.payload);
    yield put(
      /**
       * On app load, if we find any alts in the local storage,
       * we need to load them into the entry slip. They need to
       * be loaded separately from `setInitialPickEmSelections()` above
       * because we need to fetch the alternate projections before
       * we can validate them.
       */
      setAlternateProjections({
        altProjectionsResponse: response.data,
        ...(action.payload.alternateLineIdToChoose && {
          alternateLineIdToChoose: action.payload.alternateLineIdToChoose,
        }),
        ...(action.payload.constructedAppearanceId && {
          constructedAppearanceId: action.payload.constructedAppearanceId,
        }),
        ...(action.payload.alternateOptionToChoose && {
          alternateOptionToChoose: action.payload.alternateOptionToChoose,
        }),
      })
    );
    if (action.payload.loadPicksFromLocalStorage) {
      const powerUps: RedeemablePowerUp[] = yield select(
        (state) => state.pickEmFeaturedLobby?.powerUps
      );
      yield put(
        setInitialAltSelection({
          altProjections: response.data,
          overUnderId: action.payload.overUnderId,
          powerUps,
        })
      );
    }
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getLivePickEmEntrySlipsSaga = function* getLivePickEmEntrySlipsSaga(
  action: ReturnType<typeof getLivePickEmEntries>
): SagaIterator {
  try {
    const response = yield call(api.getActiveEntrySlips, action.payload);

    yield put(setLivePickEmEntries({ data: response.data, pageNumber: action.payload.page }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getPickEmPoolLeaderboardSaga = function* getPickEmPoolLeaderboardSaga(
  action: ReturnType<typeof getPickEmPoolLeaderboard>
): SagaIterator {
  try {
    const response = yield call(api.getPickEmPoolLeaderboard, action.payload);

    yield put(setPickEmPoolLeaderboard({ data: response.data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getPickEmPoolUserLeaderboardSaga = function* getPickEmPoolUserLeaderboardSaga(
  action: ReturnType<typeof getPickEmPoolUserLeaderboard>
): SagaIterator {
  try {
    const response = yield call(api.getUserPickEmPoolsLeaderboardEntrySlip, action.payload);

    yield put(setPickEmPoolUserLeaderboard({ data: response.data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getSettledPickEmEntrySlipsSaga = function* getSettledPickEmEntrySlipsSaga(
  action: ReturnType<typeof getSettledPickEmEntries>
): SagaIterator {
  try {
    const response = yield call(api.getSettledEntrySlips, action.payload);

    yield put(setSettledPickEmEntries({ data: response.data, pageNumber: action.payload.page }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getPoolV1StylesSaga = function* getPoolV1StylesSaga(): SagaIterator {
  try {
    const response = yield call(api.getPoolV1Styles);

    yield put(setPoolV1Styles({ data: response.data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getPoolV2StylesSaga = function* getPoolV2StylesSaga(): SagaIterator {
  try {
    const response = yield call(api.getPoolV2Styles);

    yield put(setPoolV2Styles({ data: response.data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
