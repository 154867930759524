import React, { useState } from 'react';
import { connect } from 'react-redux';

import { RootState } from '@/store';

import Button from '@/components/atoms/button';
import Icon from '@/components/atoms/icon';
import { useModal } from '@/components/atoms/modal';
import { useToast } from '@/components/atoms/toast';
import DialogModal from '@/components/molecules/dialog-modal';
import { ErrorContext } from '@/interfaces/error';
import { User } from '@/interfaces/user';
import { askForLocation } from '@/utilities/location';

import styles from './styles.scss';

export interface LocationModalProps {
  handleCloseModal: () => void;
  content?: JSX.Element;
  confirmAction?: () => void;
  customClickAway?: () => void;
  dismissAction?: () => void;
  errorContext?: ErrorContext;
  user: User;
}

const LocationModal = (props: LocationModalProps) => {
  const {
    handleCloseModal,
    content,
    confirmAction,
    customClickAway,
    dismissAction,
    errorContext,
    user,
  } = props;
  const openModal = useModal();
  const openToast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [showActions, setShowActions] = useState(true);
  const [message, setMessage] = useState(
    content ||
      // eslint-disable-next-line max-len
      'We could not detect your location, we need it for regulatory reasons and to provide you with the best experience. Please enable location permissions or contact support@underdogfantasy.com'
  );

  try {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'denied') {
        // eslint-disable-next-line max-len
        setMessage(
          "We don't have permission to get your location, please update your browser settings and try again or contact support@underdogfantasy.com"
        );
        setShowActions(false);
      }
    });
  } catch (e) {
    openToast({
      message: "Your browser doesn't support geolocation permissions",
      open: true,
    });
  }

  const handleConfirm = () => {
    const onAsk = () => setIsLoading(true);
    const onSuccess = () => {
      if (confirmAction) confirmAction();
      setIsLoading(false);
      handleCloseModal();
    };
    const onFailure = () => handleCloseModal();

    askForLocation({
      onAsk,
      onSuccess,
      onFailure,
      errorContext,
      userId: user.id,
      triggerGeoComply: true,
    });
  };

  const handleCustomDismissAction = () => {
    if (dismissAction) dismissAction();
    handleCloseModal();
  };

  const handleDismiss = () => {
    // If they dismiss the location services modal, we ask them again, and inform them that we need it
    openModal(
      () => (
        <DialogModal
          handleCloseModal={handleCloseModal}
          title="Are you sure?"
          content={
            <p>
              {/* eslint-disable-next-line max-len */}
              Before you can enter contests or deposit money, regulators require that we have your
              location.
            </p>
          }
          confirmText="Enable location"
          confirmAction={handleConfirm}
          dismissText="Do not allow location"
          dismissAction={dismissAction}
        />
      ),
      {
        customClickAway,
      }
    );
    // No need to close this modal as the new modal now handles closing
  };

  return (
    <div className={styles.locationModal}>
      <div className={styles.headerSection}>
        <h1 className={styles.title}>We need your location</h1>
        <button
          className={styles.closeButton}
          onClick={handleCustomDismissAction}
          aria-label="Close"
        >
          <Icon name="clear" />
        </button>
      </div>
      <div className={styles.contentScrollable}>
        {isLoading ? <p>Waiting on location...</p> : message}
      </div>
      {!isLoading && showActions && (
        <div className={styles.buttonSection}>
          <div className={styles.button}>
            <Button
              type="text"
              text="No thanks"
              size="small"
              width="intrinsic"
              color="grey"
              onClick={handleDismiss}
              classNames={{
                button: styles.dismissButton,
              }}
            />
          </div>
          <div className={styles.button}>
            <Button
              type="text"
              text="Yes, I will"
              size="small"
              width="intrinsic"
              onClick={handleConfirm}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default connect((state: RootState) => ({
  user: state.user,
}))(LocationModal);
