import * as contentful from 'contentful';

const host = process.env.APP_ENV === 'production' ? 'cdn.contentful.com' : 'preview.contentful.com';

const client = contentful.createClient({
  space: process.env.CONTENTFUL_SPACE_ID,
  // We use a preview access token for non-production environments
  accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
  host,
});

export default client;
