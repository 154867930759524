import { EntryCollection } from 'contentful';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';
import { setUnderdogCareersFaqs } from '@/store/modules/underdog-careers-faqs/actions';

import * as api from '@/api-contentful/careers-faqs';
import { ICareersFaqFields } from '@/interfaces/contentful';

export const getUnderdogCareersFaqsSaga = function* getUnderdogCareersFaqsSaga(): SagaIterator {
  try {
    // eslint-disable-next-line max-len
    const contentfulCareersFaqs: EntryCollection<ICareersFaqFields> = yield call(
      api.getContentfulCareersFaqs
    );

    yield put(setUnderdogCareersFaqs({ contentfulCareersFaqs }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
