import { Source } from '@/interfaces/drafts';
/* eslint-disable camelcase */
import { LiveDraftForSlate, LiveDraftForSlateResponse } from '@/interfaces/live';
import { toCamel } from '@/utilities/helpers';

const liveDraftForSlateAdapter = (drafts: LiveDraftForSlateResponse[]): LiveDraftForSlate[] =>
  drafts.map((draft) => {
    const {
      id,
      clock,
      contest_style_id,
      draft_at,
      entry_count,
      entry_role,
      entry_style_id,
      slate_id,
      source: sourceResponse,
      status,
      title,
      source_entry_style_id,
      tournament_id,
      user_draft_entry_id,
      user_draft_entry_payout,
      user_draft_entry_payout_text,
      user_draft_entry_place,
      user_draft_entry_points,
    } = draft;

    return {
      id,
      clock,
      contestStyleId: contest_style_id,
      draftAt: draft_at,
      entryCount: entry_count,
      entryRole: entry_role,
      entryStyleId: entry_style_id,
      slateId: slate_id,
      source: toCamel(sourceResponse) as Source,
      status,
      title,
      sourceEntryStyleId: source_entry_style_id,
      tournamentId: tournament_id,
      userDraftEntryId: user_draft_entry_id,
      userDraftEntryPayout: user_draft_entry_payout,
      userDraftEntryPayoutText: user_draft_entry_payout_text,
      userDraftEntryPlace: user_draft_entry_place,
      userDraftEntryPoints: user_draft_entry_points,
    };
  });

export default liveDraftForSlateAdapter;
