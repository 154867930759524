import { ConstructedUserPromotion, UserPromotionsResponse } from '@/interfaces/promotions';

import {
  DISMISS_USER_PROMOTION,
  dismissUserPromotion,
  SET_USER_PROMOTIONS,
  setUserPromotions,
} from '../actions';
import userPromotionAdapter from '../adapters/user-promotion';

type State = ConstructedUserPromotion | null;

type UserPromotionsActions = ReturnType<typeof setUserPromotions | typeof dismissUserPromotion>;

const initialState: ConstructedUserPromotion = null;

const set = ({ data }: { data: UserPromotionsResponse }): ConstructedUserPromotion => {
  const userPromotions = data?.user_promotions?.map(userPromotionAdapter);

  return {
    userPromotion: userPromotions?.[0] || null,
    dismissed: false,
  };
};

const dismiss = (state: ConstructedUserPromotion): ConstructedUserPromotion => ({
  ...state,
  dismissed: true,
});

export const userPromotionsReducer = (
  state: State = initialState,
  action: UserPromotionsActions = {} as UserPromotionsActions
): State => {
  switch (action.type) {
    case SET_USER_PROMOTIONS:
      return set(action.payload);
    case DISMISS_USER_PROMOTION:
      return dismiss(state);
    default:
      return state;
  }
};
