import { getOverUnderResultStatus } from '@/components/pages/pick-em/helpers';
import { ConstructedEntrySlipPickEmOverUnderAppearance } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { ConstructedPickEmRivalLine } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { LiveStatLines } from '@/interfaces/constructed-interfaces/constructed-slates';
import { EntrySlip } from '@/interfaces/pick-em';

export const getMultiplier = ({
  entrySlip,
  lossCount,
}: {
  entrySlip: EntrySlip;
  lossCount: number;
}) => {
  // looks like this only works for non-champions
  const currentMaxPayoutMultiplier = parseFloat(entrySlip.currentMaxPayoutMultiplier);
  const initialMaxPayoutMultiplier = parseFloat(entrySlip.initialMaxPayoutMultiplier);

  if (entrySlip.status !== 'settled') return initialMaxPayoutMultiplier;
  if (parseFloat(entrySlip.payout) === 0) return 0;
  if (entrySlip.insured && lossCount > 0) {
    const fallback = entrySlip.insuranceFallbacks?.find((iF) => iF.lossCount === lossCount);
    return parseFloat(fallback?.currentMaxPayoutMultiplier) || 0;
  }
  return currentMaxPayoutMultiplier;
};

export const getViableSelections = ({
  cESOUAS,
  cRLS,
  liveStatLines,
}: {
  cESOUAS: ConstructedEntrySlipPickEmOverUnderAppearance[];
  cRLS: ConstructedPickEmRivalLine[];
  liveStatLines: LiveStatLines;
}) => {
  const overUnderSelections = (cESOUAS || []).reduce((acc, cOUS) => {
    if (cOUS.selection.result === 'pushed') return acc;

    acc.push(getOverUnderResultStatus({ cA: cOUS, liveStatLines }));
    return acc;
  }, []);
  const rivalSelections = (cRLS || []).reduce((acc, cRL) => {
    if (cRL.selection.result === 'pushed') return acc;

    const result =
      cRL.selection.inPlay && cRL.selection.result === 'pending' ? 'live' : cRL.selection.result;
    acc.push(result);
    return acc;
  }, []);

  return [...overUnderSelections, ...rivalSelections];
};
