/* eslint-disable camelcase */
import { Score, ScoreResponse } from '@/interfaces/constructed-interfaces/constructed-slates';

const scoreAdapter = (score: ScoreResponse): Score => {
  const { id, points, scoring_type_id } = score;

  return {
    id,
    points,
    scoringTypeId: scoring_type_id,
  };
};

export default scoreAdapter;
