import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { IconTypes } from '@/components/atoms/icon';
import Label, { LabelProps } from '@/components/atoms/label';
import { useTimer } from '@/components/atoms/timer';
import { numberToTime } from '@/utilities/helpers';

import styles from './styles.scss';

interface CountdownLabelProps {
  color?: LabelProps['color'];
  className?: string;
  expiresAt: string;
  iconName?: IconTypes;
}

/**
 * Currently this countdown type only exists in a label. It should remain separate from
 * the PromoCards and modals to prevent unnecessary state changes.
 * In the future, it can be abstracted out if ever needed outside of a Label.
 */
const CountdownLabel = (props: CountdownLabelProps) => {
  const { color = 'dark', className, expiresAt, iconName } = props;
  const time = useTimer(expiresAt);

  const formattedExpiredAt = dayjs(expiresAt);
  const days = formattedExpiredAt.diff(dayjs(), 'days');

  const expiredAtCountdown = numberToTime(time, {
    timestampFormat: days > 0 ? 'DD:HH:mm:ss' : 'HH:mm:ss',
    timestampFormatCutoff: Infinity,
  });

  return (
    <Label
      classNames={{ label: clsx(styles.countdownLabel, className) }}
      color={color}
      iconNameLeft={iconName}
      text={expiredAtCountdown}
      type="neutral"
      data-chromatic="ignore"
    />
  );
};
export default CountdownLabel;
