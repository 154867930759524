import React from 'react';
import { ReactFitty } from 'react-fitty';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '@/store';

import Avatar from '@/components/atoms/avatar';
import Icon from '@/components/atoms/icon';
import { ConstructedPickEmRivalLineAppearance } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { Teams } from '@/interfaces/drafting-config';
import { PlayerCellRivalLine, RivalOption } from '@/interfaces/pick-em';
import useTeamColors from '@/utilities/hooks/use-team-colors';
import langHelper from '@/utilities/lang-helper';

import styles from './styles.scss';

const TeamStripe = (props: { className: string; backgroundColor: string }) => {
  const { className, backgroundColor } = props;
  return <div className={className} style={{ backgroundColor }} />;
};
export interface RivalPlayerCellProps {
  constructedAppearance: ConstructedPickEmRivalLineAppearance;
  teams: Teams;
  selectedOptionId: string;
  className?: string;
  handleOptionClick?: ({
    id,
    option,
    constructedPickEmRivalAppearance,
  }: // not SelectRival (because it doesn't need the rivalLine)
  {
    id: string;
    option: RivalOption;
    constructedPickEmRivalAppearance: ConstructedPickEmRivalLineAppearance;
  }) => void;
  handlePlayerClick?: (appearanceId: string) => void;
  hideStripes?: boolean;
  disableButtons?: boolean; // used to disable buttons for outgoing shared modal
  liveRivalLine?: PlayerCellRivalLine;
}

const RivalPlayerCell = (props: RivalPlayerCellProps) => {
  const {
    constructedAppearance: cA,
    teams,
    selectedOptionId,
    handleOptionClick,
    handlePlayerClick,
    className,
    hideStripes,
    disableButtons = false,
    liveRivalLine,
  } = props;
  const [primaryColor, secondaryColor] = useTeamColors({ team: cA?.team, sport: cA?.sport });

  const selected = cA.rivalOption.id === selectedOptionId;

  const keyboardSelect = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (disableButtons) return;
    if ((event.key === 'Enter' || event.key === ' ') && handlePlayerClick) {
      handlePlayerClick(cA.id);
    }
  };

  const spreadToUse = cA?.sharedSpread?.current || cA.rivalOption?.spread;

  let statValueDirectionChange: null | 'up' | 'down' = null;
  if (parseFloat(cA.sharedSpread?.current) - parseFloat(cA.sharedSpread?.previous) < 0) {
    statValueDirectionChange = 'down';
  }
  if (parseFloat(cA.sharedSpread?.current) - parseFloat(cA.sharedSpread?.previous) > 0) {
    statValueDirectionChange = 'up';
  }

  const playerFullName = langHelper.getPlayerFullName(cA.player);

  return (
    <div className={styles.rivalPlayerCellWrapper} data-testid="rival-player-cell">
      <div
        className={clsx(styles.rivalPlayerCell, className, { [styles.selectedBorder]: selected })}
        role="button"
        onClick={() => {
          handleOptionClick({
            id: cA.rivalOption.rivalLineId, // set id to rivalLineId, as that's easier to look up by
            option: cA.rivalOption,
            constructedPickEmRivalAppearance: cA,
          });
        }}
        tabIndex={0}
        onKeyDown={keyboardSelect}
        data-appearance-id={cA.id}
      >
        <div className={styles.topHalf}>
          <Icon
            name="checkCircle"
            className={clsx(styles.selectedCheckIcon, { [styles.selectedFill]: selected })}
          />
          <div className={styles.player}>
            {!hideStripes && (
              <>
                <TeamStripe
                  className={styles.teamStripeSecondary}
                  backgroundColor={selected ? secondaryColor : ''}
                />
                <TeamStripe
                  className={styles.teamStripePrimary}
                  backgroundColor={selected ? primaryColor : ''}
                />
              </>
            )}
            <Avatar
              url={cA.player.imageUrl}
              customPlaceholder="placeholderPlayer"
              classNames={{
                wrapper: styles.avatarWrapper,
                avatar: styles.avatar,
              }}
            />
          </div>
          <div
            className={clsx(styles.playerInfoIcon, { [styles.hideButton]: disableButtons })}
            onClick={(e) => {
              e.stopPropagation();
              if (disableButtons) return;
              handlePlayerClick?.(cA.id);
            }}
            data-testid="player-info-button"
            role="button"
            tabIndex={0}
            onKeyDown={keyboardSelect}
            aria-label="Open player card"
          >
            <Icon name="barGraph" />
          </div>
        </div>
        <div className={styles.bottomHalf}>
          <div className={styles.playerInfo}>
            <div className={styles.playerName} title={playerFullName} data-testid="player-name">
              <ReactFitty minSize={12} maxSize={16} wrapText>
                {playerFullName}
              </ReactFitty>
            </div>
            <div className={styles.matchInfo}>
              {liveRivalLine?.liveEvent && liveRivalLine.liveEventStat ? (
                <>
                  <p className={styles.matchInfoText}>
                    {langHelper.matchTextWithScore({
                      match: cA.match,
                      team: cA.team,
                      teams,
                      soloGame: cA.soloGame,
                      sport: cA.sport,
                      isRivalLobby: true,
                    })}
                  </p>
                  <div className={styles.inGameProjectionWrapper}>
                    <Icon name="ellipse" className={styles.inGameProjectionStatIcon} />
                    <div className={styles.inGameProjectionStat}>
                      Current stat: {liveRivalLine.liveEventStat}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.matchName}>
                    <Icon className={styles.sportIcon} name={cA.sport?.icon} />
                    <p className={styles.matchInfoText}>
                      {langHelper.matchText({
                        gameType: cA.sport.gameType,
                        match: cA.match,
                        soloGame: cA.soloGame,
                        team: cA.team,
                        teams,
                      })}
                    </p>
                  </div>
                  <p className={styles.matchInfoText}>
                    {langHelper.timeToDayOrDate(cA.match?.scheduledAt || cA.soloGame?.scheduledAt)}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {spreadToUse && (parseFloat(spreadToUse) !== 0 || statValueDirectionChange) && (
        <div className={clsx(styles.spreadBox, className, { [styles.selectedBorder]: selected })}>
          <Icon className={styles.adjustmentIcon} name="adjustment" />
          <p className={clsx({ [styles.valueChanged]: statValueDirectionChange })}>
            {`${spreadToUse} Adjustment`}
          </p>
          {statValueDirectionChange && (
            <Icon
              className={styles.lineChangeIcon}
              name={`${statValueDirectionChange}load` as 'upload' | 'download'}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default connect((state: RootState) => ({
  teams: state.draftingConfig.teams,
}))(RivalPlayerCell);
