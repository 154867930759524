import Cookies from 'js-cookie';

import { ErrorContext } from '@/interfaces/error';
import errorLogger from '@/utilities/errors/logger';
// eslint-disable-next-line import/no-cycle
import geoService from '@/utilities/location/geocomply';

import { provincesArray } from './constants';

// Important: latitude and longitude are considered sensitive data, so we
// should not store them in plain text in any browser storage. Instead, we
// only cache them in memory. Unfortunately, this does mean that the cache
// will be cleared if the user refreshes the page, and the cache cannot
// be shared between tabs. The alternative is to encrypt the data and store it,
// but that would require an API endpoint for both encryption and decryption, which
// would be overkill for this use case.
let locationCache: { lat: string | null; long: string | null } = {
  lat: null,
  long: null,
};

export const getLocationHeaders = async ({ required }: { required?: boolean } = {}): Promise<{
  lat: string | null;
  long: string | null;
}> => {
  // When the app is running in the context of a mobile app webview, the webview
  // may set the `ud_latitude` and `ud_longitude` cookies with the lat/long values.
  // If both of those cookies are set, we need to use them as the lat/long values.
  const delegatedLat = Cookies.get('ud_latitude');
  const delegatedLong = Cookies.get('ud_longitude');
  if (delegatedLat && delegatedLong) {
    return {
      lat: delegatedLat,
      long: delegatedLong,
    };
  }

  // If we have the lat and long values in cache, then return them.
  // Or, if lat or long are null and we don't require them, then return whatever values we have.
  if ((locationCache.lat && locationCache.long) || !required) {
    return locationCache;
  }

  // If navigator permissions aren't a thing, like in safari < 16, then just get the location straight up
  if (typeof navigator.permissions === 'undefined') {
    try {
      const result = await getNavigatorGeolocationPosition();
      locationCache = result;

      return result;
    } catch (e) {
      return { lat: null, long: null };
    }
  }

  // Otherwise, retrieve the permissions we have.
  const geoPermissionStatus = await navigator.permissions.query({ name: 'geolocation' });

  // We also need to watch for permission changes so we can clear the lat/long
  // values we may have stored.
  // eslint-disable-next-line no-param-reassign
  geoPermissionStatus.onchange = () => {
    // If the user removes their location permissions, then clear the cache
    if (geoPermissionStatus.state !== 'granted') {
      locationCache = { lat: null, long: null };
    }
  };

  // If we have permission to get position, then do so and return.
  if (geoPermissionStatus.state === 'granted') {
    try {
      const result = await getNavigatorGeolocationPosition();
      locationCache = result;

      return result;
    } catch (e) {
      return { lat: null, long: null };
    }
  }

  return { lat: null, long: null };
};

async function getNavigatorGeolocationPosition(): Promise<{
  lat: string;
  long: string;
}> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        const lat = location.coords.latitude.toString();
        const long = location.coords.longitude.toString();

        resolve({ lat, long });
      },
      (positionError) => {
        errorLogger(true, 'Error getting location', { positionError });
        reject(positionError);
      },
      {
        timeout: 10000,
      }
    );
  });
}

export const logoutLocation = () => {
  geoService.endUserSession();
};

/**
 * This just checks if the user has granted permission and returns true or false
 */
export const checkLocationPermissionsGranted = async () =>
  new Promise((resolve) => {
    try {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          resolve(true);
        }
        resolve(false);
      });
    } catch (e) {
      resolve(false);
    }
  });

/**
 * Ask for the user's location and store it in cache if successful
 */
export const askForLocation = ({
  onAsk,
  onSuccess,
  onFailure,
  errorContext,
  userId,
  triggerGeoComply,
}: {
  onAsk?: () => void;
  onSuccess?: () => void;
  onFailure?: (err?: any) => void;
  errorContext?: ErrorContext;
  userId?: string;
  triggerGeoComply?: boolean;
} = {}): void => {
  if (onAsk) onAsk();
  if (triggerGeoComply) {
    if (geoService.initialized) {
      geoService
        .getToken()
        .then(() => {
          if (onSuccess) onSuccess();
        })
        .catch((e) => {
          errorLogger(true, `Error on askForLocation - ${JSON.stringify(errorContext)}`, {
            value: JSON.stringify(e),
          });
        });
    } else {
      geoService
        .init({ userId })
        .then(() => {
          geoService.getToken().then(() => {
            if (onSuccess) onSuccess();
          });
        })
        .catch((e) => {
          errorLogger(true, `Error on askForLocation - ${JSON.stringify(errorContext)}`, {
            value: JSON.stringify(e),
          });
        });
    }
  }

  navigator.geolocation.getCurrentPosition(
    (resp) => {
      locationCache = {
        lat: resp.coords.latitude.toString(),
        long: resp.coords.longitude.toString(),
      };

      if (onSuccess) onSuccess();
    },
    (err) => {
      if (err.code === 1) {
        // denied permission
        errorLogger(
          true,
          `You denied us location permission: ${err.code} ${err.message}`,
          errorContext
        );
      }
      if (err.code === 2) {
        // couldn't get location
        errorLogger(true, `Could not get location: ${err.code} ${err.message}`, errorContext);
      }
      if (err.code === 3) {
        // timed out
        errorLogger(true, `Location timed out: ${err.code} ${err.message}`, errorContext);
      }
      if (onFailure) onFailure(err);
    },
    {
      timeout: 10000,
    }
  );
};

/**
 * Watch for permission changes (if a user already has location and refreshes, then the onchange binding goes away)
 */
export const watchLocationPermissionChanges = () => {
  try {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      // eslint-disable-next-line no-param-reassign
      result.onchange = () => {
        // if the user removes their location permissions, then clear the cache
        if (result.state !== 'granted') {
          locationCache = { lat: null, long: null };
        }
      };
    });
  } catch (e) {
    // swallow
  }
};

/**
 * Return Country based on State
 */
export const stateProvinceToCountry = (stateProvince: string): string => {
  if (provincesArray.includes(stateProvince)) return 'CAN';
  switch (stateProvince) {
    case 'AS':
      return 'ASM';
    case 'GU':
      return 'GUM';
    case 'MP':
      return 'MNP';
    case 'PR':
      return 'PRI';
    case 'UM':
      return 'UMI';
    case 'VI':
      return 'VIR';
    default:
      return 'USA';
  }
};
