import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setActiveDrafts } from '@/store/modules/active-drafts/actions';
import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/active';

export const getActiveDraftsSaga = function* getActiveDraftsSaga(): SagaIterator {
  try {
    const response = yield call(api.getActiveDrafts);
    yield put(setActiveDrafts(response.data));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
