/* eslint-disable camelcase */
import { InsuranceFallback, InsuranceFallbackResponse } from '@/interfaces/pick-em';

const insuranceFallbackAdapter = (
  insuranceFallback: InsuranceFallbackResponse
): InsuranceFallback => {
  const {
    current_max_payout_multiplier,
    initial_max_payout_multiplier,
    loss_count,
    unboosted_power_up_multiplier,
  } = insuranceFallback;

  return {
    currentMaxPayoutMultiplier: current_max_payout_multiplier,
    initialMaxPayoutMultiplier: initial_max_payout_multiplier,
    lossCount: loss_count,
    unboostedPowerUpMultiplier: unboosted_power_up_multiplier,
  };
};

export default insuranceFallbackAdapter;
