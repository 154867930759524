import { EntryCollection } from 'contentful';

import { IMiscSportsRulesFields, ISportsRulesFields } from '@/interfaces/contentful';

export const GET_MISC_SPORTS_RULES = 'rules/GET_MISC_SPORTS_RULES' as const;
export const SET_MISC_SPORTS_RULES = 'rules/SET_MISC_SPORTS_RULES' as const;
export const GET_SPORTS_RULES = 'rules/GET_SPORTS_RULES' as const;
export const SET_SPORTS_RULES = 'rules/SET_SPORTS_RULES' as const;

export const getMiscSportsRules = () => ({ type: GET_MISC_SPORTS_RULES });

export const getSportsRules = () => ({ type: GET_SPORTS_RULES });

export const setMiscSportsRules = (payload: {
  miscSportsRules: EntryCollection<IMiscSportsRulesFields>;
}) => ({ type: SET_MISC_SPORTS_RULES, payload });

export const setSportsRules = (payload: { sportsRules: EntryCollection<ISportsRulesFields> }) => ({
  type: SET_SPORTS_RULES,
  payload,
});
