/* eslint-disable camelcase */
import {
  TournamentRoundForSlate,
  TournamentRoundForSlateResponse,
} from '@/interfaces/tournament-rounds';

import { tournamentForTournamentRoundAdapter } from './tournament-for-tournament-round';

export const tournamentRoundAdapter = (
  tournamentRound: TournamentRoundForSlateResponse
): TournamentRoundForSlate => {
  const {
    id,
    abbr,
    description,
    draft_at,
    entry_style_id,
    final_round,
    leaderboard_enabled,
    leaderboard_url,
    number,
    paginated,
    prize_breakdown_title,
    slate_id,
    status,
    title,
    tournament: tournamentResponse,
    user_tournament_entry_count,
    user_tournament_entry_payouts,
    winnings,
  } = tournamentRound;

  const tournament = tournamentForTournamentRoundAdapter(tournamentResponse);

  return {
    id,
    abbr,
    description,
    draftAt: draft_at,
    entryStyleId: entry_style_id,
    finalRound: final_round,
    leaderboardEnabled: leaderboard_enabled,
    leaderboardUrl: leaderboard_url,
    number,
    paginated,
    prizeBreakdownTitle: prize_breakdown_title,
    slateId: slate_id,
    status,
    title,
    tournament,
    userTournamentEntryCount: user_tournament_entry_count,
    userTournamentEntryPayouts: user_tournament_entry_payouts,
    winnings,
  };
};
