import React from 'react';

import Icon from '@/components/atoms/icon';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import AltProjectionsModal from '@/components/molecules/alt-projections-modal';
import { OverUnderLine, PickLocation } from '@/interfaces/pick-em';

import styles from './styles.scss';

export interface AltsButtonProps {
  overUnderLine: OverUnderLine;
  pickLocation: PickLocation;
}

const AltsButton = (props: AltsButtonProps) => {
  const { overUnderLine, pickLocation } = props;
  const openModal = useModal();

  if (!overUnderLine) {
    return null;
  }

  const { hasAlternates } = overUnderLine;

  const handleOnClick = () => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <AltProjectionsModal
        overUnderLineId={overUnderLine.id}
        pickLocation={pickLocation}
        handleCloseModal={() => {
          handleCloseModal();
        }}
      />
    ));
  };

  return (
    <button
      className={styles.altsButton}
      onClick={(e) => {
        e.stopPropagation();
        handleOnClick();
      }}
      style={{ visibility: hasAlternates ? 'visible' : 'hidden' }}
    >
      <Icon name="upCaret" />
      <Icon name="downCaret" />
    </button>
  );
};

export default AltsButton;
