import React from 'react';
import { clsx } from 'clsx';

import DialogModal from '@/components/molecules/dialog-modal';
import { ConstructedEntrySlipPickEmOverUnderAppearance } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { ConstructedPickEmRivalLine } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { LiveStatLines } from '@/interfaces/constructed-interfaces/constructed-slates';
import { EntrySlip, InfoModalContentToShow } from '@/interfaces/pick-em';
import { contentMap } from '@/shared/pick-em-info-modal-content';
import langHelper from '@/utilities/lang-helper';

import { getMultiplier, getViableSelections } from './helpers';

import styles from './styles.scss';

interface PickEmInfoModalProps {
  handleCloseModal: () => void;
  contentToShow: InfoModalContentToShow;
  title:
    | 'Modified payouts'
    | 'Modified payouts and flex'
    | 'Flex'
    | 'Your payout'
    | "Pick'em Rescues";
  entrySlip?: EntrySlip;
  cESOUAS?: ConstructedEntrySlipPickEmOverUnderAppearance[];
  cRLS?: ConstructedPickEmRivalLine[];
  liveStatLines?: LiveStatLines;
}

/** The PickEmInfoModal can exist in the following states:
 * With no entrySlip. i.e. When the modal is opened outside the live and results pages

 ** The info modal on the Live page can be subdivided into two main types
 ** Live Standard:  When `isInsured` and `isResultSlip` are both false. Standard slips with at least one
 **  loss render a strikethrough the payment details category.

 ** Live Flex: When `isInsured` is true and `isResultSlip` is false.
 ** picks > 5 show three payout detail categories
 ** picks <= 5 and `isInsured` display two payout detail categories
 ** categories that are no longer attainable are displayed with a strikethrough

 ** Result Slips: One payout details category for the total number of correct selections
 */

const PickEmInfoModal = (props: PickEmInfoModalProps) => {
  const { cESOUAS, cRLS, handleCloseModal, contentToShow, liveStatLines, title, entrySlip } = props;

  const allContent = contentToShow.map((content) => {
    // We don't want to try to render a component if the content is null
    if (!content) return null;

    const Component = contentMap[content];

    return <Component key={content} />;
  });

  if (entrySlip) {
    /** all picks that are not of type `selection.result === 'pushed' */
    const viableSelections = getViableSelections({ cESOUAS, cRLS, liveStatLines });
    const selectionCount = viableSelections.length;

    const lossCount = viableSelections.filter((selection) => selection === 'lost').length;
    /** Insured slips have at least 3 viable picks and a value of `entrySlip.insured === true` */
    const isInsured = selectionCount > 2 && entrySlip.insured;
    const multiplier = getMultiplier({ entrySlip, lossCount });
    const isResultSlip = entrySlip.status === 'settled';
    /** use to determine when to display fallback payout details on Live Flex slips */
    const isLiveFlexSlip = isInsured && !isResultSlip;

    /** This determines the value displayed in the `Picks Correct` category. For result slips, we
     * display the number of correct (winning) picks on the slip. On live slips, we display the
     * total number of viable picks (all picks that don't have a `selection.result === 'pushed` )  */
    const picksCorrectCount = isResultSlip ? selectionCount - lossCount : selectionCount;
    const amountString = (multiplier * parseFloat(entrySlip.fee)).toString();

    /** Check if any live slip (both standard and flex) contains a loss. Used to determine when to
     * strike through payout detail categories that are no longer attainable. */
    const doesLiveSlipContainLoss = (count: number) => {
      return !isResultSlip && lossCount > count;
    };
    /** strikes out "All Correct" category when true */
    const containsLoss = doesLiveSlipContainLoss(0);
    /** strikes out "one loss (n-1 of n)" category when true */
    const containsOverOneLoss = doesLiveSlipContainLoss(1);
    /** strikes out "two loss (n-2 of n)" category when true */
    const containsOverTwoLosses = doesLiveSlipContainLoss(2);

    const fallbackWinContent = entrySlip.insuranceFallbacks?.map((iF) => {
      const insuredMultiplier = parseFloat(iF.currentMaxPayoutMultiplier);
      const insuredAmountString = (insuredMultiplier * parseFloat(entrySlip.fee)).toString();

      return (
        <p key={insuredAmountString}>
          <span
            className={clsx(styles.value, {
              [styles.strikethrough]: doesLiveSlipContainLoss(iF.lossCount),
              [styles.winning]: !doesLiveSlipContainLoss(iF.lossCount),
            })}
          >
            ${langHelper.formatCash(insuredAmountString)}
          </span>
        </p>
      );
    });

    const fallbackMultiplierContent = entrySlip.insuranceFallbacks?.map((iF) => {
      const insuredMultiplier = parseFloat(iF.currentMaxPayoutMultiplier);

      return (
        <p
          key={iF.currentMaxPayoutMultiplier}
          className={clsx(styles.value, {
            [styles.strikethrough]: doesLiveSlipContainLoss(iF.lossCount),
          })}
        >
          {insuredMultiplier}x
        </p>
      );
    });

    const payoutContent = (
      <section key="payoutContent">
        <h2>Payout details</h2>
        <ul className={styles.metaDataContainer}>
          <li className={styles.metaData}>
            <p className={styles.label}>Entry amount</p>
            <p className={styles.value}>${langHelper.formatCash(entrySlip.fee)}</p>
          </li>
          <li className={styles.metaData}>
            <p className={styles.label}>Picks correct</p>
            <p
              className={clsx(styles.value, {
                [styles.strikethrough]: containsLoss,
              })}
            >
              {picksCorrectCount} of {selectionCount}
            </p>
            {isLiveFlexSlip && (
              <p
                className={clsx(styles.value, {
                  [styles.strikethrough]: containsOverOneLoss,
                })}
              >
                {selectionCount - 1} of {selectionCount}
              </p>
            )}
            {
              // The second fallback is only displayed for slips with > 5 picks (forced flex)
              isLiveFlexSlip && selectionCount > 5 && (
                <p
                  className={clsx(styles.value, {
                    [styles.strikethrough]: containsOverTwoLosses,
                  })}
                >
                  {selectionCount - 2} of {selectionCount}
                </p>
              )
            }
          </li>
          <li className={styles.metaData}>
            <p className={styles.label}>Multiplier</p>
            <p
              className={clsx(styles.value, {
                [styles.strikethrough]: containsLoss,
              })}
            >
              {multiplier}x
            </p>
            {isLiveFlexSlip && fallbackMultiplierContent}
          </li>
          <li className={styles.metaData}>
            <p className={styles.label}>Wins up to</p>
            <p
              className={clsx(styles.value, {
                [styles.strikethrough]: containsLoss,
                [styles.winning]: !containsLoss,
              })}
            >
              ${langHelper.formatCash(amountString)}
            </p>
            {isLiveFlexSlip && fallbackWinContent}
          </li>
        </ul>
      </section>
    );

    allContent.unshift(payoutContent);
    if (contentToShow.length === 0) {
      const Component = contentMap.default;

      allContent.push(<Component key="default" />);
    }
  }

  return (
    <DialogModal
      title={title}
      handleCloseModal={handleCloseModal}
      content={<div>{allContent}</div>}
    />
  );
};

export default PickEmInfoModal;
