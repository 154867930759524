/* eslint-disable max-classes-per-file */
import { ApiErrorDataResponse, AppErrorResponse, type InvalidOptions } from '@/interfaces/error';

/**
 * @deprecated
 * All api calls that originate from the `request` module
 * already log/track errors, so this function should not be used
 * and will eventually be removed.
 */
export const apiErrorTracking = (error: {
  detail: string;
  message: string;
}): { message: string } => {
  // add error tracking in here
  const message: string = error.message || error.detail;
  // eslint-disable-next-line no-console
  console.warn('Error:', message);
  return {
    message,
  };
};

export const contactSupport = (url: string) => {
  if (!url) return;

  // If url contains zendesk, show intercom
  // if url is not zendesk, go to that url/page
  if (url.includes('zendesk')) {
    if (window.Intercom) {
      window.Intercom('show');
    }
  } else {
    window.location.href = encodeURI(url);
  }
};

export class APIError extends Error {
  apiCode?: string | number;

  ctaText?: string;

  ctaUrl?: string;

  entryErrorCode?: string;

  invalidOptions?: InvalidOptions[];

  message: string;

  secondaryCtaText?: string;

  secondaryCtaUrl?: string;

  status?: number;

  title?: string;

  constructor(apiErrorData: ApiErrorDataResponse) {
    super();
    // Set the prototype explicitly to ensure `instanceof` works correctly
    Object.setPrototypeOf(this, new.target.prototype);

    this.apiCode = apiErrorData.api_code;
    this.ctaText = apiErrorData.cta_text;
    this.ctaUrl = apiErrorData.cta_url;
    this.entryErrorCode = apiErrorData.entry_error_code;
    this.invalidOptions = apiErrorData.invalid_options?.map((invalidOption) => {
      return {
        id: invalidOption.id,
        lineId: invalidOption.line_id,
        propId: invalidOption.prop_id,
      };
    });
    this.message = apiErrorData.detail;
    this.secondaryCtaText = apiErrorData.secondary_cta_text;
    this.secondaryCtaUrl = apiErrorData.secondary_cta_url;
    this.status = apiErrorData.http_status_code || apiErrorData.status; // fix this somehow
    this.title = apiErrorData.title;
  }
}

// TODO [FAN-2616]: this error handling might be a little convoluted and
// I might lose error data by doing too much massaging
export class AppError extends Error {
  apiCode?: string | number;

  ctaText?: string;

  ctaUrl?: string;

  message: string;

  secondaryCtaText?: string;

  secondaryCtaUrl?: string;

  status?: number;

  title?: string;

  constructor(apiErrorData: AppErrorResponse) {
    super();
    // Set the prototype explicitly to ensure `instanceof` works correctly
    Object.setPrototypeOf(this, new.target.prototype);

    this.apiCode = apiErrorData.apiCode ?? undefined;
    this.ctaText = apiErrorData.ctaText;
    this.ctaUrl = apiErrorData.ctaUrl;
    this.message = apiErrorData.message ?? 'Unknown error';
    this.secondaryCtaText = apiErrorData.secondaryCtaText;
    this.secondaryCtaUrl = apiErrorData.secondaryCtaUrl;
    this.status = apiErrorData.status;
    this.title = apiErrorData.title;
  }
}
