import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import Icon from '@/components/atoms/icon';

import styles from './styles.scss';

interface CarouselVerticalProps {
  data: any[];
  selectedIndex?: number;
  customRowComponent?: React.ReactElement;
  onSelectIndex?: (index: number) => void;
}

const CarouselVertical = (props: CarouselVerticalProps) => {
  const { data, selectedIndex, customRowComponent, onSelectIndex } = props;

  const [activeIndex, setActiveIndex] = useState<number>(selectedIndex ?? 0);

  // Used to determine the height/spacing of each item
  const itemHeight = 56;

  const determinePlacement = (itemIndex: number) => {
    // If these match, the item is active
    if (activeIndex === itemIndex) return 0;

    return (itemIndex - activeIndex) * itemHeight;
  };

  const handleClick = (direction: string) => {
    setActiveIndex((prevIndex) => {
      if (direction === 'next') {
        if (prevIndex + 1 > data.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      }

      if (prevIndex - 1 < 0) {
        return data.length - 1;
      }

      return prevIndex - 1;
    });
  };

  useEffect(() => {
    onSelectIndex?.(activeIndex);
  }, [activeIndex, onSelectIndex]);

  return (
    <div className={styles.container}>
      <div className={styles.carouselWrapper}>
        <div className={styles.carousel}>
          <div className={styles.slides}>
            <div className={styles.carouselInner}>
              {data.map((item, i) => (
                <button
                  type="button"
                  onClick={() => setActiveIndex(i)}
                  className={clsx(
                    styles.carouselItem,
                    activeIndex === i ? styles.active : {},
                    activeIndex === i ? styles.visible : {}
                  )}
                  key={item.id}
                  style={{
                    transform: `translateY(${determinePlacement(i)}px)`,
                  }}
                >
                  {customRowComponent && React.cloneElement(customRowComponent, { item })}
                  {!customRowComponent && item}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.carouselButtonsWrapper}>
          <button
            type="button"
            className={styles.carouselButton}
            onClick={() => handleClick('prev')}
          >
            <Icon name="upCaret" />
          </button>
          <button
            type="button"
            className={styles.carouselButton}
            onClick={() => handleClick('next')}
          >
            <Icon name="downCaret" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarouselVertical;
