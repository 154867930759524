/* eslint-disable camelcase */

import { Team, TeamResponse } from '@/interfaces/drafting-config';

const teamAdapter = (team: TeamResponse): Team => {
  const {
    id,
    abbr,
    name,
    nickname,
    primary_color_dark_mode,
    primary_color_light_mode,
    secondary_color_dark_mode,
    secondary_color_light_mode,
    sport_id,
  } = team;

  return {
    id,
    abbr,
    name,
    nickname,
    primaryColorDarkMode: primary_color_dark_mode,
    primaryColorLightMode: primary_color_light_mode,
    secondaryColorDarkMode: secondary_color_dark_mode,
    secondaryColorLightMode: secondary_color_light_mode,
    sportId: sport_id,
  };
};

export default teamAdapter;
