import { EntryCollection } from 'contentful';

import { ITextPageFields } from '@/interfaces/contentful';

export const GET_TEXT_PAGES = 'config/GET_TEXT_PAGES' as const;
export const SET_TEXT_PAGES = 'config/SET_TEXT_PAGES' as const;

export const getTextPages = (payload: { id: string }) => ({ type: GET_TEXT_PAGES, payload });

export const setTextPages = (payload: {
  id: string;
  textPages: EntryCollection<ITextPageFields> | Record<string, never>;
}) => ({ type: SET_TEXT_PAGES, payload });
