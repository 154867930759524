/* eslint-disable camelcase */
import { IconTypes } from '@/components/atoms/icon';
import { Boost, BoostResponse } from '@/interfaces/pick-em';
import { toCamel } from '@/utilities/helpers/index';

const boostAdapter = (boost: BoostResponse): Boost => {
  const {
    id,
    boost_type,
    color,
    coming_soon_enabled,
    contentful_info_id,
    description,
    display_title,
    expired_at,
    icon,
    payout_style_id,
    payout_type,
    text_color,
  } = boost;

  return {
    id,
    boostType: toCamel(boost_type) as Boost['boostType'],
    color,
    comingSoonEnabled: coming_soon_enabled,
    contentfulInfoId: contentful_info_id,
    description,
    displayTitle: display_title,
    expiredAt: expired_at,
    icon: toCamel(icon) as IconTypes,
    payoutStyleId: payout_style_id,
    payoutType: payout_type,
    textColor: text_color,
  };
};

export default boostAdapter;
