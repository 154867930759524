import { UserPromotionsResponse } from '@/interfaces/promotions';

export const GET_USER_PROMOTIONS = 'promotions/GET_USER_PROMOTIONS' as const;
export const SET_USER_PROMOTIONS = 'promotions/SET_USER_PROMOTIONS' as const;
export const DISMISS_USER_PROMOTION = 'promotions/DISMISS_USER_PROMOTION' as const;

// Actions

export const getUserPromotions = () => ({ type: GET_USER_PROMOTIONS });

export const setUserPromotions = (payload: { data: UserPromotionsResponse }) => ({
  type: SET_USER_PROMOTIONS,
  payload,
});

export const dismissUserPromotion = () => ({ type: DISMISS_USER_PROMOTION });
