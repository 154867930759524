import { Exposure, ExposureResponse } from '@/interfaces/exposure';

import { GET_EXPOSURE, getExposure, SET_EXPOSURE, setExposure } from '../actions';
import { exposureAdapter } from '../adapters/exposure';

type State = Exposure;

type ExposureActions = ReturnType<typeof setExposure | typeof getExposure>;

const initialState: Exposure = null;

const set = (data: ExposureResponse): Exposure => {
  const exposure = exposureAdapter(data);

  return exposure;
};

const clear = () => initialState;

export const exposuresReducer = (
  state: State = initialState,
  action: ExposureActions = {} as ExposureActions
): State => {
  switch (action.type) {
    case GET_EXPOSURE:
      return clear();
    case SET_EXPOSURE:
      return set(action.payload);
    default:
      return state;
  }
};
