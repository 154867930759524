import stateConfigAdapter from '@/store/modules/drafting-config/adapters/state-config';
import { UPDATE_GAMEPLAY_SETTINGS, updateGameplaySettings } from '@/store/modules/pick-em/actions';
import userAdapter, {
  bonusWalletAccountAdapter,
  bonusWalletAdapter,
} from '@/store/modules/user/adapters/user';

import { StateConfigResponse } from '@/interfaces/drafting-config';
import {
  BonusBalanceUpdateResponse,
  GameplaySettings,
  IdentificationStatusChangeResponse,
  RegistrationSourceResponse,
  User,
  UserBonusWalletResponse,
  UserCosmeticUpdateResponse,
  UserProfileResponse,
  UserResponse,
  WalletBalanceUpdateResponse,
} from '@/interfaces/user';
import { toCamel } from '@/utilities/helpers';

import {
  SET_USER,
  SET_USER_BONUS_WALLET,
  SET_USER_REGISTRATION_SOURCE,
  SET_USER_STATE_CONFIG,
  setUser,
  setUserBonusWallet,
  setUserRegistrationSource,
  setUserStateConfig,
  UPDATE_NOT_NEW_USER,
  UPDATE_USER_BONUS_CASH,
  UPDATE_USER_COSMETIC,
  UPDATE_USER_ID_STATUS,
  UPDATE_USER_WALLET,
  UPDATE_VERIFY_USER,
  updateNotNewUser,
  updateUserBonusCash,
  updateUserCosmetic,
  updateUserIdStatus,
  updateUserWallet,
  updateVerifyUser,
} from '../actions';

type State = User;

type UserActions = ReturnType<
  | typeof setUser
  | typeof setUserBonusWallet
  | typeof setUserStateConfig
  | typeof setUserRegistrationSource
  | typeof updateGameplaySettings
  | typeof updateUserIdStatus
  | typeof updateVerifyUser
  | typeof updateNotNewUser
  | typeof updateUserWallet
  | typeof updateUserBonusCash
  | typeof updateUserCosmetic
>;

const initialUserState: User = {
  id: null,
  appDownloadEligible: false,
  badges: [],
  bonusCash: null,
  bonusWallet: {
    bonusWalletAccounts: [],
    nonExpiringBonusBalance: null,
  },
  email: null,
  gameplaySettings: null,
  hasPhone: null,
  identificationStatus: null,
  newRegistration: null,
  preRegEligible: false,
  profile: null,
  profileColor: null,
  profileIconUrl: null,
  registrationSource: null,
  roles: [],
  state: null,
  stateConfig: null,
  username: null,
  wallet: {
    id: null,
    balance: null,
    version: null,
  },
};

const set = (userData: {
  data: UserResponse;
  bonusWalletData: UserBonusWalletResponse;
  profileData: UserProfileResponse;
  newRegistration?: boolean;
}): User => {
  if (!userData) return { ...initialUserState };
  const { data, bonusWalletData, newRegistration, profileData } = userData;

  const user = userAdapter({ user: data.user });
  const bonusWallet = bonusWalletAdapter(bonusWalletData);

  return {
    ...user,
    bonusWallet,
    newRegistration,
    profile: profileData.profile,
    stateConfig: null,
  };
};

const setStateConfig = (
  state: User,
  {
    stateConfigData,
  }: {
    stateConfigData: StateConfigResponse;
  }
): User => {
  const stateConfig = stateConfigData ? stateConfigAdapter(stateConfigData) : null;
  return {
    ...state,
    stateConfig,
  };
};

const updateVerify = (state: User, { data }: { data: UserResponse }): User => {
  const user = userAdapter({ user: data.user });

  return {
    ...state,
    ...user,
  };
};

const updateIdStatus = (state: User, data: IdentificationStatusChangeResponse): User => {
  if (state.id !== data.id) return state;
  return {
    ...state,
    identificationStatus: toCamel(data.identification_status) as User['identificationStatus'],
  };
};

const updateWallet = (state: User, data: WalletBalanceUpdateResponse): User => {
  if (state.id !== data.id) return state;
  return {
    ...state,
    wallet: {
      ...state.wallet,
      balance: data.balance,
    },
  };
};

const updateBonusCash = (state: User, data: BonusBalanceUpdateResponse): User => {
  if (state.id !== data.id) return state;

  return {
    ...state,
    bonusCash: data.bonus_cash,
  };
};

const setBonusWallet = (state: User, data: UserBonusWalletResponse): User => {
  const bonusWalletAccounts = data.bonus_wallet_accounts.map(bonusWalletAccountAdapter);

  return {
    ...state,
    bonusWallet: {
      bonusWalletAccounts,
      nonExpiringBonusBalance: data.non_expiring_bonus_balance,
    },
  };
};

const updateCosmetic = (state: User, data: UserCosmeticUpdateResponse): User => {
  const updatedUserResponse = data.user;

  return {
    ...state,
    profileColor: updatedUserResponse.profile_color,
    profileIconUrl: updatedUserResponse.profile_icon_url,
  };
};

const setNotNewRegistration = (state: User): User => ({
  ...state,
  newRegistration: null,
});

const setRegistrationSource = (state: User, payload: RegistrationSourceResponse): User => ({
  ...state,
  registrationSource: payload.registration_source,
});

const updateUserGameplaySettings = (state: User, gameplaySettings: GameplaySettings): User => ({
  ...state,
  gameplaySettings,
});

export const userReducer = (
  state: State = initialUserState,
  action: UserActions = {} as UserActions
): State => {
  switch (action.type) {
    case SET_USER:
      return set(action.payload);
    case SET_USER_STATE_CONFIG:
      return setStateConfig(state, action.payload);
    case SET_USER_BONUS_WALLET:
      return setBonusWallet(state, action.payload);
    case SET_USER_REGISTRATION_SOURCE:
      return setRegistrationSource(state, action.payload);
    case UPDATE_GAMEPLAY_SETTINGS:
      return updateUserGameplaySettings(state, action.payload);
    case UPDATE_VERIFY_USER:
      return updateVerify(state, action.payload);
    case UPDATE_NOT_NEW_USER:
      return setNotNewRegistration(state);
    case UPDATE_USER_ID_STATUS:
      return updateIdStatus(state, action.payload);
    case UPDATE_USER_WALLET:
      return updateWallet(state, action.payload);
    case UPDATE_USER_BONUS_CASH:
      return updateBonusCash(state, action.payload);
    case UPDATE_USER_COSMETIC:
      return updateCosmetic(state, action.payload);
    default:
      return state;
  }
};
