/* eslint-disable camelcase */
import { RivalLine, RivalLineResponse } from '@/interfaces/pick-em';

import rivalAdapter from './rival';
import rivalOptionAdapter from './rival-option';

const rivalLineAdapter = (rivalLine: RivalLineResponse): RivalLine => {
  const {
    id,
    live_event,
    live_event_stats,
    options: optionsResponse,
    rival: rivalResponse,
    rank,
    rival_id,
    stat_value,
    status,
  } = rivalLine;

  const options = optionsResponse ? optionsResponse?.map(rivalOptionAdapter) : null;

  const rival = rivalResponse ? rivalAdapter(rivalResponse) : null;

  return {
    id,
    liveEvent: live_event,
    liveEventStats: live_event_stats,
    options,
    rank,
    rival,
    rivalId: rival_id,
    statValue: stat_value,
    status,
  };
};

export default rivalLineAdapter;
