import { AxiosResponse } from 'axios';

import request from '@request';
import { WeeklyAppearancesResponse } from '@/interfaces/weekly-appearances';
import { apiErrorTracking, AppError } from '@/utilities/errors';

/**
 * Get appearances for weekly
 */

export const getWeeklyAppearances = async ({
  weekId,
  scoringTypeId,
}: {
  weekId: number;
  scoringTypeId: string;
}): Promise<AxiosResponse<WeeklyAppearancesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      underdogAPIVersion: 'v2',
      url: `/weeks/${weekId.toString()}/scoring_types/${scoringTypeId}/appearances`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
