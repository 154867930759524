import React from 'react';
import type { IndexRouteProps, PathRouteProps } from 'react-router-dom';

import { Route } from '@/services/router/service';

import type { FullPageLayoutHandle } from './full-page-layout';

/*
react-router does not provide a way to type the `handle` prop on the `Route`
component (or most other `Route` props either). To get around this, we trick
TypeScript into thinking that the `Route` component is actually our own component
by re-exporting `Route` with a different name and, more importantly,
a different type signature.

Why can't we just wrap the `Route` component in our own component and type that?
Because `react-router` performs an identity check on components declared in
a `<Route>` tree and will throw an error if the component is not of type `Route`.

For example, we cannot do the following:
```
// This is valid code, but...
const FullPageLayoutRoute = (props) => <Route {...props} />;

// If used like this, it will throw a runtime error:
<Route>
  <FullPageLayoutRoute>
    <Route />
  </FullPageLayoutRoute>
</Route>
```
*/

type RoutePropsWithHandle<THandle> =
  | (Omit<IndexRouteProps, 'handle'> & { handle: THandle })
  | (Omit<PathRouteProps, 'handle'> & { handle: THandle });

export const FullPageLayoutRoute: (
  props: RoutePropsWithHandle<FullPageLayoutHandle>
) => React.ReactElement | null = Route;
