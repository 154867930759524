/* eslint-disable camelcase */
import { OldPoolV2Style, PoolV2StyleResponse } from '@/interfaces/pick-em';

const poolV2StyleAdapter = (poolV2StyleResponse: PoolV2StyleResponse): OldPoolV2Style => {
  const { id, active, base_fee, fees, max_fee, prize_per_point, rake, title, total_fees } =
    poolV2StyleResponse;

  return {
    id,
    active,
    baseFee: base_fee,
    default: poolV2StyleResponse.default,
    fees,
    maxFee: max_fee,
    prizePerPoint: prize_per_point,
    rake,
    title,
    totalFees: total_fees,
  };
};

export default poolV2StyleAdapter;
