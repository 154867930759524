/* eslint-disable camelcase */
import {
  GeoComplyLicense,
  GeoComplyLicenseResponse,
  GeoComplyLocation,
  GeoComplyLocationResponse,
} from '@/interfaces/geocomply';

export const geoComplyLicenseAdapter = (response: GeoComplyLicenseResponse): GeoComplyLicense => {
  const { license, expires_at, fetched_at } = response;

  return {
    license,
    expiresAt: expires_at,
    fetchedAt: fetched_at,
  };
};

export const geoComplyLocationAdapter = (
  response: GeoComplyLocationResponse
): GeoComplyLocation => {
  const { expires_at, is_compliant, country, state, latitude, longitude } = response;

  return {
    expiresAt: expires_at,
    isCompliant: is_compliant,
    country,
    state,
    latitude,
    longitude,
  };
};
