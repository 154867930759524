import React from 'react';

import Icon from '@/components/atoms/icon';

import styles from './styles.scss';

interface ExpandablePicksButtonProps {
  expanded: boolean;
  expandablePickNum: number;
  setExpanded: (expanded: boolean) => void;
}

const ExpandablePicksButton = (props: ExpandablePicksButtonProps) => {
  const { expanded, expandablePickNum, setExpanded } = props;

  const expandButtonCopy = expanded ? 'Fewer picks' : `More picks (${expandablePickNum})`;

  return (
    <button
      className={styles.expandablePicksButton}
      onClick={(e) => {
        e.stopPropagation();
        setExpanded(!expanded);
      }}
    >
      <span className={styles.toggleSpan}>{expandButtonCopy}</span>
      <Icon className={styles.toggleArrow} name={expanded ? 'upCaret' : 'downCaret'} />
    </button>
  );
};

export default ExpandablePicksButton;
