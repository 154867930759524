/* eslint-disable camelcase */
import { DraftPoolsForSlate, DraftPoolsForSlateResponse } from '@/interfaces/draft-pools';

import { SET_DRAFT_POOLS_FOR_SLATE, setDraftPoolsForSlate } from '../actions';
import { draftPoolForSlateAdapter } from '../adapters/draft-pool-for-slate';

type State = DraftPoolsForSlate | null;

type DraftPoolsActions = ReturnType<typeof setDraftPoolsForSlate>;

const initialState: DraftPoolsForSlate = {};

const set = (
  state: DraftPoolsForSlate,
  { data, slateId }: { data: DraftPoolsForSlateResponse; slateId: string }
): DraftPoolsForSlate => {
  const draftPools = data.draft_pools && data.draft_pools.map(draftPoolForSlateAdapter);
  return {
    ...state,
    [slateId]: draftPools,
  };
};

export const draftPoolsForSlateReducer = (
  state: State = initialState,
  action: DraftPoolsActions = {} as DraftPoolsActions
): State => {
  switch (action.type) {
    case SET_DRAFT_POOLS_FOR_SLATE:
      return set(state, action.payload);
    default:
      return state;
  }
};
