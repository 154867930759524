/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 101
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '4dd69e17da1815ddbaba4ea24846ac65',
  development: '98bce688df8c3993aa3ef5dc13dd3886',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '101',
    branch: 'main',
    source: 'web',
    versionId: '7e5bd931-87a1-4a04-b741-428d0db86f8d',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: BrowserOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * The partner Id of whom referred the current user if they were referred to Underdog directly by a partner and not via a promo code.
   */
  referred_by_partner_id?: string;
  /**
   * The username of an existing Underdog user from whom the current user was referred by
   */
  referred_by_username?: string;
  /**
   * Promo code the user supplied at registration time
   */
  registration_promo_code?: string;
}

export interface CollapseAllButtonSelectedProperties {
  lobby_name: string;
}

export interface CollapseGameButtonSelectedProperties {
  /**
   * This is the rank of the game that is set by ops in admin. We want to use this property to evaluate the position of a game in the lobby and it's impact on customer interaction
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  game_rank: number;
  lobby_name: string;
}

export interface CollapsePlayerLobbyCardProperties {
  /**
   * database Id corresponding to a specific player in the database
   */
  player_id: string;
  /**
   * The name of the player (singular)
   */
  player_name: string;
  position_id: string;
  /**
   * The name of the sport (singular)
   */
  sport_name: string;
}

export interface DefaultDepositAmountSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  deposit_amount: number;
}

export interface DepositAmountChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  deposit_amount: number;
}

export interface DraftEntryButtonClickedProperties {
  /**
   * This property captures the draft contest type
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Best Ball, Battle Royale, Weekly Winner, Sit n Go |
   */
  draft_contest_type: 'Best Ball' | 'Battle Royale' | 'Weekly Winner' | 'Sit n Go';
  /**
   * This property captures the draft speed selected by the customer.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  draft_speed: number;
  /**
   * The count of drafts a user requests when entering a draft contest
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 1 |
   */
  drafts_requested_count: number;
  /**
   * The wager amount on a entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  entry_amount: number;
  /**
   * The name of the sport (singular)
   */
  sport_name: string;
}

export interface EditPicksSelectionFromCorrelationToastProperties {
  /**
   * The name of sport when adding a pick to Pickem Entry
   */
  pick_sport_name: string;
  /**
   * The name of the stat for a pickem selection
   */
  stat_name: string;
}

export interface ExistingPaymentMethodRetriedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ios, android, web |
   */
  client_type: 'ios' | 'android' | 'web';
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  deposit_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PayPal, Credit Card, Bank Account, Intercheck, Apple Pay |
   */
  payment_method: 'PayPal' | 'Credit Card' | 'Bank Account' | 'Intercheck' | 'Apple Pay';
}

export interface ExistingPaymentMethodSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  deposit_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PayPal, Credit Card, Bank Account, Intercheck, Apple Pay |
   */
  payment_method: 'PayPal' | 'Credit Card' | 'Bank Account' | 'Intercheck' | 'Apple Pay';
}

export interface ExpandAllButtonSelectedProperties {
  lobby_name: string;
}

export interface ExpandGameButtonSelectedProperties {
  /**
   * This is the rank of the game that is set by ops in admin. We want to use this property to evaluate the position of a game in the lobby and it's impact on customer interaction
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  game_rank: number;
  lobby_name: string;
}

export interface ExpandPlayerLobbyCardProperties {
  /**
   * database Id corresponding to a specific player in the database
   */
  player_id: string;
  /**
   * The name of the player (singular)
   */
  player_name: string;
  position_id: string;
  /**
   * The name of the sport (singular)
   */
  sport_name: string;
}

export interface HigherLowerFilterSelectedProperties {
  /**
   * This property is true if a pick'em lobby has  no projections and is therefore displaying an empty state.
   */
  is_blank?: boolean;
  /**
   * The name of the sport or lobby the user selects. ex. "MLB" or "Home".
   */
  lobby_name?: string;
  /**
   * This is the rank of the sport that is set by ops in admin. We want to use this property to evaluate the position of a sport in the lobby and it's impact on customer interaction
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  sport_rank: number;
}

export interface InviteFriendsShareOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | new_user, existing_user |
   */
  user_type?: 'new_user' | 'existing_user';
}

export interface LearnMoreFromPickCalloutProperties {
  /**
   * The name of sport when adding a pick to Pickem Entry
   */
  pick_sport_name: string;
  /**
   * The name of the stat for a pickem selection
   */
  stat_name: string;
}

export interface NewPaymentMethodRetriedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  deposit_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PayPal, Credit Card, Bank Account, Intercheck, Apple Pay |
   */
  payment_method: 'PayPal' | 'Credit Card' | 'Bank Account' | 'Intercheck' | 'Apple Pay';
}

export interface NewPaymentMethodSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  deposit_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PayPal, Credit Card, Bank Account, Intercheck, Apple Pay |
   */
  payment_method: 'PayPal' | 'Credit Card' | 'Bank Account' | 'Intercheck' | 'Apple Pay';
}

export interface PasswordResetUnsuccessfulProperties {
  error_text?: string;
}

export interface PaymentsDepositAmountButtonPressedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  existing_amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  new_amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_lifetime_deposits?: number;
}

export interface PaymentsDepositAmountTextEnteredProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  existing_amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  new_amount?: number;
}

export interface PaymentsDepositBalanceClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  balance_amount?: number;
}

export interface PaymentsDepositButtonPressedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  deposit_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PayPal, Credit Card, Bank Account, Intercheck, Apple Pay |
   */
  deposit_source: 'PayPal' | 'Credit Card' | 'Bank Account' | 'Intercheck' | 'Apple Pay';
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_lifetime_deposits?: number;
}

export interface PaymentsDepositFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  deposit_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Confirmation Needed, API Error |
   */
  deposit_failure_reason: 'Confirmation Needed' | 'API Error';
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PayPal, Credit Card, Bank Account, Intercheck, Apple Pay |
   */
  deposit_source: 'PayPal' | 'Credit Card' | 'Bank Account' | 'Intercheck' | 'Apple Pay';
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_lifetime_deposits?: number;
}

export interface PaymentsDepositSucceededProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  deposit_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PayPal, Credit Card, Bank Account, Intercheck, Apple Pay |
   */
  deposit_source: 'PayPal' | 'Credit Card' | 'Bank Account' | 'Intercheck' | 'Apple Pay';
  /**
   * Text-based description shown to the user the describes the promotion details
   */
  promotion_description?: string;
  /**
   * Unique identifier of the promotion entity
   */
  promotion_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_lifetime_deposits?: number;
}

export interface PaymentsInviteFriendsLinkSharedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | facebook, twitter, weibo, message, mail, copy, airdrop, other |
   */
  share_type?: 'facebook' | 'twitter' | 'weibo' | 'message' | 'mail' | 'copy' | 'airdrop' | 'other';
}

export interface PaymentsPaymentMethodRemovedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PayPal, Credit Card, Bank Account, Intercheck, Apple Pay |
   */
  payment_method: 'PayPal' | 'Credit Card' | 'Bank Account' | 'Intercheck' | 'Apple Pay';
}

export interface PaymentsWithdrawalFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  withdrawal_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Confirmation Needed, API Error |
   */
  withdrawal_failure_reason: 'Confirmation Needed' | 'API Error';
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PayPal, Intercheck, Bank Account |
   */
  withdrawal_source: 'PayPal' | 'Intercheck' | 'Bank Account';
}

export interface PaymentsWithdrawalSucceededProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  withdrawal_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PayPal, Intercheck, Bank Account |
   */
  withdrawal_source: 'PayPal' | 'Intercheck' | 'Bank Account';
}

export interface PickTypeFilterSelectedProperties {
  /**
   * This property determines which filter was selected amongst our available options for pick time
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | All, Pre Game, In Game |
   */
  filter_option_selected: 'All' | 'Pre Game' | 'In Game';
  /**
   * This property is true if a pick'em lobby has  no projections and is therefore displaying an empty state.
   */
  is_blank?: boolean;
  /**
   * The name of the sport or lobby the user selects. ex. "MLB" or "Home".
   */
  lobby_name: string;
  /**
   * This is the rank of the sport that is set by ops in admin. We want to use this property to evaluate the position of a sport in the lobby and it's impact on customer interaction
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  sport_rank: number;
}

export interface PickemEntryCancelledProperties {
  /**
   * string database id that represents a specific boost in the database
   */
  boost_id?: string;
  /**
   * The type of promotion applied to a Pickem Entry
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | payout booster, special, none, sweepstakes, discount |
   */
  boost_type?: 'payout booster' | 'special' | 'none' | 'sweepstakes' | 'discount';
  /**
   * The wager amount on a entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  entry_amount: number;
  /**
   * The number of modified picks within an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_modified_picks?: number;
  /**
   * number of higher picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_higher: number;
  /**
   * number of in game selections on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_in_game: number;
  /**
   * number of lower picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_lower: number;
  /**
   * number of rivals picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_rivals: number;
  /**
   * total number of picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_total: number;
  /**
   * The amount a user wins if all picks are won
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_amount_standard: number;
  /**
   * The payout multipler
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_multiplier_standard: number;
  /**
   * array containing the string database IDs of players
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_ids: string[];
  /**
   * The names of all players in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_names: string[];
  /**
   * The name of all sports in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  sport_names: string[];
}

export interface PickemEntryCancelledInsuredProperties {
  /**
   * The wager amount on a entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  entry_amount: number;
  /**
   * The number of modified picks within an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_modified_picks?: number;
  /**
   * number of higher picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_higher: number;
  /**
   * number of in game selections on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_in_game: number;
  /**
   * number of lower picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_lower: number;
  /**
   * number of rivals picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_rivals: number;
  /**
   * total number of picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_total: number;
  /**
   * The maximum payout amount for an Insured Pickem Entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_amount_insurance_max: number;
  /**
   * The payout amount for an Insured Pickem Enty is user gets one pick wrong
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_amount_insurance_with_loss: number;
  /**
   * The max multiplier for an Insured Pickem Entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_multiplier_insurance_max: number;
  /**
   * The multiplier for an Insured Pickem Entry if user gets one pick wrong
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_multiplier_insurance_with_loss: number;
  /**
   * array containing the string database IDs of players
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_ids: string[];
  /**
   * The names of all players in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_names: string[];
  /**
   * The name of all sports in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  sport_names: string[];
}

export interface PickemEntryCancelledStandardProperties {
  /**
   * string database id that represents a specific boost in the database
   */
  boost_id?: string;
  /**
   * The type of promotion applied to a Pickem Entry
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | payout booster, special, none, sweepstakes, discount |
   */
  boost_type?: 'payout booster' | 'special' | 'none' | 'sweepstakes' | 'discount';
  /**
   * Whether or not a pick'em entry has a modifier which if included, will affect the entry's payout
   */
  contains_modifier?: boolean;
  /**
   * The wager amount on a entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  entry_amount: number;
  /**
   * The number of modified picks within an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_modified_picks?: number;
  /**
   * number of higher picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_higher: number;
  /**
   * number of in game selections on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_in_game: number;
  /**
   * number of lower picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_lower: number;
  /**
   * number of rivals picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_rivals: number;
  /**
   * total number of picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_total: number;
  /**
   * The amount a user wins if all picks are won
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_amount_standard: number;
  /**
   * The payout multipler
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_multiplier_standard: number;
  /**
   * array containing the string database IDs of players
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_ids: string[];
  /**
   * The names of all players in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_names: string[];
  /**
   * The name of all sports in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  sport_names: string[];
}

export interface PickemEntryCreatedInsuredProperties {
  /**
   * Whether or not a pick'em entry has a modifier which if included, will affect the entry's payout
   */
  contains_modifier?: boolean;
  /**
   * The wager amount on a entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  entry_amount: number;
  /**
   * The system assigned entry ID
   */
  entry_id: string;
  has_shifted_payout?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Yes, No |
   */
  includes_pack?: 'Yes' | 'No';
  /**
   * the number of correlated selections in an entry that are contributing to a block or a payout shift
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_correlated_selections?: number;
  /**
   * The number of modified picks within an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_modified_picks?: number;
  /**
   * number of higher picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_higher: number;
  /**
   * number of in game selections on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_in_game: number;
  /**
   * number of lower picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_lower: number;
  /**
   * number of rivals picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_rivals: number;
  /**
   * total number of picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_total: number;
  /**
   * The maximum payout amount for an Insured Pickem Entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_amount_insurance_max: number;
  /**
   * The payout amount for an Insured Pickem Enty is user gets one pick wrong
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_amount_insurance_with_loss: number;
  /**
   * The max multiplier for an Insured Pickem Entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_multiplier_insurance_max: number;
  /**
   * The multiplier for an Insured Pickem Entry if user gets one pick wrong
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_multiplier_insurance_with_loss: number;
  /**
   * the stats represented in the entry that are causing a shift. the stats should be seperated by a comma
   */
  payout_shifted_pair?: string;
  /**
   * array containing the string database IDs of players
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_ids: string[];
  /**
   * The names of all players in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_names: string[];
  /**
   * the entry multiplier for the shifted payout, null if not shifted
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  shifted_payout_multiplier?: number;
  /**
   * The name of all sports in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  sport_names: string[];
}

export interface PickemEntryCreatedStandardProperties {
  /**
   * string database id that represents a specific boost in the database
   */
  boost_id?: string;
  /**
   * The type of promotion applied to a Pickem Entry
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | payout booster, special, none, sweepstakes, discount |
   */
  boost_type?: 'payout booster' | 'special' | 'none' | 'sweepstakes' | 'discount';
  /**
   * Whether or not a pick'em entry has a modifier which if included, will affect the entry's payout
   */
  contains_modifier?: boolean;
  /**
   * The wager amount on a entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  entry_amount: number;
  /**
   * The system assigned entry ID
   */
  entry_id: string;
  /**
   * whether or not a created entry has a shifted payout
   */
  has_shifted_payout?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Yes, No |
   */
  includes_pack?: 'Yes' | 'No';
  /**
   * the number of correlated selections in an entry that are contributing to a block or a payout shift
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_correlated_selections?: number;
  /**
   * The number of modified picks within an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_modified_picks?: number;
  /**
   * number of higher picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_higher: number;
  /**
   * number of in game selections on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_in_game: number;
  /**
   * number of lower picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_lower: number;
  /**
   * number of rivals picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_rivals: number;
  /**
   * total number of picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_total: number;
  /**
   * The amount a user wins if all picks are won
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_amount_standard: number;
  /**
   * The payout multipler
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  payout_multiplier_standard: number;
  /**
   * the stats represented in the entry that are causing a shift. the stats should be seperated by a comma
   */
  payout_shifted_pair?: string;
  /**
   * array containing the string database IDs of players
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_ids: string[];
  /**
   * The names of all players in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_names: string[];
  /**
   * the entry multiplier for the shifted payout, null if not shifted
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  shifted_payout_multiplier?: number;
  /**
   * The name of all sports in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  sport_names: string[];
}

export interface PickemEntryShareOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | new_user, existing_user |
   */
  user_type?: 'new_user' | 'existing_user';
}

export interface PickemEntrySubmissionReusedProperties {
  /**
   * The wager amount on a entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  entry_amount: number;
  /**
   * The system assigned entry ID
   */
  entry_id: string;
  /**
   * total number of picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_total: number;
  /**
   * array containing the string database IDs of players
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_ids: string[];
  /**
   * The names of all players in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_names: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | facebook, twitter, weibo, message, mail, copy, airdrop, other |
   */
  share_type?: 'facebook' | 'twitter' | 'weibo' | 'message' | 'mail' | 'copy' | 'airdrop' | 'other';
  /**
   * The name of all sports in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  sport_names: string[];
}

export interface PickemEntrySubmissionSharedProperties {
  /**
   * The wager amount on a entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  entry_amount: number;
  /**
   * The system assigned entry ID
   */
  entry_id: string;
  /**
   * total number of picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_total: number;
  /**
   * array containing the string database IDs of players
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_ids: string[];
  /**
   * The names of all players in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  player_names: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  screen?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | facebook, twitter, weibo, message, mail, copy, airdrop, other |
   */
  share_type?: 'facebook' | 'twitter' | 'weibo' | 'message' | 'mail' | 'copy' | 'airdrop' | 'other';
  /**
   * The name of all sports in an array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  sport_names: string[];
}

export interface PickemFavoriteAddedProperties {
  /**
   * database Id corresponding to a specific player in the database
   */
  player_id: string;
  /**
   * The name of the player (singular)
   */
  player_name: string;
  /**
   * The name of the sport (singular)
   */
  sport_name: string;
}

export interface PickemFavoriteRemovedProperties {
  /**
   * database Id corresponding to a specific player in the database
   */
  player_id: string;
  /**
   * The name of the player (singular)
   */
  player_name: string;
  /**
   * The name of the sport (singular)
   */
  sport_name: string;
}

export interface PickemHigherLowerSelectionAddedProperties {
  /**
   * string database id that represents a specific boost in the database
   */
  boost_id?: string;
  /**
   * The type of promotion applied to a Pickem Entry
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | payout booster, special, none, sweepstakes, discount |
   */
  boost_type?: 'payout booster' | 'special' | 'none' | 'sweepstakes' | 'discount';
  /**
   * The display stat name that is displayed in the app UI that is associated with the pick being added
   */
  display_stat_name: string;
  /**
   * Did the user choose higher or lower when adding a pick to Pickem Entry
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | higher, lower, better, worse, yes, no |
   */
  higher_lower: 'higher' | 'lower' | 'better' | 'worse' | 'yes' | 'no';
  /**
   * Whether or not a pick'em projection has a modifier which if included, will affect the entry's payout
   */
  is_modified_projection?: boolean;
  /**
   * true if selection is eligible for a currently opted-in promo for the user
   */
  is_opt_in_token_eligible?: boolean;
  /**
   * The name of the sport or lobby the user selects. ex. "MLB" or "Home".
   */
  lobby_name?: string;
  /**
   * the value of a projection’s modifier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  modifier_value?: number;
  /**
   * total number of picks on an entry
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_picks_total: number;
  /**
   * The displayed selection text of the higher/lower pick made
   */
  pick_display_choice: string;
  /**
   * The screen the user was on when pick was added to entry
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | lobby, player card, favorites, review, shared entry, swipe game |
   */
  pick_location: 'lobby' | 'player card' | 'favorites' | 'review' | 'shared entry' | 'swipe game';
  /**
   * The type of pick added to an entry. In Game or Pre Game.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | pregame, ingame |
   */
  pick_type?: 'pregame' | 'ingame';
  /**
   * database Id corresponding to a specific player in the database
   */
  player_id: string;
  /**
   * The name of the player (singular)
   */
  player_name: string;
  /**
   * The value of the projection offered
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projection_value: number;
  /**
   * The name of the sport (singular)
   */
  sport_name: string;
  /**
   * The name of the stat for a pickem selection
   */
  stat_name: string;
}

export interface PickemPackAddedProperties {
  /**
   * Featured packs appear on the homepage
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Yes, No |
   */
  is_featured: 'Yes' | 'No';
  is_tokenized?: boolean;
  /**
   * The name of the sport or lobby the user selects. ex. "MLB" or "Home".
   */
  lobby_name?: string;
  /**
   * This property describes the type of pack the customer interacted with.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | partner pack, user pack |
   */
  pack_type: 'partner pack' | 'user pack';
  /**
   * Rank is the rank assigned to the pack in the admin
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  rank: number;
  token_type?: string;
}

export interface PickemPackViewAllOpenedProperties {
  /**
   * The name of the sport or lobby the user selects. ex. "MLB" or "Home".
   */
  lobby_name?: string;
}

export interface PickemPlayerCardScreenOpenedProperties {
  /**
   * database Id corresponding to a specific player in the database
   */
  player_id: string;
  /**
   * The name of the player (singular)
   */
  player_name: string;
  /**
   * The name of the sport (singular)
   */
  sport_name: string;
}

export interface PickemRivalsSelectionAddedProperties {
  /**
   * The adjustment amount for a Rivals selection
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  adjustment_amount: number;
  /**
   * string database id that represents a specific boost in the database
   */
  boost_id?: string;
  /**
   * The type of promotion applied to a Pickem Entry
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | payout booster, special, none, sweepstakes, discount |
   */
  boost_type?: 'payout booster' | 'special' | 'none' | 'sweepstakes' | 'discount';
  /**
   * The display stat name that is displayed in the app UI that is associated with the pick being added
   */
  display_stat_name: string;
  /**
   * The name of the sport or lobby the user selects. ex. "MLB" or "Home".
   */
  lobby_name?: string;
  /**
   * The screen the user was on when pick was added to entry
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | lobby, player card, favorites, review, shared entry, swipe game |
   */
  pick_location: 'lobby' | 'player card' | 'favorites' | 'review' | 'shared entry' | 'swipe game';
  /**
   * database Id corresponding to a specific player in the database
   */
  player_id: string;
  /**
   * The name of the player (singular)
   */
  player_name: string;
  /**
   * The name of the sport (singular)
   */
  sport_name: string;
  /**
   * The name of the stat for a pickem selection
   */
  stat_name: string;
}

export interface PickemSportLobbySelectedProperties {
  /**
   * This property is true if a pick'em lobby has  no projections and is therefore displaying an empty state.
   */
  is_blank?: boolean;
  /**
   * The name of the sport or lobby the user selects. ex. "MLB" or "Home".
   */
  lobby_name?: string;
  /**
   * This is the rank of the sport that is set by ops in admin. We want to use this property to evaluate the position of a sport in the lobby and it's impact on customer interaction
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  sport_rank: number;
}

export interface PromoSwapModalInteractionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Confirm, Cancel |
   */
  modal_choice: 'Confirm' | 'Cancel';
}

export interface PromoSwapModalOpenProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Boost, Token, Tokenized Pack |
   */
  existing_promo_type: 'Boost' | 'Token' | 'Tokenized Pack';
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Boost, Token, Tokenized Pack |
   */
  new_promo_type: 'Boost' | 'Token' | 'Tokenized Pack';
}

export interface RegistrationBirthdayEnteredUnsuccessfulProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  age: number;
}

export interface RegistrationEmailEnteredUnsuccessfulProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Already Taken, Invalid Format, Confirmation Needed, API Error |
   */
  failure_reason?: 'Already Taken' | 'Invalid Format' | 'Confirmation Needed' | 'API Error';
}

export interface RegistrationReferralCodeEnteredSuccessfulProperties {
  promo_code: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | code, referral |
   */
  promotion_type: 'code' | 'referral';
}

export interface RegistrationReferralCodeEnteredUnsuccessfulProperties {
  promo_code: string;
}

export interface RegistrationSignUpInitiatedProperties {
  /**
   * The URL a user was on when initiating signup. Only include the URL path. ex. "/promos"
   */
  sign_up_path_web?: string;
}

export interface RegistrationUnsuccessfulRegistrationDialogShownProperties {
  error_text?: string;
  promo_code: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | code, referral |
   */
  promotion_type: 'code' | 'referral';
}

export interface RegistrationUsernameEnteredUnsuccessfulProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Already Taken, Invalid Format, Confirmation Needed, API Error |
   */
  failure_reason?: 'Already Taken' | 'Invalid Format' | 'Confirmation Needed' | 'API Error';
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Already Taken, Invalid Format |
   */
  username_failure_reason?: 'Already Taken' | 'Invalid Format';
}

export interface RemovePickFromYourPicksScreenProperties {
  /**
   * Whether or not a pick'em projection has a modifier which if included, will affect the entry's payout
   */
  is_modified_projection?: boolean;
  /**
   * the value of a projection’s modifier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  modifier_value?: number;
  part_of_tokenized_pack?: boolean;
  /**
   * The name of sport when adding a pick to Pickem Entry
   */
  pick_sport_name: string;
  /**
   * The name of the stat for a pickem selection
   */
  stat_name: string;
}

export interface RemoveTokenizedPackProperties {
  is_tokenized?: boolean;
  token_type?: string;
}

export interface RemoveTokenizedPackSelectionModalInteractionProperties {
  /**
   * True if the customer checks the box which will result in the warning not appearing in the future
   */
  "don't_show_again"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Confirm, Cancel |
   */
  modal_choice: 'Confirm' | 'Cancel';
}

export interface RewardsHubOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | lobby gift box, lobby view all, review, account page |
   */
  location?: 'lobby gift box' | 'lobby view all' | 'review' | 'account page';
}

export interface RivalFilterSelectedProperties {
  /**
   * This property is true if a pick'em lobby has  no projections and is therefore displaying an empty state.
   */
  is_blank?: boolean;
  /**
   * The name of the sport or lobby the user selects. ex. "MLB" or "Home".
   */
  lobby_name: string;
  /**
   * This is the rank of the sport that is set by ops in admin. We want to use this property to evaluate the position of a sport in the lobby and it's impact on customer interaction
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  sport_rank: number;
}

export interface SearchBarSelectedProperties {
  lobby_name: string;
}

export interface SocureIdUploadFlowInitiatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Phone Number, QR Code |
   */
  id_upload_flow?: 'Phone Number' | 'QR Code';
}

export interface TokenAddedToEntryProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | lobby, player card, rewards hub, info screen, filtered lobby |
   */
  location: 'lobby' | 'player card' | 'rewards hub' | 'info screen' | 'filtered lobby';
  /**
   * this property captures an array of a token's restrictions
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  restrictions?: string[];
  token_type?: string;
}

export interface TokenAppliedToSelectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | lobby, review, player card, filtered lobby |
   */
  location: 'lobby' | 'review' | 'player card' | 'filtered lobby';
  /**
   * this property captures an array of a token's restrictions
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  restrictions?: string[];
  token_type: string;
}

export interface TokenOptedInProperties {
  token_type?: string;
}

export interface TokenRemovedFromEntryProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | lobby, player card, rewards hub, info screen, filtered lobby |
   */
  location: 'lobby' | 'player card' | 'rewards hub' | 'info screen' | 'filtered lobby';
  /**
   * this property captures an array of a token's restrictions
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  restrictions?: string[];
  token_type: string;
}

export interface TokenRemovedFromSelectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | lobby, review, player card, filtered lobby |
   */
  location: 'lobby' | 'review' | 'player card' | 'filtered lobby';
  /**
   * this property captures an array of a token's restrictions
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  restrictions?: string[];
  token_type: string;
}

export interface TypingInSearchProperties {
  /**
   * The name of the sport or lobby the user selects. ex. "MLB" or "Home".
   */
  lobby_name?: string;
}

export interface WithdrawalMethodSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PayPal, Credit Card, Bank Account, Intercheck, Apple Pay |
   */
  payment_method: 'PayPal' | 'Credit Card' | 'Bank Account' | 'Intercheck' | 'Apple Pay';
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class AdditionalIdRequiredCancelTapped implements BaseEvent {
  event_type = 'Additional ID Required Cancel Tapped';
}

export class AdditionalIdRequiredModalShown implements BaseEvent {
  event_type = 'Additional ID Required Modal Shown';
}

export class AdditionalIdRequiredVerifyIdTapped implements BaseEvent {
  event_type = 'Additional ID Required Verify ID Tapped';
}

export class CollapseAllButtonSelected implements BaseEvent {
  event_type = 'Collapse All Button Selected';

  constructor(public event_properties: CollapseAllButtonSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class CollapseGameButtonSelected implements BaseEvent {
  event_type = 'Collapse Game Button Selected';

  constructor(public event_properties: CollapseGameButtonSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class CollapsePlayerLobbyCard implements BaseEvent {
  event_type = 'Collapse Player Lobby Card';

  constructor(public event_properties: CollapsePlayerLobbyCardProperties) {
    this.event_properties = event_properties;
  }
}

export class ContestLegalityLetterOpened implements BaseEvent {
  event_type = 'Contest Legality Letter Opened';
}

export class CsVerificationNeeded implements BaseEvent {
  event_type = 'CS Verification Needed';
}

export class CsVerificationNeededClose implements BaseEvent {
  event_type = 'CS Verification Needed Close';
}

export class CsVerificationNeededContactSupport implements BaseEvent {
  event_type = 'CS Verification Needed Contact Support';
}

export class DefaultDepositAmountSelected implements BaseEvent {
  event_type = 'Default Deposit Amount Selected';

  constructor(public event_properties: DefaultDepositAmountSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class DepositAmountChanged implements BaseEvent {
  event_type = 'Deposit Amount Changed';

  constructor(public event_properties: DepositAmountChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class DepositScreenExited implements BaseEvent {
  event_type = 'Deposit Screen Exited';
}

export class DraftConfirmationButtonClicked implements BaseEvent {
  event_type = 'Draft Confirmation Button Clicked';
}

export class DraftConfirmationCancelButtonClicked implements BaseEvent {
  event_type = 'Draft Confirmation Cancel Button Clicked';
}

export class DraftEntryButtonClicked implements BaseEvent {
  event_type = 'Draft Entry Button Clicked';

  constructor(public event_properties: DraftEntryButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DraftLobbyScreenOpened implements BaseEvent {
  event_type = 'Draft Lobby Screen Opened';
}

export class EditPicksSelectionFromCorrelationToast implements BaseEvent {
  event_type = 'Edit Picks Selection from Correlation Toast';

  constructor(public event_properties: EditPicksSelectionFromCorrelationToastProperties) {
    this.event_properties = event_properties;
  }
}

export class ExistingPaymentMethodRetried implements BaseEvent {
  event_type = 'Existing Payment Method Retried';

  constructor(public event_properties: ExistingPaymentMethodRetriedProperties) {
    this.event_properties = event_properties;
  }
}

export class ExistingPaymentMethodSelected implements BaseEvent {
  event_type = 'Existing Payment Method Selected';

  constructor(public event_properties: ExistingPaymentMethodSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class ExpandAllButtonSelected implements BaseEvent {
  event_type = 'Expand All Button Selected';

  constructor(public event_properties: ExpandAllButtonSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class ExpandGameButtonSelected implements BaseEvent {
  event_type = 'Expand Game Button Selected';

  constructor(public event_properties: ExpandGameButtonSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class ExpandPlayerLobbyCard implements BaseEvent {
  event_type = 'Expand Player Lobby Card';

  constructor(public event_properties: ExpandPlayerLobbyCardProperties) {
    this.event_properties = event_properties;
  }
}

export class FlexedSlipDialogInfoButtonTapped implements BaseEvent {
  event_type = 'Flexed Slip Dialog - Info Button Tapped';
}

export class FlexedSlipDialogRemoveBoostedPick implements BaseEvent {
  event_type = 'Flexed Slip Dialog - Remove Boosted Pick';
}

export class FlexedSlipDialogRemoveFlex implements BaseEvent {
  event_type = 'Flexed Slip Dialog - Remove Flex';
}

export class FormVerificationNeeded implements BaseEvent {
  event_type = 'Form Verification Needed';
}

export class FormVerificationNeededContactSupport implements BaseEvent {
  event_type = 'Form Verification Needed Contact Support';
}

export class FormVerificationNeededTryAgain implements BaseEvent {
  event_type = 'Form Verification Needed Try Again';
}

export class FormVerificationRequiredModalCancelClicked implements BaseEvent {
  event_type = 'Form Verification Required Modal Cancel Clicked';
}

export class FormVerificationRequiredModalGetVerifiedClicked implements BaseEvent {
  event_type = 'Form Verification Required Modal Get Verified Clicked';
}

export class FormVerificationRequiredModalShown implements BaseEvent {
  event_type = 'Form Verification Required Modal Shown';
}

export class HigherLowerFilterSelected implements BaseEvent {
  event_type = 'Higher Lower Filter Selected';

  constructor(public event_properties: HigherLowerFilterSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class IdVerificationNeeded implements BaseEvent {
  event_type = 'ID Verification Needed';
}

export class IdVerificationNeededContactSupport implements BaseEvent {
  event_type = 'ID Verification Needed Contact Support';
}

export class IdVerificationNeededScanId implements BaseEvent {
  event_type = 'ID Verification Needed Scan ID';
}

export class InviteFriendsShareOpened implements BaseEvent {
  event_type = 'Invite Friends Share Opened';

  constructor(public event_properties?: InviteFriendsShareOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class LearnMoreFromPickCallout implements BaseEvent {
  event_type = 'Learn More from Pick Callout';

  constructor(public event_properties: LearnMoreFromPickCalloutProperties) {
    this.event_properties = event_properties;
  }
}

export class NewPaymentMethodRetried implements BaseEvent {
  event_type = 'New Payment Method Retried';

  constructor(public event_properties: NewPaymentMethodRetriedProperties) {
    this.event_properties = event_properties;
  }
}

export class NewPaymentMethodSelected implements BaseEvent {
  event_type = 'New Payment Method Selected';

  constructor(public event_properties: NewPaymentMethodSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class NewsFeedOpened implements BaseEvent {
  event_type = 'News Feed Opened';
}

export class PasswordResetGoToLoginTapped implements BaseEvent {
  event_type = 'Password Reset Go To Login Tapped';
}

export class PasswordResetSuccessScreen implements BaseEvent {
  event_type = 'Password Reset Success Screen';
}

export class PasswordResetSuccessful implements BaseEvent {
  event_type = 'Password Reset Successful';
}

export class PasswordResetUnsuccessful implements BaseEvent {
  event_type = 'Password Reset Unsuccessful';

  constructor(public event_properties?: PasswordResetUnsuccessfulProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentsBonusOfferBannerDepositInitiated implements BaseEvent {
  event_type = 'Payments Bonus Offer Banner Deposit Initiated';
}

export class PaymentsBonusOfferModalClosed implements BaseEvent {
  event_type = 'Payments Bonus Offer Modal Closed';
}

export class PaymentsBonusOfferModalDepositInitiated implements BaseEvent {
  event_type = 'Payments Bonus Offer Modal Deposit Initiated';
}

export class PaymentsBonusOfferModalOpened implements BaseEvent {
  event_type = 'Payments Bonus Offer Modal Opened';
}

export class PaymentsDepositAmountButtonPressed implements BaseEvent {
  event_type = 'Payments Deposit Amount Button Pressed';

  constructor(public event_properties?: PaymentsDepositAmountButtonPressedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentsDepositAmountTextEntered implements BaseEvent {
  event_type = 'Payments Deposit Amount Text Entered';

  constructor(public event_properties?: PaymentsDepositAmountTextEnteredProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentsDepositBalanceClicked implements BaseEvent {
  event_type = 'Payments Deposit Balance Clicked';

  constructor(public event_properties?: PaymentsDepositBalanceClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentsDepositButtonPressed implements BaseEvent {
  event_type = 'Payments Deposit Button Pressed';

  constructor(public event_properties: PaymentsDepositButtonPressedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentsDepositFailed implements BaseEvent {
  event_type = 'Payments Deposit Failed';

  constructor(public event_properties: PaymentsDepositFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentsDepositScreenOpened implements BaseEvent {
  event_type = 'Payments Deposit Screen Opened';
}

export class PaymentsDepositSucceeded implements BaseEvent {
  event_type = 'Payments Deposit Succeeded';

  constructor(public event_properties: PaymentsDepositSucceededProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentsInviteFriendsLinkShared implements BaseEvent {
  event_type = 'Payments Invite Friends Link Shared';

  constructor(public event_properties?: PaymentsInviteFriendsLinkSharedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentsInviteFriendsLinkTapped implements BaseEvent {
  event_type = 'Payments Invite Friends Link Tapped';
}

export class PaymentsInviteFriendsScreenOpened implements BaseEvent {
  event_type = 'Payments Invite Friends Screen Opened';
}

export class PaymentsPaymentMethodRemoved implements BaseEvent {
  event_type = 'Payments Payment Method Removed';

  constructor(public event_properties: PaymentsPaymentMethodRemovedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentsPaymentMethodsScreenOpened implements BaseEvent {
  event_type = 'Payments Payment Methods Screen Opened';
}

export class PaymentsTransactionHistoryScreenOpened implements BaseEvent {
  event_type = 'Payments Transaction History Screen Opened';
}

export class PaymentsWithdrawalFailed implements BaseEvent {
  event_type = 'Payments Withdrawal Failed';

  constructor(public event_properties: PaymentsWithdrawalFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentsWithdrawalScreenOpened implements BaseEvent {
  event_type = 'Payments Withdrawal Screen Opened';
}

export class PaymentsWithdrawalSucceeded implements BaseEvent {
  event_type = 'Payments Withdrawal Succeeded';

  constructor(public event_properties: PaymentsWithdrawalSucceededProperties) {
    this.event_properties = event_properties;
  }
}

export class PickTypeFilterSelected implements BaseEvent {
  event_type = 'Pick Type Filter Selected';

  constructor(public event_properties: PickTypeFilterSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemEntryCancelled implements BaseEvent {
  event_type = 'Pickem Entry Cancelled';

  constructor(public event_properties: PickemEntryCancelledProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemEntryCancelledInsured implements BaseEvent {
  event_type = 'Pickem Entry Cancelled - Insured';

  constructor(public event_properties: PickemEntryCancelledInsuredProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemEntryCancelledStandard implements BaseEvent {
  event_type = 'Pickem Entry Cancelled - Standard';

  constructor(public event_properties: PickemEntryCancelledStandardProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemEntryCreatedInsured implements BaseEvent {
  event_type = 'Pickem Entry Created - Insured';

  constructor(public event_properties: PickemEntryCreatedInsuredProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemEntryCreatedStandard implements BaseEvent {
  event_type = 'Pickem Entry Created - Standard';

  constructor(public event_properties: PickemEntryCreatedStandardProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemEntryShareOpened implements BaseEvent {
  event_type = 'Pickem Entry Share Opened';

  constructor(public event_properties?: PickemEntryShareOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemEntrySubmissionReused implements BaseEvent {
  event_type = 'Pickem Entry Submission Reused';

  constructor(public event_properties: PickemEntrySubmissionReusedProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemEntrySubmissionShared implements BaseEvent {
  event_type = 'Pickem Entry Submission Shared';

  constructor(public event_properties: PickemEntrySubmissionSharedProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemFavoriteAdded implements BaseEvent {
  event_type = 'Pickem Favorite Added';

  constructor(public event_properties: PickemFavoriteAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemFavoriteRemoved implements BaseEvent {
  event_type = 'Pickem Favorite Removed';

  constructor(public event_properties: PickemFavoriteRemovedProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemFavoriteScreenOpened implements BaseEvent {
  event_type = 'Pickem Favorite Screen Opened';
}

export class PickemHigherLowerSelectionAdded implements BaseEvent {
  event_type = 'Pickem Higher Lower Selection Added';

  constructor(public event_properties: PickemHigherLowerSelectionAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemLiveScreenOpened implements BaseEvent {
  event_type = 'Pickem Live Screen Opened';
}

export class PickemLobbyScreenOpened implements BaseEvent {
  event_type = 'Pickem Lobby Screen Opened';
}

export class PickemPackAdded implements BaseEvent {
  event_type = 'Pickem Pack Added';

  constructor(public event_properties: PickemPackAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemPackOverrideAccepted implements BaseEvent {
  event_type = 'Pickem Pack Override Accepted';
}

export class PickemPackOverrideNotAccepted implements BaseEvent {
  event_type = 'Pickem Pack Override Not Accepted';
}

export class PickemPackViewAllOpened implements BaseEvent {
  event_type = 'Pickem Pack View All Opened';

  constructor(public event_properties?: PickemPackViewAllOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemPlayerCardScreenOpened implements BaseEvent {
  event_type = 'Pickem Player Card Screen Opened';

  constructor(public event_properties: PickemPlayerCardScreenOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemPoolsContestAndPayoutDetailsScreenOpened implements BaseEvent {
  event_type = 'Pickem Pools Contest and Payout Details Screen Opened';
}

export class PickemPoolsInformationModalOpened implements BaseEvent {
  event_type = 'Pickem Pools Information Modal Opened';
}

export class PickemResultsScreenOpened implements BaseEvent {
  event_type = 'Pickem Results Screen Opened';
}

export class PickemRivalsSelectionAdded implements BaseEvent {
  event_type = 'Pickem Rivals Selection Added';

  constructor(public event_properties: PickemRivalsSelectionAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class PickemSportLobbySelected implements BaseEvent {
  event_type = 'Pickem Sport Lobby Selected';

  constructor(public event_properties: PickemSportLobbySelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class PromoSwapModalInteraction implements BaseEvent {
  event_type = 'Promo Swap Modal Interaction';

  constructor(public event_properties: PromoSwapModalInteractionProperties) {
    this.event_properties = event_properties;
  }
}

export class PromoSwapModalOpen implements BaseEvent {
  event_type = 'Promo Swap Modal Open';

  constructor(public event_properties: PromoSwapModalOpenProperties) {
    this.event_properties = event_properties;
  }
}

export class RegistrationBirthdayEnteredSuccessful implements BaseEvent {
  event_type = 'Registration Birthday Entered Successful';
}

export class RegistrationBirthdayEnteredUnsuccessful implements BaseEvent {
  event_type = 'Registration Birthday Entered Unsuccessful';

  constructor(public event_properties: RegistrationBirthdayEnteredUnsuccessfulProperties) {
    this.event_properties = event_properties;
  }
}

export class RegistrationDefaultReferralCodeInserted implements BaseEvent {
  event_type = 'Registration Default Referral Code Inserted';
}

export class RegistrationEmailEnteredSuccessful implements BaseEvent {
  event_type = 'Registration Email Entered Successful';
}

export class RegistrationEmailEnteredUnsuccessful implements BaseEvent {
  event_type = 'Registration Email Entered Unsuccessful';

  constructor(public event_properties?: RegistrationEmailEnteredUnsuccessfulProperties) {
    this.event_properties = event_properties;
  }
}

export class RegistrationPasswordEnteredSuccessful implements BaseEvent {
  event_type = 'Registration Password Entered Successful';
}

export class RegistrationReferralCodeEnteredSuccessful implements BaseEvent {
  event_type = 'Registration Referral Code Entered Successful';

  constructor(public event_properties: RegistrationReferralCodeEnteredSuccessfulProperties) {
    this.event_properties = event_properties;
  }
}

export class RegistrationReferralCodeEnteredUnsuccessful implements BaseEvent {
  event_type = 'Registration Referral Code Entered Unsuccessful';

  constructor(public event_properties: RegistrationReferralCodeEnteredUnsuccessfulProperties) {
    this.event_properties = event_properties;
  }
}

export class RegistrationSignUpButtonPressed implements BaseEvent {
  event_type = 'Registration Sign Up Button Pressed';
}

export class RegistrationSignUpInitiated implements BaseEvent {
  event_type = 'Registration Sign Up Initiated';

  constructor(public event_properties?: RegistrationSignUpInitiatedProperties) {
    this.event_properties = event_properties;
  }
}

export class RegistrationUnsuccessfulRegistrationDialogShown implements BaseEvent {
  event_type = 'Registration Unsuccessful Registration Dialog Shown';

  constructor(public event_properties: RegistrationUnsuccessfulRegistrationDialogShownProperties) {
    this.event_properties = event_properties;
  }
}

export class RegistrationUsernameEnteredSuccessful implements BaseEvent {
  event_type = 'Registration Username Entered Successful';
}

export class RegistrationUsernameEnteredUnsuccessful implements BaseEvent {
  event_type = 'Registration Username Entered Unsuccessful';

  constructor(public event_properties?: RegistrationUsernameEnteredUnsuccessfulProperties) {
    this.event_properties = event_properties;
  }
}

export class RemovePickFromYourPicksScreen implements BaseEvent {
  event_type = 'Remove Pick From Your Picks Screen';

  constructor(public event_properties: RemovePickFromYourPicksScreenProperties) {
    this.event_properties = event_properties;
  }
}

export class RemoveTokenizedPack implements BaseEvent {
  event_type = 'Remove Tokenized Pack';

  constructor(public event_properties?: RemoveTokenizedPackProperties) {
    this.event_properties = event_properties;
  }
}

export class RemoveTokenizedPackSelectionModalInteraction implements BaseEvent {
  event_type = 'Remove Tokenized Pack Selection Modal Interaction';

  constructor(public event_properties: RemoveTokenizedPackSelectionModalInteractionProperties) {
    this.event_properties = event_properties;
  }
}

export class RewardsHubOpened implements BaseEvent {
  event_type = 'Rewards Hub Opened';

  constructor(public event_properties?: RewardsHubOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class RivalFilterSelected implements BaseEvent {
  event_type = 'Rival Filter Selected';

  constructor(public event_properties: RivalFilterSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class SearchBarSelected implements BaseEvent {
  event_type = 'Search Bar Selected';

  constructor(public event_properties: SearchBarSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class SessionEnd implements BaseEvent {
  event_type = 'session_end';
}

export class SessionStart implements BaseEvent {
  event_type = 'session_start';
}

export class SocureIdInfoScreenOpened implements BaseEvent {
  event_type = 'Socure ID Info Screen Opened';
}

export class SocureIdUploadFlowInitiated implements BaseEvent {
  event_type = 'Socure ID Upload Flow Initiated';

  constructor(public event_properties?: SocureIdUploadFlowInitiatedProperties) {
    this.event_properties = event_properties;
  }
}

export class SocureIdUploadFlowSucceeded implements BaseEvent {
  event_type = 'Socure ID Upload Flow Succeeded';
}

export class SocureInfoScreenOpened implements BaseEvent {
  event_type = 'Socure Info Screen Opened';
}

export class SplashScreenLogInButtonPressed implements BaseEvent {
  event_type = 'Splash Screen Log In Button Pressed';
}

export class SplashScreenShown implements BaseEvent {
  event_type = 'Splash Screen Shown';
}

export class TokenAddedToEntry implements BaseEvent {
  event_type = 'Token added to Entry';

  constructor(public event_properties: TokenAddedToEntryProperties) {
    this.event_properties = event_properties;
  }
}

export class TokenAppliedToSelection implements BaseEvent {
  event_type = 'Token Applied to Selection';

  constructor(public event_properties: TokenAppliedToSelectionProperties) {
    this.event_properties = event_properties;
  }
}

export class TokenOptedIn implements BaseEvent {
  event_type = 'Token Opted In';

  constructor(public event_properties?: TokenOptedInProperties) {
    this.event_properties = event_properties;
  }
}

export class TokenRemovedFromEntry implements BaseEvent {
  event_type = 'Token Removed from Entry';

  constructor(public event_properties: TokenRemovedFromEntryProperties) {
    this.event_properties = event_properties;
  }
}

export class TokenRemovedFromSelection implements BaseEvent {
  event_type = 'Token Removed from Selection';

  constructor(public event_properties: TokenRemovedFromSelectionProperties) {
    this.event_properties = event_properties;
  }
}

export class TypingInSearch implements BaseEvent {
  event_type = 'Typing in Search';

  constructor(public event_properties?: TypingInSearchProperties) {
    this.event_properties = event_properties;
  }
}

export class VerificationScreenOpened implements BaseEvent {
  event_type = 'Verification Screen Opened';
}

export class WebLandingDownloadAppClicked implements BaseEvent {
  event_type = 'Web Landing Download App Clicked';
}

export class WebLandingPage implements BaseEvent {
  event_type = 'Web Landing Page';
}

export class WithdrawalCancelSucceeded implements BaseEvent {
  event_type = 'Withdrawal Cancel Succeeded';
}

export class WithdrawalMethodSelected implements BaseEvent {
  event_type = 'Withdrawal Method Selected';

  constructor(public event_properties: WithdrawalMethodSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class WithdrawalScreenExited implements BaseEvent {
  event_type = 'Withdrawal Screen Exited';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Additional ID Required Cancel Tapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Additional%20ID%20Required%20Cancel%20Tapped)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  additionalIdRequiredCancelTapped(
    options?: EventOptions,
  ) {
    return this.track(new AdditionalIdRequiredCancelTapped(), options);
  }

  /**
   * Additional ID Required Modal Shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Additional%20ID%20Required%20Modal%20Shown)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  additionalIdRequiredModalShown(
    options?: EventOptions,
  ) {
    return this.track(new AdditionalIdRequiredModalShown(), options);
  }

  /**
   * Additional ID Required Verify ID Tapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Additional%20ID%20Required%20Verify%20ID%20Tapped)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  additionalIdRequiredVerifyIdTapped(
    options?: EventOptions,
  ) {
    return this.track(new AdditionalIdRequiredVerifyIdTapped(), options);
  }

  /**
   * Collapse All Button Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Collapse%20All%20Button%20Selected)
   *
   * **this event fires every time a user clicks the collapse all button in the Pick’em lobby**
   *
   * @param properties The event's properties (e.g. lobby_name)
   * @param options Amplitude event options.
   */
  collapseAllButtonSelected(
    properties: CollapseAllButtonSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollapseAllButtonSelected(properties), options);
  }

  /**
   * Collapse Game Button Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Collapse%20Game%20Button%20Selected)
   *
   * this event fires every time a user collapses an individual game inside the Pick'em Lobby
   *
   * @param properties The event's properties (e.g. game_rank)
   * @param options Amplitude event options.
   */
  collapseGameButtonSelected(
    properties: CollapseGameButtonSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollapseGameButtonSelected(properties), options);
  }

  /**
   * Collapse Player Lobby Card
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Collapse%20Player%20Lobby%20Card)
   *
   * This event fires when a customer selects the ‘Show less’ button in the player cell
   *
   * @param properties The event's properties (e.g. player_id)
   * @param options Amplitude event options.
   */
  collapsePlayerLobbyCard(
    properties: CollapsePlayerLobbyCardProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollapsePlayerLobbyCard(properties), options);
  }

  /**
   * Contest Legality Letter Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Contest%20Legality%20Letter%20Opened)
   *
   * Tracks when someone views /legality-underdog-contests
   *
   * @param options Amplitude event options.
   */
  contestLegalityLetterOpened(
    options?: EventOptions,
  ) {
    return this.track(new ContestLegalityLetterOpened(), options);
  }

  /**
   * CS Verification Needed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/CS%20Verification%20Needed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  csVerificationNeeded(
    options?: EventOptions,
  ) {
    return this.track(new CsVerificationNeeded(), options);
  }

  /**
   * CS Verification Needed Close
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/CS%20Verification%20Needed%20Close)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  csVerificationNeededClose(
    options?: EventOptions,
  ) {
    return this.track(new CsVerificationNeededClose(), options);
  }

  /**
   * CS Verification Needed Contact Support
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/CS%20Verification%20Needed%20Contact%20Support)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  csVerificationNeededContactSupport(
    options?: EventOptions,
  ) {
    return this.track(new CsVerificationNeededContactSupport(), options);
  }

  /**
   * Default Deposit Amount Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Default%20Deposit%20Amount%20Selected)
   *
   * The user selects one of the default payment amounts
   *
   * @param properties The event's properties (e.g. deposit_amount)
   * @param options Amplitude event options.
   */
  defaultDepositAmountSelected(
    properties: DefaultDepositAmountSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DefaultDepositAmountSelected(properties), options);
  }

  /**
   * Deposit Amount Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Deposit%20Amount%20Changed)
   *
   * The user changes the deposit amount
   *
   * @param properties The event's properties (e.g. deposit_amount)
   * @param options Amplitude event options.
   */
  depositAmountChanged(
    properties: DepositAmountChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DepositAmountChanged(properties), options);
  }

  /**
   * Deposit Screen Exited
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Deposit%20Screen%20Exited)
   *
   * User exits deposit page
   *
   * @param options Amplitude event options.
   */
  depositScreenExited(
    options?: EventOptions,
  ) {
    return this.track(new DepositScreenExited(), options);
  }

  /**
   * Draft Confirmation Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Draft%20Confirmation%20Button%20Clicked)
   *
   * This event is fired when a customer clicks “confirm” on the draft confirmation modal
   *
   * @param options Amplitude event options.
   */
  draftConfirmationButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new DraftConfirmationButtonClicked(), options);
  }

  /**
   * Draft Confirmation Cancel Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Draft%20Confirmation%20Cancel%20Button%20Clicked)
   *
   * This event is fired when a customer clicks or taps “cancel” on the draft confirmation modal
   *
   * @param options Amplitude event options.
   */
  draftConfirmationCancelButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new DraftConfirmationCancelButtonClicked(), options);
  }

  /**
   * Draft Entry Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Draft%20Entry%20Button%20Clicked)
   *
   * Event is triggered when a user clicks on the draft entry button
   *
   * @param properties The event's properties (e.g. draft_contest_type)
   * @param options Amplitude event options.
   */
  draftEntryButtonClicked(
    properties: DraftEntryButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DraftEntryButtonClicked(properties), options);
  }

  /**
   * Draft Lobby Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Draft%20Lobby%20Screen%20Opened)
   *
   * This event tracks when a user opens the draft lobby screen (from top nav or organic direct link).
   *
   * @param options Amplitude event options.
   */
  draftLobbyScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new DraftLobbyScreenOpened(), options);
  }

  /**
   * Edit Picks Selection from Correlation Toast
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Edit%20Picks%20Selection%20from%20Correlation%20Toast)
   *
   * This event fires every time a user selects edit picks from the correlation toast
   *
   * @param properties The event's properties (e.g. pick_sport_name)
   * @param options Amplitude event options.
   */
  editPicksSelectionFromCorrelationToast(
    properties: EditPicksSelectionFromCorrelationToastProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditPicksSelectionFromCorrelationToast(properties), options);
  }

  /**
   * Existing Payment Method Retried
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Existing%20Payment%20Method%20Retried)
   *
   * The user selects an existing payment method
   *
   * @param properties The event's properties (e.g. client_type)
   * @param options Amplitude event options.
   */
  existingPaymentMethodRetried(
    properties: ExistingPaymentMethodRetriedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExistingPaymentMethodRetried(properties), options);
  }

  /**
   * Existing Payment Method Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Existing%20Payment%20Method%20Selected)
   *
   * The user selects an existing payment method
   *
   * @param properties The event's properties (e.g. deposit_amount)
   * @param options Amplitude event options.
   */
  existingPaymentMethodSelected(
    properties: ExistingPaymentMethodSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExistingPaymentMethodSelected(properties), options);
  }

  /**
   * Expand All Button Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Expand%20All%20Button%20Selected)
   *
   * **this event fires every time a user clicks the expand all button in the Pick’em lobby**
   *
   * @param properties The event's properties (e.g. lobby_name)
   * @param options Amplitude event options.
   */
  expandAllButtonSelected(
    properties: ExpandAllButtonSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExpandAllButtonSelected(properties), options);
  }

  /**
   * Expand Game Button Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Expand%20Game%20Button%20Selected)
   *
   * this event fires every time a user clicks to expand an individual game inside the Pick'em lobby
   *
   * @param properties The event's properties (e.g. game_rank)
   * @param options Amplitude event options.
   */
  expandGameButtonSelected(
    properties: ExpandGameButtonSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExpandGameButtonSelected(properties), options);
  }

  /**
   * Expand Player Lobby Card
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Expand%20Player%20Lobby%20Card)
   *
   * This event fires when a customer selects the ‘Show more’ button in the player cell
   *
   * @param properties The event's properties (e.g. player_id)
   * @param options Amplitude event options.
   */
  expandPlayerLobbyCard(
    properties: ExpandPlayerLobbyCardProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExpandPlayerLobbyCard(properties), options);
  }

  /**
   * Flexed Slip Dialog - Info Button Tapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Flexed%20Slip%20Dialog%20-%20Info%20Button%20Tapped)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  flexedSlipDialogInfoButtonTapped(
    options?: EventOptions,
  ) {
    return this.track(new FlexedSlipDialogInfoButtonTapped(), options);
  }

  /**
   * Flexed Slip Dialog - Remove Boosted Pick
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Flexed%20Slip%20Dialog%20-%20Remove%20Boosted%20Pick)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  flexedSlipDialogRemoveBoostedPick(
    options?: EventOptions,
  ) {
    return this.track(new FlexedSlipDialogRemoveBoostedPick(), options);
  }

  /**
   * Flexed Slip Dialog - Remove Flex
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Flexed%20Slip%20Dialog%20-%20Remove%20Flex)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  flexedSlipDialogRemoveFlex(
    options?: EventOptions,
  ) {
    return this.track(new FlexedSlipDialogRemoveFlex(), options);
  }

  /**
   * Form Verification Needed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Form%20Verification%20Needed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  formVerificationNeeded(
    options?: EventOptions,
  ) {
    return this.track(new FormVerificationNeeded(), options);
  }

  /**
   * Form Verification Needed Contact Support
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Form%20Verification%20Needed%20Contact%20Support)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  formVerificationNeededContactSupport(
    options?: EventOptions,
  ) {
    return this.track(new FormVerificationNeededContactSupport(), options);
  }

  /**
   * Form Verification Needed Try Again
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Form%20Verification%20Needed%20Try%20Again)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  formVerificationNeededTryAgain(
    options?: EventOptions,
  ) {
    return this.track(new FormVerificationNeededTryAgain(), options);
  }

  /**
   * Form Verification Required Modal Cancel Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Form%20Verification%20Required%20Modal%20Cancel%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  formVerificationRequiredModalCancelClicked(
    options?: EventOptions,
  ) {
    return this.track(new FormVerificationRequiredModalCancelClicked(), options);
  }

  /**
   * Form Verification Required Modal Get Verified Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Form%20Verification%20Required%20Modal%20Get%20Verified%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  formVerificationRequiredModalGetVerifiedClicked(
    options?: EventOptions,
  ) {
    return this.track(new FormVerificationRequiredModalGetVerifiedClicked(), options);
  }

  /**
   * Form Verification Required Modal Shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Form%20Verification%20Required%20Modal%20Shown)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  formVerificationRequiredModalShown(
    options?: EventOptions,
  ) {
    return this.track(new FormVerificationRequiredModalShown(), options);
  }

  /**
   * Higher Lower Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Higher%20Lower%20Filter%20Selected)
   *
   * This event fires when a user selects the higher lower filter within the pick'em lobby
   *
   * @param properties The event's properties (e.g. is_blank)
   * @param options Amplitude event options.
   */
  higherLowerFilterSelected(
    properties: HigherLowerFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HigherLowerFilterSelected(properties), options);
  }

  /**
   * ID Verification Needed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/ID%20Verification%20Needed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  idVerificationNeeded(
    options?: EventOptions,
  ) {
    return this.track(new IdVerificationNeeded(), options);
  }

  /**
   * ID Verification Needed Contact Support
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/ID%20Verification%20Needed%20Contact%20Support)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  idVerificationNeededContactSupport(
    options?: EventOptions,
  ) {
    return this.track(new IdVerificationNeededContactSupport(), options);
  }

  /**
   * ID Verification Needed Scan ID
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/ID%20Verification%20Needed%20Scan%20ID)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  idVerificationNeededScanId(
    options?: EventOptions,
  ) {
    return this.track(new IdVerificationNeededScanId(), options);
  }

  /**
   * Invite Friends Share Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Invite%20Friends%20Share%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. user_type)
   * @param options Amplitude event options.
   */
  inviteFriendsShareOpened(
    properties?: InviteFriendsShareOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InviteFriendsShareOpened(properties), options);
  }

  /**
   * Learn More from Pick Callout
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Learn%20More%20from%20Pick%20Callout)
   *
   * This event fires every time a user selects Learn More from a correlated pick callout on the Your Picks Screen
   *
   * @param properties The event's properties (e.g. pick_sport_name)
   * @param options Amplitude event options.
   */
  learnMoreFromPickCallout(
    properties: LearnMoreFromPickCalloutProperties,
    options?: EventOptions,
  ) {
    return this.track(new LearnMoreFromPickCallout(properties), options);
  }

  /**
   * New Payment Method Retried
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/New%20Payment%20Method%20Retried)
   *
   * The user selects to add a new payment method after a failed deposit attempt
   *
   * @param properties The event's properties (e.g. deposit_amount)
   * @param options Amplitude event options.
   */
  newPaymentMethodRetried(
    properties: NewPaymentMethodRetriedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewPaymentMethodRetried(properties), options);
  }

  /**
   * New Payment Method Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/New%20Payment%20Method%20Selected)
   *
   * The user selects to add a new payment method
   *
   * @param properties The event's properties (e.g. deposit_amount)
   * @param options Amplitude event options.
   */
  newPaymentMethodSelected(
    properties: NewPaymentMethodSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewPaymentMethodSelected(properties), options);
  }

  /**
   * News Feed Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/News%20Feed%20Opened)
   *
   * This event tracks when a user opened the news feed located in the bottom navigation in the app
   *
   * @param options Amplitude event options.
   */
  newsFeedOpened(
    options?: EventOptions,
  ) {
    return this.track(new NewsFeedOpened(), options);
  }

  /**
   * Password Reset Go To Login Tapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Password%20Reset%20Go%20To%20Login%20Tapped)
   *
   * Event indicating that a user tapped on "Go To Login" after initiating a password reset process.
   *
   * @param options Amplitude event options.
   */
  passwordResetGoToLoginTapped(
    options?: EventOptions,
  ) {
    return this.track(new PasswordResetGoToLoginTapped(), options);
  }

  /**
   * Password Reset Success Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Password%20Reset%20Success%20Screen)
   *
   * Event that indicates a successful password reset process, displayed on the screen to the user.
   *
   * @param options Amplitude event options.
   */
  passwordResetSuccessScreen(
    options?: EventOptions,
  ) {
    return this.track(new PasswordResetSuccessScreen(), options);
  }

  /**
   * Password Reset Successful
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Password%20Reset%20Successful)
   *
   * Event indicating that a user successfully reset their password.
   *
   * @param options Amplitude event options.
   */
  passwordResetSuccessful(
    options?: EventOptions,
  ) {
    return this.track(new PasswordResetSuccessful(), options);
  }

  /**
   * Password Reset Unsuccessful
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Password%20Reset%20Unsuccessful)
   *
   * Event to track unsuccessful attempts to reset password.
   *
   * @param properties The event's properties (e.g. error_text)
   * @param options Amplitude event options.
   */
  passwordResetUnsuccessful(
    properties?: PasswordResetUnsuccessfulProperties,
    options?: EventOptions,
  ) {
    return this.track(new PasswordResetUnsuccessful(properties), options);
  }

  /**
   * Payments Bonus Offer Banner Deposit Initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Bonus%20Offer%20Banner%20Deposit%20Initiated)
   *
   * Event is triggered when user clicks deposit CTA in deposit match banner.
   *
   * @param options Amplitude event options.
   */
  paymentsBonusOfferBannerDepositInitiated(
    options?: EventOptions,
  ) {
    return this.track(new PaymentsBonusOfferBannerDepositInitiated(), options);
  }

  /**
   * Payments Bonus Offer Modal Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Bonus%20Offer%20Modal%20Closed)
   *
   * Customer clicks 'X' on Bonus Offer Deposit Match Modal
   *
   * Owner: Alex B
   *
   * @param options Amplitude event options.
   */
  paymentsBonusOfferModalClosed(
    options?: EventOptions,
  ) {
    return this.track(new PaymentsBonusOfferModalClosed(), options);
  }

  /**
   * Payments Bonus Offer Modal Deposit Initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Bonus%20Offer%20Modal%20Deposit%20Initiated)
   *
   * User taps deposit now from deposit match bonus offer modal
   *
   * @param options Amplitude event options.
   */
  paymentsBonusOfferModalDepositInitiated(
    options?: EventOptions,
  ) {
    return this.track(new PaymentsBonusOfferModalDepositInitiated(), options);
  }

  /**
   * Payments Bonus Offer Modal Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Bonus%20Offer%20Modal%20Opened)
   *
   * User views "Deposit Match" modal after registration
   *
   * @param options Amplitude event options.
   */
  paymentsBonusOfferModalOpened(
    options?: EventOptions,
  ) {
    return this.track(new PaymentsBonusOfferModalOpened(), options);
  }

  /**
   * Payments Deposit Amount Button Pressed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Deposit%20Amount%20Button%20Pressed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. existing_amount)
   * @param options Amplitude event options.
   */
  paymentsDepositAmountButtonPressed(
    properties?: PaymentsDepositAmountButtonPressedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentsDepositAmountButtonPressed(properties), options);
  }

  /**
   * Payments Deposit Amount Text Entered
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Deposit%20Amount%20Text%20Entered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. existing_amount)
   * @param options Amplitude event options.
   */
  paymentsDepositAmountTextEntered(
    properties?: PaymentsDepositAmountTextEnteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentsDepositAmountTextEntered(properties), options);
  }

  /**
   * Payments Deposit Balance Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Deposit%20Balance%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. balance_amount)
   * @param options Amplitude event options.
   */
  paymentsDepositBalanceClicked(
    properties?: PaymentsDepositBalanceClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentsDepositBalanceClicked(properties), options);
  }

  /**
   * Payments Deposit Button Pressed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Deposit%20Button%20Pressed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. deposit_amount)
   * @param options Amplitude event options.
   */
  paymentsDepositButtonPressed(
    properties: PaymentsDepositButtonPressedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentsDepositButtonPressed(properties), options);
  }

  /**
   * Payments Deposit Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Deposit%20Failed)
   *
   * The user fails to make a deposit
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. deposit_amount)
   * @param options Amplitude event options.
   */
  paymentsDepositFailed(
    properties: PaymentsDepositFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentsDepositFailed(properties), options);
  }

  /**
   * Payments Deposit Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Deposit%20Screen%20Opened)
   *
   * The deposit landing page is opened by the user
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param options Amplitude event options.
   */
  paymentsDepositScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new PaymentsDepositScreenOpened(), options);
  }

  /**
   * Payments Deposit Succeeded
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Deposit%20Succeeded)
   *
   * The user makes a successful deposit
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. deposit_amount)
   * @param options Amplitude event options.
   */
  paymentsDepositSucceeded(
    properties: PaymentsDepositSucceededProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentsDepositSucceeded(properties), options);
  }

  /**
   * Payments Invite Friends Link Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Invite%20Friends%20Link%20Shared)
   *
   * User taps share underdog invtie with Friends
   *
   * @param properties The event's properties (e.g. share_type)
   * @param options Amplitude event options.
   */
  paymentsInviteFriendsLinkShared(
    properties?: PaymentsInviteFriendsLinkSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentsInviteFriendsLinkShared(properties), options);
  }

  /**
   * Payments Invite Friends Link Tapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Invite%20Friends%20Link%20Tapped)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  paymentsInviteFriendsLinkTapped(
    options?: EventOptions,
  ) {
    return this.track(new PaymentsInviteFriendsLinkTapped(), options);
  }

  /**
   * Payments Invite Friends Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Invite%20Friends%20Screen%20Opened)
   *
   * User views invite friends screen
   *
   * @param options Amplitude event options.
   */
  paymentsInviteFriendsScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new PaymentsInviteFriendsScreenOpened(), options);
  }

  /**
   * Payments Payment Method Removed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Payment%20Method%20Removed)
   *
   * User removed payment method (only available on payment Methods screen)
   *
   * @param properties The event's properties (e.g. payment_method)
   * @param options Amplitude event options.
   */
  paymentsPaymentMethodRemoved(
    properties: PaymentsPaymentMethodRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentsPaymentMethodRemoved(properties), options);
  }

  /**
   * Payments Payment Methods Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Payment%20Methods%20Screen%20Opened)
   *
   * User views payment Methods screen
   *
   * @param options Amplitude event options.
   */
  paymentsPaymentMethodsScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new PaymentsPaymentMethodsScreenOpened(), options);
  }

  /**
   * Payments Transaction History Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Transaction%20History%20Screen%20Opened)
   *
   * User views transaction history screen
   *
   * @param options Amplitude event options.
   */
  paymentsTransactionHistoryScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new PaymentsTransactionHistoryScreenOpened(), options);
  }

  /**
   * Payments Withdrawal Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Withdrawal%20Failed)
   *
   * The user fails to make a withdrawal request
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. withdrawal_amount)
   * @param options Amplitude event options.
   */
  paymentsWithdrawalFailed(
    properties: PaymentsWithdrawalFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentsWithdrawalFailed(properties), options);
  }

  /**
   * Payments Withdrawal Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Withdrawal%20Screen%20Opened)
   *
   * The withdrawals landing page is opened by the user
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param options Amplitude event options.
   */
  paymentsWithdrawalScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new PaymentsWithdrawalScreenOpened(), options);
  }

  /**
   * Payments Withdrawal Succeeded
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Payments%20Withdrawal%20Succeeded)
   *
   * The user successfully makes a withdrawal attempt
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. withdrawal_amount)
   * @param options Amplitude event options.
   */
  paymentsWithdrawalSucceeded(
    properties: PaymentsWithdrawalSucceededProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentsWithdrawalSucceeded(properties), options);
  }

  /**
   * Pick Type Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pick%20Type%20Filter%20Selected)
   *
   * This event fires every time a user selects the pick’em lobby filter for pick type. The options to this filter are ‘All’, ‘In-Game’, and ‘Pre-game’
   *
   * @param properties The event's properties (e.g. filter_option_selected)
   * @param options Amplitude event options.
   */
  pickTypeFilterSelected(
    properties: PickTypeFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickTypeFilterSelected(properties), options);
  }

  /**
   * Pickem Entry Cancelled
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Entry%20Cancelled)
   *
   * This event is triggered when a user taps Cancel from post-submission confirmation modal after tapping Submit on a standard entry
   *
   * @param properties The event's properties (e.g. boost_id)
   * @param options Amplitude event options.
   */
  pickemEntryCancelled(
    properties: PickemEntryCancelledProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemEntryCancelled(properties), options);
  }

  /**
   * Pickem Entry Cancelled - Insured
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Entry%20Cancelled%20-%20Insured)
   *
   * This event is triggered when a user taps Cancel from post-submission confirmation modal after tapping Submit on an insured entry
   *
   * @param properties The event's properties (e.g. entry_amount)
   * @param options Amplitude event options.
   */
  pickemEntryCancelledInsured(
    properties: PickemEntryCancelledInsuredProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemEntryCancelledInsured(properties), options);
  }

  /**
   * Pickem Entry Cancelled - Standard
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Entry%20Cancelled%20-%20Standard)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. boost_id)
   * @param options Amplitude event options.
   */
  pickemEntryCancelledStandard(
    properties: PickemEntryCancelledStandardProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemEntryCancelledStandard(properties), options);
  }

  /**
   * Pickem Entry Created - Insured
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Entry%20Created%20-%20Insured)
   *
   * An Insured Pickem Entry is created
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. contains_modifier)
   * @param options Amplitude event options.
   */
  pickemEntryCreatedInsured(
    properties: PickemEntryCreatedInsuredProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemEntryCreatedInsured(properties), options);
  }

  /**
   * Pickem Entry Created - Standard
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Entry%20Created%20-%20Standard)
   *
   * User submitted pick’em entry and it is created successfully
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. boost_id)
   * @param options Amplitude event options.
   */
  pickemEntryCreatedStandard(
    properties: PickemEntryCreatedStandardProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemEntryCreatedStandard(properties), options);
  }

  /**
   * Pickem Entry Share Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Entry%20Share%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. user_type)
   * @param options Amplitude event options.
   */
  pickemEntryShareOpened(
    properties?: PickemEntryShareOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemEntryShareOpened(properties), options);
  }

  /**
   * Pickem Entry Submission Reused
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Entry%20Submission%20Reused)
   *
   * This event tracks when a user taps the share entry button.
   *
   * @param properties The event's properties (e.g. entry_amount)
   * @param options Amplitude event options.
   */
  pickemEntrySubmissionReused(
    properties: PickemEntrySubmissionReusedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemEntrySubmissionReused(properties), options);
  }

  /**
   * Pickem Entry Submission Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Entry%20Submission%20Shared)
   *
   * This event tracks when a user taps the share entry button.
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. entry_amount)
   * @param options Amplitude event options.
   */
  pickemEntrySubmissionShared(
    properties: PickemEntrySubmissionSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemEntrySubmissionShared(properties), options);
  }

  /**
   * Pickem Favorite Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Favorite%20Added)
   *
   * User adds a player to their Favorites list
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. player_id)
   * @param options Amplitude event options.
   */
  pickemFavoriteAdded(
    properties: PickemFavoriteAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemFavoriteAdded(properties), options);
  }

  /**
   * Pickem Favorite Removed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Favorite%20Removed)
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. player_id)
   * @param options Amplitude event options.
   */
  pickemFavoriteRemoved(
    properties: PickemFavoriteRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemFavoriteRemoved(properties), options);
  }

  /**
   * Pickem Favorite Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Favorite%20Screen%20Opened)
   *
   * User views the Pickem favorites screen
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param options Amplitude event options.
   */
  pickemFavoriteScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new PickemFavoriteScreenOpened(), options);
  }

  /**
   * Pickem Higher Lower Selection Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Higher%20Lower%20Selection%20Added)
   *
   * This event tracks when a user adds a higher-lower selection to a Pickem entry.
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. boost_id)
   * @param options Amplitude event options.
   */
  pickemHigherLowerSelectionAdded(
    properties: PickemHigherLowerSelectionAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemHigherLowerSelectionAdded(properties), options);
  }

  /**
   * Pickem Live Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Live%20Screen%20Opened)
   *
   * User views the Pickem Live screen
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param options Amplitude event options.
   */
  pickemLiveScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new PickemLiveScreenOpened(), options);
  }

  /**
   * Pickem Lobby Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Lobby%20Screen%20Opened)
   *
   * This event tracks when a user opens the pick'em lobby screen (from top nav or organic direct link).
   *
   * Owner: Guy Lake
   *
   * @param options Amplitude event options.
   */
  pickemLobbyScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new PickemLobbyScreenOpened(), options);
  }

  /**
   * Pickem Pack Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Pack%20Added)
   *
   * This event is triggered when a user successfully adds a pack to their entry. Event should NOT trigger if user opts to not override existing picks in entry.
   *
   * @param properties The event's properties (e.g. is_featured)
   * @param options Amplitude event options.
   */
  pickemPackAdded(
    properties: PickemPackAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemPackAdded(properties), options);
  }

  /**
   * Pickem Pack Override Accepted
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Pack%20Override%20Accepted)
   *
   * This event should trigger when a user accepts to override picks already in their entry with picks from a Pickem pack
   *
   * @param options Amplitude event options.
   */
  pickemPackOverrideAccepted(
    options?: EventOptions,
  ) {
    return this.track(new PickemPackOverrideAccepted(), options);
  }

  /**
   * Pickem Pack Override Not Accepted
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Pack%20Override%20Not%20Accepted)
   *
   * This event should trigger when a declines to override picks already in their entry with picks from a Pickem pack
   *
   * @param options Amplitude event options.
   */
  pickemPackOverrideNotAccepted(
    options?: EventOptions,
  ) {
    return this.track(new PickemPackOverrideNotAccepted(), options);
  }

  /**
   * Pickem Pack View All Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Pack%20View%20All%20Opened)
   *
   * This event is triggered when a user clicks to view all packs and is taken to the all packs view.
   *
   * @param properties The event's properties (e.g. lobby_name)
   * @param options Amplitude event options.
   */
  pickemPackViewAllOpened(
    properties?: PickemPackViewAllOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemPackViewAllOpened(properties), options);
  }

  /**
   * Pickem Player Card Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Player%20Card%20Screen%20Opened)
   *
   * User views the player card
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. player_id)
   * @param options Amplitude event options.
   */
  pickemPlayerCardScreenOpened(
    properties: PickemPlayerCardScreenOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemPlayerCardScreenOpened(properties), options);
  }

  /**
   * Pickem Pools Contest and Payout Details Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Pools%20Contest%20and%20Payout%20Details%20Screen%20Opened)
   *
   * This event tracks when a user opens the Pickem Pools Contest and Payout Details screen from a Pickem Pools entry.
   *
   * @param options Amplitude event options.
   */
  pickemPoolsContestAndPayoutDetailsScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new PickemPoolsContestAndPayoutDetailsScreenOpened(), options);
  }

  /**
   * Pickem Pools Information Modal Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Pools%20Information%20Modal%20Opened)
   *
   * This event tracks when a user opens the information modal for Pickem Pools.
   *
   * @param options Amplitude event options.
   */
  pickemPoolsInformationModalOpened(
    options?: EventOptions,
  ) {
    return this.track(new PickemPoolsInformationModalOpened(), options);
  }

  /**
   * Pickem Results Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Results%20Screen%20Opened)
   *
   * This event is triggered when a user views the Pickem Results screen
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param options Amplitude event options.
   */
  pickemResultsScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new PickemResultsScreenOpened(), options);
  }

  /**
   * Pickem Rivals Selection Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Rivals%20Selection%20Added)
   *
   * This event tracks when a user adds a Rivals selection to Pickem entry.
   *
   * Owner: scott.bartlett@underdogfantasy.com
   *
   * @param properties The event's properties (e.g. adjustment_amount)
   * @param options Amplitude event options.
   */
  pickemRivalsSelectionAdded(
    properties: PickemRivalsSelectionAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemRivalsSelectionAdded(properties), options);
  }

  /**
   * Pickem Sport Lobby Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Pickem%20Sport%20Lobby%20Selected)
   *
   * Event Description: This event is triggered when a user selects a specific sport lobby in the Pickem game mode. It provides insights into user preferences and helps analyze the popularity of different sports among customers
   *
   * @param properties The event's properties (e.g. is_blank)
   * @param options Amplitude event options.
   */
  pickemSportLobbySelected(
    properties: PickemSportLobbySelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PickemSportLobbySelected(properties), options);
  }

  /**
   * Promo Swap Modal Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Promo%20Swap%20Modal%20Interaction)
   *
   * Event to track user interaction with the modal that confirms whether they want to swap their existing promo for a new one
   *
   * @param properties The event's properties (e.g. modal_choice)
   * @param options Amplitude event options.
   */
  promoSwapModalInteraction(
    properties: PromoSwapModalInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new PromoSwapModalInteraction(properties), options);
  }

  /**
   * Promo Swap Modal Open
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Promo%20Swap%20Modal%20Open)
   *
   * This event fires when the promo swap modal opens and gives context into what promos are involved
   *
   * @param properties The event's properties (e.g. existing_promo_type)
   * @param options Amplitude event options.
   */
  promoSwapModalOpen(
    properties: PromoSwapModalOpenProperties,
    options?: EventOptions,
  ) {
    return this.track(new PromoSwapModalOpen(properties), options);
  }

  /**
   * Registration Birthday Entered Successful
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Birthday%20Entered%20Successful)
   *
   * User enters birthday and taps continue
   *
   * @param options Amplitude event options.
   */
  registrationBirthdayEnteredSuccessful(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationBirthdayEnteredSuccessful(), options);
  }

  /**
   * Registration Birthday Entered Unsuccessful
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Birthday%20Entered%20Unsuccessful)
   *
   * User enters birthdate indicating they are under the age of 18 (or 19 in some states). View error message and greyed continue button
   *
   * @param properties The event's properties (e.g. age)
   * @param options Amplitude event options.
   */
  registrationBirthdayEnteredUnsuccessful(
    properties: RegistrationBirthdayEnteredUnsuccessfulProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationBirthdayEnteredUnsuccessful(properties), options);
  }

  /**
   * Registration Default Referral Code Inserted
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Default%20Referral%20Code%20Inserted)
   *
   * User does not enter code and taps continue
   *
   * @param options Amplitude event options.
   */
  registrationDefaultReferralCodeInserted(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationDefaultReferralCodeInserted(), options);
  }

  /**
   * Registration Email Entered Successful
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Email%20Entered%20Successful)
   *
   * User enters email and taps continue
   *
   * @param options Amplitude event options.
   */
  registrationEmailEnteredSuccessful(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationEmailEnteredSuccessful(), options);
  }

  /**
   * Registration Email Entered Unsuccessful
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Email%20Entered%20Unsuccessful)
   *
   * User enters email and views email already taken error \[THIS ERROR DOES APPEAR UNTIL USER CLICKS “SIGN UP” VERY LATE IN THE FLOW\]
   *
   * @param properties The event's properties (e.g. failure_reason)
   * @param options Amplitude event options.
   */
  registrationEmailEnteredUnsuccessful(
    properties?: RegistrationEmailEnteredUnsuccessfulProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationEmailEnteredUnsuccessful(properties), options);
  }

  /**
   * Registration Password Entered Successful
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Password%20Entered%20Successful)
   *
   * User enters password and taps Sign Up
   *
   * @param options Amplitude event options.
   */
  registrationPasswordEnteredSuccessful(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationPasswordEnteredSuccessful(), options);
  }

  /**
   * Registration Referral Code Entered Successful
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Referral%20Code%20Entered%20Successful)
   *
   * User enters promo code or friend's name in form and taps 'Continue'
   *
   * @param properties The event's properties (e.g. promo_code)
   * @param options Amplitude event options.
   */
  registrationReferralCodeEnteredSuccessful(
    properties: RegistrationReferralCodeEnteredSuccessfulProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationReferralCodeEnteredSuccessful(properties), options);
  }

  /**
   * Registration Referral Code Entered Unsuccessful
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Referral%20Code%20Entered%20Unsuccessful)
   *
   * Referral Code was not Valid
   *
   * @param properties The event's properties (e.g. promo_code)
   * @param options Amplitude event options.
   */
  registrationReferralCodeEnteredUnsuccessful(
    properties: RegistrationReferralCodeEnteredUnsuccessfulProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationReferralCodeEnteredUnsuccessful(properties), options);
  }

  /**
   * Registration Sign Up Button Pressed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Sign%20Up%20Button%20Pressed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  registrationSignUpButtonPressed(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationSignUpButtonPressed(), options);
  }

  /**
   * Registration Sign Up Initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Sign%20Up%20Initiated)
   *
   * User taps sign up on landing screen
   *
   * @param properties The event's properties (e.g. sign_up_path_web)
   * @param options Amplitude event options.
   */
  registrationSignUpInitiated(
    properties?: RegistrationSignUpInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationSignUpInitiated(properties), options);
  }

  /**
   * Registration Unsuccessful Registration Dialog Shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Unsuccessful%20Registration%20Dialog%20Shown)
   *
   * Event indicating that an error was displayed when user submitted registration, use was not able to complete registration.
   *
   * @param properties The event's properties (e.g. error_text)
   * @param options Amplitude event options.
   */
  registrationUnsuccessfulRegistrationDialogShown(
    properties: RegistrationUnsuccessfulRegistrationDialogShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationUnsuccessfulRegistrationDialogShown(properties), options);
  }

  /**
   * Registration Username Entered Successful
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Username%20Entered%20Successful)
   *
   * User enters username and taps continue
   *
   * @param options Amplitude event options.
   */
  registrationUsernameEnteredSuccessful(
    options?: EventOptions,
  ) {
    return this.track(new RegistrationUsernameEnteredSuccessful(), options);
  }

  /**
   * Registration Username Entered Unsuccessful
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Registration%20Username%20Entered%20Unsuccessful)
   *
   * User enters username and views error message
   *
   * @param properties The event's properties (e.g. failure_reason)
   * @param options Amplitude event options.
   */
  registrationUsernameEnteredUnsuccessful(
    properties?: RegistrationUsernameEnteredUnsuccessfulProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationUsernameEnteredUnsuccessful(properties), options);
  }

  /**
   * Remove Pick From Your Picks Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Remove%20Pick%20From%20Your%20Picks%20Screen)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. is_modified_projection)
   * @param options Amplitude event options.
   */
  removePickFromYourPicksScreen(
    properties: RemovePickFromYourPicksScreenProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemovePickFromYourPicksScreen(properties), options);
  }

  /**
   * Remove Tokenized Pack
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Remove%20Tokenized%20Pack)
   *
   * This event fires when a user removes a tokenized pack intentionally via the entry form button
   *
   * @param properties The event's properties (e.g. is_tokenized)
   * @param options Amplitude event options.
   */
  removeTokenizedPack(
    properties?: RemoveTokenizedPackProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemoveTokenizedPack(properties), options);
  }

  /**
   * Remove Tokenized Pack Selection Modal Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Remove%20Tokenized%20Pack%20Selection%20Modal%20Interaction)
   *
   * Event to track user interaction with the removal of a tokenized pack selection modal.
   *
   * @param properties The event's properties (e.g. don't_show_again)
   * @param options Amplitude event options.
   */
  removeTokenizedPackSelectionModalInteraction(
    properties: RemoveTokenizedPackSelectionModalInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemoveTokenizedPackSelectionModalInteraction(properties), options);
  }

  /**
   * Rewards Hub Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Rewards%20Hub%20Opened)
   *
   * This event tracks when the user opens the rewards hub, either from lobby or entry form
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  rewardsHubOpened(
    properties?: RewardsHubOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RewardsHubOpened(properties), options);
  }

  /**
   * Rival Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Rival%20Filter%20Selected)
   *
   * This event fires when a user selects the rival filter within the pick'em lobby
   *
   * @param properties The event's properties (e.g. is_blank)
   * @param options Amplitude event options.
   */
  rivalFilterSelected(
    properties: RivalFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RivalFilterSelected(properties), options);
  }

  /**
   * Search Bar Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Search%20Bar%20Selected)
   *
   * This event fires every time a user clicks the search bar
   *
   * @param properties The event's properties (e.g. lobby_name)
   * @param options Amplitude event options.
   */
  searchBarSelected(
    properties: SearchBarSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchBarSelected(properties), options);
  }

  /**
   * session_end
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/session_end)
   *
   * Owner: Thomas Lindblom
   *
   * @param options Amplitude event options.
   */
  sessionEnd(
    options?: EventOptions,
  ) {
    return this.track(new SessionEnd(), options);
  }

  /**
   * session_start
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/session_start)
   *
   * Owner: daniel.sangobanwo@underdogfantasy.com
   *
   * @param options Amplitude event options.
   */
  sessionStart(
    options?: EventOptions,
  ) {
    return this.track(new SessionStart(), options);
  }

  /**
   * Socure ID Info Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Socure%20ID%20Info%20Screen%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  socureIdInfoScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new SocureIdInfoScreenOpened(), options);
  }

  /**
   * Socure ID Upload Flow Initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Socure%20ID%20Upload%20Flow%20Initiated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. id_upload_flow)
   * @param options Amplitude event options.
   */
  socureIdUploadFlowInitiated(
    properties?: SocureIdUploadFlowInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SocureIdUploadFlowInitiated(properties), options);
  }

  /**
   * Socure ID Upload Flow Succeeded
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Socure%20ID%20Upload%20Flow%20Succeeded)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  socureIdUploadFlowSucceeded(
    options?: EventOptions,
  ) {
    return this.track(new SocureIdUploadFlowSucceeded(), options);
  }

  /**
   * Socure Info Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Socure%20Info%20Screen%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  socureInfoScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new SocureInfoScreenOpened(), options);
  }

  /**
   * Splash Screen Log In Button Pressed
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Splash%20Screen%20Log%20In%20Button%20Pressed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  splashScreenLogInButtonPressed(
    options?: EventOptions,
  ) {
    return this.track(new SplashScreenLogInButtonPressed(), options);
  }

  /**
   * Splash Screen Shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Splash%20Screen%20Shown)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  splashScreenShown(
    options?: EventOptions,
  ) {
    return this.track(new SplashScreenShown(), options);
  }

  /**
   * Token added to Entry
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Token%20added%20to%20Entry)
   *
   * This event tracks when a token is added to an entry
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  tokenAddedToEntry(
    properties: TokenAddedToEntryProperties,
    options?: EventOptions,
  ) {
    return this.track(new TokenAddedToEntry(properties), options);
  }

  /**
   * Token Applied to Selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Token%20Applied%20to%20Selection)
   *
   * This event tracks when a selection level token is applied to a selection
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  tokenAppliedToSelection(
    properties: TokenAppliedToSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new TokenAppliedToSelection(properties), options);
  }

  /**
   * Token Opted In
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Token%20Opted%20In)
   *
   * Event to track when a user has opted in to a token
   *
   * @param properties The event's properties (e.g. token_type)
   * @param options Amplitude event options.
   */
  tokenOptedIn(
    properties?: TokenOptedInProperties,
    options?: EventOptions,
  ) {
    return this.track(new TokenOptedIn(properties), options);
  }

  /**
   * Token Removed from Entry
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Token%20Removed%20from%20Entry)
   *
   * This event tracks when a token is removed from entry
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  tokenRemovedFromEntry(
    properties: TokenRemovedFromEntryProperties,
    options?: EventOptions,
  ) {
    return this.track(new TokenRemovedFromEntry(properties), options);
  }

  /**
   * Token Removed from Selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Token%20Removed%20from%20Selection)
   *
   * This event tracks when a selection level token is removed from a selection
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  tokenRemovedFromSelection(
    properties: TokenRemovedFromSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new TokenRemovedFromSelection(properties), options);
  }

  /**
   * Typing in Search
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Typing%20in%20Search)
   *
   * This event fires when a user begins typing within search while in the pick'em lobby
   *
   * @param properties The event's properties (e.g. lobby_name)
   * @param options Amplitude event options.
   */
  typingInSearch(
    properties?: TypingInSearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new TypingInSearch(properties), options);
  }

  /**
   * Verification Screen Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Verification%20Screen%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  verificationScreenOpened(
    options?: EventOptions,
  ) {
    return this.track(new VerificationScreenOpened(), options);
  }

  /**
   * Web Landing Download App Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Web%20Landing%20Download%20App%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  webLandingDownloadAppClicked(
    options?: EventOptions,
  ) {
    return this.track(new WebLandingDownloadAppClicked(), options);
  }

  /**
   * Web Landing Page
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Web%20Landing%20Page)
   *
   * When a user goes to underdogfantasy.com
   *
   * @param options Amplitude event options.
   */
  webLandingPage(
    options?: EventOptions,
  ) {
    return this.track(new WebLandingPage(), options);
  }

  /**
   * Withdrawal Cancel Succeeded
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Withdrawal%20Cancel%20Succeeded)
   *
   * The user cancels a pending withdrawal
   *
   * @param options Amplitude event options.
   */
  withdrawalCancelSucceeded(
    options?: EventOptions,
  ) {
    return this.track(new WithdrawalCancelSucceeded(), options);
  }

  /**
   * Withdrawal Method Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Withdrawal%20Method%20Selected)
   *
   * The user selects a withdrawal method
   *
   * @param properties The event's properties (e.g. payment_method)
   * @param options Amplitude event options.
   */
  withdrawalMethodSelected(
    properties: WithdrawalMethodSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WithdrawalMethodSelected(properties), options);
  }

  /**
   * Withdrawal Screen Exited
   *
   * [View in Tracking Plan](https://data.amplitude.com/underdog-fantasy/Underdog%20Fantasy/events/main/latest/Withdrawal%20Screen%20Exited)
   *
   * The user leaves the withdrawal landing page
   *
   * @param options Amplitude event options.
   */
  withdrawalScreenExited(
    options?: EventOptions,
  ) {
    return this.track(new WithdrawalScreenExited(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
