/* eslint-disable camelcase */
import { UPDATE_DRAFT_POOL_COUNT, updateDraftPoolCount } from '@/store/modules/lobbies/actions';

import { DraftPoolResponse, DraftPools } from '@/interfaces/draft-pools';
import { EntryCountUpdate } from '@/interfaces/lobbies';

import { SET_DRAFT_POOL, setDraftPool } from '../actions';
import { draftPoolAdapter } from '../adapters/draft-pool';

type State = DraftPools | null;

type DraftPoolActions = ReturnType<typeof setDraftPool | typeof updateDraftPoolCount>;

const initialState: DraftPools = {};

const set = (state: DraftPools, { data }: { data: DraftPoolResponse }): DraftPools => {
  const draftPool = draftPoolAdapter(data.draft_pool);
  return {
    ...state,
    [draftPool.id]: draftPool,
  };
};

const updateCount = (
  state: DraftPools,
  { draftPoolId, entryCount }: EntryCountUpdate
): DraftPools => {
  if (!state?.[draftPoolId]) {
    return state;
  }
  return {
    ...state,
    [draftPoolId]: {
      ...state[draftPoolId],
      entryCount,
    },
  };
};

export const draftPoolsReducer = (
  state: State = initialState,
  action: DraftPoolActions = {} as DraftPoolActions
): State => {
  switch (action.type) {
    case SET_DRAFT_POOL:
      return set(state, action.payload);
    case UPDATE_DRAFT_POOL_COUNT:
      return updateCount(state, action.payload);
    default:
      return state;
  }
};
