/* eslint-disable camelcase */
import { ActiveDraft, ActiveDraftResponse } from '@/interfaces/active-drafts';
import { Source } from '@/interfaces/drafts';
import { toCamel } from '@/utilities/helpers';

const activeDraftAdapter = (activeDraftResponse: ActiveDraftResponse): ActiveDraft => {
  const {
    id,
    auto_pick_at,
    clock,
    contest_style_id,
    draft_at,
    draft_type,
    draft_entry_id,
    entry_count,
    entry_role,
    entry_style_id,
    pick_count,
    slate_id,
    source: sourceResponse,
    source_entry_style_id,
    source_id,
    status,
    title,
    user_auto_pick,
    user_pick_order,
  } = activeDraftResponse;

  return {
    id,
    autoPickAt: auto_pick_at,
    clock,
    contestStyleId: contest_style_id,
    draftAt: draft_at,
    draftType: draft_type,
    draftEntryId: draft_entry_id,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    pickCount: pick_count,
    slateId: slate_id,
    source: toCamel(sourceResponse) as Source,
    sourceEntryStyleId: source_entry_style_id,
    sourceId: source_id,
    status,
    title,
    userAutoPick: user_auto_pick,
    userPickOrder: user_pick_order,
  };
};

export default activeDraftAdapter;
