import { MatchesResponse, WeeklyMatches } from '@/interfaces/matches';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import matchAdapter from '../../matches/adapters/match';
import {
  GET_WEEKLY_MATCHES,
  getWeeklyMatches,
  SET_WEEKLY_MATCHES,
  setWeeklyMatches,
} from '../actions';

type State = WeeklyMatches;

type WeeklyMatchesAction = ReturnType<typeof setWeeklyMatches | typeof getWeeklyMatches>;

const initialMatches: WeeklyMatches = { isLoading: false };

const set = (state: WeeklyMatches, { data, weekId }: { data: MatchesResponse; weekId: number }) => {
  const adaptedMatchesArr = data.matches && data.matches.map(matchAdapter);
  const matches = adaptedMatchesArr && arrayToObjectIdKeys(adaptedMatchesArr);

  return {
    ...state,
    isLoading: false,
    [weekId]: matches,
  };
};

export const weeklyMatchesReducer = (
  state: State = initialMatches,
  action: WeeklyMatchesAction = {} as WeeklyMatchesAction
): State => {
  switch (action.type) {
    case GET_WEEKLY_MATCHES: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SET_WEEKLY_MATCHES:
      return set(state, action.payload);
    default:
      return state;
  }
};
