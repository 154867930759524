import { AppErrorRedux } from '@/interfaces/error';

import { CLEAR_ERROR, clearError, SET_ERROR, setError } from '../actions';

type State = AppErrorRedux;

type ErrorActions = ReturnType<typeof setError | typeof clearError>;

const initialErrorState: AppErrorRedux = {};

const resetErrorState: AppErrorRedux = {};

const set = ({ type, title, message, status, apiCode }: AppErrorRedux) => ({
  type,
  title,
  message,
  status,
  apiCode,
});

export const errorReducer = (
  state: State = initialErrorState,
  action: ErrorActions = {} as ErrorActions
): State => {
  switch (action.type) {
    case SET_ERROR:
      return set(action.payload);
    case CLEAR_ERROR:
      return resetErrorState;
    default:
      return state;
  }
};
