import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Icon from '@/components/atoms/icon';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import NewsModal from '@/components/molecules/news-modal';
import { LatestNewsItem } from '@/interfaces/appearance-stats';

import styles from './styles.scss';

dayjs.extend(relativeTime);

export interface NewsTileProps {
  newsItem: LatestNewsItem;
}

const NewsTile = (props: NewsTileProps) => {
  const { newsItem } = props;
  const openModal = useModal();

  const onNewsClick = () => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <NewsModal newsItem={newsItem} handleCloseModal={handleCloseModal} />
    ));
  };

  return (
    <button className={styles.newsTile} onClick={onNewsClick}>
      <p className={styles.title}>{newsItem.title}</p>
      <div className={styles.label}>
        <Icon
          // eslint-disable-next-line max-len
          name={
            dayjs(newsItem.updatedAt).isAfter(dayjs().subtract(1, 'day')) ? 'newsRecent' : 'news'
          }
          className={styles.newsIcon}
        />
        <p className={styles.fromNow}>{dayjs(newsItem.updatedAt).fromNow()}</p>
      </div>
    </button>
  );
};

export default NewsTile;
