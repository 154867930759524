/* eslint-disable camelcase */
import { LiveSlate, LiveSlateResponse } from '@/interfaces/live';

const liveSlatesAdapter = (slates: LiveSlateResponse[]): LiveSlate[] =>
  slates.map((slate) => {
    const {
      id,
      contest_style_ids,
      description,
      draft_count,
      draft_pool_draft_count,
      game_count,
      sport_id,
      start_at,
      title,
      tournament_draft_count,
      weekly_winner_draft_count,
    } = slate;

    return {
      id,
      contestStyleIds: contest_style_ids,
      description,
      draftCount: draft_count,
      draftPoolDraftCount: draft_pool_draft_count,
      gameCount: game_count,
      sportId: sport_id,
      startAt: start_at,
      title,
      tournamentDraftCount: tournament_draft_count,
      weeklyWinnerDraftCount: weekly_winner_draft_count,
    };
  });

export default liveSlatesAdapter;
