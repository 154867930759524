import { AxiosResponse } from 'axios';

import request from '@request';
import {
  AppearancesResponse,
  PlayersResponse,
  SlateInfoResponse,
} from '@/interfaces/constructed-interfaces/constructed-slates';
import { SlatesResponse } from '@/interfaces/slates';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getSlatesForSport = async ({
  sportId,
}: {
  sportId: string;
}): Promise<AxiosResponse<SlatesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/sports/${sportId}/slates`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getSlateById = async ({
  slateId,
}: {
  slateId: string;
}): Promise<AxiosResponse<SlateInfoResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/slates/${slateId}`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get players for a slate
 */
export const getSlatePlayers = async ({
  slateId,
}: {
  slateId: string;
}): Promise<AxiosResponse<PlayersResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/slates/${slateId}/players`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get information for a slate
 *
 * Things like description, title, start time
 */
export const getSlateInfo = async ({
  slateId,
}: {
  slateId: string;
}): Promise<AxiosResponse<SlateInfoResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/slates/${slateId}`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get appearances for a slate and scoringType
 *
 * An appearance is the combination of a player and a slate, it includes info like
 * match type, match id, player id, position id, team id
 */

export const getSlateAppearances = async ({
  slateId,
  scoringTypeId,
}: {
  slateId: string;
  scoringTypeId: string;
}): Promise<AxiosResponse<AppearancesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/slates/${slateId}/scoring_types/${scoringTypeId}/appearances`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
