/* eslint-disable camelcase */
import { WeeklyWinnerWeeks, WeeklyWinnerWeeksResponse } from '@/interfaces/weekly-winners';

import { SET_WEEKLY_WINNER_WEEKS, setWeeklyWinnerWeeks } from '../actions';
import { weeklyWinnerWeeksAdapter } from '../adapters/weekly-winner-weeks';

type State = WeeklyWinnerWeeks;

type WeeklyWinnerWeeksActions = ReturnType<typeof setWeeklyWinnerWeeks>;

const initialState: WeeklyWinnerWeeks = null;

const set = (
  state: WeeklyWinnerWeeks,
  { data, weeklyWinnerId }: { data: WeeklyWinnerWeeksResponse; weeklyWinnerId: string }
): WeeklyWinnerWeeks => {
  const weeks = data.weekly_winner_weeks && data.weekly_winner_weeks.map(weeklyWinnerWeeksAdapter);

  return {
    ...state,
    [weeklyWinnerId]: {
      weeks,
      meta: data.meta,
    },
  };
};

export const weeklyWinnerWeeksReducer = (
  state: State = initialState,
  action: WeeklyWinnerWeeksActions = {} as WeeklyWinnerWeeksActions
): State => {
  switch (action.type) {
    case SET_WEEKLY_WINNER_WEEKS:
      return set(state, action.payload);
    default:
      return state;
  }
};
