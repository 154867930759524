import { AxiosResponse } from 'axios';

import request from '@request';
import { ReferralPromotionResponse, UserPromotionsResponse } from '@/interfaces/promotions';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getUserPromotions = async (): Promise<AxiosResponse<UserPromotionsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/user/promotions',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUserReferralPromotion = async ({
  username,
}: {
  username: string;
}): Promise<AxiosResponse<ReferralPromotionResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/promotions/user_referral',
      params: {
        username,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getPromoCodePromotion = async ({
  code,
}: {
  code: string;
}): Promise<AxiosResponse<ReferralPromotionResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/promotions/promo_code',
      params: {
        code,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
