import { CompletedSlatesResponse } from '@/interfaces/completed-slates';

export const GET_COMPLETED_SLATES = 'user/GET_COMPLETED_SLATES' as const;
export const SET_COMPLETED_SLATES = 'user/SET_COMPLETED_SLATES' as const;

export const getCompletedSlates = () => ({ type: GET_COMPLETED_SLATES });

export const setCompletedSlates = (payload: CompletedSlatesResponse) => ({
  type: SET_COMPLETED_SLATES,
  payload,
});
