import React from 'react';

import Nav from '@/components/molecules/nav';
import Footer from '@/components/organisms/footer';
import { Outlet } from '@/services/router/service';
import { useLastMatchWithHandle } from '@/services/router/use-last-match-with-handle';

import styles from './styles.scss';

interface FullPageLayoutProps {
  hasNav?: boolean;
  hasFooter?: boolean;
  layout: 'wide' | 'narrow';
}

// This component is exported for testing / storybook purposes
export const FullPageLayoutInternal = ({ hasFooter, hasNav, layout }: FullPageLayoutProps) => {
  return (
    <>
      {hasNav ? <Nav /> : null}
      <div className={styles.fullPage}>
        <div className={styles[layout]}>
          <Outlet />
        </div>
      </div>
      {hasFooter ? <Footer /> : null}
    </>
  );
};

// This component is exported as default and intended to be used when declaring routes for the router
const FullPageLayout = () => {
  const { layout = 'wide', hasFooter, hasNav } = useFullPageLayoutProps() ?? {};

  return <FullPageLayoutInternal hasFooter={hasFooter} hasNav={hasNav} layout={layout} />;
};

export default FullPageLayout;

export interface FullPageLayoutHandle {
  layoutProps: FullPageLayoutProps;
}

function useFullPageLayoutProps() {
  const match = useLastMatchWithHandle<FullPageLayoutHandle>((handle) =>
    Boolean(handle.layoutProps)
  );

  return match?.handle.layoutProps;
}
