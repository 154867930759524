/* eslint-disable camelcase */
import {
  DraftPoolEntriesResponse,
  DraftPoolEntry,
  DraftPoolEntryScoreUpdateResponse,
  DraftPoolsEntries,
} from '@/interfaces/draft-pools';

import {
  REPLACE_DRAFT_ENTRY,
  replaceDraftEntryForDraftPool,
  SET_USER_ENTRIES_FOR_DRAFT_POOL,
  setUserEntriesForDraftPool,
  UPDATE_ENTRIES_FOR_DRAFT,
  updateEntriesForDraft,
} from '../actions';
import { draftPoolEntryAdapter } from '../adapters/draft-pool-entry';

type State = DraftPoolsEntries | null;

type EntriesForDraftPoolsActions = ReturnType<
  | typeof setUserEntriesForDraftPool
  | typeof updateEntriesForDraft
  | typeof replaceDraftEntryForDraftPool
>;

const initialState: DraftPoolsEntries = {};

const set = (
  state: DraftPoolsEntries,
  { data, draftPoolId }: { data: DraftPoolEntriesResponse; draftPoolId: string }
): DraftPoolsEntries => {
  const newEntriesForDraftPool =
    data.draft_pool_entries && data.draft_pool_entries.map(draftPoolEntryAdapter);

  // combine with existing and remove duplicates
  const entries = state[draftPoolId]?.entries
    ? [...state[draftPoolId].entries, ...newEntriesForDraftPool].filter(
        (entry, ind, arr) => arr.findIndex((e) => e.id === entry.id) === ind
      )
    : newEntriesForDraftPool;

  return {
    ...state,
    [draftPoolId]: {
      entries,
      meta: data.meta,
    },
  };
};

const update = (
  state: DraftPoolsEntries,
  {
    data,
    draftPoolId,
    draftId,
  }: { data: DraftPoolEntryScoreUpdateResponse[]; draftPoolId: string; draftId: string }
): DraftPoolsEntries => {
  if (!state[draftPoolId]) return state;
  const updatedEntries = state[draftPoolId].entries.map((entry) => {
    if (entry.draftId !== draftId) return entry;
    const updatedEntry = data.find((e) => e.id === entry.id);
    return updatedEntry
      ? {
          ...entry,
          points: updatedEntry.points,
          place: updatedEntry.place,
          payoutText: updatedEntry.payout_text,
          payout: updatedEntry.payout,
        }
      : entry;
  });

  return {
    ...state,
    [draftPoolId]: {
      ...state[draftPoolId],
      entries: updatedEntries,
    },
  };
};

const replaceEntry = (
  state: DraftPoolsEntries,
  {
    data,
    draftPoolId,
    draftId,
  }: {
    data: DraftPoolEntry;
    draftPoolId: string;
    draftId: string;
  }
) => {
  if (!state[draftPoolId]) return state;

  const updatedEntries = state[draftPoolId].entries.map((entry) => {
    if (entry.draftId !== draftId) return entry;
    return data;
  });

  return {
    ...state,
    [draftPoolId]: {
      ...state[draftPoolId],
      entries: updatedEntries,
    },
  };
};

export const userEntriesForDraftPoolsReducer = (
  state: State = initialState,
  action: EntriesForDraftPoolsActions = {} as EntriesForDraftPoolsActions
): State => {
  switch (action.type) {
    case SET_USER_ENTRIES_FOR_DRAFT_POOL:
      return set(state, action.payload);
    case UPDATE_ENTRIES_FOR_DRAFT:
      return update(state, action.payload);
    case REPLACE_DRAFT_ENTRY:
      return replaceEntry(state, action.payload);
    default:
      return state;
  }
};
