import { SitAndGos, SitAndGosResponse } from '@/interfaces/lobbies';

import { SET_ALL_SIT_AND_GOS, setAllSitAndGos } from '../actions';
import sitAndGosAdapter from '../adapters/sit-and-gos';

type State = SitAndGos;

type SitAndGosActions = ReturnType<typeof setAllSitAndGos>;

const initialSitAndGos: SitAndGos = {};

// TODO [FAN-2426]: write quite a few tests for this if I end up using it in production
const set = ({ data }: { data: SitAndGosResponse }): any => sitAndGosAdapter(data.sit_and_gos);

export const sitAndGosReducer = (
  state: State = initialSitAndGos,
  action: SitAndGosActions = {} as SitAndGosActions
): State => {
  switch (action.type) {
    case SET_ALL_SIT_AND_GOS:
      return set(action.payload);
    default:
      return state;
  }
};
