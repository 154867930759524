/* eslint-disable camelcase */
import { DraftWeeklyScoresForDraft, DraftWeeklyScoresResponse } from '@/interfaces/drafts';

import { SET_DRAFT_WEEKLY_SCORES, setDraftWeeklyScores } from '../actions';
import { weeklyScoreAdapter } from '../adapters/weekly-score';

type State = DraftWeeklyScoresForDraft | null;

type DraftWeeklyScoresActions = ReturnType<typeof setDraftWeeklyScores>;

const initialState: DraftWeeklyScoresForDraft = {};

const set = (
  state: DraftWeeklyScoresForDraft,
  { data, draftId }: { data: DraftWeeklyScoresResponse; draftId: string }
): DraftWeeklyScoresForDraft => {
  const weeklyScores = weeklyScoreAdapter(data.draft_weekly_scores);

  return {
    ...state,
    [draftId]: weeklyScores,
  };
};

export const draftWeeklyScoresReducer = (
  state: State = initialState,
  action: DraftWeeklyScoresActions = {} as DraftWeeklyScoresActions
): State => {
  switch (action.type) {
    case SET_DRAFT_WEEKLY_SCORES:
      return set(state, action.payload);
    default:
      return state;
  }
};
