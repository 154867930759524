import { AxiosResponse } from 'axios';

import request from '@request';
import { TransactionsResponse } from '@/interfaces/transactions';
import { apiErrorTracking, AppError } from '@/utilities/errors';

// if there is a page, then send it
export const getTransactions = async ({
  page,
}: {
  page?: number;
}): Promise<AxiosResponse<TransactionsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/user/transactions',
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
