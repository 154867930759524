/* eslint-disable camelcase */
import { RENAME_DRAFT_ENTRY, renameDraftEntry } from '@/store/modules/drafts/actions';

import { RenameDraftEntry } from '@/interfaces/drafts';
import {
  DraftsForTournamentRoundResponse,
  DraftsForTournamentRounds,
} from '@/interfaces/tournament-rounds';

import { SET_USER_DRAFTS_FOR_TOURNAMENT_ROUNDS, setUserDraftsForTournamentRound } from '../actions';
import { draftForTournamentRoundAdapter } from '../adapters/drafts-for-tournament-rounds';

type State = DraftsForTournamentRounds | null;

type DraftForTournamentRoundsActions = ReturnType<
  typeof setUserDraftsForTournamentRound | typeof renameDraftEntry
>;

const initialState: DraftsForTournamentRounds = {};

const set = (
  state: DraftsForTournamentRounds,
  { data, tournamentRoundId }: { data: DraftsForTournamentRoundResponse; tournamentRoundId: string }
): DraftsForTournamentRounds => {
  const newDraftsForTournamentRound =
    data.drafts && data.drafts.map(draftForTournamentRoundAdapter);

  const drafts = state[tournamentRoundId]?.drafts
    ? [...state[tournamentRoundId].drafts, ...newDraftsForTournamentRound].filter(
        (draft, ind, arr) => arr.findIndex((d) => d.id === draft.id) === ind
      )
    : newDraftsForTournamentRound;

  return {
    ...state,
    [tournamentRoundId]: {
      drafts,
      meta: data.meta,
    },
  };
};

const rename = (state: DraftsForTournamentRounds, data: RenameDraftEntry) => {
  const tournament = state[data.tournamentRoundId];
  if (!tournament) return state;

  const drafts = tournament.drafts.map((draft) => {
    if (draft.id === data.id) {
      return {
        ...draft,
        title: data.draftEntryTitle,
      };
    }
    return draft;
  });

  return {
    ...state,
    [data.tournamentRoundId]: {
      drafts: [...drafts],
      meta: { ...tournament.meta },
    },
  };
};

export const draftsForTournamentRoundReducer = (
  state: State = initialState,
  action: DraftForTournamentRoundsActions = {} as DraftForTournamentRoundsActions
): State => {
  switch (action.type) {
    case SET_USER_DRAFTS_FOR_TOURNAMENT_ROUNDS:
      return set(state, action.payload);
    case RENAME_DRAFT_ENTRY:
      return rename(state, action.payload);
    default:
      return state;
  }
};
