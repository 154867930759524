/* eslint-disable camelcase */
import { Rival, RivalResponse } from '@/interfaces/pick-em';

const rivalAdapter = (rival: RivalResponse): Rival => {
  const { id, scoring_type_id, title } = rival;

  return {
    id,
    scoringTypeId: scoring_type_id,
    title,
  };
};

export default rivalAdapter;
