/* eslint-disable camelcase */
import { OverUnderOption, OverUnderOptionResponse } from '@/interfaces/pick-em';

const overUnderOptionAdapter = (overUnderOption: OverUnderOptionResponse): OverUnderOption => {
  const { id, choice, choice_display, over_under_line_id, payout_multiplier, type } =
    overUnderOption;

  return {
    id,
    choice,
    choiceDisplay: choice_display,
    overUnderLineId: over_under_line_id,
    payoutMultiplier: payout_multiplier ? parseFloat(payout_multiplier) : 1,
    type,
  };
};

export default overUnderOptionAdapter;
