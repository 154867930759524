import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';
import { getTransactions, setTransactions } from '@/store/modules/transactions/actions';

import * as api from '@/api/transactions';

export const getTransactionsSaga = function* getTransactionsSaga(
  action: ReturnType<typeof getTransactions>
): SagaIterator {
  try {
    const response = yield call(api.getTransactions, action.payload);

    yield put(setTransactions(response.data));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
