import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';

import ErrorFallback from '@/components/atoms/error-fallback';
import Loader from '@/components/atoms/loader';
import Modal from '@/components/atoms/modal';
import { PusherEventProvider } from '@/components/contexts/pusher-events';

import Toast from './components/atoms/toast';
import { AuthProvider } from './components/contexts/auth';
import { BranchProvider } from './components/contexts/branch';
import { NavigationProvider } from './components/contexts/navigation';
import { UIContextProvider } from './components/contexts/ui';
import { Outlet } from './services/router/service';
import errorLogger from './utilities/errors/logger';
import AppServices from './app.services';
import configureStore from './store';

import './styles/global.scss';

const App = (): JSX.Element => {
  const store = configureStore();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error: Error, info: { componentStack: string }) => {
        errorLogger(true, error, {
          componentStack: info.componentStack,
          source: 'error-boundary',
        });
      }}
    >
      <BranchProvider>
        <Provider store={store}>
          <PusherEventProvider>
            <UIContextProvider>
              <AuthProvider>
                <NavigationProvider>
                  <AppServices>
                    <Suspense fallback={<Loader />}>
                      <Outlet />
                    </Suspense>
                    <Modal />
                    <Toast />
                  </AppServices>
                </NavigationProvider>
              </AuthProvider>
            </UIContextProvider>
          </PusherEventProvider>
        </Provider>
      </BranchProvider>
    </ErrorBoundary>
  );
};

export default App;
