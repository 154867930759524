import { CompletedDraftsResponse } from '@/interfaces/completed-drafts';

export const GET_COMPLETED_DRAFTS = 'user/GET_COMPLETED_DRAFTS' as const;
export const SET_COMPLETED_DRAFTS = 'user/SET_COMPLETED_DRAFTS' as const;

export const ERROR_COMPLETED_DRAFTS = 'user/ERROR_COMPLETED_DRAFTS' as const;

export const getCompletedDrafts = (payload: { slateId: string; page?: number }) => ({
  type: GET_COMPLETED_DRAFTS,
  payload,
});

export const setCompletedDrafts = (payload: CompletedDraftsResponse) => ({
  type: SET_COMPLETED_DRAFTS,
  payload,
});

// this is in case I want to use an error to handle a bad slateId
export const setCompletedDraftsError = (payload: CompletedDraftsResponse) => ({
  type: ERROR_COMPLETED_DRAFTS,
  payload,
});
