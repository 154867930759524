/* eslint-disable camelcase */
import { Pick, PickResponse } from '@/interfaces/drafts';

export const pickAdapter = (pick: PickResponse): Pick => {
  const {
    id,
    appearance_id,
    created_at,
    draft_entry_id,
    number,
    pick_slot_id,
    points,
    projection_adp,
    projection_average,
    projection_points,
    swapped,
  } = pick;

  return {
    id,
    appearanceId: appearance_id,
    createdAt: created_at,
    draftEntryId: draft_entry_id,
    number,
    pickSlotId: pick_slot_id,
    points,
    projectionAdp: projection_adp,
    projectionAverage: projection_average,
    projectionPoints: projection_points,
    swapped,
  };
};
