import { MatchUpdateResponse } from '@/interfaces/live';
import { DateMatches, Match, MatchesResponse } from '@/interfaces/matches';
import { SportId } from '@/interfaces/types';

import {
  SET_MATCHES_FOR_DATE,
  setMatchesForDate,
  UPDATE_MATCH_FOR_DATE,
  updateMatchForDate,
} from '../actions';
import matchAdapter from '../adapters/match';

type State = DateMatches;

type DateMatchesAction = ReturnType<typeof setMatchesForDate | typeof updateMatchForDate>;

const initialDateMatches: DateMatches = {};

const set = (
  state: DateMatches,
  { data, date, sportId }: { data: MatchesResponse; date: string; sportId: SportId }
) => {
  const adaptedMatchesArr = data.matches && data.matches.map(matchAdapter);
  const sportKey = sportId.toUpperCase();

  return {
    ...state,
    [sportKey]: {
      matches: adaptedMatchesArr,
      date,
      lastUpdatedAt: new Date(),
    },
  };
};

const update = (state: DateMatches, { data }: { data: MatchUpdateResponse }) => {
  const sportKey = data.game.sport_id.toUpperCase();
  const matchesForSport = state[sportKey].matches.map((match: Match) =>
    match.id === data.game.id ? matchAdapter(data.game) : match
  );
  return {
    ...state,
    [sportKey]: {
      ...state[sportKey],
      lastUpdatedAt: new Date(),
      matches: matchesForSport,
    },
  };
};

export const dateMatchesReducer = (
  state: State = initialDateMatches,
  action: DateMatchesAction = {} as DateMatchesAction
): State => {
  switch (action.type) {
    case SET_MATCHES_FOR_DATE:
      return set(state, action.payload);
    case UPDATE_MATCH_FOR_DATE:
      return update(state, action.payload);
    default:
      return state;
  }
};
