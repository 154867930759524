import { SettledSlatesForSport, SettledSlatesResponse } from '@/interfaces/results';

import { SET_SETTLED_SLATES, setSettledSlates } from '../actions';
import settledSlatesAdapter from '../adapters/settled-slates';

type State = SettledSlatesForSport | null;

type SettledSlatesActions = ReturnType<typeof setSettledSlates>;

const initialState: SettledSlatesForSport = {};

const set = (
  state: SettledSlatesForSport,
  { data, sportId }: { data: SettledSlatesResponse; sportId: string }
): SettledSlatesForSport => {
  const newSlates = settledSlatesAdapter(data.slates);

  const slates = state?.[sportId]?.slates
    ? [...state[sportId].slates, ...newSlates].filter(
        (slate, ind, arr) => arr.findIndex((s) => s.id === slate.id) === ind
      )
    : newSlates;

  return {
    ...state,
    [sportId]: {
      slates,
      meta: data.meta,
    },
  };
};

export const settledSlatesReducer = (
  state: State = initialState,
  action: SettledSlatesActions = {} as SettledSlatesActions
): State => {
  switch (action.type) {
    case SET_SETTLED_SLATES:
      return set(state, action.payload);
    default:
      return state;
  }
};
