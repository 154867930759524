import { EntryCollection } from 'contentful';

import { ITournamentRulesFields } from '@/interfaces/contentful';
import { AllTournamentsRules, TournamentRules } from '@/interfaces/tournament-rules';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import {
  SET_ACTIVE_TOURNAMENTS_RULES,
  SET_TOURNAMENT_RULES,
  setActiveTournamentsRules,
  setTournamentRules,
} from '../actions';
import tournamentRulesAdapter from '../adapters/tournament-rules';

type State = AllTournamentsRules;

type TournamentRulesActions = ReturnType<
  typeof setActiveTournamentsRules | typeof setTournamentRules
>;

const initialState: AllTournamentsRules = {
  navItems: null,
  tournamentRules: {},
};

const setActive = (
  state: State,
  {
    tournamentsRules,
  }: {
    tournamentsRules: EntryCollection<ITournamentRulesFields>;
  }
): AllTournamentsRules => {
  const activeRules = tournamentsRules.items.map(tournamentRulesAdapter);

  const navItems = activeRules
    .map((activeRule) => ({
      label: activeRule.navLinkText,
      slug: activeRule.slug,
      navRank: activeRule.navRank,
    }))
    .sort((a, b) => b.navRank - a.navRank);

  const activeRulesObject = arrayToObjectIdKeys(activeRules, 'slug'); // object with the slug as the key

  return {
    navItems: navItems || [],
    tournamentRules: {
      ...state.tournamentRules,
      ...activeRulesObject,
    },
  };
};

const setTournament = (
  state: State,
  {
    tournamentRules,
    slug,
  }: {
    tournamentRules: EntryCollection<ITournamentRulesFields>;
    slug: string;
  }
): AllTournamentsRules => {
  const rules = tournamentRules.items.map(tournamentRulesAdapter);

  const activeRulesObject = arrayToObjectIdKeys(rules, 'slug'); // object with the slug as the key

  return {
    ...state,
    tournamentRules: {
      ...state.tournamentRules,
      [slug]: {} as TournamentRules, // this will always set something,
      ...activeRulesObject,
    },
  };
};

export const tournamentsRulesReducer = (
  state: State = initialState,
  action: TournamentRulesActions = {} as TournamentRulesActions
): State => {
  switch (action.type) {
    case SET_ACTIVE_TOURNAMENTS_RULES:
      return setActive(state, action.payload);
    case SET_TOURNAMENT_RULES:
      return setTournament(state, action.payload);
    default:
      return state;
  }
};
