/* eslint-disable camelcase */
import { ApiErrorDataResponse, InvalidOptionsResponse } from '@/interfaces/error';

const outcomeErrorAdapter = (err: ApiErrorDataResponse) => {
  const { api_code, cta_text, detail, entry_error_code, http_status_code, invalid_options, title } =
    err;

  return {
    apiCode: api_code,
    ctaText: cta_text,
    message: detail,
    entryErrorCode: entry_error_code,
    httpStatusCode: http_status_code,
    invalidOptions: invalid_options.map((option: InvalidOptionsResponse) => ({
      id: option.id,
      lineId: option.line_id,
      propId: option.prop_id,
    })),
    title,
  };
};

export default outcomeErrorAdapter;
