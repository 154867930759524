import errorLogger from '@/utilities/errors/logger';

interface Options {
  gtm?: {
    eventName: string;
    eventObject?: {
      [key: string]: string | number | boolean;
    };
  };
}

const eventLogger = (options: Options) => {
  if (!options?.gtm?.eventName) {
    errorLogger(false, 'You need an event to fire');
  }
  if (window?.dataLayer && options?.gtm?.eventName) {
    window.dataLayer.push({
      event: options.gtm.eventName,
      ...(options.gtm?.eventObject && options.gtm.eventObject),
    });
  }
};

export default eventLogger;
