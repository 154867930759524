/* eslint-disable camelcase */
import { ExposureDraftPool, ExposureDraftPoolResponse } from '@/interfaces/exposure';

export const exposureDraftPoolAdapter = (
  draftPool: ExposureDraftPoolResponse
): ExposureDraftPool => {
  const {
    id,
    contest_style_id,
    description,
    entry_count,
    entry_role,
    entry_style_id,
    image_url,
    max_entries,
    rules_url,
    slate_id,
    status,
    title,
  } = draftPool;

  return {
    id,
    contestStyleId: contest_style_id,
    description,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    imageUrl: image_url,
    maxEntries: max_entries,
    rulesUrl: rules_url,
    slateId: slate_id,
    status,
    title,
  };
};
