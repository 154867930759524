import { EntryCollection } from 'contentful';

import client from '@/api-contentful/contentful-request';
import { IStateConfigsFields } from '@/interfaces/contentful';

export const getContentfulConfiguration = async ({
  title,
}: {
  title: string;
}): Promise<EntryCollection<IStateConfigsFields>> => {
  const response = await client.getEntries<IStateConfigsFields>({
    content_type: 'stateConfigs',
    'fields.title': title,
  });

  return response;
};
