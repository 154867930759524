import React from 'react';
import clsx from 'clsx';

import styles from './styles.scss';

export interface ProgressBarProps {
  classNames?: {
    wrapper?: string;
    emptyBar?: string;
    fullBar?: string;
  };
  currentCount: number;
  totalCount: number;
  fullBarColor?: string;
  title?: string;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { classNames = {}, currentCount, totalCount, fullBarColor, title } = props;

  const percentFilled = totalCount === 0 ? 0 : Math.min((currentCount / totalCount) * 100, 100);

  const fullBarStyle = fullBarColor ? { backgroundColor: fullBarColor } : {};

  return (
    <div className={clsx(styles.progressBar, classNames.wrapper)} title={title}>
      <div className={clsx(styles.bar, styles.emptyBar, classNames.emptyBar)}>
        <div
          style={{ width: `${percentFilled}%`, ...fullBarStyle }}
          className={clsx(styles.bar, styles.fullBar, classNames.fullBar)}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
