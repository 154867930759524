import { TournamentResponse } from '@/interfaces/tournaments';

export const GET_TOURNAMENT = 'drafts/GET_TOURNAMENT' as const; // main saga
export const SET_TOURNAMENT = 'drafts/SET_TOURNAMENT' as const;

export const getTournament = (payload: { tournamentId?: string; draftId?: string }) => ({
  type: GET_TOURNAMENT,
  payload,
});

export const setTournament = (payload: TournamentResponse) => ({ type: SET_TOURNAMENT, payload });
