import React from 'react';
import clsx from 'clsx';

import styles from './styles.scss';

export interface SupportLinkProps {
  className?: string;
  label: string | JSX.Element;
}

const SupportLink = (props: SupportLinkProps) => {
  const { className, label } = props;

  const onClick = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };

  return (
    <button className={clsx(styles.button, className)} onClick={onClick}>
      {label}
    </button>
  );
};

export default SupportLink;
