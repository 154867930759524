import { AxiosResponse } from 'axios';

import request from '@request';
import { AppearanceStatsResponse } from '@/interfaces/appearance-stats';
import { apiErrorTracking, AppError } from '@/utilities/errors';

/*
 * Get stats for an appearance
 */

export const getAppearanceStats = async ({
  scoringTypeId,
  appearanceId,
  isWkAvg,
}: {
  scoringTypeId: string;
  appearanceId: string;
  isWkAvg?: boolean;
}): Promise<AxiosResponse<AppearanceStatsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/scoring_types/${scoringTypeId}/appearances/${appearanceId}`,
      ...(isWkAvg && { params: { contest_type: 'weekly_winner' } }),
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get stats for an appearance
 */

export const getAppearanceStatsById = async ({
  appearanceId,
}: {
  appearanceId: string;
}): Promise<AxiosResponse<AppearanceStatsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/appearances/${appearanceId}`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get stats for a weekly appearance
 */

export const getWeeklyAppearanceStats = async ({
  weekId,
  contestStyleId,
  appearanceId,
}: {
  weekId: number;
  contestStyleId: string;
  appearanceId: string;
}): Promise<AxiosResponse<AppearanceStatsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/weeks/${weekId}/contest_styles/${contestStyleId}/appearances/${appearanceId}`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
