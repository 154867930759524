import { useSelector } from 'react-redux';

import { RootState } from '@/store';

export const useInventoryButtonData = () => {
  const draftingConfig = useSelector((state: RootState) => state.draftingConfig);
  const hasLobbyDataLoaded = useSelector(
    (state: RootState) => state.pickEmOverUnder.hasLobbyDataLoaded
  );
  const payoutOutcome = useSelector((state: RootState) => state.pickEmEntries.payoutOutcome);
  const pickEmEntries = useSelector((state: RootState) => state.pickEmEntries);

  return {
    draftingConfig,
    hasLobbyDataLoaded,
    payoutOutcome,
    pickEmEntries,
  };
};
