/* eslint-disable camelcase */
import appearanceAdapter from '@/store/modules/constructed-slates/adapters/appearance';

import { WeeklyAppearancesResponse } from '@/interfaces/weekly-appearances';

export const weeklyAppearanceAdapter = (weeklyAppearances: WeeklyAppearancesResponse) => {
  const { appearances } = weeklyAppearances;

  return appearances.map((appearance) => appearanceAdapter(appearance));
};
