import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';

import Icon from '@/components/atoms/icon';

import styles from './styles.scss';

interface CommonDialogModalProps {
  closeIconAction?: () => void;
  confirmText?: string;
  content: JSX.Element;
  destructiveConfirm?: boolean;
  dismissAction?: () => void;
  dismissText?: string;
  handleCloseModal: () => void;
  hideCloseIcon?: boolean;
  neutralConfirm?: boolean;
  title: ReactNode;
}

type ConditionalDialogModalProps =
  | {
      confirmAction?: () => void;
      asyncConfirmAction?: never;
    }
  | {
      confirmAction?: never;
      asyncConfirmAction?: () => Promise<any>;
    };

export type DialogModalProps = CommonDialogModalProps & ConditionalDialogModalProps;

const DialogModal = (props: DialogModalProps) => {
  const {
    asyncConfirmAction,
    closeIconAction,
    confirmAction,
    confirmText,
    content,
    destructiveConfirm,
    dismissAction,
    dismissText,
    handleCloseModal,
    hideCloseIcon,
    neutralConfirm,
    title,
  } = props;

  const [disableButtons, setDisableButtons] = useState<boolean>(false);

  const handleConfirm = async () => {
    setDisableButtons(true);
    if (asyncConfirmAction) {
      await asyncConfirmAction();
    }
    if (confirmAction) {
      confirmAction();
    }
    setDisableButtons(false);
    handleCloseModal();
  };

  const handleDismiss = () => {
    if (dismissAction) {
      dismissAction();
    }
    handleCloseModal();
  };

  const handleCloseIconClick = () => {
    if (closeIconAction) {
      closeIconAction();
      handleCloseModal();
    } else {
      handleDismiss();
    }
  };

  return (
    <div className={styles.dialogModal}>
      <div className={styles.headerSection}>
        <h1 className={styles.title} data-testid="dialog-modal-title">
          {title}
        </h1>
        {!hideCloseIcon && (
          <button
            className={styles.closeButton}
            onClick={handleCloseIconClick}
            data-testid="dialog-modal-close-button"
            aria-label="Close"
          >
            <Icon name="clear" />
          </button>
        )}
      </div>
      <div className={styles.contentScrollable} data-testid="dialog-modal-content">
        {content}
      </div>
      {(dismissText || confirmText) && (
        <div className={styles.buttonSection}>
          {dismissText && (
            <button
              className={clsx(styles.button, styles.dismissButton)}
              onClick={handleDismiss}
              disabled={disableButtons}
              data-testid="dialog-modal-dismiss-button"
            >
              {dismissText}
            </button>
          )}
          {confirmText && (
            <button
              className={clsx(styles.button, styles.confirmButton, {
                [styles.destructive]: destructiveConfirm,
                [styles.neutral]: neutralConfirm,
              })}
              onClick={handleConfirm}
              disabled={disableButtons}
              data-testid="dialog-modal-confirm-button"
            >
              {confirmText}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DialogModal;
