import React, { ReactNode } from 'react';
import clsx from 'clsx';

import Icon from '@/components/atoms/icon';

import styles from './styles.scss';

export interface ExternalLinkProps {
  className?: string;
  href: string;
  showArrow?: boolean;
  text: string | ReactNode;
}

const ExternalLink = (props: ExternalLinkProps) => {
  const { className, href, showArrow, text } = props;
  return (
    <span className={styles.linkWrapper}>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={clsx(styles.link, className)}
        data-testid="external-link"
      >
        {text}
      </a>
      {showArrow && <Icon name="arrowOutward" className={styles.arrowIcon} />}
    </span>
  );
};

export default ExternalLink;
