/* eslint-disable camelcase */

import slateAdapter from '@/store/modules/lobbies/adapters/slates';

import { Draft, DraftResponse, Source } from '@/interfaces/drafts';
import { arrayToObjectIdKeys, toCamel } from '@/utilities/helpers';

import { draftEntryAdapter } from './draft-entry';
import { draftUserAdapter } from './draft-user';
import { pickAdapter } from './pick';

const draftAdapter = (draft: DraftResponse['draft']): Draft => {
  const {
    id,
    auto_pick_at,
    clock,
    contest_style_id,
    creator_id,
    draft_at,
    draft_entries,
    draft_type,
    entry_count,
    entry_role,
    entry_style_id,
    picks: picksResponse,
    slate,
    slate_id,
    source,
    source_id,
    status,
    title,
    source_entry_style_id,
    users: usersResponse,
  } = draft;

  // This doesn't exist on the last statusChange update
  const draftEntries = draft_entries && draft_entries.map(draftEntryAdapter);

  const picks = picksResponse ? picksResponse.map(pickAdapter) : [];
  const adaptedSlate = slate ? slate.map(slateAdapter) : [];

  // This doesn't exist on the last statusChange update
  const users = usersResponse && arrayToObjectIdKeys(usersResponse.map(draftUserAdapter));

  const appearanceIds = picks?.map((pick) => pick.appearanceId);

  return {
    id,
    autoPickAt: auto_pick_at,
    clock,
    contestStyleId: contest_style_id,
    creatorId: creator_id,
    draftAt: draft_at,
    draftEntries,
    draftType: draft_type,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    picks,
    slate: adaptedSlate,
    slateId: slate_id,
    source: toCamel(source) as Source,
    sourceId: source_id,
    status,
    title,
    sourceEntryStyleId: source_entry_style_id,
    users,
    appearanceIds, // for pusher events
  };
};

export default draftAdapter;
