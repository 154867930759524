import React, { createContext, useContext, useMemo } from 'react';

import type { ServiceContainer } from './container';

export type ServiceContainerProviderProps<TServiceContainer extends ServiceContainer> =
  React.PropsWithChildren<{
    container: TServiceContainer;
  }>;

export type ServiceContainerContextValue<TServiceContainer extends ServiceContainer> = {
  container: TServiceContainer;
};

const ServiceContainerContext = createContext<
  ServiceContainerContextValue<ServiceContainer> | undefined
>(undefined);

export const ServiceContainerProvider = <TServiceContainer extends ServiceContainer>({
  container,
  children,
}: ServiceContainerProviderProps<TServiceContainer>) => {
  const value = useMemo(() => {
    return {
      container,
    };
  }, [container]);

  return (
    <ServiceContainerContext.Provider value={value}>{children}</ServiceContainerContext.Provider>
  );
};

export function useServiceContainerContext<TServiceContainer extends ServiceContainer>() {
  const context = useContext(ServiceContainerContext);
  if (!context) {
    throw new Error('useServiceContainerContext must be used within a ServiceContainerProvider');
  }

  // warning: this is not a safe cast, there is no guarantee that the value of the
  // nearest ServiceContainerContext is of type `TServiceContainer`.
  // We probably shouldn't have this hook or rather should encourage container-specific hooks
  // for container-specific providers.
  // e.g. <AppServiceContainerProvider> and useAppServiceContainerContext() and useAppService()
  // and those container-specific functions would wrap the more generic functions
  // from this file.
  return context as ServiceContainerContextValue<TServiceContainer>;
}
