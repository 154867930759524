import { AxiosResponse } from 'axios';

import request from '@request';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const checkUsername = async (username: string): Promise<AxiosResponse<void>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/usernames/${username}`,
    });

    // if this succeeds the username is taken
    return response;
  } catch (e) {
    // if this 404s you can use the username
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
