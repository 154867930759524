import { SettledDraftsForSlateResponse, SettledSlatesResponse } from '@/interfaces/results';

export const GET_SETTLED_SLATES = 'user/GET_SETTLED_SLATES' as const;
export const SET_SETTLED_SLATES = 'user/SET_SETTLED_SLATES' as const;

export const GET_SETTLED_DRAFTS_FOR_SLATE = 'user/GET_SETTLED_DRAFTS_FOR_SLATE' as const;
export const SET_SETTLED_DRAFTS_FOR_SLATE = 'user/SET_SETTLED_DRAFTS_FOR_SLATE' as const;

// Actions

export const getSettledSlates = (payload: { sportId: string; page?: number }) => ({
  type: GET_SETTLED_SLATES,
  payload,
});

export const setSettledSlates = (payload: { data: SettledSlatesResponse; sportId: string }) => ({
  type: SET_SETTLED_SLATES,
  payload,
});

export const getSettledDraftsForSlate = (payload: { slateId: string; page: number }) => ({
  type: GET_SETTLED_DRAFTS_FOR_SLATE,
  payload,
});

export const setSettledDraftsForSlate = (payload: {
  data: SettledDraftsForSlateResponse;
  slateId: string;
}) => ({ type: SET_SETTLED_DRAFTS_FOR_SLATE, payload });
