import React from 'react';

import Icon from '@/components/atoms/icon';

import styles from './styles.scss';

export interface LogoModalHeaderProps {
  handleCloseModal?: () => void;
}

const LogoModalHeader = (props: LogoModalHeaderProps) => {
  const { handleCloseModal } = props;

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerCircle} />
      <Icon name="logo" className={styles.icon} />
      <button className={styles.closeIconButton} onClick={handleCloseModal} aria-label="Close">
        <Icon name="clear" />
      </button>
    </div>
  );
};

export default LogoModalHeader;
