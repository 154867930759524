import { EntryCollection } from 'contentful';

import { ITeamFields, ITeamMemberFields } from '@/interfaces/contentful';
import { UnderdogTeam } from '@/interfaces/underdog-team';

import { SET_UNDERDOG_TEAM, setUnderdogTeam } from '../actions';

type State = UnderdogTeam[] | null;

type UnderdogTeamActions = ReturnType<typeof setUnderdogTeam>;

const initialState: UnderdogTeam[] = null;

const set = ({
  contentfulTeams,
  contentfulTeamMembers,
}: {
  contentfulTeams: EntryCollection<ITeamFields>;
  contentfulTeamMembers: EntryCollection<ITeamMemberFields>;
}): UnderdogTeam[] => {
  const teams = contentfulTeams.items.map((team) => {
    const id = team?.sys.id;
    const name = team?.fields.teamName;
    const displayOrder = team?.fields.displayOrder;
    const teamMembers = contentfulTeamMembers.items
      .filter((member) => member.fields.team.sys.id === id)
      .map((member) => ({
        displayOrder: member?.fields.displayOrder,
        name: member?.fields.name,
        title: member?.fields.title,
        imageUrl: member?.fields.image?.fields.file.url,
        twitterUrl: member?.fields.twitterLink,
        linkedInUrl: member?.fields.linkedIn,
        instagramUrl: member?.fields.instagram,
      }))
      .sort((a, b) => a.displayOrder - b.displayOrder);

    return {
      id,
      name,
      displayOrder,
      teamMembers,
    };
  });

  return teams;
};

export const underdogTeamReducer = (
  state: State = initialState,
  action: UnderdogTeamActions = {} as UnderdogTeamActions
): State => {
  switch (action.type) {
    case SET_UNDERDOG_TEAM:
      return set(action.payload);
    default:
      return state;
  }
};
