/* eslint-disable camelcase */
import { WeeklyWinnerWeek, WeeklyWinnerWeekResponse } from '@/interfaces/weekly-winners';

export const weeklyWinnerWeeksAdapter = (
  winnerWeek: WeeklyWinnerWeekResponse
): WeeklyWinnerWeek => {
  const { entry_style_id, id, status, week, weekly_winner_id } = winnerWeek;

  return {
    entryStyleId: entry_style_id,
    id,
    status,
    week,
    weeklyWinnerId: weekly_winner_id,
  };
};
