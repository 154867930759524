import { Entry } from 'contentful';

import { ITournamentRulesFields } from '@/interfaces/contentful';
import { TournamentRules } from '@/interfaces/tournament-rules';

const tournamentRulesAdapter = (tournamentRule: Entry<ITournamentRulesFields>): TournamentRules => {
  const { id } = tournamentRule.sys;
  const {
    title,
    slug,
    navLinkText,
    activeTournament,
    navRank,
    content,
    contestStyleId,
    entryStyleId,
    maximumEntries,
    commonContent,
    tournamentId,
  } = tournamentRule.fields;

  return {
    id,
    title,
    slug,
    navLinkText,
    activeTournament,
    navRank,
    content,
    contestStyleId,
    entryStyleId,
    maximumEntries,
    commonContent,
    tournamentId,
  };
};

export default tournamentRulesAdapter;
