/* eslint-disable camelcase */
import { SlateSitAndGo, SlateSitAndGoResponse } from '@/interfaces/lobbies';

const slateSitAndGosAdapter = (slateSitAndGos: SlateSitAndGoResponse[]): SlateSitAndGo[] =>
  slateSitAndGos.map((slateSitAndGo) => {
    const { slate_id, sit_and_go_id, entry_count } = slateSitAndGo;

    return {
      slateId: slate_id,
      sitAndGoId: sit_and_go_id,
      entryCount: entry_count,
    };
  });

export default slateSitAndGosAdapter;
