import { DraftResponse } from '@/interfaces/drafts';
import { LiveDraftsForSlateResponse, LiveSlatesForSportResponse } from '@/interfaces/live';

export const GET_LIVE_SLATES_FOR_DAILY_SPORT = 'user/GET_LIVE_SLATES_FOR_DAILY_SPORT' as const;
export const SET_LIVE_SLATES_FOR_DAILY_SPORT = 'user/SET_LIVE_SLATES_FOR_DAILY_SPORT' as const;

// eslint-disable-next-line max-len
export const GET_LIVE_SLATES_FOR_BEST_BALL_SPORT =
  'user/GET_LIVE_SLATES_FOR_BEST_BALL_SPORT' as const;
// eslint-disable-next-line max-len
export const SET_LIVE_SLATES_FOR_BEST_BALL_SPORT =
  'user/SET_LIVE_SLATES_FOR_BEST_BALL_SPORT' as const;

export const GET_LIVE_DRAFTS_FOR_SLATE = 'user/GET_LIVE_DRAFTS_FOR_SLATE' as const;
export const SET_LIVE_DRAFTS_FOR_SLATE = 'user/SET_LIVE_DRAFTS_FOR_SLATE' as const;

export const GET_LIVE_DRAFT = 'user/GET_LIVE_DRAFT' as const;
export const SET_LIVE_DRAFT = 'user/SET_LIVE_DRAFT' as const;

// Actions

export const getLiveSlatesForDailySport = (payload: { sportId: string }) => ({
  type: GET_LIVE_SLATES_FOR_DAILY_SPORT,
  payload,
});

export const setLiveSlatesForDailySport = (payload: {
  data: LiveSlatesForSportResponse;
  sportId: string;
}) => ({ type: SET_LIVE_SLATES_FOR_DAILY_SPORT, payload });

export const getLiveSlatesForBestBallSport = (payload: { sportId: string }) => ({
  type: GET_LIVE_SLATES_FOR_BEST_BALL_SPORT,
  payload,
});

export const setLiveSlatesForBestBallSport = (payload: {
  data: LiveSlatesForSportResponse;
  sportId: string;
}) => ({ type: SET_LIVE_SLATES_FOR_BEST_BALL_SPORT, payload });

export const getLiveDraftsForSlate = (payload: { slateId: string; page?: number }) => ({
  type: GET_LIVE_DRAFTS_FOR_SLATE,
  payload,
});

export const setLiveDraftsForSlate = (payload: { data: LiveDraftsForSlateResponse }) => ({
  type: SET_LIVE_DRAFTS_FOR_SLATE,
  payload,
});

export const getLiveDraft = (payload: { draftId: string }) => ({ type: GET_LIVE_DRAFT, payload });

export const setLiveDraft = (payload: { data: DraftResponse }) => ({
  type: SET_LIVE_DRAFT,
  payload,
});
