import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';

import { RootState } from '@/store';

import Button from '@/components/atoms/button';
import { User } from '@/interfaces/user';
import { CDN_HOST } from '@/utilities/constants';

import commonStyles from '../../common-styles.scss';
import styles from './styles.scss';

const REFERRAL_BACKGROUND_IMAGE = `${CDN_HOST}/pickem/referral-bg.png`;
const REFERRAL_IMAGE = `${CDN_HOST}/pickem/referral-icon.png`;

export interface PromoCardReferralProps {
  handleCloseModal?: () => void;
  user: User;
}

const PromoCardReferral = (props: PromoCardReferralProps) => {
  const { handleCloseModal, user } = props;
  const referralAmount = user?.profile?.referral?.amount || '$10';
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);

  const navigateToReferralPage = () => {
    handleCloseModal?.();
    navigate('/account/refer-a-friend');
  };

  const keyboardSelect = (e: React.KeyboardEvent<HTMLElement>) => {
    if (containerRef.current === e.target) {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();
        navigateToReferralPage();
      }
    }
  };

  return (
    <div
      ref={containerRef}
      className={clsx(commonStyles.promoCardWrapper, styles.promoCardReferral)}
      onClick={navigateToReferralPage}
      onKeyDown={keyboardSelect}
      role="button"
      style={{
        backgroundImage: `url(${REFERRAL_BACKGROUND_IMAGE})`,
      }}
      tabIndex={0}
    >
      <img alt="referral-icon" className={styles.image} src={REFERRAL_IMAGE} />
      <div className={clsx(commonStyles.infoSection, styles.infoSection)}>
        <h2 className={clsx(commonStyles.header, styles.header)}>
          Refer a friend and get {referralAmount}
        </h2>
        <p className={clsx(commonStyles.text, styles.text)}>
          For each friend that signs up, you&apos;ll get {referralAmount} bonus cash after their
          first time deposits.
        </p>
        <div className={styles.buttonWrapper}>
          <Button
            onClick={navigateToReferralPage}
            size="small"
            text="Refer a friend"
            type="solid"
            width="intrinsic"
          />
        </div>
      </div>
    </div>
  );
};
export default connect((state: RootState) => ({
  user: state.user,
}))(PromoCardReferral);
