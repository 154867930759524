/* eslint-disable camelcase */
import {
  AppearanceStat,
  AppearanceStatsResponse,
  LatestNewsItem,
  LatestNewsItemResponse,
  Projection,
  ProjectionResponse,
  StatsTable,
  StatsTableResponse,
} from '@/interfaces/appearance-stats';

import scoreAdapter from '../../constructed-slates/adapters/score';

export const newsItemAdapter = (latestNewsItem: LatestNewsItemResponse): LatestNewsItem => ({
  id: latestNewsItem.id,
  content: latestNewsItem.content,
  source: latestNewsItem.source,
  title: latestNewsItem.title,
  updatedAt: latestNewsItem.updated_at,
  url: latestNewsItem.url,
});

export const projectionAdapter = (projection: ProjectionResponse): Projection => ({
  id: projection.id,
  adp: projection.adp,
  average: projection.average,
  avgWeeklyPoints: projection.avg_weekly_points,
  points: projection.points,
  positionRank: projection.position_rank,
  scoringTypeId: projection.scoring_type_id,
});

export const statsTableAdapter = (statsTable: StatsTableResponse): StatsTable => {
  const { headers, rows, titles: titlesResponse } = statsTable;

  const titles =
    titlesResponse &&
    titlesResponse.map((titleResponse) => ({
      text: titleResponse.text,
      colStart: titleResponse.col_start,
      colEnd: titleResponse.col_end,
    }));

  return {
    headers,
    rows,
    titles,
  };
};

const appearanceStatsAdapter = (
  appearanceStatsResponse: AppearanceStatsResponse
): AppearanceStat => {
  const {
    appearance: {
      id,
      badges,
      latest_news_item,
      lineup_status_id,
      match_id,
      match_type,
      player_id,
      position_id,
      projection: projectionResponse,
      score: scoreResponse,
      stats_table,
      team_id,
    },
  } = appearanceStatsResponse;

  const statsTable = stats_table ? statsTableAdapter(stats_table) : null;
  const projection = projectionResponse ? projectionAdapter(projectionResponse) : null;
  const latestNewsItem = latest_news_item ? newsItemAdapter(latest_news_item) : null;
  const score = scoreResponse && scoreAdapter(scoreResponse);

  return {
    id,
    badges,
    latestNewsItem,
    lineupStatusId: lineup_status_id,
    matchId: match_id,
    matchType: match_type,
    playerId: player_id,
    positionId: position_id,
    projection,
    score,
    statsTable,
    teamId: team_id,
  };
};

export default appearanceStatsAdapter;
