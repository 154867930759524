/* eslint-disable camelcase */
import { MainLobby, MainLobbyResponse } from '@/interfaces/lobbies';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import lobbyDraftPoolAdapter from './lobby-draft-pool';
import { lobbyItemAdapter } from './lobby-item';
import lobbySlateAdapter from './lobby-slate';
import lobbyTournamentAdapter from './lobby-tournaments';
import lobbyWeeklyWinnerAdapter from './lobby-weekly-winners';
import slateSitAndGosAdapter from './slate-sit-and-gos';

const mainLobbyAdapter = ({ lobby }: { lobby: MainLobbyResponse['lobby'] }): MainLobby => {
  const {
    id,
    draft_pools,
    lobby_items,
    slate_sit_and_gos,
    slates,
    tournaments: tournamentsResponse,
    weekly_winners: weeklyWinnersResponse,
  } = lobby;

  const draftPools = draft_pools && draft_pools.map(lobbyDraftPoolAdapter);
  const lobbyItems =
    lobby_items && arrayToObjectIdKeys(lobby_items.map(lobbyItemAdapter), 'itemId');
  const slateSitAndGos = slateSitAndGosAdapter(slate_sit_and_gos);
  const lobbySlates = slates?.map(lobbySlateAdapter);
  const tournaments = lobbyTournamentAdapter(tournamentsResponse);
  const weeklyWinners = weeklyWinnersResponse?.map(lobbyWeeklyWinnerAdapter) || [];

  return {
    id,
    draftPools,
    lobbyItems,
    slateSitAndGos,
    lobbySlates,
    tournaments,
    weeklyWinners,
  };
};

export default mainLobbyAdapter;
