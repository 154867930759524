import { EntryCollection } from 'contentful';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api-contentful/sweepstakes-terms-and-conditions';
import { ISweepstakeTermsAndConditionsFields } from '@/interfaces/contentful';

import { getSweepstakeTsAndCs, setAllSweepstakesTsAndCs, setSweepstakeTsAndCs } from '../actions';

export const getSweepstakesTsAndCsSaga = function* getSweepstakesTsAndCsSaga(
  action: ReturnType<typeof getSweepstakeTsAndCs>
): SagaIterator {
  try {
    const sweepstakesTermsAndConditions: EntryCollection<ISweepstakeTermsAndConditionsFields> =
      yield call(api.getContentfulSweepstakesTermsAndConditions, action.payload);

    yield put(
      setSweepstakeTsAndCs({
        sweepstakesTermsAndConditions,
        slug: action.payload.slug,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getAllSweepstakesTsAndCsSaga = function* getAllSweepstakesTsAndCsSaga(): SagaIterator {
  try {
    const sweepstakesTermsAndConditions: EntryCollection<ISweepstakeTermsAndConditionsFields> =
      yield call(api.getContentfulAllSweepstakesTermsAndConditions);

    yield put(setAllSweepstakesTsAndCs({ sweepstakesTermsAndConditions }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
