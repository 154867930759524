import { EntryCollection } from 'contentful';

import { ISweepstakeTermsAndConditionsFields } from '@/interfaces/contentful';
import {
  AllSweepstakesTermsAndConditions,
  SweepstakesTermsAndConditions,
} from '@/interfaces/sweepstakes-terms-and-conditions';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import {
  SET_ALL_SWEEPSTAKES_TS_AND_CS,
  SET_SWEEPSTAKE_TS_AND_CS,
  setAllSweepstakesTsAndCs,
  setSweepstakeTsAndCs,
} from '../actions';
import sweepstakesTermsAndConditionsAdapter from '../adapters/sweepstakes-terms-and-conditions';

type State = AllSweepstakesTermsAndConditions;

type SweepstakesTsAndCsActions = ReturnType<
  typeof setAllSweepstakesTsAndCs | typeof setSweepstakeTsAndCs
>;

const initialState: AllSweepstakesTermsAndConditions = {
  navItems: null,
  sweepstakesTsAndCs: {},
};

const setAll = (
  state: State,
  {
    sweepstakesTermsAndConditions,
  }: {
    sweepstakesTermsAndConditions: EntryCollection<ISweepstakeTermsAndConditionsFields>;
  }
): AllSweepstakesTermsAndConditions => {
  const allSTsAndCs = sweepstakesTermsAndConditions.items.map(sweepstakesTermsAndConditionsAdapter);

  const navItems = allSTsAndCs
    .map((sweepstakeTsAndCs) => ({
      label: sweepstakeTsAndCs.linkText,
      slug: sweepstakeTsAndCs.slug,
      order: sweepstakeTsAndCs.order,
    }))
    .sort((a, b) => b.order - a.order);

  const allSTsAndCsObject = arrayToObjectIdKeys(allSTsAndCs, 'slug'); // object with the slug as the key

  return {
    navItems: navItems || [],
    sweepstakesTsAndCs: allSTsAndCsObject,
  };
};

const set = (
  state: State,
  {
    sweepstakesTermsAndConditions,
    slug,
  }: {
    sweepstakesTermsAndConditions: EntryCollection<ISweepstakeTermsAndConditionsFields>;
    slug: string;
  }
): AllSweepstakesTermsAndConditions => {
  const allSTsAndCs = sweepstakesTermsAndConditions.items.map(sweepstakesTermsAndConditionsAdapter);

  const currentSTsAndCs = arrayToObjectIdKeys(allSTsAndCs, 'slug'); // object with the slug as the key

  return {
    ...state,
    sweepstakesTsAndCs: {
      ...state.sweepstakesTsAndCs,
      [slug]: {} as SweepstakesTermsAndConditions, // this will always set something,
      ...currentSTsAndCs,
    },
  };
};

export const sweepstakesTermsAndConditionsReducer = (
  state: State = initialState,
  action: SweepstakesTsAndCsActions = {} as SweepstakesTsAndCsActions
): State => {
  switch (action.type) {
    case SET_ALL_SWEEPSTAKES_TS_AND_CS:
      return setAll(state, action.payload);
    case SET_SWEEPSTAKE_TS_AND_CS:
      return set(state, action.payload);
    default:
      return state;
  }
};
