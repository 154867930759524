import React from 'react';
import clsx from 'clsx';

import styles from './styles.scss';

const GamblingAdvice = ({ classNames }: { classNames?: { link?: string; container?: string } }) => (
  <p className={clsx(styles.container, classNames?.container)}>
    If you or someone you know has a gambling problem, help is available. Call{' '}
    <a className={clsx(styles.link, classNames?.link)} href="tel:1800GAMBLER">
      1-800-GAMBLER
    </a>{' '}
    or go to to{' '}
    <a
      href="https://www.ncpgambling.org/"
      target="_blank"
      rel="noopener noreferrer"
      className={clsx(styles.link, classNames?.link)}
    >
      ncpgambling.org
    </a>
    .
  </p>
);

export default GamblingAdvice;
