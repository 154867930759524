import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/matches';
import errorLogger from '@/utilities/errors/logger';

import { getMatchesForDate, setMatchesForDate } from '../actions';

export const getMatchesForDateSaga = function* getMatchesForDateSaga(
  action: ReturnType<typeof getMatchesForDate>
): SagaIterator {
  try {
    const response = yield call(api.getDateMatches, action.payload);

    yield put(
      setMatchesForDate({
        data: response.data,
        date: action.payload.date,
        sportId: action.payload.sportId,
      })
    );
  } catch (e) {
    errorLogger(true, `getMatchesForDateSaga: ${e}`);
    yield put(setError({ ...e, type: 'toast' }));
  }
};
