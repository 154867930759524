import React from 'react';

import LogoModalHeader from '@/components/atoms/logo-modal-header';
/* eslint-disable-next-line import/no-cycle */
import ForgotPasswordForm from '@/components/molecules/forgot-password-form';

import styles from './styles.scss';

export interface ForgotPasswordProps {
  handleCloseModal: () => void;
}

const ForgotPasswordModal = (props: ForgotPasswordProps) => {
  const { handleCloseModal } = props;
  return (
    <div className={styles.modal}>
      <LogoModalHeader handleCloseModal={handleCloseModal} />
      <div className={styles.content}>
        <ForgotPasswordForm isModal />
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
