import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';
import { setUnderdogTeam } from '@/store/modules/underdog-team/actions';

import * as api from '@/api-contentful/teams';

export const getUnderdogTeamSaga = function* getUnderdogTeamSaga(): SagaIterator {
  try {
    const [contentfulTeams, contentfulTeamMembers] = yield all([
      call(api.getContentfulTeams),
      call(api.getContentfulTeamMembers),
    ]);

    yield put(setUnderdogTeam({ contentfulTeams, contentfulTeamMembers }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
