/* eslint-disable camelcase */
import {
  BonusWalletAccount,
  BonusWalletAccountResponse,
  GameplaySettings,
  GameplaySettingsResponse,
  Role,
  RoleResponse,
  User,
  UserBadge,
  UserBadgeResponse,
  UserBonusWalletResponse,
  UserResponse,
} from '@/interfaces/user';
import { toCamel } from '@/utilities/helpers';

export const badgeAdapter = (badgeResponse: UserBadgeResponse): UserBadge => ({
  title: badgeResponse.title,
  lightModeUrl: badgeResponse.light_mode_url,
  darkModeUrl: badgeResponse.dark_mode_url,
});

export const bonusWalletAccountAdapter = (
  bonusWalletAccount: BonusWalletAccountResponse
): BonusWalletAccount => ({
  createdAt: bonusWalletAccount.created_at,
  expiresAt: bonusWalletAccount.expires_at,
  id: bonusWalletAccount.id,
  originType: bonusWalletAccount.origin_type,
  originalAmount: bonusWalletAccount.original_amount,
  remainingAmount: bonusWalletAccount.remaining_amount,
});

export const bonusWalletAdapter = (
  bonusWalletData: UserBonusWalletResponse
): User['bonusWallet'] => {
  const bonusWalletAccounts = bonusWalletData.bonus_wallet_accounts.map(bonusWalletAccountAdapter);
  return {
    bonusWalletAccounts,
    nonExpiringBonusBalance: bonusWalletData.non_expiring_bonus_balance,
  };
};

export const gameplaySettingsAdapter = (
  gameplaySettingsResponse: GameplaySettingsResponse
): GameplaySettings => ({
  payoutDefault: gameplaySettingsResponse.payout_default,
  autoAcceptProjectionChanges: gameplaySettingsResponse.auto_accept_projection_changes,
  userPromptedAt: gameplaySettingsResponse.user_prompted_at,
});

export const roleAdapter = (rolesResponse: RoleResponse): Role => toCamel(rolesResponse) as Role;

const userAdapter = ({ user }: UserResponse): User => {
  const {
    id,
    badges: badgesResponse,
    app_download_eligible,
    bonus_cash,
    email,
    gameplay_settings: gameplaySettingsResponse,
    has_phone,
    identification_status,
    pre_reg_eligible,
    profile_color,
    profile_icon_url,
    roles: rolesResponse,
    registration_source,
    state,
    username,
    wallet,
  } = user;

  const badges = badgesResponse?.map(badgeAdapter);
  const gameplaySettings = gameplaySettingsAdapter(gameplaySettingsResponse);
  const roles = rolesResponse?.map(roleAdapter);

  return {
    id,
    appDownloadEligible: app_download_eligible,
    badges,
    bonusCash: bonus_cash || '0',
    email,
    gameplaySettings,
    hasPhone: has_phone,
    identificationStatus: toCamel(identification_status) as User['identificationStatus'],
    profileColor: profile_color,
    preRegEligible: pre_reg_eligible,
    profileIconUrl: profile_icon_url,
    registrationSource: registration_source,
    roles,
    state,
    username,
    wallet: wallet || {},
  };
};

export default userAdapter;
