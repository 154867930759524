import { CompletedSlate, CompletedSlatesResponse } from '@/interfaces/completed-slates';

import { SET_COMPLETED_SLATES, setCompletedSlates } from '../actions';
import { completedSlateAdapter } from '../adapters/completed-slate';

type State = CompletedSlate[] | [];

type CompletedSlatesActions = ReturnType<typeof setCompletedSlates>;

const initialState: CompletedSlate[] = [];

const setSlates = (data: CompletedSlatesResponse): CompletedSlate[] => {
  const slates = data.slates.map(completedSlateAdapter);
  return slates;
};

export const completedSlatesReducer = (
  state: State = initialState,
  action: CompletedSlatesActions = {} as CompletedSlatesActions
): State => {
  switch (action.type) {
    case SET_COMPLETED_SLATES:
      return setSlates(action.payload);
    default:
      return state;
  }
};
