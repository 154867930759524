/* eslint-disable camelcase */
import { WeeklyWinnerWeekEntry, WeeklyWinnerWeekEntryResponse } from '@/interfaces/weekly-winners';

export const weeklyWinnerWeekEntryAdapter = (
  entry: WeeklyWinnerWeekEntryResponse
): WeeklyWinnerWeekEntry => {
  const {
    appearance_ids,
    appearance_summary,
    draft_id,
    id,
    payout,
    place,
    points,
    tied,
    user_id,
    weekly_winner_entry_id,
  } = entry;

  return {
    appearanceIds: appearance_ids,
    appearanceSummary: appearance_summary,
    draftId: draft_id,
    id,
    payout,
    place,
    points,
    tied,
    userId: user_id,
    weeklyWinnerEntryId: weekly_winner_entry_id,
  };
};
