import { Entry } from 'contentful';

import { ITextPageFields } from '@/interfaces/contentful';
import { TextPage } from '@/interfaces/text-pages';

const textPageAdapter = (textPage: Entry<ITextPageFields>): TextPage => {
  const { content, id, title } = textPage.fields;
  return {
    content,
    id,
    title,
  };
};

export default textPageAdapter;
