/* eslint-disable camelcase */
import {
  FallbackResponse,
  OtherPayoutResponse,
  PayoutOutcome,
  PayoutOutcomeResponse,
} from '@/interfaces/pick-em';

import outcomeErrorAdapter from './outcome-error';

const payoutOutcomeAdapter = ({
  data,
  entryRoles,
}: {
  data: PayoutOutcomeResponse;
  entryRoles: string[];
}): PayoutOutcome => {
  const { errors, insurance, pickem_pools, selection_count, standard } = data;

  const adaptedErrors = errors.map(outcomeErrorAdapter);
  const adaptedStandardErrors = standard?.errors.map(outcomeErrorAdapter) ?? [];
  const adaptedInsuranceErrors = insurance?.errors.map(outcomeErrorAdapter) ?? [];

  const adaptedInsuranceFallback = insurance?.fallbacks.map((fallback: FallbackResponse) => ({
    lossCount: fallback.loss_count,
    payoutMultiplier: fallback.payout_multiplier,
    unboostedPowerUpMultiplier: fallback.unboosted_power_up_multiplier,
  }));
  const adaptedInsuranceOtherPayouts = insurance?.other_payouts.map(
    (payout: OtherPayoutResponse) => ({
      payoutMultiplier: payout.payout_multiplier,
      selectionCount: payout.selection_count,
      unboostedPowerUpMultiplier: payout.unboosted_power_up_multiplier,
    })
  );
  const adaptedInsurance = insurance
    ? {
        isBoostedMultiplier: insurance.is_boosted_multiplier,
        fallbacks: adaptedInsuranceFallback,
        maxFee: insurance.max_fee,
        maxMultiplier: insurance.max_multiplier,
        minFee: insurance.min_fee,
        minSelectionCount: insurance.min_selection_count,
        otherPayouts: adaptedInsuranceOtherPayouts,
        payoutMultiplier: insurance.payout_multiplier,
        payoutOptionId: insurance.payout_option_id,
        selectionCount: selection_count,
        errors: adaptedInsuranceErrors,
        unboostedPowerUpMultiplier: insurance.unboosted_power_up_multiplier,
      }
    : null;

  const adaptedStandardOtherPayouts = standard?.other_payouts.map(
    (payout: OtherPayoutResponse) => ({
      payoutMultiplier: payout.payout_multiplier,
      selectionCount: payout.selection_count,
      unboostedPowerUpMultiplier: payout.unboosted_power_up_multiplier,
    })
  );

  const adaptedStandard = standard
    ? {
        isBoostedMultiplier: standard.is_boosted_multiplier,
        maxFee: standard.max_fee,
        maxMultiplier: standard.max_multiplier,
        minFee: standard.min_fee,
        minSelectionCount: standard.min_selection_count,
        otherPayouts: adaptedStandardOtherPayouts,
        payoutMultiplier: standard.payout_multiplier,
        payoutOptionId: standard.payout_option_id,
        selectionCount: selection_count,
        errors: adaptedStandardErrors,
        unboostedPowerUpMultiplier: standard.unboosted_power_up_multiplier,
      }
    : null;

  const userPickEmPool =
    pickem_pools.find((pool) => entryRoles.includes(pool.entry_role)) ||
    pickem_pools.find((pool) => pool.entry_role === null);

  return {
    errors: adaptedErrors,
    insurance: adaptedInsurance,
    lastUpdated: new Date().toString(),
    pickEmPool: {
      entryRole: userPickEmPool.entry_role,
      maxFee: userPickEmPool.max_fee,
      rake: userPickEmPool.rake,
      styleId: userPickEmPool.style_id,
      totalFees: userPickEmPool.total_fees,
    },
    selectionCount: selection_count,
    standard: adaptedStandard,
  };
};

export default payoutOutcomeAdapter;
