import React from 'react';

import Icon from '@/components/atoms/icon';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import DialogModal from '@/components/molecules/dialog-modal';
import { ConstructedPickEmRivalLineAppearance } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { AdjustmentDisplay, PickEmResult } from '@/interfaces/types';
import langHelper from '@/utilities/lang-helper';

import styles from './styles.scss';

interface LobbyDividingContainerProps {
  selectedOptionId: string;
  selectedLeft: boolean;
  selectedRight: boolean;
}

type AdjustedPlayer = {
  cA: ConstructedPickEmRivalLineAppearance;
  actualValue: string;
};

export const LobbyDividingContainer = (props: LobbyDividingContainerProps) => {
  const { selectedOptionId, selectedLeft, selectedRight } = props;
  return (
    <div className={styles.rivalLobbyDividingContainer}>
      <div className={styles.vsWrapper}>
        <Icon
          name="leftArrow"
          className={`${styles.selectorIcon} ${styles.left} ${selectedLeft ? styles.selected : ''}`}
        />
        <p className={`${styles.lobbyVsText} ${selectedOptionId ? styles.selectedBorder : ''}`}>
          <span>vs</span>
        </p>
        <Icon
          name="rightArrow"
          className={`${styles.selectorIcon} ${styles.right} ${
            selectedRight ? styles.selected : ''
          }`}
        />
      </div>
    </div>
  );
};

interface LiveDividingContainerProps {
  result: PickEmResult;
  leftScore: number;
  rightScore: number;
  adjustmentDisplay: AdjustmentDisplay;
  adjustedPlayer: AdjustedPlayer;
  disableButtons?: boolean;
}

export const LiveDividingContainer = (props: LiveDividingContainerProps) => {
  const {
    result,
    leftScore,
    rightScore,
    adjustmentDisplay,
    adjustedPlayer,
    disableButtons = false,
  } = props;
  const openModal = useModal();

  const showAdjustment = ({ cA, actualValue }: AdjustedPlayer) => {
    const {
      rivalOption: { spread, appearanceStat },
      player: { firstName, lastName },
    } = cA;
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <DialogModal
        handleCloseModal={handleCloseModal}
        title="Adjustment"
        content={
          <div className={styles.adjustmentContent}>
            <h3>
              {firstName} {lastName}
            </h3>
            <p>
              An adjustment of {spread} {appearanceStat.displayStat.toLowerCase()} has been applied
              and is included in the displayed stat value.
            </p>
            <p>
              The actual stat value is {actualValue} {appearanceStat.displayStat.toLowerCase()}.
            </p>
          </div>
        }
      />
    ));
  };
  return (
    <div className={styles.rivalLiveDividingContainer}>
      <div className={`${styles.vsWrapper} ${styles.live}`}>
        <p className={styles.statValue}>{langHelper.formatNumber(leftScore)}</p>
        <div className={`${styles.selectionLine} ${styles[result]}`} />
        <p className={`${styles.liveVsText} ${styles[result]}`}>
          <span>vs</span>
        </p>
        <div className={`${styles.selectionLine} ${styles.hidden}`} />
        <p className={styles.statValue}>{langHelper.formatNumber(rightScore)}</p>
      </div>
      <div className={`${styles.adjustmentButton} ${styles[adjustmentDisplay]}`}>
        <button
          className={styles.adjustmentIcon}
          onClick={disableButtons ? () => null : () => showAdjustment(adjustedPlayer)}
          disabled={adjustmentDisplay === 'hidden'}
          aria-label="Adjustment info"
        >
          <Icon name="adjustment" />
        </button>
      </div>
    </div>
  );
};
