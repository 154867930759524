import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { getTournament, setTournament } from '@/store/modules/tournaments/actions';

import * as api from '@/api/tournaments';
import { TournamentResponse } from '@/interfaces/tournaments';
import errorLogger from '@/utilities/errors/logger';

export const getTournamentSaga = function* getTournamentSaga(
  action: ReturnType<typeof getTournament>
): SagaIterator {
  try {
    const response = yield call(api.getTournamentInfo, action.payload);
    const { data }: { data: TournamentResponse } = response;

    yield put(setTournament(data));
  } catch (e) {
    errorLogger(true, `getTournamentSaga: ${e}`);
  }
};
