import React from 'react';
import { connect, useSelector } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '@/store';

import BoostIcon from '@/components/atoms/boost-icon';
import TextSlideUpAnimation from '@/components/molecules/animations/text-slide-up';
import OptionsWrapper from '@/components/molecules/options-wrapper';
import {
  AppearanceLine,
  ConstructedPickEmOverUnderLineAppearance,
  SelectedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { PickLocation } from '@/interfaces/pick-em';
import { RedeemablePowerUp } from '@/interfaces/power-ups';

import AltsButton from './components/alts-button';
import InGameProjection from './components/in-game-projection';
import ScoringInfoButton from './components/scoring-info-button';
import StatChangeIcon from './components/stat-change-icon';
import { getStatValues } from './helpers';

import styles from './styles.scss';

export interface OverUnderListCellProps {
  appearanceLine: AppearanceLine;
  constructedPickEmLineAppearance: ConstructedPickEmOverUnderLineAppearance;
  isAlternateProjectionsEnabled: boolean;
  pickLocation: PickLocation;
  powerUp: RedeemablePowerUp;
  selectedOverUnder: SelectedOverUnder;
}

const OverUnderListCell = (props: OverUnderListCellProps) => {
  const {
    appearanceLine,
    constructedPickEmLineAppearance: cPEOULA,
    isAlternateProjectionsEnabled,
    pickLocation,
    powerUp,
    selectedOverUnder,
  } = props;

  const overUnderLine = useSelector((state: RootState) => {
    return state.pickEmOverUnder.overUnderLines[appearanceLine.id];
  });

  if (!overUnderLine) {
    return null;
  }

  const swapStatValueAndAnimate = Boolean(
    overUnderLine?.oldOverUnderLineId &&
      !(overUnderLine?.oldStatValue === overUnderLine?.statValue && overUnderLine?.oldLineOptions)
  );

  const swapLineOptionAndAnimate = Boolean(overUnderLine?.oldLineOptions);

  const { prevStatValue, statValue } = getStatValues({
    overUnderLine,
    powerUp: selectedOverUnder?.powerUpId === powerUp?.id ? powerUp : null,
  });

  const statEl = (
    <div className={clsx(styles.currentStat, { [styles.updatedStat]: swapStatValueAndAnimate })}>
      <div className={styles.statValue}>
        {prevStatValue ? <span className={styles.prevStatValue}>{prevStatValue}</span> : null}
        <span className={styles.statValue}>{statValue}</span>{' '}
        <ScoringInfoButton overUnderLine={overUnderLine} />{' '}
        <BoostIcon boost={overUnderLine.overUnder?.boost} />{' '}
        {overUnderLine.liveEvent && <InGameProjection overUnderLine={overUnderLine} />}
      </div>
      <div className={styles.displayStat}>
        {overUnderLine.overUnder?.appearanceStat.displayStat}
      </div>
    </div>
  );

  return (
    <div
      key={overUnderLine.id}
      className={clsx({
        [styles.removed]: overUnderLine?.status === 'removed',
        [styles.overUnderListCell]: pickLocation !== PickLocation.PLAYER_CARD,
        [styles.playerModal]: pickLocation === PickLocation.PLAYER_CARD,
      })}
      data-rank={overUnderLine.rank}
      data-over-under-line-id={overUnderLine.id}
      data-testid="over-under-list-cell"
    >
      <div className={styles.overUnderLineInfoWrapper}>
        {isAlternateProjectionsEnabled && cPEOULA.hasAlts && (
          <AltsButton overUnderLine={overUnderLine} pickLocation={pickLocation} />
        )}
        <div
          className={styles.statLineColumn}
          data-testid="stat-line-container"
          data-appearance-stat={overUnderLine?.overUnder?.appearanceStat.stat}
        >
          <div className={styles.topHalf} data-testid="stat-line">
            <div
              className={clsx(styles.statLine, {
                [styles.suspendedStat]: overUnderLine?.status === 'suspended',
              })}
            >
              <TextSlideUpAnimation
                defaultContent={statEl}
                incomingContent={statEl}
                swapCondition={swapStatValueAndAnimate}
              />
              <StatChangeIcon overUnderLine={overUnderLine} />
            </div>
          </div>
        </div>
      </div>
      <OptionsWrapper
        pickLocation={pickLocation}
        constructedPickEmLineAppearance={cPEOULA}
        overUnderLine={overUnderLine}
        selectedOptionId={selectedOverUnder?.option.id}
        swapLineOptionAndAnimate={swapLineOptionAndAnimate}
        swapStatValueAndAnimate={swapStatValueAndAnimate}
        classNames={{
          wrapper: styles.overUnderOptionsWrapper,
        }}
      />
    </div>
  );
};

export default connect((state: RootState) => ({
  isAlternateProjectionsEnabled: state.featureFlags.webAlternateProjections,
}))(OverUnderListCell);
