import React from 'react';
import clsx from 'clsx';

import Icon from '@/components/atoms/icon';
import { Sport } from '@/interfaces/drafting-config';

import styles from './styles.scss';

interface SportBadgeProps {
  className?: string;
  size?: 'small' | 'large';
  sport: Sport;
}

const SportBadge = (props: SportBadgeProps) => {
  const { className, size = 'large', sport } = props;
  const { color, icon } = sport;
  const title = sport.name;
  return (
    <div
      className={clsx(styles.sportBadge, className, { [styles[size]]: size })}
      title={title}
      style={color ? { backgroundColor: color } : {}}
    >
      <Icon name={icon} />
    </div>
  );
};

export default SportBadge;
