import { EntryCollection } from 'contentful';

import { IGeneralRulesFields } from '@/interfaces/general-rules';

export const GET_GENERAL_RULES = 'rules/GET_GENERAL_RULES' as const;
export const SET_GENERAL_RULES = 'rules/SET_GENERAL_RULES' as const;

export const getGeneralRules = () => ({ type: GET_GENERAL_RULES });

export const setGeneralRules = (payload: {
  generalRules: EntryCollection<IGeneralRulesFields>;
}) => ({ type: SET_GENERAL_RULES, payload });
