import React from 'react';

import { CDN_HOST } from '@/utilities/constants';

import styles from './styles.scss';

const EmptyState = () => {
  return (
    <div className={styles.emptyState}>
      <img
        className={styles.image}
        alt="Eyes indicating that inventory is empty"
        src={`${CDN_HOST}/pickem/no-player-pick.png`}
      />
      <p className={styles.header}>Nothing to see here.</p>
      <p className={styles.text}>Check back later to see if you have any available promos!</p>
    </div>
  );
};

export default EmptyState;
