import { EntryCollection } from 'contentful';

import client from '@/api-contentful/contentful-request';
import { ICareersFaqFields } from '@/interfaces/contentful';

export const getContentfulCareersFaqs = async (): Promise<EntryCollection<ICareersFaqFields>> => {
  const response = await client.getEntries<ICareersFaqFields>({
    content_type: 'careersFaq',
  });

  return response;
};
