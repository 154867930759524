import type { IconTypes } from '@/components/atoms/icon';

export interface Badge {
  icon: IconTypes | null;
  color: string;
  text: string | null;
  title: string;
}

export type BadgeName = keyof typeof badges;

const battleRoyaleBadge: Badge = {
  icon: 'battleRoyale',
  color: 'battleRoyale',
  text: null,
  title: 'Battle Royale',
};

// We use a generic function to create the badges object so that we can enforce a specific
// interface for each badge and allow for arbitrary badge "names" (keys) to be used
// without having to define them in the BadgeName type and without having to
// widen the key type to just `string`.
// For example, if we used `Record<string, Badge>` instead, then `keyof typeof badges`
// would just be `string` instead of a union of the badge names.
const badges = createBadges({
  draftPool: battleRoyaleBadge,
  Home: {
    icon: 'home',
    color: 'default',
    text: null,
    title: 'Home',
  },
  admin: {
    icon: null,
    color: 'admin',
    text: 'A',
    title: 'Admin',
  },
  autopilot: {
    icon: 'autopilot',
    color: 'autopilot',
    text: null,
    title: 'Autopilot',
  },
  beginner: {
    icon: null,
    color: 'beginner',
    text: 'B',
    title: 'Beginner',
  },
  battleRoyale: battleRoyaleBadge,
  bestBall: {
    icon: null,
    color: 'bestBall',
    text: 'BB',
    title: 'Best Ball',
  },
  expert1: {
    icon: null,
    color: 'expert',
    text: 'E',
    title: 'Expert',
  },
  expert2: {
    icon: null,
    color: 'expert',
    text: 'E',
    title: 'Expert',
  },
  guaranteed: {
    icon: null,
    color: 'guaranteed',
    text: 'G',
    title: 'Guaranteed',
  },
  multiEntry: {
    icon: null,
    color: 'multiEntry',
    text: 'M',
    title: 'Multi-entry',
  },
  privateSitAndGo: {
    icon: 'people',
    color: 'privateSitAndGo',
    text: null,
    title: 'Private draft',
  },
  slow: {
    icon: 'timer',
    color: 'slow',
    text: null,
    title: 'Slow draft',
  },
  tournament: {
    icon: null,
    color: 'tournament',
    text: 'T',
    title: 'Tournament',
  },
  infoWarning: {
    icon: null,
    color: 'infoWarning',
    text: '!',
    title: 'Warning',
  },
  instant: {
    icon: 'instant',
    color: 'instant',
    text: null,
    title: 'Instant',
  },
  weeklyWinner: {
    icon: null,
    color: 'weeklyWinner',
    text: 'WW',
    title: 'Weekly Winners',
  },
});

function createBadges<TBadges extends Record<string, Badge>>(val: TBadges): TBadges {
  return val;
}

export default badges;
