import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/feature-flags';

import { setFeatureFlags } from '../actions';

export const getGlobalFeatureFlagsSaga = function* getGlobalFeatureFlagsSaga(): SagaIterator {
  try {
    const response = yield call(api.getGlobalFeatures);

    yield put(setFeatureFlags(response.data));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
