/* eslint-disable camelcase */
import {
  WeeklyWinnerWeekEntries,
  WeeklyWinnerWeekEntriesResponse,
  WeeklyWinnerWeekEntryScoreUpdateResponse,
} from '@/interfaces/weekly-winners';

import {
  SET_USER_ENTRIES_FOR_WEEKLY_WINNER_WEEK,
  setUserEntriesForWeeklyWinnerWeek,
  UPDATE_ENTRIES_FOR_WEEKLY_WINNER_WEEK,
  updateEntriesForWeeklyWinnerWeek,
} from '../actions';
import { weeklyWinnerWeekEntryAdapter } from '../adapters/weekly-winner-week-entry';

type State = WeeklyWinnerWeekEntries | null;

type EntriesForWeeklyWinnerWeeksActions = ReturnType<
  typeof setUserEntriesForWeeklyWinnerWeek | typeof updateEntriesForWeeklyWinnerWeek
>;

const initialState: WeeklyWinnerWeekEntries = {};

const update = (
  state: WeeklyWinnerWeekEntries,
  {
    data,
    draftId,
    weeklyWinnerWeekId,
  }: {
    data: WeeklyWinnerWeekEntryScoreUpdateResponse;
    weeklyWinnerWeekId: string;
    draftId: string;
  }
): WeeklyWinnerWeekEntries => {
  if (!state[weeklyWinnerWeekId]) return state;
  const updatedEntries = state[weeklyWinnerWeekId].entries.map((currentEntry) => {
    if (currentEntry.draftId !== draftId && currentEntry.id !== data.id) return currentEntry;

    return {
      ...currentEntry,
      ...data,
    };
  });

  return {
    ...state,
    [weeklyWinnerWeekId]: {
      ...state[weeklyWinnerWeekId],
      entries: updatedEntries,
    },
  };
};

const set = (
  state: WeeklyWinnerWeekEntries,
  {
    data,
    weeklyWinnerWeekId,
  }: { data: WeeklyWinnerWeekEntriesResponse; weeklyWinnerWeekId: string }
): WeeklyWinnerWeekEntries => {
  const newEntriesForWeeklyWinner =
    data.weekly_winner_week_entries &&
    data.weekly_winner_week_entries.map(weeklyWinnerWeekEntryAdapter);

  // combine with existing and remove duplicates
  const entries = state[weeklyWinnerWeekId]?.entries
    ? [...state[weeklyWinnerWeekId].entries, ...newEntriesForWeeklyWinner].filter(
        (entry, ind, arr) => arr.findIndex((e) => e.id === entry.id) === ind
      )
    : newEntriesForWeeklyWinner;

  return {
    ...state,
    [weeklyWinnerWeekId]: {
      entries,
      meta: data.meta,
    },
  };
};

export const userEntriesForWeeklyWinnerWeeksReducer = (
  state: State = initialState,
  action: EntriesForWeeklyWinnerWeeksActions = {} as EntriesForWeeklyWinnerWeeksActions
): State => {
  switch (action.type) {
    case SET_USER_ENTRIES_FOR_WEEKLY_WINNER_WEEK:
      return set(state, action.payload);
    case UPDATE_ENTRIES_FOR_WEEKLY_WINNER_WEEK:
      return update(state, action.payload);
    default:
      return state;
  }
};
