import { DraftingConfig, SetDraftingConfigResponse } from '@/interfaces/drafting-config';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import { SET_DRAFTING_CONFIG, setDraftingConfig } from '../actions';
import contestStyleAdapter from '../adapters/contest-style';
import entryStyleAdapter from '../adapters/entry-style';
import lineupStatusAdapter from '../adapters/lineup-status';
import scoringTypeAdapter from '../adapters/scoring-type';
import slotAdapter from '../adapters/slot';
import sportAdapter from '../adapters/sport';
import teamAdapter from '../adapters/team';

type State = DraftingConfig;

type DraftingConfigActions = ReturnType<typeof setDraftingConfig>;

const initialDraftingConfigState: DraftingConfig = {
  lastUpdated: null,
  contestStyles: null,
  entryStyles: null,
  lineupStatuses: null,
  scoringTypes: null,
  slots: null,
  sports: null,
  teams: null,
};

const set = (
  state: DraftingConfig,
  draftingConfigResponse: SetDraftingConfigResponse
): DraftingConfig => {
  const {
    contestStylesResponse,
    entryStylesResponse,
    lineupStatusesResponse,
    scoringTypesResponse,
    slotsResponse,
    sportsResponse,
    teamsResponse,
  } = draftingConfigResponse;

  // TODO [FAN-2426]: Heavily test all of this, because the entire drafting flow relies on it

  /*
   * Sports, contestStyles, correlations, entryStyles, slots and teams come back as an array of objects
   ************************************************************************
   */
  const contestStylesById = arrayToObjectIdKeys(
    // convert to object with ids as keys
    contestStylesResponse.contest_styles.map(
      (contestStyle) => contestStyleAdapter(contestStyle) // convert response shape to redux shape
    )
  );
  const entryStylesById = arrayToObjectIdKeys(
    // convert to object with ids as keys
    entryStylesResponse.entry_styles.map(
      (entryStyle) => entryStyleAdapter(entryStyle) // convert response shape to redux shape
    )
  );
  const lineupStatusesById = arrayToObjectIdKeys(
    // convert to object with ids as keys
    lineupStatusesResponse.lineup_statuses.map(
      (lineupStatus) => lineupStatusAdapter(lineupStatus) // convert response shape to redux shape
    )
  );
  const scoringTypesById = arrayToObjectIdKeys(
    // convert to object with ids as keys
    scoringTypesResponse.scoring_types.map(
      (scoringType) => scoringTypeAdapter(scoringType) // convert response shape to redux shape
    )
  );
  const slotsById = arrayToObjectIdKeys(
    // convert to object with ids as keys
    slotsResponse.slots.map(
      (slot) => slotAdapter(slot) // convert response shape to redux shape
    )
  );
  const sportsById = arrayToObjectIdKeys(
    // convert to object with ids as keys
    sportsResponse.sports.map(
      (sport) => sportAdapter(sport) // convert response shape to redux shape
    )
  );
  const teamsById = arrayToObjectIdKeys(
    // convert to object with ids as keys
    teamsResponse.teams.map(
      (team) => teamAdapter(team) // convert response shape to redux shape
    )
  );

  return {
    ...state,
    lastUpdated: new Date(),
    contestStyles: contestStylesById,
    entryStyles: entryStylesById,
    lineupStatuses: lineupStatusesById,
    scoringTypes: scoringTypesById,
    slots: slotsById,
    sports: sportsById,
    teams: teamsById,
  };
};

export const draftingConfigReducer = (
  state: State = initialDraftingConfigState,
  action: DraftingConfigActions = {} as DraftingConfigActions
): State => {
  switch (action.type) {
    case SET_DRAFTING_CONFIG:
      return set(state, action.payload);
    default:
      return state;
  }
};
