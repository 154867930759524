import { Entry } from 'contentful';

import { IStateConfigurationFields } from '@/interfaces/contentful';
import { StateConfiguration } from '@/interfaces/state-configuration';

const stateConfigurationAdapter = (
  stateConfiguration: Entry<IStateConfigurationFields>
): StateConfiguration => {
  const {
    rgAccountText,
    rgDraftText,
    rgLandingText,
    rgPickemText,
    sbAppDownloadCtaUrl,
    sbPreRegistrationCtaUrl,
    state,
  } = stateConfiguration.fields;
  return {
    rgAccountText,
    rgDraftText,
    rgLandingText,
    rgPickemText,
    sbAppDownloadCtaUrl,
    sbPreRegistrationCtaUrl,
    state,
  };
};

export default stateConfigurationAdapter;
