import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import { OAuthTokenResponse } from '@/interfaces/user';
import { AUTH0_SESSION_REFRESH } from '@/utilities/constants';

export const auth0Service = (() => {
  let accessToken: string | undefined;
  let accessTokenExpiration: string | undefined;
  let pendingRefresh: Promise<AxiosResponse<OAuthTokenResponse>> | undefined;

  const getAccessToken = (): string | undefined => accessToken;

  const setAccessToken = (token: string | undefined): void => {
    // this is a Bearer token, but auth0 doesn't require the prefix
    accessToken = token;
  };

  const isAccessTokenExpired = (): boolean => {
    // If there's no expiration date defined, we assume the token is expired.
    if (!accessTokenExpiration) {
      return true;
    }

    const currentDate = dayjs();
    const isTokenExpired = currentDate.isAfter(accessTokenExpiration);
    return isTokenExpired;
  };

  // `value` is the number of seconds to add to the current browser time
  const setAccessTokenExpiration = (value: number): void => {
    const currentDate = dayjs();
    const futureDate = currentDate.add(value, 'second');
    accessTokenExpiration = futureDate.format();
  };

  const removeRefreshToken = (): void => Cookies.remove(AUTH0_SESSION_REFRESH);

  const getRefreshToken = (): string | undefined => Cookies.get(AUTH0_SESSION_REFRESH);

  const setRefreshToken = (token: string): void => {
    Cookies.set(AUTH0_SESSION_REFRESH, token);
  };

  const getAuth = async (): Promise<string | undefined> => {
    const refreshToken = getRefreshToken();

    if (refreshToken) {
      // check if access token is still valid
      const isTokenExpired = auth0Service.isAccessTokenExpired();
      if (accessToken && !isTokenExpired) {
        return accessToken;
      }
      // otherwise fetch and replace it
      await refreshAccessToken();

      // caution: `accessToken` can still technically be `undefined` here,
      // there's no guarantee that `refreshAccessToken` will set an access token.
      return accessToken;
    }

    // unauthenticated
    return undefined;
  };

  const refreshAccessToken = async (): Promise<void> => {
    // pendingRefresh pauses all subsequent api requests until the new token exists
    if (pendingRefresh) {
      return;
    }

    // fetch new access token from auth0
    // failed requests to this auth0 api are handled in the axios error interceptor
    pendingRefresh = axios.request({
      method: 'POST',
      url: `${process.env.AUTH0_DOMAIN}/oauth/token`,
      data: {
        audience: process.env.AUTH0_AUDIENCE,
        client_id: process.env.AUTH0_CLIENT_ID,
        grant_type: 'refresh_token',
        refresh_token: getRefreshToken(),
        scope: 'offline_access',
      },
    });

    const response: AxiosResponse<OAuthTokenResponse> = await pendingRefresh;

    if (response?.data?.access_token) {
      setAccessToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);
      setAccessTokenExpiration(response.data.expires_in);
    }

    pendingRefresh = undefined;
  };

  const resetPendingRefresh = (): void => {
    pendingRefresh = undefined;
  };

  return {
    getAccessToken,
    getAuth,
    getRefreshToken,
    isAccessTokenExpired,
    removeRefreshToken,
    refreshAccessToken,
    resetPendingRefresh,
    setAccessToken,
    /**
     * Set the expiration time for the access token. The `value` provided
     * is the number of seconds to add to the current browser time.
     * @example
     * // set the token to expire 1 hour from now
     * setAccessTokenExpiration(3600);
     */
    setAccessTokenExpiration,
    setRefreshToken,
  };
})();
