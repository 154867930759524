/* eslint-disable camelcase */
import { draftEntryAdapter } from '@/store/modules/drafts/adapters/draft-entry';
import { pickAdapter } from '@/store/modules/drafts/adapters/pick';

import {
  UserDraftEntriesForDraftResponse,
  UserDraftEntriesForDrafts,
} from '@/interfaces/tournament-rounds';

import {
  SET_USER_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT,
  setUserPaginatedDraftEntriesForDraft,
} from '../actions';

type State = UserDraftEntriesForDrafts | null;

type UserPaginatedDraftEntriesForDraftActions = ReturnType<
  typeof setUserPaginatedDraftEntriesForDraft
>;

const initialState: UserDraftEntriesForDrafts = {};

const set = (
  state: UserDraftEntriesForDrafts,
  { data, draftId }: { data: UserDraftEntriesForDraftResponse; draftId: string }
): UserDraftEntriesForDrafts => {
  const newDraftEntries = data.draft_entries.map(draftEntryAdapter);
  const newPicks = data.picks.map(pickAdapter);

  const draftEntries = state[draftId]?.draftEntries
    ? [...state[draftId].draftEntries, ...newDraftEntries].filter(
        (draftEntry, ind, arr) => arr.findIndex((d) => d.id === draftEntry.id) === ind
      )
    : newDraftEntries;

  const picks = state[draftId]?.picks
    ? [...state[draftId].picks, ...newPicks].filter(
        (pick, ind, arr) => arr.findIndex((p) => p.id === pick.id) === ind
      )
    : newPicks;

  return {
    ...state,
    [draftId]: {
      draftEntries,
      picks,
      meta: data.meta,
    },
  };
};

export const userPaginatedDraftEntriesForDraftReducer = (
  state: State = initialState,
  action: UserPaginatedDraftEntriesForDraftActions = {} as UserPaginatedDraftEntriesForDraftActions
): State => {
  switch (action.type) {
    case SET_USER_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT:
      return set(state, action.payload);
    default:
      return state;
  }
};
