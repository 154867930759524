import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import clsx from 'clsx';

import { RootState } from '@/store';

import { ampli } from '@/ampli';
import Icon from '@/components/atoms/icon';
import LinkWrapper from '@/components/atoms/link-wrapper';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import NavLinkWrapper from '@/components/atoms/nav-link-wrapper';
import SignUpButton from '@/components/atoms/sign-up-button';
import { ThemeContext } from '@/components/contexts/theme';
import LoginModal from '@/components/molecules/login-modal';
import RegisterModal from '@/components/molecules/register-modal';
import { FeatureFlags } from '@/interfaces/feature-flags';
import { User } from '@/interfaces/user';
import { ALTERNATIVE_HOME_ROUTE, DEFAULT_ROUTE } from '@/utilities/constants';
import useIsPoolsEnabled from '@/utilities/hooks/use-is-pools-enabled';
import langHelper from '@/utilities/lang-helper';

import InventoryButton from '../inventory-button';

import styles from './styles.scss';

export interface NavProps {
  isAlternateHomeRoute: FeatureFlags['lobbySwitch'];
  user: User;
}

const Nav = (props: NavProps): JSX.Element => {
  const { isAlternateHomeRoute, user } = props;
  const theme = useContext(ThemeContext);
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const location = useLocation();
  const openModal = useModal();
  // `isMobileWidth` refers to  whether the browser window is narrow
  const maxWidth = 1150;
  const isMobileWidth = useMediaQuery({ maxWidth });
  const isPoolsEnabled = useIsPoolsEnabled();

  const onToggleThemeClick = () => {
    theme.toggle();
  };

  const handleCloseNav = () => {
    setShowMobileNav(false);
  };

  const openLoginModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <LoginModal handleCloseModal={handleCloseModal} />
    ));
  };

  const openRegisterModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    ampli.registrationSignUpButtonPressed();
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <RegisterModal handleCloseModal={handleCloseModal} />
    ));
  };

  const draftIsActive =
    location.pathname.includes('/lobby') ||
    location.pathname.includes('/active') ||
    location.pathname.includes('/completed');

  return (
    <nav className={styles.nav} id="nav">
      {user.id &&
        (isMobileWidth && !showMobileNav ? (
          <div className={styles.showNavWrapper}>
            <button
              className={styles.showNavButton}
              onClick={() => setShowMobileNav(true)}
              aria-label="Show navigation"
            >
              <Icon className={styles.showNavIcon} name="menu" />
            </button>
          </div>
        ) : (
          <ClickAwayListener onClickAway={handleCloseNav}>
            <div className={clsx(styles.leftLinks, { [styles.mobileNav]: isMobileWidth })}>
              {isAlternateHomeRoute && (
                <NavLinkWrapper
                  to="/pick-em"
                  classNames={{
                    activeNavLink: styles.active,
                    navLink: styles.link,
                  }}
                  onClick={handleCloseNav}
                  element={isPoolsEnabled ? 'Champions' : "Pick'em"}
                />
              )}
              <NavLinkWrapper
                to="/lobby"
                classNames={{
                  activeNavLink: '', // because of url structure, active class is on navLink
                  navLink: clsx(styles.link, { [styles.active]: draftIsActive }),
                }}
                onClick={() => {
                  handleCloseNav();
                  ampli.draftLobbyScreenOpened();
                }}
                element="Drafts"
              />
              {!isAlternateHomeRoute && (
                <NavLinkWrapper
                  to="/pick-em"
                  classNames={{
                    activeNavLink: styles.active,
                    navLink: styles.link,
                  }}
                  onClick={handleCloseNav}
                  element="Pick'em"
                />
              )}
              <NavLinkWrapper
                to="/live"
                classNames={{
                  activeNavLink: styles.active,
                  navLink: styles.link,
                }}
                onClick={handleCloseNav}
                element="Live"
              />
              <NavLinkWrapper
                to="/results"
                classNames={{
                  activeNavLink: styles.active,
                  navLink: styles.link,
                }}
                onClick={handleCloseNav}
                element="Results"
              />
              <NavLinkWrapper
                to="/rankings"
                classNames={{
                  activeNavLink: styles.active,
                  navLink: styles.link,
                }}
                onClick={handleCloseNav}
                element="Rankings"
              />
              <NavLinkWrapper
                to="/news-feed"
                classNames={{
                  activeNavLink: styles.active,
                  navLink: styles.link,
                }}
                onClick={handleCloseNav}
                element="News feed"
              />
            </div>
          </ClickAwayListener>
        ))}
      <NavLinkWrapper
        to={isAlternateHomeRoute ? ALTERNATIVE_HOME_ROUTE : DEFAULT_ROUTE}
        end
        classNames={{
          activeNavLink: '',
          navLink: clsx(styles.homeLink, { [styles.leftHomeLink]: isMobileWidth || !user.id }),
        }}
        ariaLabel="home"
        element={<Icon name="logo" className={styles.icon} />}
      />

      <div
        className={clsx(styles.rightLinks, {
          [styles.mobileRightLinks]: isMobileWidth || !user.id,
        })}
      >
        <button
          title="Toggle light and dark mode"
          onClick={onToggleThemeClick}
          className={styles.themeButton}
          aria-label={theme.theme === 'dark' ? 'Toggle to light mode' : 'Toggle to dark mode'}
        >
          <Icon
            name={theme.theme === 'dark' ? 'darkMode' : 'lightMode'}
            className={styles.themeIcon}
          />
        </button>
        {user.id ? (
          <>
            <div className={styles.inventoryButtonWrapper}>
              <InventoryButton
                iconName="gift"
                location="lobby gift box"
                size="small"
                text={null}
                user={user}
              />
            </div>
            <LinkWrapper
              className={styles.balance}
              onClick={() => {
                ampli.paymentsDepositBalanceClicked({
                  balance_amount: parseFloat(user.wallet.balance),
                });
              }}
              testId="deposit-balance-link"
              to="/deposit"
            >
              <div className={styles.depositContainer}>
                <div className={styles.depositCashContainer}>
                  {`$${langHelper.formatCash(user.wallet.balance)}`}
                </div>
                <div className={styles.depositIconContainer}>
                  <Icon name="plusSign" className={styles.depositIcon} />
                </div>
              </div>
            </LinkWrapper>
            <NavLinkWrapper
              to="/account"
              classNames={{
                activeNavLink: styles.active,
                navLink: styles.accountLink,
              }}
              element="Account"
              testId="account-link"
            />
          </>
        ) : (
          <>
            <button className={styles.loginButton} onClick={(e) => openLoginModal(e)}>
              Log in
            </button>
            <SignUpButton
              classNames={{
                button: styles.signUpButton,
              }}
              eventType="initiate"
              onClick={(e) => openRegisterModal(e)}
              type="solid"
              size="small"
              width="intrinsic"
            />
          </>
        )}
      </div>
    </nav>
  );
};

export default connect((state: RootState) => ({
  isAlternateHomeRoute: state.featureFlags.lobbySwitch,
  user: state.user,
}))(Nav);
