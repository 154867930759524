// eslint-disable-next-line import/no-extraneous-dependencies
import { BLOCKS } from '@contentful/rich-text-types';

import { AllStateConfigurations } from '@/interfaces/state-configuration';

// Formatted to match the API response from Contentful for rich text
export const fallbackStateConfiguration = {
  DEFAULT: {
    rgAccountText: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Concerned with your play? Call 1-800-GAMBLER or visit ',
              nodeType: 'text',
            },
            {
              data: {
                uri: 'https://www.ncpgambling.org',
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'www.ncpgambling.org',
                  nodeType: 'text',
                },
              ],
              nodeType: 'hyperlink',
            },
            {
              data: {},
              marks: [],
              value:
                '; NY: Call the 24/7 HOPEline at 1-877-8-HOPENY or Text HOPENY (467369) Learn more about Underdog Contests and how to identify highly experienced players at ',
              nodeType: 'text',
            },
            {
              data: {
                uri: 'https://www.underdogfantasy.com/rules',
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'www.underdogfantasy.com/rules',
                  nodeType: 'text',
                },
              ],
              nodeType: 'hyperlink',
            },
            {
              data: {},
              marks: [],
              value: '; Learn more about average results at ',
              nodeType: 'text',
            },
            {
              data: {
                uri: 'https://www.underdogfantasy.com/average-results',
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'www.underdogfantasy.com/average-results',
                  nodeType: 'text',
                },
              ],
              nodeType: 'hyperlink',
            },
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: BLOCKS.DOCUMENT,
    },
    rgDraftText: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Concerned with your play? Call 1-800-GAMBLER or visit ',
              nodeType: 'text',
            },
            {
              data: {
                uri: 'https://www.ncpgambling.org',
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'www.ncpgambling.org',
                  nodeType: 'text',
                },
              ],
              nodeType: 'hyperlink',
            },
            {
              data: {},
              marks: [],
              value:
                '; NY: Call the 24/7 HOPEline at 1-877-8-HOPENY or Text HOPENY (467369) Learn more about Underdog Contests and how to identify highly experienced players at ',
              nodeType: 'text',
            },
            {
              data: {
                uri: 'https://www.underdogfantasy.com/rules',
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'www.underdogfantasy.com/rules',
                  nodeType: 'text',
                },
              ],
              nodeType: 'hyperlink',
            },
            {
              data: {},
              marks: [],
              value: '; Learn more about average results at ',
              nodeType: 'text',
            },
            {
              data: {
                uri: 'https://www.underdogfantasy.com/average-results',
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'www.underdogfantasy.com/average-results',
                  nodeType: 'text',
                },
              ],
              nodeType: 'hyperlink',
            },
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: BLOCKS.DOCUMENT,
    },
    rgLandingText: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Concerned with your play? Call 1-800-GAMBLER or visit ',
              nodeType: 'text',
            },
            {
              data: {
                uri: 'https://www.ncpgambling.org',
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'www.ncpgambling.org',
                  nodeType: 'text',
                },
              ],
              nodeType: 'hyperlink',
            },
            {
              data: {},
              marks: [],
              value:
                '; NY: Call the 24/7 HOPEline at 1-877-8-HOPENY or Text HOPENY (467369) Learn more about Underdog Contests and how to identify highly experienced players at ',
              nodeType: 'text',
            },
            {
              data: {
                uri: 'https://www.underdogfantasy.com/rules',
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'www.underdogfantasy.com/rules',
                  nodeType: 'text',
                },
              ],
              nodeType: 'hyperlink',
            },
            {
              data: {},
              marks: [],
              value: '; Learn more about average results at ',
              nodeType: 'text',
            },
            {
              data: {
                uri: 'https://www.underdogfantasy.com/average-results',
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'www.underdogfantasy.com/average-results',
                  nodeType: 'text',
                },
              ],
              nodeType: 'hyperlink',
            },
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: BLOCKS.DOCUMENT,
    },
    rgPickemText: {
      nodeType: BLOCKS.DOCUMENT,
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Concerned with your play? Call 1-800-GAMBLER or visit ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'hyperlink',
              data: {
                uri: 'https://www.ncpgambling.org',
              },
              content: [
                {
                  nodeType: 'text',
                  value: 'www.ncpgambling.org',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'text',
              value:
                '; NY: Call the 24/7 HOPEline at 1-877-8-HOPENY or Text HOPENY (467369) Learn more about Underdog Contests and how to identify highly experienced players at ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'hyperlink',
              data: {
                uri: 'https://www.underdogfantasy.com/rules',
              },
              content: [
                {
                  nodeType: 'text',
                  value: 'www.underdogfantasy.com/rules',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'text',
              value: '; Learn more about average results at ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'hyperlink',
              data: {
                uri: 'https://www.underdogfantasy.com/average-results',
              },
              content: [
                {
                  nodeType: 'text',
                  value: 'www.underdogfantasy.com/average-results',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'text',
              value: '',
              marks: [],
              data: {},
            },
          ],
        },
      ],
    },
    sbAppDownloadCtaUrl: 'https://play.underdogsports.com',
    sbPreRegistrationCtaUrl: 'https://app.staging.underdogsports.com/login',
    state: 'DEFAULT',
  },
} as AllStateConfigurations;
