import React, { useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { numberToTime, NumberToTimeOptions } from '@/utilities/helpers';
import useInterval from '@/utilities/hooks/use-interval';

import styles from './styles.scss';

dayjs.extend(duration);

export const useTimer = (endTime: string) => {
  const [time, setTime] = useState<number>(0);
  useInterval(() => {
    const timeDiff = dayjs.duration(dayjs(endTime).diff(dayjs())).asSeconds();
    const displayTime = timeDiff > 0 ? Math.round(timeDiff) : 0;

    setTime(displayTime);
  }, 500);
  return time;
};

export interface TimerProps {
  className?: string;
  endTime: string;
  options?: NumberToTimeOptions;
}

// I think this is going to want to take in a time to countdown from,
// and an event for when to start again
// it will also want to be able to trigger something when the time is 0
// it won't be the concern of this to work out what to trigger

const Timer = (props: TimerProps) => {
  const { className, endTime, options } = props;

  const time = useTimer(endTime);

  const wrapperClasses = clsx(styles.timer, className);
  return (
    <div className={wrapperClasses}>
      <span data-testid="test-timer">{numberToTime(time, options)}</span>
    </div>
  );
};

export default Timer;
