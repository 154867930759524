import React, {
  ComponentType,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import { RootState } from '@/store';
import { setError as setErrorAction } from '@/store/modules/error/actions';
import { setFeatureFlags as setFeatureFlagsAction } from '@/store/modules/feature-flags/actions';
import { setUser as setUserAction } from '@/store/modules/user/actions';

import { ampli, RegistrationReferralCodeEnteredSuccessfulProperties } from '@/ampli';
import * as featuresApi from '@/api/feature-flags';
import { clientType, clientVersion, getDeviceId, removeAuth, removeReferral } from '@/api/request';
import * as api from '@/api/users';
import Loader from '@/components/atoms/loader';
import { unsubscribeToPrivateChannels } from '@/components/contexts/pusher-events';
import { StateConfigResponse } from '@/interfaces/drafting-config';
import { AppErrorRedux } from '@/interfaces/error';
import { FeatureData, FeatureResponse } from '@/interfaces/feature-flags';
import {
  User,
  UserBonusWalletResponse,
  UserLoginRequest,
  UserProfileResponse,
  UserRegistrationRequest,
  UserResponse,
} from '@/interfaces/user';
import { batchAmplitudeEvents } from '@/utilities/amplitude';
import { AUTH0_SESSION_REFRESH, UD_THEME } from '@/utilities/constants';
import { AppError } from '@/utilities/errors';
import { ERROR_MESSAGES, ERROR_NAMES } from '@/utilities/errors/constants';
import errorLogger from '@/utilities/errors/logger';
import useQuery from '@/utilities/hooks/use-query';
import { logoutLocation } from '@/utilities/location';

import { auth0Service } from './auth0-service';

import styles from './styles.scss';

export interface AuthContextProps {
  loginUser: (args: UserLoginRequest) => Promise<void>;
  registerUser: ({
    ampliPromotionType,
    promoCode,
    userRegistrationObject,
  }: {
    ampliPromotionType: RegistrationReferralCodeEnteredSuccessfulProperties['promotion_type'];
    promoCode: string;
    userRegistrationObject: UserRegistrationRequest;
  }) => Promise<void>;
  logoutUser: () => Promise<void>;
  isLoading: boolean;
  isAuthenticated: boolean;
  isAlternateHomeRoute: boolean;
}

export const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
  const context = useContext<AuthContextProps | undefined>(AuthContext);

  if (!context) {
    throw new Error(`AuthProvider context is undefined,
    please verify you are calling useAuth()
    as child of a <AuthProvider> component.`);
  }

  return context;
};

export function withAuth<P>(
  Component: React.ComponentType<P>
): ComponentType<Omit<P, keyof AuthContextProps>> {
  return (props) => {
    const auth = useAuth();

    if (auth.isLoading) {
      return <Loader className={styles.loader} />;
    }

    return <Component {...(props as P)} {...auth} />;
  };
}

export const deviceMetadata: {
  clientVersion: string | number;
  client: string;
  deviceId: string;
  latitude?: string;
  longitude?: string;
} = {
  clientVersion,
  client: clientType,
  deviceId: getDeviceId(),
};

const AuthProviderEntity = ({
  children,
  user,
  setError,
  setFeatureFlags,
  setUser,
}: PropsWithChildren<{
  setError: typeof setErrorAction;
  setFeatureFlags: typeof setFeatureFlagsAction;
  setUser: typeof setUserAction;
  user?: User;
}>) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isAlternateHomeRoute, setIsAlternateHomeRoute] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobilePage = /^\/m\//.test(location.pathname);
  const queryParams = useQuery();
  // API errors may include a "cta url" that can be used to direct users to a specific URL
  // in the event of an error. One such URL is: `/login?force=true`
  // When the `force` query param is present and `true`, we need to logout the user
  // and redirect them to the login page.
  const forceLogout = queryParams.get('force') === 'true';

  if (forceLogout) {
    // When force logout is true, we logout the user without updating local state.
    // Then invoke a full page navigation to `/login` instead of just using `navigate()`,
    // which only updates the browser history. This helps ensure that we reset app state
    // after logging out, and avoid any potential issues with infinite redirects.
    // Also be mindful that we render a loader while `forceLogout` is true, so the app
    // doesn't try to render in an inaccurate state.
    // Note: in general, redirecting from within the render path is not a recommended pattern.
    // Ideally, we'd handle this force logout scenario _before_ the app is rendered, but
    // that requires extracting most of the code in `AuthProviderEntity` into a separate
    // service/module, which has many downstream implications in the app.
    // For now, this is the simplest solution.
    logoutUserInternal(user?.id).then(() => {
      window.location.href = '/login';
    });
  }

  const handleWebLobbySwitch = useCallback((data: FeatureResponse) => {
    const webLobbySwitch = data?.features.find(
      (feature: FeatureData) => feature.key === 'lobby_switch'
    )?.status;
    setIsAlternateHomeRoute(webLobbySwitch);
  }, []);

  const logoutUser = useCallback(async (): Promise<void> => {
    await logoutUserInternal(user?.id);
    setIsAuthenticated(false);
    setUser(undefined);
    setIsLoading(false);
  }, [setUser, user?.id]);

  const getUser = useCallback(async () => {
    if (!user.id) {
      try {
        const userData = await api.getUserData();
        const userFeaturesData = await featuresApi.getUserFeatures();
        const bonusWalletResponse = await api.getUserBonusWallet();

        handleWebLobbySwitch(userFeaturesData.data);
        setFeatureFlags(userFeaturesData.data);
        setUser({ ...userData, bonusWalletData: bonusWalletResponse.data });
        setIsLoading(false);
        setIsAuthenticated(true);
      } catch (err) {
        setError({
          ...err,
          type: 'toast',
        });
      }
    }
    return null;
  }, [handleWebLobbySwitch, setError, setFeatureFlags, setUser, user.id]);

  const loginUser = useCallback(
    async (userLoginObject: UserLoginRequest): Promise<void> => {
      let userData: UserResponse;
      let profileResponse: UserProfileResponse;

      try {
        await api.loginUserAuth0(userLoginObject);
        const userDataResponse = await api.getUserData();
        userData = userDataResponse.data;
        profileResponse = userDataResponse.profileData;
        const featuresResponse = await featuresApi.getUserFeatures();
        const bonusWalletResponse = await api.getUserBonusWallet();

        handleWebLobbySwitch(featuresResponse.data);
        setFeatureFlags(featuresResponse.data);
        setUser({
          data: userData,
          bonusWalletData: bonusWalletResponse.data,
          profileData: profileResponse,
        });
        setIsAuthenticated(true);
        if (window.Intercom) {
          window.Intercom('boot', {
            app_id: process.env.INTERCOM_APP_ID,
          });
        }
      } catch (e) {
        if (e?.status === 422) {
          setError({ ...e, type: 'modal' });
        } else {
          setError({ ...e, type: 'toast' });
        }
      }
    },
    [handleWebLobbySwitch, setError, setFeatureFlags, setUser]
  );

  const registerUser = useCallback(
    async ({
      ampliPromotionType,
      promoCode,
      userRegistrationObject,
    }: {
      ampliPromotionType: RegistrationReferralCodeEnteredSuccessfulProperties['promotion_type'];
      promoCode: string;
      userRegistrationObject: UserRegistrationRequest;
    }): Promise<void> => {
      let userData: UserResponse;
      let profileData: UserProfileResponse;

      let auth0RegistrationResponse;
      try {
        // auth0 - register, login, fetch user, fetch user profile
        auth0RegistrationResponse = await api.registerUserAuth0(userRegistrationObject);
        await api.loginUserAuth0({
          user: {
            email: userRegistrationObject.user.email,
            password: userRegistrationObject.user.password,
            birthdate: userRegistrationObject.user.birthdate,
          },
        });

        const userDataResponse = await api.getUserData();
        userData = userDataResponse.data;
        profileData = userDataResponse.profileData;
        const bonusWalletResponse = await api.getUserBonusWallet();

        setUser({
          data: userData,
          profileData,
          newRegistration: true,
          bonusWalletData: bonusWalletResponse.data,
        });

        const featuresResponse = await featuresApi.getUserFeatures();
        const featuresData: FeatureResponse = featuresResponse.data;
        handleWebLobbySwitch(featuresResponse.data);
        setFeatureFlags(featuresData);

        setIsAuthenticated(true);

        ampli.registrationBirthdayEnteredSuccessful();
        ampli.registrationPasswordEnteredSuccessful();
        ampli.registrationEmailEnteredSuccessful();
        ampli.registrationUsernameEnteredSuccessful();
        ampli.registrationReferralCodeEnteredSuccessful({
          promo_code: promoCode,
          promotion_type: ampliPromotionType,
        });
      } catch (e) {
        if (auth0RegistrationResponse) {
          // if auth0 sign up is successful, but login fails, show user custom error msg
          // and bring them to the login screen
          const errorMessage = 'Your account was successfully created. Please try logging in.';
          setError({
            message: errorMessage,
            type: 'toast',
          });
          navigate('/login');
          return;
        }
        setError({ ...e, type: 'toast' });
      }
    },
    [setUser, handleWebLobbySwitch, navigate, setFeatureFlags, setError]
  );

  const errInterceptor = async (axiosError: AxiosError<any, any>) => {
    const isUnauthorized = axiosError?.response?.status === 401;
    const signOutRoute = axiosError?.config?.url?.includes('/sign_out'); // devise

    // check if error is a failed refresh token request (403, auth0)
    const refreshTokenFailed =
      JSON.parse(axiosError?.config?.data || '{}').grant_type === 'refresh_token';

    if (refreshTokenFailed) {
      // there's an edge case where auth0 might be enabled, but the user has a devise cookie
      // so we need to set auth0 to true before logging them out
      await logoutUser();
      setError({
        message: ERROR_MESSAGES.UNAUTHORIZED_ERROR,
        type: 'toast',
      });
      return Promise.reject(
        new AppError({
          ...axiosError,
          message: ERROR_MESSAGES.UNAUTHORIZED_ERROR,
          title: 'Something went wrong',
        })
      );
    }

    // mobile apps will send a fresh access token before opening web views
    // the token is stored as session. web should never refresh it.
    // web should catch the 401 and direct the user back to the app
    if (isMobilePage && isUnauthorized) {
      errorLogger(false, ERROR_MESSAGES.UNAUTHORIZED_ERROR_MOBILE, {
        url: axiosError.config.url,
      });
      return Promise.reject(
        new AppError({
          ...axiosError,
          status: 401,
          title: 'Something went wrong',
          message: ERROR_MESSAGES.UNAUTHORIZED_ERROR_MOBILE,
        })
      );
    }

    // try to get a new access token, then retry the original request
    // log user out if the original request fails
    // failed refresh token requests are caught in the block above
    if (isUnauthorized) {
      await auth0Service.refreshAccessToken();
      const accessToken = auth0Service.getAccessToken();
      if (accessToken) {
        // this is clean up for a bug introduced in FANGROWTH-871 -it removes the access token from cookies
        // lines 345-347 can be deleted after any current refresh tokens have definitively expired (6/1/2024)
        Cookies.remove('session');

        // retry logic
        const originalRequest = axiosError.config;
        originalRequest.headers.Authorization = accessToken;
        const originalRequestResponse = await axios(originalRequest);
        if (originalRequestResponse.status !== 401) {
          return Promise.resolve(originalRequestResponse);
        }
        await logoutUser();
        // we can't assume that all of our API calls are handled by a toast message
        // so we need to set this error AND bubble the message up to the original request
        setError({
          message: ERROR_MESSAGES.UNAUTHORIZED_ERROR,
          type: 'toast',
        });
        return Promise.reject(ERROR_MESSAGES.UNAUTHORIZED_ERROR);
      }
    }

    if (signOutRoute) {
      // if a devise token is invalid or deleted, the sign_out api request will send a 403 or 500
      // we need to handle this error and show the user the correct toast message
      return Promise.reject(ERROR_MESSAGES.UNAUTHORIZED_ERROR);
    }

    // handle auth0 sign up error common password
    if (axiosError?.response?.data?.name === ERROR_NAMES.PASSWORD_DICTIONARY_ERROR) {
      return Promise.reject(
        new AppError({
          ...axiosError,
          message: ERROR_MESSAGES.DICTIONARY_ERROR,
        })
      );
    }
    // handle auth0 error invalid password
    if (axiosError?.response?.data?.name === ERROR_NAMES.PASSWORD_NO_USER_INFO_ERROR) {
      return Promise.reject(
        new AppError({
          ...axiosError,
          message: ERROR_MESSAGES.NO_USER_INFO_ERROR,
        })
      );
    }

    // handle auth0 errors, eg. invalid password on sign in
    if (axiosError?.response?.data?.description) {
      return Promise.reject(
        new AppError({
          ...axiosError,
          message:
            axiosError.response.data.friendly_message || axiosError.response.data.description,
        })
      );
    }

    // handle fantasy api errors
    return Promise.reject(axiosError);
  };

  const interceptor = axios.interceptors.response.use(undefined, errInterceptor);
  useEffect(() => {
    return () => axios.interceptors.response.eject(interceptor);
  });

  useEffect(() => {
    if (isLoading) {
      // continue supporting devise token, mobile auth0 token is stored as session too
      const deviseOrMobileAccessToken = Cookies.get('session') || auth0Service.getAccessToken();
      const refreshToken = auth0Service.getRefreshToken();

      if (deviseOrMobileAccessToken || refreshToken) {
        // try to log user in
        getUser();
      } else {
        // show unauthenticated state
        setIsLoading(false);
      }
    }
  }, [getUser, isLoading, setError]);

  const values = useMemo(
    () => ({
      isAuthenticated,
      isAlternateHomeRoute,
      loginUser,
      registerUser,
      logoutUser,
      isLoading,
    }),
    [isAuthenticated, isAlternateHomeRoute, loginUser, registerUser, logoutUser, isLoading]
  );

  return (
    <AuthContext.Provider value={values}>
      {/*
        By showing the loader while auth is loading/resolving, we can prevent the UI
        from "flashing" between unauthenticated and authenticated states. This can
        save us from a lot of conditional code within components and UI shifting.
      */}
      {forceLogout || isLoading ? <Loader className={styles.loader} /> : children}
    </AuthContext.Provider>
  );
};

export const AuthProvider = connect(
  (state: RootState) => ({
    user: state.user,
  }),
  (dispatch) => ({
    setError: (payload: AppErrorRedux) => dispatch(setErrorAction(payload)),
    setFeatureFlags: (payload: FeatureResponse) => dispatch(setFeatureFlagsAction(payload)),
    setUser: (payload: {
      data: UserResponse;
      bonusWalletData: UserBonusWalletResponse;
      newRegistration?: true;
      profileData: UserProfileResponse;
      stateConfigData: StateConfigResponse;
    }) => dispatch(setUserAction(payload)),
  })
)(AuthProviderEntity);

function clearStorage() {
  const underdogTheme = localStorage.getItem(UD_THEME);
  localStorage.clear();
  localStorage.setItem(UD_THEME, underdogTheme);
  sessionStorage.clear();
}

let isLoggingOut = false;
/**
 * This function should be used to log out the user without triggering
 * any redux state changes or redirects.
 */
async function logoutUserInternal(userId: string | undefined) {
  // We want to prevent multiple (inadvertent) logout calls from
  // occurring simultaneously.
  if (isLoggingOut) {
    return;
  }

  isLoggingOut = true;

  removeReferral();
  logoutLocation();

  // batches all unsent events to Amplitude.
  batchAmplitudeEvents();

  datadogRum.clearUser();
  if (userId) {
    unsubscribeToPrivateChannels(userId);
  }

  try {
    // if user has a devise token, try to invalidate it
    if (!Cookies.get(AUTH0_SESSION_REFRESH)) {
      await api.logoutUser();
    }
  } finally {
    isLoggingOut = false;
    clearStorage();
    removeAuth();
    window.Intercom('shutdown');
  }
}
