import { EntryCollection } from 'contentful';

import { ISweepstakeTermsAndConditionsFields } from '@/interfaces/contentful';

export const GET_SWEEPSTAKE_TS_AND_CS = 'rules/GET_SWEEPSTAKE_TS_AND_CS' as const;
export const SET_SWEEPSTAKE_TS_AND_CS = 'rules/SET_SWEEPSTAKE_TS_AND_CS' as const;

export const GET_ALL_SWEEPSTAKES_TS_AND_CS = 'rules/GET_ALL_SWEEPSTAKES_TS_AND_CS' as const;
export const SET_ALL_SWEEPSTAKES_TS_AND_CS = 'rules/SET_ALL_SWEEPSTAKES_TS_AND_CS' as const;

export const getSweepstakeTsAndCs = (payload: { slug: string }) => ({
  type: GET_SWEEPSTAKE_TS_AND_CS,
  payload,
});

export const setSweepstakeTsAndCs = (payload: {
  sweepstakesTermsAndConditions: EntryCollection<ISweepstakeTermsAndConditionsFields>;
  slug: string;
}) => ({ type: SET_SWEEPSTAKE_TS_AND_CS, payload });

export const getAllSweepstakesTsAndCs = () => ({ type: GET_ALL_SWEEPSTAKES_TS_AND_CS });

export const setAllSweepstakesTsAndCs = (payload: {
  sweepstakesTermsAndConditions: EntryCollection<ISweepstakeTermsAndConditionsFields>;
}) => ({ type: SET_ALL_SWEEPSTAKES_TS_AND_CS, payload });
