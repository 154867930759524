import { EntryCollection } from 'contentful';

import { IPromoInfoFields } from '@/interfaces/contentful';
import { ContentfulPromoInfo } from '@/interfaces/contentful-promo-info';

export const contentfulPromoInfoAdapter = (
  modal: EntryCollection<IPromoInfoFields>
): ContentfulPromoInfo => {
  const { active, content, contentfulInfoId, ctaPath, ctaText, headerImage, title, usersList } =
    modal.items[0].fields; // always an array of one bc contentfulModalId is unique

  const adaptedHeaderImage = {
    title: headerImage.fields.title,
    url: headerImage.fields.file.url,
  };

  return {
    active,
    content,
    contentfulInfoId,
    ctaPath,
    ctaText,
    headerImage: adaptedHeaderImage,
    title,
    usersList,
  };
};
