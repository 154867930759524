/* eslint-disable camelcase */
import {
  DisplayStat,
  DisplayStatResponse,
  ScoringType,
  ScoringTypeResponse,
} from '@/interfaces/drafting-config';

export const displayStatAdapter = (displayStat: DisplayStatResponse): DisplayStat => {
  const { display_group, stats } = displayStat;

  return {
    displayGroup: display_group,
    stats, // for now, stats is a shape that works on the front end and doesn't need an adapter
  };
};

const scoringTypesAdapter = (scoringType: ScoringTypeResponse): ScoringType => {
  const { id, display_stats, sport_id, title } = scoringType;

  const displayStats = display_stats.map((displayStat) => displayStatAdapter(displayStat));

  return {
    id,
    displayStats,
    sportId: sport_id,
    title,
  };
};

export default scoringTypesAdapter;
