import { AxiosResponse } from 'axios';

import request from '@request';
import {
  DraftPoolEntriesResponse,
  DraftPoolLeaderboardResponse,
  DraftPoolResponse,
  DraftPoolsForSlateResponse,
  SingleDraftPoolEntryResponse,
} from '@/interfaces/draft-pools';
import { DraftResponse, UnfilledDraftsResponse } from '@/interfaces/drafts';
import { UserLeaderboardMarkersResponse } from '@/interfaces/leaderboard';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getDraftPool = async ({
  draftPoolId,
  draftId,
}: {
  draftPoolId?: string;
  draftId?: string;
}): Promise<AxiosResponse<DraftPoolResponse>> => {
  const url = draftPoolId ? `/draft_pools/${draftPoolId}` : `/drafts/${draftId}/draft_pool`;
  try {
    const response = await request({
      method: 'GET',
      url,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getDraftPoolEntry = async ({
  draftPoolEntryId,
}: {
  draftPoolEntryId: string;
}): Promise<AxiosResponse<SingleDraftPoolEntryResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/draft_pool_entries/${draftPoolEntryId}`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUnfilledDraftPool = async ({
  draftPoolId,
}: {
  draftPoolId?: string;
}): Promise<AxiosResponse<UnfilledDraftsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/draft_pools/${draftPoolId}/unfilled_drafts`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const enterDraftPool = async ({
  draftPoolId,
  confirmed,
  draft,
  entryCount,
}: {
  draftPoolId: string;
  confirmed?: boolean;
  draft: { clock: number };
  entryCount: string;
}): Promise<AxiosResponse<DraftResponse>> => {
  try {
    const response = await request({
      method: 'POST',
      url: `/draft_pools/${draftPoolId}/entries`,
      requiresGeoComply: true,
      params: {
        ...(confirmed ? { confirmed: true } : {}),
      },
      data: {
        draft,
        entry_count: entryCount,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUserDraftPoolsBySlate = async ({
  slateId,
}: {
  slateId: string;
}): Promise<AxiosResponse<DraftPoolsForSlateResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/slates/${slateId}/draft_pools`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUserEntriesForDraftPool = async ({
  draftPoolId,
  page,
}: {
  draftPoolId: string;
  page?: number;
}): Promise<AxiosResponse<DraftPoolEntriesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/draft_pools/${draftPoolId}/entries`,
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getLeaderboardForDraftPool = async ({
  draftPoolId,
  page,
}: {
  draftPoolId: string;
  page?: number;
}): Promise<AxiosResponse<DraftPoolLeaderboardResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/draft_pools/${draftPoolId}/leaderboard`,
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUserLeaderboardForDraftPool = async ({
  draftPoolId,
}: {
  draftPoolId: string;
}): Promise<AxiosResponse<UserLeaderboardMarkersResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/draft_pools/${draftPoolId}/leaderboard`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
