import { Appearance, Player } from '@/interfaces/constructed-interfaces/constructed-slates';
import { StateConfig } from '@/interfaces/drafting-config';
import { Match } from '@/interfaces/matches';
import { OverUnderLine, SoloGame } from '@/interfaces/pick-em';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

export const getLinesByStateConfig = (
  overUnderLines: OverUnderLine[],
  pickEmStateConfig: StateConfig['pickEm'],
  isAdmin: boolean
) => {
  return overUnderLines.filter((line) => {
    if (isAdmin) return true;
    if (!pickEmStateConfig.visibleStats) return true;
    if (
      !pickEmStateConfig.payoutModifier &&
      line.options.some((option) => option.payoutMultiplier !== 1)
    ) {
      return false;
    }
    return pickEmStateConfig.visibleStats.includes(line?.overUnder.appearanceStat.stat);
  });
};

export const removeDuplicateAppearances = (appearances: Appearance[]) => {
  return appearances.filter(
    (appearance, ind, arr) =>
      ind ===
      arr.findIndex(
        (
          a // removes duplicates
        ) => a.id === appearance.id
      )
  );
};

export const convertToObjects = (
  games: Match[],
  soloGames: SoloGame[],
  players: Player[],
  overUnderLines?: OverUnderLine[]
) => {
  return {
    games: arrayToObjectIdKeys(games),
    soloGames: arrayToObjectIdKeys(soloGames),
    players: arrayToObjectIdKeys(players),
    overUnderLines: arrayToObjectIdKeys(overUnderLines),
  };
};
