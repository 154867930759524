import { ExposureResponse } from '@/interfaces/exposure';

export const GET_EXPOSURE = 'user/GET_EXPOSURE' as const;
export const SET_EXPOSURE = 'user/SET_EXPOSURE' as const;

export const ERROR_COMPLETED_DRAFTS = 'user/ERROR_COMPLETED_DRAFTS' as const;

export const getExposure = (payload: { slateId: string; contestStyleId: string }) => ({
  type: GET_EXPOSURE,
  payload,
});

export const setExposure = (payload: ExposureResponse) => ({ type: SET_EXPOSURE, payload });
