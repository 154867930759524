/* eslint-disable max-len */
import {
  DraftPoolEntriesResponse,
  DraftPoolEntry,
  DraftPoolEntryScoreUpdateResponse,
  DraftPoolResponse,
  DraftPoolsForSlateResponse,
} from '@/interfaces/draft-pools';

export const GET_DRAFT_POOL = 'draft-pools/GET_DRAFT_POOL' as const;
export const SET_DRAFT_POOL = 'draft-pools/SET_DRAFT_POOL' as const;

export const GET_DRAFT_POOLS_FOR_SLATE = 'draft-pools/GET_DRAFT_POOLS_FOR_SLATE' as const;
export const SET_DRAFT_POOLS_FOR_SLATE = 'draft-pools/SET_DRAFT_POOLS_FOR_SLATE' as const;

export const GET_USER_ENTRIES_FOR_DRAFT_POOL =
  'draft-pools/GET_USER_ENTRIES_FOR_DRAFT_POOL' as const;
export const SET_USER_ENTRIES_FOR_DRAFT_POOL =
  'draft-pools/SET_USER_ENTRIES_FOR_DRAFT_POOL' as const;

export const UPDATE_ENTRIES_FOR_DRAFT = 'draft-pools/UPDATE_ENTRIES_FOR_DRAFT' as const;

export const REPLACE_DRAFT_ENTRY = 'draft-pools/REPLACE_DRAFT_ENTRY' as const;

export const getDraftPoolsForSlate = (payload: { slateId: string }) => ({
  type: GET_DRAFT_POOLS_FOR_SLATE,
  payload,
});

export const setDraftPoolsForSlate = (payload: {
  data: DraftPoolsForSlateResponse;
  slateId: string;
}) => ({ type: SET_DRAFT_POOLS_FOR_SLATE, payload });

export const getUserEntriesForDraftPool = (payload: { draftPoolId: string; page?: number }) => ({
  type: GET_USER_ENTRIES_FOR_DRAFT_POOL,
  payload,
});

export const setUserEntriesForDraftPool = (payload: {
  data: DraftPoolEntriesResponse;
  draftPoolId: string;
}) => ({ type: SET_USER_ENTRIES_FOR_DRAFT_POOL, payload });

export const updateEntriesForDraft = (payload: {
  data: DraftPoolEntryScoreUpdateResponse[];
  draftPoolId: string;
  draftId: string;
}) => ({ type: UPDATE_ENTRIES_FOR_DRAFT, payload });

export const replaceDraftEntryForDraftPool = (payload: {
  data: DraftPoolEntry;
  draftPoolId: string;
  draftId: string;
}) => ({ type: REPLACE_DRAFT_ENTRY, payload });

export const getDraftPool = (payload: { draftPoolId?: string; draftId?: string }) => ({
  type: GET_DRAFT_POOL,
  payload,
});

export const setDraftPool = (payload: { data: DraftPoolResponse }) => ({
  type: SET_DRAFT_POOL,
  payload,
});
