import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '@/store';

import BoostIcon from '@/components/atoms/boost-icon';
import Icon from '@/components/atoms/icon';
import { ScoringSection } from '@/components/atoms/info-modal-components';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import ProgressBar from '@/components/atoms/progress-bar';
import { useToast } from '@/components/atoms/toast';
import DialogModal from '@/components/molecules/dialog-modal';
import ManualScoringIcon from '@/components/molecules/manual-scoring-icon';
// eslint-disable-next-line import/no-cycle
import PickEmPlayerModal from '@/components/molecules/pick-em-player-modal';
import PlayerDetailedAvatar, {
  AvatarBorderType,
} from '@/components/molecules/player-detailed-avatar';
import PickEmInfoModal from '@/components/pages/pick-em/components/review-section/components/pick-em-info-modal';
import { getOverUnderResultStatus } from '@/components/pages/pick-em/helpers';
import { ConstructedEntrySlipPickEmOverUnderAppearance } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { LiveStatLines } from '@/interfaces/constructed-interfaces/constructed-slates';
import { ScoringTypes, Teams } from '@/interfaces/drafting-config';
import { Matches } from '@/interfaces/matches';
import { Boost } from '@/interfaces/pick-em';
import langHelper from '@/utilities/lang-helper';

import { getStatValues } from './helpers';

import styles from './styles.scss';

export interface OverUnderLiveResultCellProps {
  teams: Teams;
  scoringTypes: ScoringTypes;
  constructedPickEmAppearance: ConstructedEntrySlipPickEmOverUnderAppearance;
  isPools?: boolean;
  liveStatLines: LiveStatLines;
  liveGames: Matches;
  boost?: Boost; // depends on entry slip, not cA
  disableButtons?: boolean;
}

const OverUnderLiveResultCell = (props: OverUnderLiveResultCellProps) => {
  const {
    constructedPickEmAppearance: cA,
    teams,
    scoringTypes,
    isPools,
    liveStatLines,
    liveGames,
    boost,
    disableButtons,
  } = props;
  const openModal = useModal();
  const openToast = useToast();

  const statLine = liveStatLines?.[cA.id] || cA.statLine; // fallback to cA.statLine if on results page
  const match = liveGames?.[cA.match?.id] || cA.match; // fallback to cA.match if on results page
  const { soloGame } = cA;

  if (!cA) return null;

  const isLive =
    match?.status === 'scoring' ||
    match?.status === 'scheduled' ||
    soloGame?.status === 'scoring' ||
    soloGame?.status === 'scheduled';

  // actualStatValue is the stat value once a selection has been settled
  // actualStatValue won't change if there's a stat correction after being settled
  const activeStatValue =
    cA.selection?.actualStatValue ||
    (cA.overUnder.appearanceStat.stat === 'fantasy_points'
      ? statLine?.scores?.find((score) => score.scoringTypeId === cA.overUnder?.scoringTypeId)
          ?.points
      : statLine?.data?.[cA.overUnder.appearanceStat.stat]);

  const handleScoringModalClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const scoringType = scoringTypes?.[cA?.scoringTypeId] || null;
    if (scoringType) {
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <DialogModal
          handleCloseModal={handleCloseModal}
          title="Scoring"
          content={<ScoringSection scoringType={scoringType} displayTitle={false} />}
        />
      ));
    }
  };

  const handlePlayerClick = () => {
    if (!disableButtons) {
      if (cA.sport?.manual) {
        openToast({
          open: true,
          message: (
            <div className={styles.noPlayerCardToast}>
              <Icon name="recentActors" className={styles.toastIcon} />
              <p className={styles.noPlayerCardText}>Player card not available</p>
            </div>
          ),
        });
      } else {
        openModal(({ handleCloseModal }: HandleCloseModal) => (
          <PickEmPlayerModal
            constructedAppearance={cA}
            handleCloseModal={handleCloseModal}
            isResults
            boost={boost}
          />
        ));
      }
    }
  };

  const keyboardSelect = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!disableButtons) {
      if (event.key === 'Enter' || event.key === ' ') {
        handlePlayerClick();
      }
    }
  };

  const result = getOverUnderResultStatus({ cA, liveStatLines });

  const openInGameProjectionInfoModal = () => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <DialogModal
        handleCloseModal={handleCloseModal}
        title="In-game projection"
        content={
          <p>
            When you see this icon displayed, that means you&apos;ve picked a stat projection that
            occurred live, in-game.
          </p>
        }
        dismissText="Got it"
        dismissAction={handleCloseModal}
      />
    ));
  };

  const liveMatchInfo = () => {
    if (match?.period > 0 || soloGame?.period > 0) {
      // game is being played and live event
      return (
        <p className={styles.matchInfoText}>
          {langHelper.matchTextWithScore({
            match: cA.match,
            team: cA.team,
            teams,
            soloGame: cA.soloGame,
            sport: cA.sport,
          })}
        </p>
      );
    }
    return (
      <p className={styles.matchInfoText}>
        {langHelper.matchText({
          gameType: cA.sport.gameType,
          match,
          soloGame: cA.soloGame,
          team: cA.team,
          teams,
        })}
        &nbsp;- {langHelper.timeToDayOrDate(match?.scheduledAt || soloGame?.scheduledAt)}
      </p>
    );
  };

  const handleClickOnRescuesBadge = () => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <PickEmInfoModal
        handleCloseModal={handleCloseModal}
        contentToShow={[`rescues${isPools ? 'Champions' : 'Standard'}`]}
        title="Pick'em Rescues"
      />
    ));
  };

  const getProgressBarCurrentCount = (): number => {
    if (cA.selection.result === 'pushed') return 1;

    const parsedActiveStatValue = parseFloat(activeStatValue);
    if (!parsedActiveStatValue || parsedActiveStatValue < 0) return 0;

    return parsedActiveStatValue;
  };

  const isSportScoredManually =
    !activeStatValue && (cA?.match?.manuallyCreated || cA?.soloGame?.manuallyCreated);

  const warningLabelEl = isSportScoredManually ? <ManualScoringIcon /> : null;

  // powerUp overrides all other types
  let borderType: AvatarBorderType = 'none';
  if (cA.selection.powerUp) {
    borderType = 'powerUp';
  }

  const { prevStatValue, statValue } = getStatValues({ cA });

  const statValueEl = (
    <>
      {prevStatValue ? <span className={styles.prevStatValue}>{prevStatValue}</span> : null}
      <span className={styles.statValue}>{statValue}</span>
    </>
  );

  return (
    <div
      className={clsx(styles.overUnderLiveResultCell, styles[result], {
        [styles.disabled]: disableButtons,
      })}
      onClick={handlePlayerClick}
      role="button"
      tabIndex={0}
      onKeyDown={keyboardSelect}
      data-appearance-id={cA.id}
    >
      <div className={styles.actualTopRow}>
        <div className={styles.playerInfoWrapper}>
          <div className={styles.avatarWrapper}>
            <PlayerDetailedAvatar
              team={cA.team}
              player={cA.player}
              sport={cA.sport}
              boost={boost}
              borderType={borderType}
              backgroundStripes
              powerUp={cA.selection.powerUp}
              size="regular"
            />
          </div>
          <div className={styles.playerInfo}>
            <h1 className={styles.playerName}>{langHelper.getPlayerFullName(cA.player)}</h1>
            <div className={styles.statLineRow}>
              {cA.selection.rebooted ? (
                <div className={styles.rescuesRow}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickOnRescuesBadge();
                    }}
                    className={styles.rescuesBadge}
                  >
                    <Icon name="medicalServices" className={styles.medicalServicesIcon} />
                    <span>Rescued</span>
                  </button>
                  <BoostIcon boost={boost} />
                </div>
              ) : (
                <>
                  <p className={styles.statLine}>
                    <span className={styles.choiceText}>
                      {cA.selectedOverUnderOption.choiceDisplay}
                    </span>
                    {statValueEl}
                    {cA.overUnderLineInfo.displayStat} <BoostIcon boost={boost} />
                  </p>
                  {cA.overUnder?.scoringTypeId && (
                    <button
                      disabled={disableButtons}
                      className={styles.scoringModalButton}
                      onClick={handleScoringModalClick}
                      aria-label="Scoring info"
                    >
                      <Icon name="info" className={styles.scoringModalIcon} />
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {cA.hasLiveLines && (
          <div className={styles.inGameProjectionWrapper}>
            <button
              disabled={disableButtons}
              onClick={(e) => {
                e.stopPropagation();
                openInGameProjectionInfoModal();
              }}
              aria-label="In-game projection info"
            >
              <Icon name="timer" className={styles.inGameProjectionIcon} />
            </button>
          </div>
        )}
      </div>
      <ProgressBar // if it's pushed, fill the whole bar
        totalCount={
          cA.selection.result === 'pushed' ? 1 : parseFloat(cA.overUnderLineInfo.statValue)
        }
        currentCount={getProgressBarCurrentCount()}
        classNames={{
          fullBar: styles.fullBar,
          emptyBar: styles.emptyBar,
          wrapper: styles.progressBar,
        }}
      />
      {isLive ? (
        <div className={styles.liveRow}>
          <div className={styles.matchInfo}>
            <Icon className={styles.sportIcon} name={cA.sport?.icon} />
            {liveMatchInfo()}
          </div>
          {activeStatValue && (
            <div className={styles.resultStat}>
              <p>
                {/* eslint-disable-next-line max-len */}
                {langHelper.formatNumber(activeStatValue)} {cA.overUnder.appearanceStat.displayStat}
              </p>
            </div>
          )}
          {warningLabelEl}
        </div>
      ) : (
        <div className={styles.resultsRow}>
          <div className={styles.matchInfo}>
            <Icon className={styles.sportIcon} name={cA.sport?.icon} />
            <p className={styles.matchInfoText}>
              {langHelper.matchResultText({
                teams,
                match,
                team: cA.team,
                soloGame: cA.soloGame,
              })}{' '}
              - {langHelper.timeToDayOrDate(match?.scheduledAt || soloGame?.scheduledAt)}
            </p>
          </div>
          {activeStatValue && (
            <div className={styles.resultStat}>
              <p>
                {/* eslint-disable-next-line max-len */}
                {langHelper.formatNumber(activeStatValue)} {cA.overUnder.appearanceStat.displayStat}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default connect((state: RootState) => ({
  teams: state.draftingConfig.teams,
  scoringTypes: state.draftingConfig.scoringTypes,
  liveStatLines: state.pickEmEntries.live.liveStatLines,
  liveGames: state.pickEmEntries.live.liveGames,
}))(OverUnderLiveResultCell);
