import { datadogRum } from '@datadog/browser-rum';

import { AppErrorResponse } from '@/interfaces/error';

const errorLogger = (
  logError: boolean,
  error: Error | AppErrorResponse | string,
  context?: {
    [key: string]: object | string;
  }
): void => {
  if (process.env.APP_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  if (logError) {
    // handle errors that aren't tracked automatically by the RUM SDK
    // these will only record, if datadogRUM was initialized
    if (typeof error === 'string') {
      datadogRum.addError(new Error(error), context);
    } else {
      datadogRum.addError(error, context);
    }
  }
};

export default errorLogger;
