/* eslint-disable camelcase */

import { Player, PlayerResponse } from '@/interfaces/constructed-interfaces/constructed-slates';

const playerAdapter = (playerResponse: PlayerResponse): Player => {
  const { id, country, first_name, image_url, last_name, position_id, sport_id, team_id } =
    playerResponse;

  return {
    id,
    country,
    firstName: first_name,
    imageUrl: image_url,
    lastName: last_name,
    positionId: position_id,
    sportId: sport_id,
    teamId: team_id,
  };
};

export default playerAdapter;
