import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';

import { setDraftingConfig } from '@/store/modules/drafting-config/actions';
import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/drafting-config';
import {
  ContestStylesResponse,
  EntryStylesResponse,
  LineupStatusesResponse,
  ScoringTypesResponse,
  SlotsResponse,
  SportsResponse,
  TeamsResponse,
} from '@/interfaces/drafting-config';

export const getDraftingConfigSaga = function* getDraftingConfigSaga(): SagaIterator {
  try {
    const [
      contestStylesData,
      entryStylesData,
      lineupStatusesData,
      scoringTypesData,
      slotsData,
      sportsData,
      teamsData,
    ] = yield all([
      call(api.getContestStyles),
      call(api.getEntryStyles),
      call(api.getLineupStatuses),
      call(api.getScoringTypes),
      call(api.getSlots),
      call(api.getSports),
      call(api.getTeams),
    ]);

    const { data: contestStylesResponse }: { data: ContestStylesResponse } = contestStylesData;
    const { data: entryStylesResponse }: { data: EntryStylesResponse } = entryStylesData;
    const { data: lineupStatusesResponse }: { data: LineupStatusesResponse } = lineupStatusesData;
    const { data: scoringTypesResponse }: { data: ScoringTypesResponse } = scoringTypesData;
    const { data: slotsResponse }: { data: SlotsResponse } = slotsData;
    const { data: sportsResponse }: { data: SportsResponse } = sportsData;
    const { data: teamsResponse }: { data: TeamsResponse } = teamsData;

    yield put(
      setDraftingConfig({
        contestStylesResponse,
        entryStylesResponse,
        lineupStatusesResponse,
        scoringTypesResponse,
        slotsResponse,
        sportsResponse,
        teamsResponse,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
