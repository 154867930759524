import { EntryCollection } from 'contentful';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api-contentful/general-rules';
import { IGeneralRulesFields } from '@/interfaces/general-rules';

import { setGeneralRules } from '../actions';

export const getGeneralRulesSaga = function* getGeneralRulesSaga(): SagaIterator {
  try {
    // eslint-disable-next-line max-len
    const generalRules: EntryCollection<IGeneralRulesFields> = yield call(
      api.getContentfulGeneralRules
    );
    yield put(setGeneralRules({ generalRules }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
