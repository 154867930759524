import {
  UPDATE_WEEKLY_WINNER_COUNT,
  updateWeeklyWinnerCount,
} from '@/store/modules/lobbies/actions';

import { EntryCountUpdate } from '@/interfaces/lobbies';
import { WeeklyWinnerResponse, WeeklyWinners } from '@/interfaces/weekly-winners';

import { SET_WEEKLY_WINNER, setWeeklyWinner } from '../actions';
import { weeklyWinnerAdapter } from '../adapters/weekly-winner';

type State = WeeklyWinners;

type WeeklyWinnerActions = ReturnType<typeof setWeeklyWinner | typeof updateWeeklyWinnerCount>;

const initialState: WeeklyWinners = {};

const set = (state: WeeklyWinners, data: WeeklyWinnerResponse): WeeklyWinners => {
  const weeklyWinner = weeklyWinnerAdapter(data.weekly_winner);

  return {
    ...state,
    [weeklyWinner.id]: weeklyWinner,
  };
};

const updateCount = (
  state: WeeklyWinners,
  { weeklyWinnerId, entryCount }: EntryCountUpdate
): WeeklyWinners => {
  if (!state[weeklyWinnerId]) {
    return state;
  }

  return {
    ...state,
    [weeklyWinnerId]: {
      ...state[weeklyWinnerId],
      entryCount,
    },
  };
};

export const weeklyWinnersReducer = (
  state: State = initialState,
  action: WeeklyWinnerActions = {} as WeeklyWinnerActions
): State => {
  switch (action.type) {
    case SET_WEEKLY_WINNER:
      return set(state, action.payload);
    case UPDATE_WEEKLY_WINNER_COUNT:
      return updateCount(state, action.payload);
    default:
      return state;
  }
};
