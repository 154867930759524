import { AxiosResponse } from 'axios';

import request from '@/api/request';

export const getIntercomHash = async (): Promise<AxiosResponse<{ hashed_id: string }>> => {
  const response = await request({
    method: 'GET',
    requiresLatLong: true,
    url: '/intercom/identity_verification',
  });

  return response;
};
