import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { ThemeProvider, ThemeWrapper } from '@/components/contexts/theme';

import { loadSvgSpriteSheet } from './assets/svg-sprite-sheet-loader';
import { rootRouter } from './app.routes';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const renderApp = async () => {
  // We need to wait for the sprite sheet to load, otherwise it's possible
  // that the app will render and no icons will appear.
  await loadSvgSpriteSheet();

  root.render(
    <ThemeProvider>
      <ThemeWrapper>
        <RouterProvider router={rootRouter} />
      </ThemeWrapper>
    </ThemeProvider>
  );
};

renderApp();
