import { EntryCollection } from 'contentful';

import client from '@/api-contentful/contentful-request';
import { ISweepstakeTermsAndConditionsFields } from '@/interfaces/contentful';

export const getContentfulAllSweepstakesTermsAndConditions = async (): Promise<any> => {
  const response = await client.getEntries({
    content_type: 'sweepstakeTermsAndConditions',
  });

  return response;
};

export const getContentfulSweepstakesTermsAndConditions = async ({
  slug,
}: {
  slug: string;
}): Promise<EntryCollection<ISweepstakeTermsAndConditionsFields>> => {
  const response = await client.getEntries<ISweepstakeTermsAndConditionsFields>({
    content_type: 'sweepstakeTermsAndConditions',
    'fields.slug': slug,
  });

  return response;
};
