import { EntryCollection } from 'contentful';

import client from '@/api-contentful/contentful-request';
import { IStateConfigurationFields } from '@/interfaces/contentful';

export const getContentfulStateConfiguration = async (): Promise<
  EntryCollection<IStateConfigurationFields>
> => {
  const response = await client.getEntries<IStateConfigurationFields>({
    content_type: 'stateConfiguration',
  });

  return response;
};
