import { FeatureFlags, FeatureResponse } from '@/interfaces/feature-flags';
import { UD_FEATURE_FLAGS } from '@/utilities/constants';

import { SET_FEATURE_FLAGS, setFeatureFlags } from '../actions';
import { featuresAdapter } from '../adapters/features';

type State = FeatureFlags;

type FeatureFlagsActions = ReturnType<typeof setFeatureFlags>;

const initialFeatureFlagsState = {} as FeatureFlags;

const set = (featuresData: FeatureResponse): FeatureFlags => {
  if (!featuresData) return initialFeatureFlagsState;

  const features = featuresData ? featuresAdapter(featuresData.features) : null;
  localStorage.setItem(UD_FEATURE_FLAGS, JSON.stringify(features));

  return {
    ...features,
  };
};

export const featureFlagsReducer = (
  state: State = initialFeatureFlagsState,
  action: FeatureFlagsActions = {} as FeatureFlagsActions
): State => {
  switch (action.type) {
    case SET_FEATURE_FLAGS:
      return set(action.payload);
    default:
      return state;
  }
};
