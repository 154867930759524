import { useContext } from 'react';

import { ThemeContext } from '@/components/contexts/theme';
import { Sport, Team } from '@/interfaces/drafting-config';

/**
 * Return the primary and secondary colors for a team
 *
 * Depending on the light or dark theme that a user has set, return
 * the primary and secondary colors for a team in an array and fallback
 * to generic colors if they don't exist.
 *
 * @param team           obj.team    team
 *
 * @return {[string, string]} Returns [primaryColor, secondaryColor]
 */
const useTeamColors = ({ team, sport }: { team: Team; sport: Sport }): [string, string] => {
  const { theme } = useContext(ThemeContext);

  if (!team && sport?.color) return [sport.color, sport.color];

  const lightColor = '#f0f0f0';
  const darkColor = '#1b1b1b';

  if (!team && !sport) {
    if (theme === 'light') {
      return [darkColor, lightColor];
    }
    return [lightColor, darkColor];
  }

  if (theme === 'light') {
    if (!team.primaryColorLightMode && !team.secondaryColorLightMode) {
      if (sport?.color) {
        return [sport.color, sport.color];
      }
      return [darkColor, lightColor];
    }
    if (!team.primaryColorLightMode) {
      return [darkColor, team.secondaryColorLightMode];
    }
    if (!team.secondaryColorLightMode) {
      return [team.primaryColorLightMode, lightColor];
    }
    return [team.primaryColorLightMode, team.secondaryColorLightMode];
  }
  if (!team.primaryColorDarkMode && !team.secondaryColorDarkMode) {
    if (sport?.color) {
      return [sport.color, sport.color];
    }
    return [lightColor, darkColor];
  }
  if (!team.primaryColorDarkMode) {
    return [lightColor, team.secondaryColorDarkMode];
  }
  if (!team.secondaryColorDarkMode) {
    return [team.primaryColorDarkMode, darkColor];
  }
  return [team.primaryColorDarkMode, team.secondaryColorDarkMode];
};

export default useTeamColors;
