import React from 'react';

import Icon, { IconTypes } from '@/components/atoms/icon';

export interface PickEmCellMessageProps {
  classNames: {
    message: string;
    icon: string;
  };
  message: string | JSX.Element;
  icon: IconTypes;
  onClick?: () => void;
}

const PickEmCellMessage = (props: PickEmCellMessageProps) => {
  const { classNames, message, icon, onClick } = props;

  const handleOnClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  const keyboardSelect = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleOnClick(event);
    }
  };

  return (
    <div
      className={classNames.message}
      data-testid="pickem-cell-message"
      role={onClick ? 'button' : undefined}
      onClick={(e) => handleOnClick(e)}
      onKeyDown={keyboardSelect}
      tabIndex={0}
    >
      <Icon name={icon} className={classNames.icon} />
      {typeof message === 'string' ? <p>{message}</p> : message}
    </div>
  );
};

export default PickEmCellMessage;
