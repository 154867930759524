/* eslint-disable camelcase */
import { PickEmEntrySlipLimit, PickEmEntrySlipLimitResponse } from '@/interfaces/pick-em';

// eslint-disable-next-line max-len
const pickEmEntrySlipLimitsAdapter = (
  entrySlipLimit: PickEmEntrySlipLimitResponse
): PickEmEntrySlipLimit => {
  const { id, max_fee, max_appearance_fee, sport_id, user_override } = entrySlipLimit;

  return {
    id,
    maxFee: max_fee,
    maxAppearanceFee: max_appearance_fee,
    sportId: sport_id,
    userOverride: user_override,
  };
};

export default pickEmEntrySlipLimitsAdapter;
