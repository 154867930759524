import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export interface TextSlideUpAnimationProps {
  defaultContent: JSX.Element | string;
  incomingContent: JSX.Element | string;
  swapCondition: boolean;
}

const TextSlideUpAnimation = (props: TextSlideUpAnimationProps) => {
  const { defaultContent, incomingContent, swapCondition } = props;

  return swapCondition ? (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        key="incomingContent"
        initial={{ y: 10 }}
        animate={{ y: [10, -5, 0] }}
        exit={{ y: [0, 5, -10] }}
        transition={{ duration: 0.15 }}
      >
        {incomingContent}
      </motion.div>
    </AnimatePresence>
  ) : (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        key="defaultContent"
        initial={{ y: 10 }}
        animate={{ y: 0 }}
        exit={{ y: [0, 5, -10] }}
        transition={{ duration: 0.15 }}
      >
        {defaultContent}
      </motion.div>
    </AnimatePresence>
  );
};

export default TextSlideUpAnimation;
