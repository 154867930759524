/* eslint-disable camelcase */
import { DraftWeeklyScores, DraftWeeklyScoresResponse } from '@/interfaces/drafts';

// eslint-disable-next-line max-len
export const weeklyScoreAdapter = (
  weeklyScores: DraftWeeklyScoresResponse['draft_weekly_scores']
): DraftWeeklyScores[] =>
  weeklyScores.map((weeklyScore): DraftWeeklyScores => {
    const { id, draft_id, draft_entries_points, week } = weeklyScore;

    return {
      id,
      draftId: draft_id,
      draftEntryPoints: draft_entries_points,
      week,
    };
  });
