import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';

import { UD_DEVICE_THEME, UD_THEME } from '@/utilities/constants';

import './styles.scss';

export const ThemeContext = React.createContext<ThemeContextValue | null>(null);

export type Theme = 'light' | 'dark';
export type UserTheme = Theme | 'system';

export interface ThemeContextValue {
  setUserTheme: React.Dispatch<React.SetStateAction<UserTheme>>;
  toggle: () => void;
  theme: Theme;
  updateTheme: (newTheme: Theme) => void;
  userTheme: UserTheme; // based on user selection on settings page
}

interface ThemeProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const useTheme = (): [
  Theme,
  React.Dispatch<React.SetStateAction<Theme>>,
  React.Dispatch<React.SetStateAction<UserTheme>>,
  UserTheme,
] => {
  const [theme, setTheme] = useState<Theme>('light');
  const [userTheme, setUserTheme] = useState<UserTheme>('system');

  useEffect(() => {
    // The cookie is used for mobile webview pages
    const underdogTheme = Cookies.get('ud_theme') || localStorage.getItem(UD_THEME);
    if (underdogTheme === 'light') {
      setTheme('light');
    } else if (underdogTheme === 'dark') {
      setTheme('dark');
    } else {
      const browserTheme = window.matchMedia('(prefers-color-scheme: dark)');
      setTheme(browserTheme.matches ? 'dark' : 'light');
    }

    const deviceTheme = localStorage.getItem(UD_DEVICE_THEME);
    if (deviceTheme === 'light') {
      setUserTheme('light');
    } else if (deviceTheme === 'dark') {
      setUserTheme('dark');
    } else {
      setUserTheme('system');
    }
  }, []);

  return [theme, setTheme, setUserTheme, userTheme];
};

export const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  const themeContext = useThemeContext();
  return <div className={`theme--${themeContext.theme}`}>{children}</div>;
};

export const ThemeProvider = (props: ThemeProviderProps) => {
  const [theme, setTheme, setUserTheme, userTheme] = useTheme();

  const toggle = useCallback(() => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    localStorage.setItem(UD_THEME, newTheme);
    setTheme(newTheme);
    localStorage.setItem(UD_DEVICE_THEME, newTheme);
    setUserTheme(newTheme);
  }, [setTheme, setUserTheme, theme]);

  const updateTheme = useCallback(
    (newTheme: Theme) => {
      localStorage.setItem(UD_THEME, newTheme);
      setTheme(newTheme);
    },
    [setTheme]
  );

  const contextValue = useMemo(() => {
    const val: ThemeContextValue = {
      setUserTheme,
      toggle,
      theme,
      updateTheme,
      userTheme,
    };
    return val;
  }, [setUserTheme, toggle, theme, updateTheme, userTheme]);

  return <ThemeContext.Provider value={contextValue}>{props.children}</ThemeContext.Provider>;
};

export function useThemeContext() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be a descendant of <ThemeProvider />');
  }

  return context;
}

export default ThemeContext;
