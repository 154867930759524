import { EntryCollection } from 'contentful';

import { IPowerUpInfoFields } from '@/interfaces/contentful';
import { ContentfulPowerUpInfos } from '@/interfaces/contentful-power-up-info';

import { SET_CONTENTFUL_POWER_UP_INFO, setContentfulPowerUpInfo } from '../actions';

type State = ContentfulPowerUpInfos;

type PowerUpInfosActions = ReturnType<typeof setContentfulPowerUpInfo>;

const initialContentfulPowerUpInfosState: State = {};

const set = (
  state: State,
  data: {
    powerUpInfoResponse: EntryCollection<IPowerUpInfoFields>;
    contentfulInfoId: string;
  }
): State => {
  if (!data.powerUpInfoResponse.items.length) {
    return {
      ...state,
      [data.contentfulInfoId]: {} as IPowerUpInfoFields,
    };
  }
  const powerUpInfo: IPowerUpInfoFields = data.powerUpInfoResponse.items[0].fields;

  return {
    ...state,
    [powerUpInfo.contentfulInfoId]: powerUpInfo,
  };
};

export const contentfulPowerUpInfosReducer = (
  state: State = initialContentfulPowerUpInfosState,
  action: PowerUpInfosActions = {} as PowerUpInfosActions
): State => {
  switch (action.type) {
    case SET_CONTENTFUL_POWER_UP_INFO:
      return set(state, action.payload);
    default:
      return state;
  }
};
