import axios, { AxiosResponse } from 'axios';

import request, { clientType, clientVersion, getDeviceId } from '@/api/request';
import { auth0Service } from '@/components/contexts/auth/auth0-service';
import { CosmeticRequest, UserAchievementsResponse } from '@/interfaces/cosmetics';
import {
  CreditCardAddRequest,
  CreditCardResponse,
  CreditCardsResponse,
  PaysafeCreditCardAddRequest,
} from '@/interfaces/deposit';
import {
  DepositResponseObject,
  DepositSettingsResponse,
  OAuthTokenResponse,
  PaymentMethod,
  PaymentMethodsResponse,
  UserBonusWalletResponse,
  UserCosmeticUpdateResponse,
  UserExperiencesResponse,
  UserLoginRequest,
  UserProfileResponse,
  UserReferralStatsResponse,
  UserRegistrationAuth0Response,
  UserRegistrationRequest,
  UserResponse,
} from '@/interfaces/user';
import eventLogger from '@/utilities/analytics';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const registerUserAuth0 = async (
  userRegistrationObject: UserRegistrationRequest
): Promise<AxiosResponse<UserRegistrationAuth0Response>> => {
  try {
    const response = await axios.request({
      url: `${process.env.AUTH0_DOMAIN}/dbconnections/signup`,
      method: 'POST',
      data: {
        client_id: process.env.AUTH0_CLIENT_ID,
        connection: 'Underdog-Database',
        email: userRegistrationObject.user.email,
        password: userRegistrationObject.user.password,
        username: userRegistrationObject.user.username,
        user_metadata: {
          promotion_id: userRegistrationObject.user.promotion_id,
          referring_link: userRegistrationObject.user.referring_link,
        },
      },
    });
    eventLogger({
      gtm: {
        eventName: 'complete_registration',
        eventObject: {
          userId: response?.data?.app_metadata?.ud_player_id,
        },
      },
    });
    return response;
  } catch (e) {
    apiErrorTracking(e);
    // handle auth0 error message
    throw new AppError({
      ...e,
      title: e?.response?.data?.error?.title || 'Something went wrong',
      message: e?.response?.data?.error?.error_detail || e?.message,
    });
  }
};

export const loginUserAuth0 = async (
  userLoginObject: UserLoginRequest
): Promise<AxiosResponse<OAuthTokenResponse>> => {
  try {
    const response = await axios.request({
      method: 'POST',
      url: `${process.env.AUTH0_DOMAIN}/oauth/token`,
      data: {
        audience: process.env.AUTH0_AUDIENCE,
        birthdate: userLoginObject.user.birthdate,
        client_id: process.env.AUTH0_CLIENT_ID,
        grant_type: 'password',
        password: userLoginObject.user.password,
        scope: 'offline_access',
        username: userLoginObject.user.email, // send email even though property is username
        ud_client_type: clientType,
        ud_client_version: clientVersion,
        ud_device_id: getDeviceId(),
      },
    });

    if (response?.data?.access_token) {
      auth0Service.setAccessToken(response.data.access_token);
      auth0Service.setRefreshToken(response.data.refresh_token);
      auth0Service.setAccessTokenExpiration(response.data.expires_in);
    }

    return response;
  } catch (e) {
    apiErrorTracking(e);
    // handle auth0 error message
    throw new AppError({
      ...e,
      title: e?.response?.data?.error || 'Something went wrong',
      message: e?.response?.data?.error_description || e?.message,
    });
  }
};

export const logoutUser = async (): Promise<AxiosResponse<void>> => {
  const response = await request({
    method: 'DELETE',
    url: '/users/sign_out',
  });

  return response;
};

export const changePasswordAuth0 = async ({
  email,
}: {
  email: string;
}): Promise<AxiosResponse<string>> => {
  const response = await axios.request({
    method: 'POST',
    url: `${process.env.AUTH0_DOMAIN}/dbconnections/change_password`,
    data: {
      client_id: process.env.AUTH0_CLIENT_ID,
      email,
      connection: 'Underdog-Database',
    },
  });

  return response;
};

export const getDepositSettings = async (): Promise<AxiosResponse<DepositSettingsResponse>> => {
  const response = await request({
    method: 'GET',
    requiresLatLong: true,
    url: '/user/deposit_settings',
    underdogAPIVersion: 'v3',
  });

  return response;
};

export const getUserReferralStats = async (): Promise<AxiosResponse<UserReferralStatsResponse>> => {
  const response = await request({
    method: 'GET',
    url: '/user/referral_stats',
  });

  return response;
};

export const getUserProfile = async (): Promise<AxiosResponse<UserProfileResponse>> => {
  const response = await request({
    method: 'GET',
    url: '/user/profile',
  });

  return response;
};

export const getUserAchievements = async (): Promise<AxiosResponse<UserAchievementsResponse>> => {
  const response = await request({
    method: 'GET',
    url: '/user/achievements',
  });

  return response;
};

export const setUserCosmetics = async (
  cosmetic: CosmeticRequest
): Promise<AxiosResponse<UserCosmeticUpdateResponse>> => {
  const response = await request({
    method: 'POST',
    url: '/user/cosmetics',
    data: {
      cosmetic,
    },
  });

  return response;
};

/**
 *
 * Add and retrieve Paysafe user credit cards
 */
export const getPaymentMethodsAndSettings = async (): Promise<
  AxiosResponse<PaymentMethodsResponse>
> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v2',
      url: '/user/payment_methods',
      requiresLatLong: true,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/**
 *
 * Retrieve a single payment method from Finorc
 */
export const getPaymentMethod = async ({
  paymentMethodId,
}: {
  paymentMethodId: string;
}): Promise<AxiosResponse<PaymentMethod>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v1',
      url: `/user/payment_methods/${paymentMethodId}`,
      requiresLatLong: true,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/**
 *
 * Deposit with Paysafe credit cards
 */
export const depositWithPaysafeCreditCard = async ({
  depositObject,
}: {
  depositObject: PaysafeCreditCardAddRequest;
}): Promise<AxiosResponse<DepositResponseObject>> => {
  try {
    const response = await request({
      method: 'POST',
      url: '/paysafe/deposits',
      requiresGeoComply: true,
      data: {
        deposit: {
          amount: depositObject.amount,
          credit_card: {
            token: depositObject.token,
            payment_method_id: depositObject.paymentMethodId,
            last_name: depositObject.lastName,
          },
          promotion_id: depositObject.promotionId,
        },
        confirmed: depositObject.confirmed,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/**
 *
 * Add and retrieve user credit cards
 */

export const getCreditCards = async (): Promise<AxiosResponse<CreditCardsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      requiresLatLong: true,
      underdogAPIVersion: 'v3',
      url: '/user/credit_cards',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const addCreditCard = async ({
  depositObject,
}: {
  depositObject: CreditCardAddRequest;
}): Promise<AxiosResponse<CreditCardResponse>> => {
  try {
    const response = await request({
      data: {
        credit_card: {
          description: depositObject.description,
          expiration: depositObject.expiration,
          first_name: depositObject.firstName,
          last_name: depositObject.lastName,
          token: depositObject.token,
          zip: depositObject.zip,
        },
      },
      method: 'POST',
      requiresLatLong: true,
      url: '/user/credit_cards',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const removeCreditCard = async ({
  creditCardId,
}: {
  creditCardId: string;
}): Promise<AxiosResponse<void>> => {
  try {
    const response = await request({
      method: 'DELETE',
      underdogAPIVersion: 'v3',
      url: `/user/credit_cards/${creditCardId}`,
      requiresLatLong: true,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const removePaymentMethod = async ({
  paymentMethodId,
}: {
  paymentMethodId: string;
}): Promise<AxiosResponse<void>> => {
  try {
    const response = await request({
      method: 'DELETE',
      url: `/user/payment_methods/${paymentMethodId}`,
      requiresLatLong: true,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/**
 * This function provides preloaded state to the redux store
 */
export const getUserData = async (): Promise<{
  data: UserResponse;
  profileData: UserProfileResponse;
}> => {
  const userResponse = await request({
    url: '/user',
  });
  const profileResponse = await request({
    url: '/user/profile',
  });
  return {
    data: userResponse.data,
    profileData: profileResponse?.data,
  };
};

export const claimAirdrop = async ({
  airdropId,
}: {
  airdropId: string;
}): Promise<AxiosResponse<void>> => {
  const response = await request({
    method: 'POST',
    url: '/user/airdrop_offers/claims',
    data: {
      id: airdropId,
    },
  });
  return response;
};

export const getUserExperiences = async (): Promise<{ data: UserExperiencesResponse }> => {
  const response = await request({
    url: '/user/experiences',
  });
  return response;
};

export const getUserBonusWallet = async (): Promise<{
  data: UserBonusWalletResponse;
}> => {
  const response = await request({
    url: '/user/bonus_wallet_accounts',
  });
  return response;
};
