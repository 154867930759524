export const ERROR_CODES = {
  BLOCKED_EMAIL: 'blocked_email',
  BOOSTED_ENTRY_SLIP: 'boosted_entry_slip',
  CONFIRMATION_NEEDED: 'confirmation_needed',
  CORRELATION: 'correlation',
  CS_VERIFICATION_NEEDED: 'cs_verification_needed',
  DEPOSIT_NEEDED: 'deposit_needed',
  FORM_VERIFICATION_NEEDED: 'form_verification_needed',
  ID_VERIFICATION_NEEDED: 'id_verification_needed',
  INVALID_ENTRY: 'invalid_entry',
  INVALID_OVER_OPTION: 'invalid_over_option',
  INVALID_OVER_UNDER_OPTION: 'invalid_over_under_option',
  INVALID_UNDER_OPTION: 'invalid_under_option',
  INVALID_SCORCHER_ENTRY_SLIP: 'invalid_scorcher_entry_slip',
  INVALID_SCORCHER_SELECTION: 'invalid_scorcher_selection',
  LOCATION_NEEDED: 'location_needed',
  MAX_SELECTION_COUNT_EXCEEDED: 'invalid_power_up_selection_count',
  RANKINGS_NEEDED: 'rankings_needed',
  TAX_INFO_NEEDED: 'tax_info_needed',
  UNAVAILABLE_COUNTRY_FAILED: 'unavailable_country_failed',
  UPGRADE_REQUIRED: 'upgrade_required',
  USER_DISMISS: 'user_dismiss',
};

export const ERROR_NAMES = {
  PASSWORD_DICTIONARY_ERROR: 'PasswordDictionaryError',
  PASSWORD_NO_USER_INFO_ERROR: 'PasswordNoUserInfoError',
};

export const ERROR_MESSAGES = {
  BOOSTED_ENTRY_SLIP: 'Cannot apply multiple promos in one entry.',
  CORRELATION_GENERIC_ERROR: 'Locked picks must be resolved before submitting entry.',
  DICTIONARY_ERROR: 'Your password is too common. Please select a more unique and secure password.',
  INVALID_IN_GAME_PROJECTION: `Token cannot be applied to an 'In-game' pick.`,
  INVALID_OVER_OPTION: `Token cannot be applied to a 'Lower' selection.`,
  INVALID_OVER_UNDER_OPTION: `Token cannot be applied to a 'Rivals' selection.`,
  INVALID_SCORCHER_ENTRY_SLIP:
    'Payout modifiers are not eligible on entries containing this token.',
  INVALID_SCORCHER_SELECTION: 'Token cannot be applied to a scorched selection.',
  INVALID_UNDER_OPTION: `Token cannot be applied to a 'Higher' selection.`,
  NO_USER_INFO_ERROR:
    'Your password contains your username or email address. Please choose a different password.',
  PAYOUT_SERVICE_GENERIC_ERROR:
    "There was an issue and some entry form information didn't load. Please refresh and try again.",
  TOKEN_CANNOT_BE_FLEXED: 'Token cannot be flexed.',
  TOKEN_MUST_BE_APPLIED_TO_PICK:
    'Your token must be applied to a pick. Remove it or apply it to an eligible pick.',
  UNAUTHORIZED_ERROR: 'You were logged out from your account, please log in again.',
  UNAUTHORIZED_ERROR_MOBILE: 'Please return to the app and try again.',
  WEAK_PASSWORD: `Your password must contain at least one uppercase letter, one lowercase letter,
    at least one special character or number, and must be 8+ characters long.`,
};

export const AUTH0_ERRORS = {
  INVALID_GRANT: 'invalid_grant',
  MFA_REQUIRED: 'mfa_required',
  TOO_MANY_ATTEMPTS: 'too_many_attempts',
};

export const PICK_EM_SUBMISSION_ERROR_CODES = {
  APPLY_TOKEN: 'apply_token',
  INVALID_FLEXED_TOKEN: 'invalid_power_up_insurance',
  INVALID_IN_GAME_PROJECTION: 'invalid_in_game_projection',
  INVALID_INVERSE_SCORCHER_ENTRY_SLIP: 'invalid_inverse_scorcher_entry_slip',
  MAX_FEE_EXCEEDED: 'max_fee_exceeded',
  PAYOUT_SERVICE_FAILED: 'payout_service_failed',
};
