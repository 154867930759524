/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';

import request from '@request';
import {
  DraftResponse,
  DraftWeeklyScoresResponse,
  SingleDraftEntryResponse,
} from '@/interfaces/drafts';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const enterDraft = async ({
  slateId,
  sitAndGoId,
  confirmed,
}: {
  slateId: string;
  sitAndGoId: string;
  confirmed?: boolean;
}): Promise<AxiosResponse<DraftResponse>> => {
  try {
    const response = await request({
      method: 'POST',
      url: `/slates/${slateId}/sit_and_gos/${sitAndGoId}/drafts`,
      requiresGeoComply: true,
      params: {
        ...(confirmed ? { confirmed: true } : {}),
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const createPrivateDraft = async ({
  slateId,
  sitAndGoId,
  confirmed,
}: {
  slateId: string;
  sitAndGoId: string;
  confirmed?: boolean;
}): Promise<AxiosResponse<DraftResponse>> => {
  try {
    const response = await request({
      method: 'POST',
      url: `/slates/${slateId}/sit_and_gos/${sitAndGoId}/private_drafts`,
      requiresGeoComply: true,
      params: {
        ...(confirmed ? { confirmed: true } : {}),
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const createPrivateDraftShareLink = async ({
  draftEntryId,
}: {
  draftEntryId: string;
}): Promise<AxiosResponse<SingleDraftEntryResponse>> => {
  try {
    const response = await request({
      method: 'POST',
      url: `/draft_entries/${draftEntryId}/shares`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const enterPrivateDraft = async ({
  draftId,
  confirmed,
}: {
  draftId: string;
  confirmed?: boolean;
}): Promise<AxiosResponse<DraftResponse>> => {
  try {
    const response = await request({
      method: 'POST',
      url: `/drafts/${draftId}/draft_entries`,
      requiresGeoComply: true,
      params: {
        ...(confirmed ? { confirmed: true } : {}),
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getDraft = async ({
  draftId,
}: {
  draftId: string;
}): Promise<AxiosResponse<DraftResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/drafts/${draftId}`,
      underdogAPIVersion: 'v2',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getDraftWeeklyScores = async ({
  draftId,
}: {
  draftId: string;
}): Promise<AxiosResponse<DraftWeeklyScoresResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/drafts/${draftId}/weekly_scores`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const updateDraftTitle = async ({
  draftEntryId,
  title,
}: {
  draftEntryId: string;
  title: string;
}): Promise<AxiosResponse<SingleDraftEntryResponse>> => {
  try {
    const response = await request({
      method: 'PUT',
      url: `/draft_entries/${draftEntryId}/title`,
      data: {
        draft_entry: {
          title,
        },
      },
    });

    return response;
  } catch (e) {
    throw new AppError(e);
  }
};
