import { AxiosResponse } from 'axios';

import request from '@request';
import { CompletedDraftsResponse } from '@/interfaces/completed-drafts';
import { CompletedSlatesResponse } from '@/interfaces/completed-slates';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getCompletedSlates = async (): Promise<AxiosResponse<CompletedSlatesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/user/completed_slates',
      underdogAPIVersion: 'v2',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getCompletedDrafts = async ({
  slateId,
  page,
}: {
  slateId: string;
  page?: number;
}): Promise<AxiosResponse<CompletedDraftsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/slates/${slateId}/completed_drafts`,
      underdogAPIVersion: 'v2',
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
