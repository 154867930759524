/* eslint-disable camelcase */
import { LobbyWeeklyWinner, LobbyWeeklyWinnerResponse } from '@/interfaces/lobbies';

import lobbySlateAdapter from './lobby-slate';

const lobbyWeeklyWinnerAdapter = (
  lobbyWeeklyWinner: LobbyWeeklyWinnerResponse
): LobbyWeeklyWinner => {
  const {
    id,
    additional_info,
    contest_style_id,
    current_weekly_winner_week_id,
    cutoff_at,
    description,
    draft_entry_style_id,
    entry_count,
    entry_role,
    entry_style_id,
    image_url,
    max_entries,
    prize_breakdown_info,
    rules_url,
    slate: slateResponse,
    slate_id,
    status,
    title,
    weekly_entry_style_id,
  } = lobbyWeeklyWinner;

  const slate = slateResponse && lobbySlateAdapter(slateResponse);

  return {
    id,
    additionalInfo: additional_info,
    contestStyleId: contest_style_id,
    currentWeeklyWinnerWeekId: current_weekly_winner_week_id,
    cutoffAt: cutoff_at,
    description,
    draftEntryStyleId: draft_entry_style_id,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    imageUrl: image_url,
    maxEntries: max_entries,
    prizeBreakdownInfo: prize_breakdown_info,
    rulesUrl: rules_url,
    slate,
    slateId: slate_id,
    status,
    title,
    weeklyEntryStyleId: weekly_entry_style_id,
  };
};

export default lobbyWeeklyWinnerAdapter;
