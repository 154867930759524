import { LandingPageConfigs, LandingPageConfigsResponse } from '@/interfaces/landing-page-configs';

import { SET_LANDING_PAGE_CONFIGS, setLandingPageConfigs } from '../actions';
import landingPageConfigsAdapter from '../adapters/landing-page-configs';

type State = LandingPageConfigs;

type LandingPageConfigsActions = ReturnType<typeof setLandingPageConfigs>;

const initialState: LandingPageConfigs = {
  id: '',
  landingPageConfigs: {
    allGamesStates: [],
    draftStates: [],
    numberOfAvailableStates: '',
    pickEmChampionsStates: [],
    pickEmOnlyStates: [],
  },
};

const set = (data: LandingPageConfigsResponse): LandingPageConfigs => {
  const landingPageConfigs = landingPageConfigsAdapter(data);
  return landingPageConfigs;
};

export const landingPageConfigsReducer = (
  state: State = initialState,
  action: LandingPageConfigsActions = {} as LandingPageConfigsActions
): State => {
  switch (action.type) {
    case SET_LANDING_PAGE_CONFIGS:
      return set(action.payload);
    default:
      return state;
  }
};
