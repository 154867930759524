import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { RootState } from '@/store';

import LogoModalHeader from '@/components/atoms/logo-modal-header';
/* eslint-disable-next-line import/no-cycle */
import LoginForm from '@/components/molecules/login-form';
import { User } from '@/interfaces/user';

import styles from './styles.scss';

export interface LoginModalProps {
  handleCloseModal: () => void;
  user: User;
}

const LoginModal = (props: LoginModalProps) => {
  const { handleCloseModal, user } = props;

  useEffect(() => {
    if (user.id) {
      handleCloseModal();
    }
  }, [user, handleCloseModal]);

  return (
    <div className={styles.modal}>
      <LogoModalHeader handleCloseModal={handleCloseModal} />
      <div className={styles.content}>
        <LoginForm isModal />
      </div>
    </div>
  );
};

export default connect((state: RootState) => ({
  user: state.user,
}))(LoginModal);
