import { Dispatch } from 'redux';

import { getDraftingConfig as getDraftingConfigAction } from '@/store/modules/drafting-config/actions';
import {
  getPickEm as getPickEmAction,
  getSlipPayoutOutcome as getSlipPayoutOutcomeAction,
  removeAirdropOffer as removeAirdropOfferAction,
  removePowerUp as removePowerUpAction,
  removeSelectedPickEmError as removeSelectedPickEmErrorAction,
  setPowerUpOnEntry as setPowerUpOnEntryAction,
} from '@/store/modules/pick-em/actions';

import { TokenAddedToEntryProperties } from '@/ampli';
import { DraftingConfig, StateConfig } from '@/interfaces/drafting-config';
import { PayoutOutcomeRequest } from '@/interfaces/pick-em';
import { PowerUpInventoryUpdatedResponse, RedeemablePowerUp } from '@/interfaces/power-ups';
import { Role } from '@/interfaces/user';

export const getDraftingConfigDispatcher = (dispatch: Dispatch) => () => {
  dispatch(getDraftingConfigAction());
};

export const getPickEmDispatcher =
  (dispatch: Dispatch) =>
  ({
    draftingConfig,
    pickEmStateConfig,
    roles,
    isAlternateProjectionsEnabled,
  }: {
    draftingConfig: DraftingConfig;
    pickEmStateConfig: StateConfig['pickEm'];
    roles: Role[];
    isAlternateProjectionsEnabled: boolean;
  }) => {
    dispatch(
      getPickEmAction({ draftingConfig, pickEmStateConfig, roles, isAlternateProjectionsEnabled })
    );
  };

export const getSlipPayoutOutcomeDispatcher =
  (dispatch: Dispatch) =>
  ({
    options,
    entryRoles,
    powerUpId,
  }: {
    options: PayoutOutcomeRequest[];
    entryRoles: string[];
    powerUpId: string;
  }) => {
    dispatch(
      getSlipPayoutOutcomeAction({
        options,
        entryRoles,
        powerUpId,
      })
    );
  };

export const removeSelectedPickEmErrorDispatcher = (dispatch: Dispatch) => () => {
  dispatch(removeSelectedPickEmErrorAction());
};

export const removeAirdropOfferDispatcher =
  (dispatch: Dispatch) => (data: { airdropOfferId: string }) =>
    dispatch(removeAirdropOfferAction(data));

export const removePowerUpDispatcher =
  (dispatch: Dispatch) => (data: PowerUpInventoryUpdatedResponse) =>
    dispatch(removePowerUpAction({ data }));

export const setPowerUpOnEntryDispatcher =
  (dispatch: Dispatch) =>
  ({
    action,
    location,
    powerUp,
    reason,
  }: {
    action: 'add' | 'apiRemove' | 'remove';
    location: TokenAddedToEntryProperties['location'];
    powerUp: RedeemablePowerUp;
    reason?: PowerUpInventoryUpdatedResponse['reason'];
  }) =>
    dispatch(setPowerUpOnEntryAction({ action, location, powerUp, reason }));
