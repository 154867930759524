import React from 'react';
import clsx from 'clsx';

import { svgSpriteNames } from '@/assets/generated/svg-sprite-sheet-types';

import { SvgSprite, type SvgSpriteProps } from '../svg-sprite';

import styles from './styles.scss';

export type IconTypes = SvgSpriteProps['spriteName'];

export interface IconProps {
  className?: string;
  customStyles?: {
    fill?: string;
  };
  name: IconTypes;
  title?: string;
}

const Icon = ({ name, className = '', customStyles, title }: IconProps) => {
  if (!iconExists(name)) {
    return null;
  }

  return (
    <i
      className={clsx(styles.icon, className, {
        [styles.newBackground]: name === 'new',
      })}
      title={title}
      data-testid={`test-icon-${name}`}
      style={{
        fill: customStyles?.fill,
      }}
    >
      <SvgSprite spriteName={name} />
    </i>
  );
};

export default Icon;

export function iconExists(icon: IconTypes) {
  return svgSpriteNames.has(icon);
}
