import { ExposureAppearance, ExposureAppearanceResponse } from '@/interfaces/exposure';

export const exposureAppearanceAdapter = (
  appearances: ExposureAppearanceResponse[]
): ExposureAppearance[] =>
  appearances.map((appearance) => ({
    appearanceId: appearance.appearance_id,
    pickCount: appearance.pick_count,
    totalEntryFees: appearance.total_entry_fees,
  }));
