import { CosmeticsAndAchievementsResponse } from '@/interfaces/constructed-interfaces/constructed-cosmetics-and-achievements';

export const GET_COSMETICS_AND_ACHIEVEMENTS = 'user/GET_COSMETICS_AND_ACHIEVEMENTS' as const;
export const SET_COSMETICS_AND_ACHIEVEMENTS = 'user/SET_COSMETICS_AND_ACHIEVEMENTS' as const;

export const getCosmeticsAndAchievements = () => ({ type: GET_COSMETICS_AND_ACHIEVEMENTS });

export const setCosmeticsAndAchievements = (payload: CosmeticsAndAchievementsResponse) => ({
  type: SET_COSMETICS_AND_ACHIEVEMENTS,
  payload,
});
