import { takeEvery, takeLatest } from 'redux-saga/effects';

import { GET_ACTIVE_DRAFTS } from '@/store/modules/active-drafts/actions';
import { getActiveDraftsSaga } from '@/store/modules/active-drafts/saga';
import {
  GET_APPEARANCE_STATS,
  GET_APPEARANCE_STATS_BY_ID,
  GET_WEEKLY_APPEARANCE_STATS,
} from '@/store/modules/appearance-stats/actions';
import {
  getAppearanceStatsByIdSaga,
  getAppearanceStatsSaga,
  getWeeklyAppearanceStatsSaga,
} from '@/store/modules/appearance-stats/saga';
import { GET_APPEARANCE_STATS_WK_AVG } from '@/store/modules/appearance-stats-wk-avg/actions';
import { getAppearanceStatsWkAvgSaga } from '@/store/modules/appearance-stats-wk-avg/saga';
import { GET_COMPLETED_DRAFTS } from '@/store/modules/completed-drafts/actions';
import { getCompletedDraftsSaga } from '@/store/modules/completed-drafts/saga';
import { GET_COMPLETED_SLATES } from '@/store/modules/completed-slates/actions';
import { getCompletedSlatesSaga } from '@/store/modules/completed-slates/saga';
import {
  GET_CONSTRUCTED_SLATE,
  GET_SLATE_APPEARANCES,
} from '@/store/modules/constructed-slates/actions';
import {
  getConstructedSlateSaga,
  getSlateAppearancesSaga,
} from '@/store/modules/constructed-slates/saga';
import { GET_CONTENTFUL_POWER_UP_INFO } from '@/store/modules/contentful-power-up-infos/actions';
import { getContentfulPowerUpInfoSaga } from '@/store/modules/contentful-power-up-infos/saga';
import { GET_CONTENTFUL_PROMO_INFO } from '@/store/modules/contentful-promo-info/actions';
import { getContentfulPromoInfoSaga } from '@/store/modules/contentful-promo-info/saga';
import { GET_COSMETICS_AND_ACHIEVEMENTS } from '@/store/modules/cosmetics/actions';
import { getCosmeticsAndAchievementsSaga } from '@/store/modules/cosmetics/saga';
import {
  GET_DRAFT_POOL,
  GET_DRAFT_POOLS_FOR_SLATE,
  GET_USER_ENTRIES_FOR_DRAFT_POOL,
} from '@/store/modules/draft-pools/actions';
import {
  getDraftPoolSaga,
  getDraftPoolsForSlateSaga,
  getUserEntriesForDraftPoolSaga,
} from '@/store/modules/draft-pools/saga';
import { GET_DRAFTING_CONFIG } from '@/store/modules/drafting-config/actions';
import { getDraftingConfigSaga } from '@/store/modules/drafting-config/saga';
import { GET_DRAFT, GET_DRAFT_WEEKLY_SCORES } from '@/store/modules/drafts/actions';
import { getDraftSaga, getDraftWeeklyScoresSaga } from '@/store/modules/drafts/saga';
import { GET_EXPOSURE } from '@/store/modules/exposure/actions';
import { getExposureSaga } from '@/store/modules/exposure/saga';
import { GET_GLOBAL_FEATURE_FLAGS } from '@/store/modules/feature-flags/actions';
import { getGlobalFeatureFlagsSaga } from '@/store/modules/feature-flags/sagas';
import { GET_GENERAL_RULES } from '@/store/modules/general-rules/actions';
import { getGeneralRulesSaga } from '@/store/modules/general-rules/saga';
import { GET_LANDING_PAGE_CONFIGS } from '@/store/modules/landing-page-configs/actions';
import { getLandingPageConfigsSaga } from '@/store/modules/landing-page-configs/saga';
import {
  GET_LIVE_DRAFTS_FOR_SLATE,
  GET_LIVE_SLATES_FOR_BEST_BALL_SPORT,
  GET_LIVE_SLATES_FOR_DAILY_SPORT,
} from '@/store/modules/live/actions';
import {
  getLiveDraftsForSlateSaga,
  getLiveSlatesForBestBallSportSaga,
  getLiveSlatesForDailySportSaga,
} from '@/store/modules/live/saga';
import {
  GET_CONTESTS_FOR_SLATE,
  GET_CONTESTS_FOR_SPORT_LOBBY,
  GET_MAIN_LOBBY,
  GET_SLATES_FOR_SPORT,
} from '@/store/modules/lobbies/actions';
import {
  getContestsForSlateSaga,
  getContestsForSportLobbySaga,
  getMainLobbySaga,
  getSlatesForSportSaga,
} from '@/store/modules/lobbies/saga';
import { GET_MATCHES_FOR_DATE } from '@/store/modules/matches/actions';
import { getMatchesForDateSaga } from '@/store/modules/matches/saga';
import {
  GET_ALTERNATE_PROJECTIONS,
  GET_LIVE_PICK_EM_ENTRIES,
  GET_PAYOUT_OUTCOME,
  GET_PICK_EM,
  GET_PICK_EM_POOL_LEADERBOARD,
  GET_PICK_EM_POOL_USER_LEADERBOARD,
  GET_POOL_V1_STYLES,
  GET_POOL_V2_STYLES,
  GET_SETTLED_PICK_EM_ENTRIES,
} from '@/store/modules/pick-em/actions';
import {
  getAlternateProjectionsSaga,
  getLivePickEmEntrySlipsSaga,
  getPayoutOutcomeSaga,
  getPickEmPoolLeaderboardSaga,
  getPickEmPoolUserLeaderboardSaga,
  getPickEmSaga,
  getPoolV1StylesSaga,
  getPoolV2StylesSaga,
  getSettledPickEmEntrySlipsSaga,
} from '@/store/modules/pick-em/saga';
import { GET_USER_PROMOTIONS } from '@/store/modules/promotions/actions';
import { getUserPromotionsSaga } from '@/store/modules/promotions/saga';
import { GET_SETTLED_DRAFTS_FOR_SLATE, GET_SETTLED_SLATES } from '@/store/modules/results/actions';
import { getSettledDraftsForSlateSaga, getSettledSlatesSaga } from '@/store/modules/results/saga';
import { GET_ALL_SIT_AND_GOS } from '@/store/modules/sit-and-gos/actions';
import { getAllSitAndGosSaga } from '@/store/modules/sit-and-gos/saga';
import { GET_MISC_SPORTS_RULES, GET_SPORTS_RULES } from '@/store/modules/sport-rules/actions';
import { getMiscSportsRulesSaga, getSportsRulesSaga } from '@/store/modules/sport-rules/saga';
import { GET_STATE_CONFIGURATION } from '@/store/modules/state-configuration/actions';
import { getStateConfigurationSaga } from '@/store/modules/state-configuration/saga';
import {
  GET_ALL_SWEEPSTAKES_TS_AND_CS,
  GET_SWEEPSTAKE_TS_AND_CS,
} from '@/store/modules/sweepstakes-terms-and-conditions/actions';
import {
  getAllSweepstakesTsAndCsSaga,
  getSweepstakesTsAndCsSaga,
} from '@/store/modules/sweepstakes-terms-and-conditions/saga';
import { GET_TEXT_PAGES } from '@/store/modules/text-pages/actions';
import { getTextPagesSaga } from '@/store/modules/text-pages/saga';
import {
  GET_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT,
  GET_TOURNAMENT_ROUNDS_FOR_SLATE,
  GET_USER_DRAFTS_FOR_TOURNAMENT_ROUNDS,
  GET_USER_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT,
} from '@/store/modules/tournament-rounds/actions';
import {
  getPaginatedDraftEntriesForDraftSaga,
  getTournamentRoundsForSlateSaga,
  getUserDraftsForTournamentRoundsSaga,
  getUserPaginatedDraftEntriesForDraftSaga,
} from '@/store/modules/tournament-rounds/saga';
import {
  GET_ACTIVE_TOURNAMENTS_RULES,
  GET_TOURNAMENT_RULES,
} from '@/store/modules/tournament-rules/actions';
import {
  getActiveTournamentsRulesSaga,
  getTournamentRulesSaga,
} from '@/store/modules/tournament-rules/saga';
import { GET_TOURNAMENT } from '@/store/modules/tournaments/actions';
import { getTournamentSaga } from '@/store/modules/tournaments/saga';
import { GET_TRANSACTIONS } from '@/store/modules/transactions/actions';
import { getTransactionsSaga } from '@/store/modules/transactions/saga';
import { GET_UNDERDOG_CAREERS_FAQS } from '@/store/modules/underdog-careers-faqs/actions';
import { getUnderdogCareersFaqsSaga } from '@/store/modules/underdog-careers-faqs/saga';
import { GET_UNDERDOG_TEAM } from '@/store/modules/underdog-team/actions';
import { getUnderdogTeamSaga } from '@/store/modules/underdog-team/saga';
import { GET_USER_BONUS_WALLET, GET_USER_STATE_CONFIG } from '@/store/modules/user/actions';
import { getUserBonusWalletSaga, getUserStateConfigSaga } from '@/store/modules/user/saga';
import { GET_WEEKLY_APPEARANCES } from '@/store/modules/weekly-appearances/actions';
import { getWeeklyAppearancesSaga } from '@/store/modules/weekly-appearances/saga';
import { GET_WEEKLY_MATCHES } from '@/store/modules/weekly-matches/actions';
import { getWeeklyMatchesSaga } from '@/store/modules/weekly-matches/saga';
import {
  GET_USER_DRAFTS_FOR_WEEKLY_WINNER,
  GET_USER_ENTRIES_FOR_WEEKLY_WINNER_WEEK,
  GET_USER_LEADERBOARD_MARKERS_FOR_WEEKLY_WINNER_WEEK,
  GET_WEEKLY_WINNER,
  GET_WEEKLY_WINNER_WEEKS,
  GET_WEEKLY_WINNERS_FOR_SLATE,
} from '@/store/modules/weekly-winners/actions';
import {
  getUserDraftsForWeeklyWinnersSaga,
  getUserEntriesForWeeklyWinnerSaga,
  getUserLeaderboardMarkersForWeeklyWinnerWeekSaga,
  getWeeklyWinnerSaga,
  getWeeklyWinnersForSlateSaga,
  getWeeklyWinnerWeeksSaga,
} from '@/store/modules/weekly-winners/saga';

function* rootSaga(): any {
  yield takeLatest(GET_ACTIVE_DRAFTS, getActiveDraftsSaga);
  yield takeLatest(GET_ACTIVE_TOURNAMENTS_RULES, getActiveTournamentsRulesSaga);
  yield takeLatest(GET_ALL_SIT_AND_GOS, getAllSitAndGosSaga);
  yield takeLatest(GET_ALL_SWEEPSTAKES_TS_AND_CS, getAllSweepstakesTsAndCsSaga);
  yield takeLatest(GET_APPEARANCE_STATS_BY_ID, getAppearanceStatsByIdSaga);
  yield takeLatest(GET_APPEARANCE_STATS, getAppearanceStatsSaga);
  yield takeLatest(GET_APPEARANCE_STATS_WK_AVG, getAppearanceStatsWkAvgSaga);
  yield takeLatest(GET_COMPLETED_DRAFTS, getCompletedDraftsSaga);
  yield takeLatest(GET_COMPLETED_SLATES, getCompletedSlatesSaga);
  yield takeLatest(GET_CONSTRUCTED_SLATE, getConstructedSlateSaga);
  yield takeLatest(GET_CONTENTFUL_PROMO_INFO, getContentfulPromoInfoSaga);
  yield takeLatest(GET_CONTENTFUL_POWER_UP_INFO, getContentfulPowerUpInfoSaga);
  yield takeLatest(GET_CONTESTS_FOR_SLATE, getContestsForSlateSaga);
  yield takeLatest(GET_COSMETICS_AND_ACHIEVEMENTS, getCosmeticsAndAchievementsSaga);
  yield takeLatest(GET_DRAFT_POOL, getDraftPoolSaga);
  yield takeLatest(GET_DRAFT_POOLS_FOR_SLATE, getDraftPoolsForSlateSaga);
  yield takeLatest(GET_DRAFT_WEEKLY_SCORES, getDraftWeeklyScoresSaga);
  yield takeLatest(GET_DRAFT, getDraftSaga);
  yield takeLatest(GET_DRAFTING_CONFIG, getDraftingConfigSaga);
  yield takeLatest(GET_EXPOSURE, getExposureSaga);
  yield takeLatest(GET_GENERAL_RULES, getGeneralRulesSaga);
  yield takeLatest(GET_GLOBAL_FEATURE_FLAGS, getGlobalFeatureFlagsSaga);
  yield takeLatest(GET_LANDING_PAGE_CONFIGS, getLandingPageConfigsSaga);
  yield takeLatest(GET_LIVE_DRAFTS_FOR_SLATE, getLiveDraftsForSlateSaga);
  yield takeLatest(GET_LIVE_PICK_EM_ENTRIES, getLivePickEmEntrySlipsSaga);
  yield takeLatest(GET_LIVE_SLATES_FOR_BEST_BALL_SPORT, getLiveSlatesForBestBallSportSaga);
  yield takeLatest(GET_LIVE_SLATES_FOR_DAILY_SPORT, getLiveSlatesForDailySportSaga);
  yield takeLatest(GET_MAIN_LOBBY, getMainLobbySaga);
  yield takeLatest(GET_CONTESTS_FOR_SPORT_LOBBY, getContestsForSportLobbySaga);
  yield takeLatest(GET_MATCHES_FOR_DATE, getMatchesForDateSaga);
  yield takeLatest(GET_MISC_SPORTS_RULES, getMiscSportsRulesSaga);
  yield takeLatest(GET_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT, getPaginatedDraftEntriesForDraftSaga);
  yield takeLatest(GET_PAYOUT_OUTCOME, getPayoutOutcomeSaga);
  yield takeLatest(GET_PICK_EM, getPickEmSaga);
  yield takeEvery(GET_ALTERNATE_PROJECTIONS, getAlternateProjectionsSaga);
  yield takeLatest(GET_PICK_EM_POOL_LEADERBOARD, getPickEmPoolLeaderboardSaga);
  yield takeLatest(GET_PICK_EM_POOL_USER_LEADERBOARD, getPickEmPoolUserLeaderboardSaga);
  yield takeLatest(GET_POOL_V1_STYLES, getPoolV1StylesSaga);
  yield takeLatest(GET_POOL_V2_STYLES, getPoolV2StylesSaga);
  yield takeLatest(GET_SETTLED_DRAFTS_FOR_SLATE, getSettledDraftsForSlateSaga);
  yield takeLatest(GET_SETTLED_PICK_EM_ENTRIES, getSettledPickEmEntrySlipsSaga);
  yield takeLatest(GET_SETTLED_SLATES, getSettledSlatesSaga);
  yield takeLatest(GET_SLATE_APPEARANCES, getSlateAppearancesSaga);
  yield takeLatest(GET_SLATES_FOR_SPORT, getSlatesForSportSaga);
  yield takeLatest(GET_SPORTS_RULES, getSportsRulesSaga);
  yield takeLatest(GET_STATE_CONFIGURATION, getStateConfigurationSaga);
  yield takeLatest(GET_SWEEPSTAKE_TS_AND_CS, getSweepstakesTsAndCsSaga);
  yield takeLatest(GET_TEXT_PAGES, getTextPagesSaga);
  yield takeLatest(GET_TOURNAMENT, getTournamentSaga);
  yield takeLatest(GET_TOURNAMENT_ROUNDS_FOR_SLATE, getTournamentRoundsForSlateSaga);
  yield takeLatest(GET_TOURNAMENT_RULES, getTournamentRulesSaga);
  yield takeLatest(GET_TRANSACTIONS, getTransactionsSaga);
  yield takeLatest(GET_USER_DRAFTS_FOR_TOURNAMENT_ROUNDS, getUserDraftsForTournamentRoundsSaga);
  yield takeLatest(GET_USER_ENTRIES_FOR_DRAFT_POOL, getUserEntriesForDraftPoolSaga);
  yield takeLatest(
    GET_USER_LEADERBOARD_MARKERS_FOR_WEEKLY_WINNER_WEEK,
    getUserLeaderboardMarkersForWeeklyWinnerWeekSaga
  );
  yield takeLatest(
    GET_USER_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT,
    getUserPaginatedDraftEntriesForDraftSaga
  );
  yield takeLatest(GET_UNDERDOG_CAREERS_FAQS, getUnderdogCareersFaqsSaga);
  yield takeLatest(GET_UNDERDOG_TEAM, getUnderdogTeamSaga);
  yield takeLatest(GET_USER_PROMOTIONS, getUserPromotionsSaga);
  yield takeLatest(GET_USER_BONUS_WALLET, getUserBonusWalletSaga);
  yield takeLatest(GET_USER_STATE_CONFIG, getUserStateConfigSaga);
  yield takeLatest(GET_WEEKLY_APPEARANCES, getWeeklyAppearancesSaga);
  yield takeLatest(GET_WEEKLY_APPEARANCE_STATS, getWeeklyAppearanceStatsSaga);
  yield takeLatest(GET_WEEKLY_MATCHES, getWeeklyMatchesSaga);
  yield takeLatest(GET_WEEKLY_WINNER, getWeeklyWinnerSaga);
  yield takeLatest(GET_WEEKLY_WINNER_WEEKS, getWeeklyWinnerWeeksSaga);
  yield takeLatest(GET_WEEKLY_WINNERS_FOR_SLATE, getWeeklyWinnersForSlateSaga);
  yield takeLatest(GET_USER_DRAFTS_FOR_WEEKLY_WINNER, getUserDraftsForWeeklyWinnersSaga);
  yield takeLatest(GET_USER_ENTRIES_FOR_WEEKLY_WINNER_WEEK, getUserEntriesForWeeklyWinnerSaga);
}

export default rootSaga;
