import { OpenToastProps, Toast } from '@/interfaces/toast';

import { CLOSE_TOAST, closeToast, OPEN_TOAST, openToast } from '../actions';

// TODO [FAN-2619]: Does the following line in all the reducers add too much complexity
type State = Toast;

const initialState: Toast = {
  isOpen: false,
  toastConfig: null,
};

type ToastActions = ReturnType<typeof openToast | typeof closeToast>;

const open = (data: OpenToastProps): Toast => ({
  isOpen: true,
  toastConfig: data,
});

const close = (): Toast => ({
  isOpen: false,
  toastConfig: null,
});

export const toastReducer = (
  state: State = initialState,
  action: ToastActions = {} as ToastActions
): State => {
  switch (action.type) {
    case OPEN_TOAST:
      return open(action.payload);
    case CLOSE_TOAST:
      return close();
    default:
      return state;
  }
};
