import { RENAME_DRAFT_ENTRY, renameDraftEntry } from '@/store/modules/drafts/actions';

import { RenameDraftEntry } from '@/interfaces/drafts';
import { SettledDraftsForSlate, SettledDraftsForSlateResponse } from '@/interfaces/results';

import { SET_SETTLED_DRAFTS_FOR_SLATE, setSettledDraftsForSlate } from '../actions';
import settledDraftsAdapter from '../adapters/settled-drafts';

type State = SettledDraftsForSlate | null;

type SettledDraftsActions = ReturnType<typeof setSettledDraftsForSlate | typeof renameDraftEntry>;

const initialState: SettledDraftsForSlate = {};

const set = (
  state: SettledDraftsForSlate,
  { data, slateId }: { data: SettledDraftsForSlateResponse; slateId: string }
): SettledDraftsForSlate => {
  const newDrafts = settledDraftsAdapter(data.drafts);

  const drafts = state?.[slateId]?.drafts
    ? [...state[slateId].drafts, ...newDrafts].filter(
        (draft, ind, arr) => arr.findIndex((d) => d.id === draft.id) === ind
      )
    : newDrafts;

  return {
    ...state,
    [slateId]: {
      drafts,
      meta: data.meta,
    },
  };
};

const rename = (state: SettledDraftsForSlate, data: RenameDraftEntry) => {
  const slate = state[data.slateId];
  if (!slate) return state;

  const drafts = slate.drafts.map((item) => {
    if (item.id === data.id) {
      return {
        ...item,
        title: data.draftEntryTitle,
      };
    }
    return item;
  });

  return {
    ...state,
    [data.slateId]: {
      drafts,
      meta: slate.meta,
    },
  };
};

export const settledDraftsForSlatesReducer = (
  state: State = initialState,
  action: SettledDraftsActions = {} as SettledDraftsActions
): State => {
  switch (action.type) {
    case SET_SETTLED_DRAFTS_FOR_SLATE:
      return set(state, action.payload);
    case RENAME_DRAFT_ENTRY:
      return rename(state, action.payload);
    default:
      return state;
  }
};
