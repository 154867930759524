/* eslint-disable camelcase */
import { IconTypes } from '@/components/atoms/icon';

import { SportId } from '../types';

/**
 * DraftingConfig redux helpers
 */
export enum SportStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  PICK_EM = 'pickEm', // to be deprecated
  HIDDEN = 'hidden',
}

export enum SportStatusKey {
  DRAFT_STATUS = 'draftStatus',
  NEWS_STATUS = 'newsStatus',
  PICKEM_STATUS = 'pickemStatus',
}

export interface Sport {
  color: string;
  gameType: string;
  icon: IconTypes;
  id: SportId;
  manual: boolean;
  name: string;
  overUndersEnabled: boolean;
  periods: number;
  periodLabel: string;
  periodsLabel: string;
  rank: number;
  rivalsEnabled: boolean;
  status: Exclude<SportStatus, 'hidden'>;
  draftStatus: Exclude<SportStatus, 'pickEm'>;
  pickemStatus: Exclude<SportStatus, 'pickEm'>;
  newsStatus: Exclude<SportStatus, 'pickEm'>;
  teamSport: boolean;
}
export interface Sports {
  [id: string]: Sport;
}

export interface PickSlot {
  id: string;
  slotId: string;
}

export interface PickSlots {
  [id: string]: PickSlot;
}

export interface Slot {
  id: string;
  color: string;
  displayType: 'always' | 'onSelection' | 'hidden' | 'hideOnDrafting';
  limitAt: number | null;
  name: string;
  positionIds: string[];
  rank: number;
  scoring: boolean;
}

export interface Slots {
  [id: string]: Slot;
}

export interface ContestStyle {
  id: string;
  bestBall: boolean;
  displayScoringGuideIds?: string[];
  name: string;
  pickSlots: PickSlots;
  rounds: number;
  rulesUrl: string | null;
  scoringTypeId: string;
  sportId: SportId;
  status: 'active' | 'inactive';
}

export interface ContestStyles {
  [id: string]: ContestStyle;
}

export interface Payout {
  placeEnd: number;
  placeStart: number;
  prize: string;
  prizeItem?: {
    id: string;
    amount: string;
    description: string;
    displayText: string;
    status: string;
    ticketType: string;
  };
}

export interface RoundPayout {
  placeEnd: number;
  placeStart: number;
  prize: string;
  round: number;
}

export interface EntryStyle {
  id: string;
  displayRake: boolean;
  entryCount: number;
  fee: string;
  guaranteed: boolean;
  payouts: Payout[];
  prize: string;
  rake?: string;
  specialPrize: boolean;
  specialPrizeTotal: string | null;
  tournamentRoundPayouts?: RoundPayout[];
}

export interface EntryStyles {
  [id: string]: EntryStyle;
}

/**
 * LineupStatuses
 */

export interface LineupStatus {
  id: string;
  abbr: string;
  autoPick: boolean;
  colorDarkMode: string;
  colorLightMode: string;
  display: string;
}

export interface LineupStatuses {
  [id: string]: LineupStatus;
}

export interface Stat {
  name: string;
  value: string;
}

export interface DisplayStat {
  displayGroup: string;
  stats: Stat[];
  title?: string;
}

export interface ScoringType {
  id: string;
  displayStats: DisplayStat[];
  sportId: string;
  title: string;
}

export interface ScoringTypes {
  [id: string]: ScoringType;
}

/**
 * StateConfig
 */

export interface StateConfig {
  pickEm: {
    maximumSelectionSize: number;
    minimumSelectionSize: number;
    payoutModifier: boolean;
    pickemPoolsEnabled: boolean; // allow user to enter
    pickemPoolsFreeEntry: boolean;
    pickemPoolsVisible: boolean; // show user pools
    visibleSports: SportId[];
    visibleStats: string[];
  };
}

/**
 * Teams
 */

export interface Team {
  id: string;
  abbr: string;
  name: string;
  nickname?: string;
  primaryColorDarkMode: string;
  primaryColorLightMode: string;
  secondaryColorDarkMode: string;
  secondaryColorLightMode: string;
  sportId: SportId;
}

export interface Teams {
  [id: string]: Team;
}

/*
 * DraftingConfig redux
 **********************
 */

export interface DraftingConfig {
  lastUpdated: Date;
  contestStyles: ContestStyles;
  entryStyles: EntryStyles;
  lineupStatuses: LineupStatuses;
  scoringTypes: ScoringTypes;
  slots: Slots;
  sports: Sports;
  teams: Teams;
}

/**
 * SetDraftingConfig response
 */

export interface SetDraftingConfigResponse {
  contestStylesResponse: ContestStylesResponse;
  entryStylesResponse: EntryStylesResponse;
  lineupStatusesResponse: LineupStatusesResponse;
  scoringTypesResponse: ScoringTypesResponse;
  slotsResponse: SlotsResponse;
  sportsResponse: SportsResponse;
  teamsResponse: TeamsResponse;
}

/**
 * Sports response
 */

export interface SportResponse {
  color: string;
  game_type: string;
  icon: IconTypes;
  id: SportId;
  manual: boolean;
  name: string;
  over_unders_enabled: boolean;
  periods: number;
  period_label: string;
  periods_label: string;
  rank: number;
  rivals_enabled: boolean;
  status: Exclude<SportStatus, 'hidden'>; // to be deprecated
  draft_status: Exclude<SportStatus, 'pickEm'>;
  pickem_status: Exclude<SportStatus, 'pickEm'>;
  news_status: Exclude<SportStatus, 'pickEm'>;
  team_sport: boolean;
}

export interface SportsResponse {
  sports: SportResponse[];
}

/**
 * ContestStyles response
 */

export interface PickSlotResponse {
  id: string;
  slot_id: number;
}

export interface ContestStyleResponse {
  id: string;
  best_ball: boolean;
  display_scoring_guide_ids?: string[];
  name: string;
  pick_slots: PickSlotResponse[];
  rounds: number;
  rules_url: string | null;
  scoring_type_id: string;
  sport_id: SportId;
  status: 'active' | 'inactive';
}

export interface ContestStylesResponse {
  contest_styles: ContestStyleResponse[];
}

/**
 * EntryStyles response
 */

export interface PayoutResponse {
  place_end: number;
  place_start: number;
  prize: string;
  prize_item: {
    id: string;
    amount: string;
    description: string;
    display_text: string;
    status: string;
    ticket_type: string;
  };
}

export interface RoundPayoutResponse {
  place_end: number;
  place_start: number;
  prize: string;
  round: number;
}

export interface EntryStyleResponse {
  id: string;
  display_rake: boolean;
  entry_count: number;
  fee: string;
  guaranteed: boolean;
  payouts: PayoutResponse[];
  prize: string;
  rake?: string;
  special_prize: boolean;
  special_prize_total: string | null;
  tournament_round_payouts?: RoundPayoutResponse[];
}

export interface EntryStylesResponse {
  entry_styles: EntryStyleResponse[];
}

/**
 * LineupStatuses response
 */

export interface LineupStatusResponse {
  id: string;
  abbr: string;
  auto_pick: boolean;
  color_dark_mode: string;
  color_light_mode: string;
  display: string;
}

export interface LineupStatusesResponse {
  lineup_statuses: LineupStatusResponse[];
}

/**
 * ScoringTypes response
 */

export interface DisplayStatResponse {
  display_group: string | null;
  stats: {
    name: string;
    value: string;
  }[];
}

export interface ScoringTypeResponse {
  id: string;
  display_stats: DisplayStatResponse[];
  sport_id: string;
  title: string;
}

export interface ScoringTypesResponse {
  scoring_types: ScoringTypeResponse[];
}

/**
 * Teams response
 */

export interface TeamResponse {
  id: string;
  abbr: string;
  // city: string; // this is set up in the api, but isn't used yet
  // division: string; // this is set up in the api, but isn't used yet
  name: string;
  nickname?: string;
  primary_color_dark_mode: string;
  primary_color_light_mode: string;
  secondary_color_dark_mode: string;
  secondary_color_light_mode: string;
  sport_id: SportId;
}

export interface TeamsResponse {
  teams: TeamResponse[];
}

/**
 * Positions response
 */

export interface PositionResponse {
  id: string;
  sport_id: string;
}

export interface PositionsResponse {
  positions: PositionResponse[];
}

/**
 * Slots response
 */

export interface SlotResponse {
  id: number;
  color: string;
  display_type: 'always' | 'on_selection' | 'hidden' | 'hide_on_drafting';
  limit_at: number | null;
  name: string;
  position_ids: string[];
  rank: number;
  scoring: boolean;
}

export interface SlotsResponse {
  slots: SlotResponse[];
}

/**
 * State config response
 */

export interface StateConfigResponse {
  state_config: {
    pick_em: {
      maximum_selection_size: number;
      minimum_selection_size: number;
      payout_modifiers: boolean;
      pickem_pools_enabled: boolean;
      pickem_pools_free_entry: boolean;
      pickem_pools_visible: boolean;
      visible_sports: SportId[];
      visible_stats: string[];
    };
  };
}
