import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import clsx from 'clsx';

import { useNavigationContext } from '@/components/contexts/navigation';

export interface NavLinkWrapperProps extends NavLinkProps {
  ariaLabel?: string;
  classNames: { navLink: string; activeNavLink: string };
  element: string | JSX.Element;
  testId?: string;
}

const NavLinkWrapper = (props: NavLinkWrapperProps) => {
  const { ariaLabel, end, classNames, element, onClick, to, testId } = props;
  const { getNextRoute } = useNavigationContext();

  return (
    <NavLink
      aria-label={ariaLabel}
      className={({ isActive }: { isActive: boolean }) =>
        clsx(classNames.navLink, { [classNames.activeNavLink]: isActive })
      }
      end={end}
      onClick={onClick}
      to={getNextRoute({ path: to, end })}
      data-testid={testId}
    >
      {element}
    </NavLink>
  );
};

export default NavLinkWrapper;
