/* eslint-disable camelcase */
import { Source } from '@/interfaces/drafts';
import { DraftForWeeklyWinner, DraftForWeeklyWinnerResponse } from '@/interfaces/weekly-winners';
import { toCamel } from '@/utilities/helpers';

export const draftForWeeklyWinnerAdapter = (
  draft: DraftForWeeklyWinnerResponse
): DraftForWeeklyWinner => {
  const {
    id,
    clock,
    contest_style_id,
    draft_at,
    entry_count,
    entry_role,
    entry_style_id,
    slate_id,
    source: sourceResponse,
    status,
    title,
    user_draft_entry_id,
    user_draft_entry_payout,
    user_draft_entry_payout_text,
    user_draft_entry_place,
    user_draft_entry_points,
  } = draft;

  return {
    id,
    clock,
    contestStyleId: contest_style_id,
    draftAt: draft_at,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    slateId: slate_id,
    source: (toCamel(sourceResponse) as Source) || null,
    status,
    title,
    userDraftEntryId: user_draft_entry_id,
    userDraftEntryPayout: user_draft_entry_payout,
    userDraftEntryPayoutText: user_draft_entry_payout_text,
    userDraftEntryPlace: user_draft_entry_place,
    userDraftEntryPoints: user_draft_entry_points,
  };
};
