import { SetDraftingConfigResponse } from '@/interfaces/drafting-config';

export const GET_DRAFTING_CONFIG = 'draftingConfig/GET_DRAFTING_CONFIG' as const; // main saga

export const SET_DRAFTING_CONFIG = 'draftingConfig/SET_DRAFTING_CONFIG' as const;

export const getDraftingConfig = () => ({ type: GET_DRAFTING_CONFIG });

export const setDraftingConfig = (payload: SetDraftingConfigResponse) => ({
  type: SET_DRAFTING_CONFIG,
  payload,
});
