/* eslint-disable camelcase */
import { PickEmAppearanceStat, PickEmAppearanceStatResponse } from '@/interfaces/pick-em';
import { toCamel } from '@/utilities/helpers';

const appearanceStatAdapter = (
  appearanceStat: PickEmAppearanceStatResponse
): PickEmAppearanceStat => {
  const {
    id,
    appearance_id: appearanceId,
    display_stat: displayStat,
    graded_by,
    pickem_stat_id: pickemStatId,
    stat,
  } = appearanceStat;

  return {
    id,
    appearanceId,
    displayStat,
    gradedBy: toCamel(graded_by) as PickEmAppearanceStat['gradedBy'],
    pickemStatId,
    stat,
  };
};

export default appearanceStatAdapter;
