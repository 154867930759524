import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { ampli } from '@/ampli';
import { displayDob, validDob } from '@/utilities/helpers';

export const useDobField = ({
  initialValue = '',
  eventType,
}: {
  initialValue?: string;
  eventType?: 'registration';
}) => {
  const [dob, setDobValue] = useState(initialValue);
  const [dobValid, setDobValid] = useState(false);
  const [dobError, setDobError] = useState<boolean | string>(false);

  const handleValidate = (value: string) => {
    let isValid = true;
    let errorMessage = '';

    if (value.length === 0) {
      isValid = false;
      errorMessage = 'Please enter a date of birth';
      return { isValid, errorMessage };
    }

    if (
      !dayjs(value, 'MM/DD/YYYY').isValid() ||
      dayjs(value, 'MM/DD/YYYY').format('MM/DD/YYYY') !== value
    ) {
      isValid = false;
      errorMessage = 'Please enter a valid date of birth';
      return { isValid, errorMessage };
    }

    const [month, day, year] = value.split('/');

    if (parseInt(month, 10) > 12 || parseInt(month, 10) < 1) {
      isValid = false;
      errorMessage = 'Please enter a month between 01 and 12';
    } else if (parseInt(year, 10) > dayjs().year()) {
      isValid = false;
      errorMessage = 'Please enter a valid date of birth';
    } else if (parseInt(day, 10) > 31 || parseInt(day, 10) < 1) {
      isValid = false;
      errorMessage = 'Please enter a day between 01 and 31';
    } else if (!validDob(value)) {
      isValid = false;
      errorMessage = 'You must be over 18 to join';
    } else {
      isValid = true;
      errorMessage = '';
    }
    if (!isValid) {
      setDobValid(isValid);
      return { isValid, errorMessage };
    }

    setDobValid(true);
    setDobError(false);

    return {
      isValid,
      errorMessage,
    };
  };

  const handleDobInit = useCallback((value: string) => {
    setDobValue(value);
    handleValidate(value);
  }, []);

  const onDobChange = ({ value }: { value: string }) => {
    const strippedVal = value.replace(/\D/g, '');
    setDobValue(displayDob(strippedVal));
    handleValidate(value);
  };

  const onDobBlur = () => {
    const { isValid, errorMessage } = handleValidate(dob);
    if (!isValid) {
      setDobValid(false);
      setDobError(errorMessage);

      if (eventType === 'registration') {
        const userDob = dayjs(dob);
        const age = dayjs().diff(userDob, 'year');
        ampli.registrationBirthdayEnteredUnsuccessful({
          age: age > 0 ? age : 0,
        });
      }
    }
  };

  useEffect(() => {
    if (initialValue) {
      handleDobInit(initialValue);
    }
  }, [handleDobInit, initialValue]);

  return {
    dob,
    dobError,
    dobValid,
    onDobChange,
    onDobBlur,
    handleDobInit,
  };
};
