/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import clsx from 'clsx';

import { RootState } from '@/store';
import {
  closeToast as closeToastAction,
  openToast as openToastAction,
} from '@/store/modules/toast/actions';

import Icon from '@/components/atoms/icon';
import { OpenToastProps } from '@/interfaces/toast';

import styles from './styles.scss';

export interface ToastMessage {
  message: string | React.ReactNode;
  key: number;
}

export interface ToastProps {
  isOpen: boolean;
  closeToast: (data?: OpenToastProps) => any;
  toastProps: OpenToastProps;
}

const Toast = ({ isOpen, closeToast, toastProps }: ToastProps) => {
  // separating these so that we don't pass customClose into snackbar
  const {
    autoHideDuration = 6000,
    customClose,
    showCloseButton = false,
    message,
    variant = 'primary',
    ...remainingToastProps
  } = toastProps || {};

  const [open, setOpen] = useState(isOpen);
  const [messageQueue, setMessageQueue] = useState<readonly ToastMessage[]>([]);
  const [toastMessage, setToastMessage] = useState<ToastMessage | null>(null);

  useEffect(() => {
    if (messageQueue.length && !toastMessage) {
      // Set a new snack when we don't have an active one
      setToastMessage({ ...messageQueue[0] });
      setMessageQueue((prev) => prev.slice(1));
      setOpen(true);
    } else if (messageQueue.length && toastMessage && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [messageQueue, toastMessage, open]);

  useEffect(() => {
    if (message && isOpen) {
      setMessageQueue((prev) => [...prev, { message, key: new Date().getTime() }]);
    }

    if (!message) {
      setOpen(false);
    }
  }, [message, isOpen]);

  const handleExited = () => {
    customClose?.();
    setToastMessage(null);
    if (messageQueue.length < 1) {
      closeToast();
    }
  };

  return (
    <Snackbar
      {...remainingToastProps}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={autoHideDuration}
      data-testid="snackbar-container"
      onClose={() => setOpen(false)}
      open={open}
      TransitionProps={{ onExited: handleExited }}
    >
      <SnackbarContent
        action={
          showCloseButton && (
            <button onClick={() => setOpen(false)}>
              <Icon name="clear" className={clsx(styles.clearIcon, styles[variant])} />
            </button>
          )
        }
        classes={{ message: styles.message }}
        className={clsx(styles.snackbarContent, styles[variant])}
        data-testid="snackbar-content"
        message={toastMessage && toastMessage.message}
      />
    </Snackbar>
  );
};

export const useToast = () => {
  const store = useStore();
  return useCallback(
    (toastConfig: OpenToastProps) => store.dispatch(openToastAction(toastConfig)),
    [store]
  );
};

export default connect(
  (state: RootState) => ({
    isOpen: state.toast.isOpen,
    toastProps: state.toast.toastConfig,
  }),
  (dispatch) => ({
    closeToast: () => dispatch(closeToastAction()),
  })
)(Toast);
