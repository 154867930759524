import { EntryCollection } from 'contentful';

import { ITextPageFields } from '@/interfaces/contentful';
import { TextPages } from '@/interfaces/text-pages';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import { SET_TEXT_PAGES, setTextPages } from '../actions';
import textPageAdapter from '../adapters/text-page';

type State = TextPages;

type TextPagesActions = ReturnType<typeof setTextPages>;

const initialState: TextPages = { pages: {} };

const set = (
  state: State,
  {
    textPages,
  }: {
    textPages: EntryCollection<ITextPageFields> | Record<string, never>;
  }
): TextPages => {
  const allTextPages = textPages.items.map(textPageAdapter);
  const currentTextPage = arrayToObjectIdKeys(allTextPages, 'id');
  return {
    ...state,
    pages: {
      ...state.pages,
      ...currentTextPage,
    },
  };
};

export const textPagesReducer = (
  state: State = initialState,
  action: TextPagesActions = {} as TextPagesActions
): State => {
  switch (action.type) {
    case SET_TEXT_PAGES:
      return set(state, action.payload);
    default:
      return state;
  }
};
