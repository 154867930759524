/* eslint-disable camelcase */

import { Slot, SlotResponse } from '@/interfaces/drafting-config';
import { toCamel } from '@/utilities/helpers';

const slotAdapter = (slot: SlotResponse): Slot => {
  const { id, color, display_type, limit_at, name, position_ids, rank, scoring } = slot;

  return {
    id: id.toString(),
    color,
    displayType: toCamel(display_type) as Slot['displayType'],
    limitAt: limit_at,
    name,
    positionIds: position_ids,
    rank,
    scoring,
  };
};

export default slotAdapter;
