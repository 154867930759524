/* eslint-disable camelcase */
import { LobbyTournament, LobbyTournamentResponse } from '@/interfaces/lobbies';

import lobbySlatesAdapter from './lobby-slate';
import tournamentRoundAdapter from './lobby-tournament-rounds';

const tournamentsAdapter = (lobbyTournaments: LobbyTournamentResponse[]): LobbyTournament[] =>
  lobbyTournaments.map((lobbyTournament) => {
    const {
      id,
      additional_info,
      contest_style_id,
      cutoff_at,
      description,
      entry_count,
      entry_role,
      entry_style_id,
      image_url,
      max_entries,
      rules_url,
      slates: slatesResponse,
      title,
      tournament_rounds,
    } = lobbyTournament;

    const slates = slatesResponse?.map(lobbySlatesAdapter);
    const tournamentRounds = tournament_rounds?.map(tournamentRoundAdapter);

    return {
      id,
      additionalInfo: additional_info,
      contestStyleId: contest_style_id,
      cutoffAt: cutoff_at,
      description,
      entryCount: entry_count,
      entryRole: entry_role,
      entryStyleId: entry_style_id,
      imageUrl: image_url,
      maxEntries: max_entries,
      rulesUrl: rules_url,
      slates,
      title,
      tournamentRounds,
    };
  });

export default tournamentsAdapter;
