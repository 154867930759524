import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export interface FadeAnimationProps {
  defaultContent: JSX.Element | string;
  incomingContent: JSX.Element | string;
  swapCondition: boolean;
}

const FadeAnimation = (props: FadeAnimationProps) => {
  const { defaultContent, incomingContent, swapCondition } = props;

  return swapCondition ? (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        key="incomingContent"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {incomingContent}
      </motion.div>
    </AnimatePresence>
  ) : (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        key="defaultContent"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {defaultContent}
      </motion.div>
    </AnimatePresence>
  );
};

export default FadeAnimation;
