// Be mindful of any dependencies we add to this file. The loader will
// typically be called before the app is rendered, which is when
// most of our service dependencies are initialized (for now).
// This is also why we don't emit errors to our logging service here and
// instead just emit them to the console.

import { svgSpriteSheetFetchPath } from '../generated/svg-sprite-sheet-stuff';

export async function loadSvgSpriteSheet() {
  try {
    const response = await fetch(`${svgSpriteSheetFetchPath}`);
    if (!response.ok) {
      // In rare cases, we may encounter a non-200 status code from the server
      // when fetching the sprite sheet. We don't want to throw an error and
      // completely prevent the app from loading, so we just console the error
      // and continue. The app will render without icons in this case.
      // eslint-disable-next-line no-console
      console.error('Error fetching sprite sheet:', response.statusText);
      return;
    }

    const svgText = await response.text();
    if (svgText.indexOf('<svg') === -1) {
      // If the response doesn't contain an SVG element, we assume some unknown error.
      // eslint-disable-next-line no-console
      console.error('Unexpected sprite sheet content:', svgText);
      return;
    }

    let container = document.querySelector<HTMLDivElement>('#svg-spritesheet__ud');
    if (!container) {
      container = document.createElement('div');
      container.id = 'svg-spritesheet__ud';
      // We want to "hide" the sprite sheet but can't use `display:none` because
      // that prevents elements within the sprite sheet from being referenced.
      container.style.width = '0';
      container.style.height = '0';
      document.body.appendChild(container);
    }
    container.innerHTML = svgText;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to load SVG sprite sheet:', error);
  }
}
