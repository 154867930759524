import { AxiosResponse } from 'axios';

import request from '@request';
import { DraftResponse, UnfilledDraftsResponse } from '@/interfaces/drafts';
import { UserLeaderboardMarkersResponse } from '@/interfaces/leaderboard';
import {
  DraftsForWeeklyWinnerResponse,
  WeeklyWinnerEntryResponse,
  WeeklyWinnerResponse,
  WeeklyWinnersForSlate,
  WeeklyWinnerWeekEntriesResponse,
  WeeklyWinnerWeekLeaderboardResponse,
  WeeklyWinnerWeeksResponse,
} from '@/interfaces/weekly-winners';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getWeeklyWinner = async ({
  weeklyWinnerId,
}: {
  weeklyWinnerId: string;
}): Promise<AxiosResponse<WeeklyWinnerResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/weekly_winners/${weeklyWinnerId}`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const enterWeeklyWinner = async ({
  weeklyWinnerId,
  confirmed,
  draft,
  entryCount,
}: {
  weeklyWinnerId: string;
  confirmed?: boolean;
  draft: { clock: number };
  entryCount: string;
}): Promise<AxiosResponse<DraftResponse>> => {
  try {
    const response = await request({
      method: 'POST',
      url: `/weekly_winners/${weeklyWinnerId}/entries`,
      requiresGeoComply: true,
      params: {
        ...(confirmed ? { confirmed: true } : {}),
      },
      data: {
        draft,
        entry_count: entryCount,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUnfilledWeeklyWinner = async ({
  weeklyWinnerId,
}: {
  weeklyWinnerId: string;
}): Promise<AxiosResponse<UnfilledDraftsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/weekly_winners/${weeklyWinnerId}/unfilled_drafts`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getWeeklyWinnersForSlate = async ({
  slateId,
}: {
  slateId: string;
}): Promise<AxiosResponse<WeeklyWinnersForSlate>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/slates/${slateId}/weekly_winners`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUserDraftsForWeeklyWinner = async ({
  weeklyWinnerId,
  page,
}: {
  weeklyWinnerId: string;
  page?: number;
}): Promise<AxiosResponse<DraftsForWeeklyWinnerResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/weekly_winners/${weeklyWinnerId}/drafts`,
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getWeeklyWinnerWeeks = async ({
  weeklyWinnerId,
}: {
  weeklyWinnerId: string;
}): Promise<AxiosResponse<WeeklyWinnerWeeksResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/weekly_winners/${weeklyWinnerId}/weekly_winner_weeks`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUserEntriesForWeeklyWinner = async ({
  weeklyWinnerWeekId,
}: {
  weeklyWinnerWeekId: string;
}): Promise<AxiosResponse<WeeklyWinnerWeekEntriesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/weekly_winner_weeks/${weeklyWinnerWeekId}/entries`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUserLeaderboardMarkersForWeeklyWinnerWeek = async ({
  weeklyWinnerWeekId,
}: {
  weeklyWinnerWeekId: string;
}): Promise<AxiosResponse<UserLeaderboardMarkersResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/weekly_winner_weeks/${weeklyWinnerWeekId}/tracker`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getLeaderboardForWeeklyWinner = async ({
  weeklyWinnerWeekId,
  page,
}: {
  weeklyWinnerWeekId: string;
  page?: number;
}): Promise<AxiosResponse<WeeklyWinnerWeekLeaderboardResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/weekly_winner_weeks/${weeklyWinnerWeekId}/leaderboard`,
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getWeeklyWinnerEntry = async ({
  weeklyWinnerEntryId,
}: {
  weeklyWinnerEntryId: string;
}): Promise<AxiosResponse<WeeklyWinnerEntryResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/weekly_winner_entries/${weeklyWinnerEntryId}`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
