import { Transactions, TransactionsResponse } from '@/interfaces/transactions';

import { SET_TRANSACTIONS, setTransactions } from '../actions';
import transactionsAdapter from '../adapters/transactions';

type State = Transactions | null;

type TransactionsActions = ReturnType<typeof setTransactions>;

const initialState: Transactions = {
  transactions: [],
  meta: null,
};

const set = (state: Transactions, data: TransactionsResponse): Transactions => {
  const newTransactions = transactionsAdapter(data);
  // filter out duplicates just in case
  const transactions = [...state.transactions, ...newTransactions.transactions].filter(
    (transaction, ind, arr) => arr.findIndex((t) => t.id === transaction.id) === ind
  );

  return {
    transactions,
    meta: newTransactions.meta,
  };
};

export const transactionsReducer = (
  state: State = initialState,
  action: TransactionsActions = {} as TransactionsActions
): State => {
  switch (action.type) {
    case SET_TRANSACTIONS:
      return set(state, action.payload);
    default:
      return state;
  }
};
