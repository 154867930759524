import React from 'react';

import Icon from '@/components/atoms/icon';

import styles from './styles.scss';

interface ModalWrapperProps {
  children: React.ReactNode;
  handleCloseModal: () => void;
  hideCloseButton?: boolean;
}

const ModalWrapper = (props: ModalWrapperProps) => {
  const { children, handleCloseModal, hideCloseButton } = props;

  return (
    <div className={styles.modalWrapper}>
      {!hideCloseButton && (
        <button onClick={handleCloseModal} className={styles.clearIcon} aria-label="Close">
          <Icon name="clear" />
        </button>
      )}
      {children}
    </div>
  );
};

export default ModalWrapper;
