import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export interface TextSlideUpAndFadeAnimationProps {
  defaultContent: JSX.Element | string;
  incomingContent: JSX.Element | string;
  swapCondition: boolean;
  secondarySwapCondition?: boolean;
}

const TextSlideUpAndFadeAnimation = (props: TextSlideUpAndFadeAnimationProps) => {
  const { defaultContent, incomingContent, swapCondition, secondarySwapCondition } = props;

  return swapCondition ? (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        key="incomingContent"
        initial={{ y: 0, opacity: 0 }}
        animate={{ y: secondarySwapCondition ? 0 : [10, 0], opacity: 1 }}
        exit={{ y: [0, -10], opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        {incomingContent}
      </motion.div>
    </AnimatePresence>
  ) : (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        key="defaultContent"
        initial={{ y: 0, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: secondarySwapCondition ? [0, -10] : 0, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        {defaultContent}
      </motion.div>
    </AnimatePresence>
  );
};

export default TextSlideUpAndFadeAnimation;
