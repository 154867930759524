import React, { useState } from 'react';
import clsx from 'clsx';

import styles from './styles.scss';

export interface ToolTipProps {
  labelEl: JSX.Element;
  messageEl: JSX.Element | null;
  align?: 'left' | 'right' | 'center';
  position?: 'top' | 'bottom';
  classNames?: {
    toolTip: string;
    message: string;
  };
}

const ToolTip = (props: ToolTipProps) => {
  const { labelEl, messageEl, align = 'center', position = 'bottom', classNames } = props;

  const [showMessage, setShowMessage] = useState(false);

  return (
    <div className={clsx(styles.toolTip, classNames?.toolTip)} data-testid="toolTip">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setShowMessage(messageEl ? !showMessage : false);
        }}
      >
        {labelEl}
      </button>
      {showMessage && (
        <div
          className={clsx(
            styles.message,
            styles[`align-${align}`],
            styles[`position-${position}`],
            classNames?.message
          )}
        >
          {messageEl}
        </div>
      )}
    </div>
  );
};

export default ToolTip;
