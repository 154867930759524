/* eslint-disable camelcase */

import { LineupStatus, LineupStatusResponse } from '@/interfaces/drafting-config';

const lineupStatusAdapter = (lineupStatus: LineupStatusResponse): LineupStatus => {
  const { id, abbr, auto_pick, color_dark_mode, color_light_mode, display } = lineupStatus;

  return {
    id,
    abbr,
    autoPick: auto_pick,
    colorDarkMode: color_dark_mode,
    colorLightMode: color_light_mode,
    display,
  };
};

export default lineupStatusAdapter;
