/* eslint-disable max-len */
import { AxiosResponse } from 'axios';

import request from '@request';
import { SettledDraftsForSlateResponse, SettledSlatesResponse } from '@/interfaces/results';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getSettledSlates = async ({
  sportId,
  page,
}: {
  sportId: string;
  page?: number;
}): Promise<AxiosResponse<SettledSlatesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/sports/${sportId}/settled_slates`,
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getSettledDraftsForSlate = async ({
  slateId,
  page,
}: {
  slateId: string;
  page: number;
}): Promise<AxiosResponse<SettledDraftsForSlateResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/slates/${slateId}/settled_drafts`,
      underdogAPIVersion: 'v2',
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
