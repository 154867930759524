import { UserLeaderboardMarkersResponse } from '@/interfaces/leaderboard';
import {
  DraftsForWeeklyWinnerResponse,
  LeaderboardMarkerPointsUpdateForWWWResponse,
  WeeklyWinnerResponse,
  WeeklyWinnersForSlateResponse,
  WeeklyWinnerWeekEntriesResponse,
  WeeklyWinnerWeekEntryScoreUpdateResponse,
  WeeklyWinnerWeeksResponse,
} from '@/interfaces/weekly-winners';

export const GET_WEEKLY_WINNER = 'weekly-winners/GET_WEEKLY_WINNER' as const;
export const SET_WEEKLY_WINNER = 'weekly-winners/SET_WEEKLY_WINNER' as const;

export const GET_WEEKLY_WINNERS_FOR_SLATE = 'weekly-winners/GET_WEEKLY_WINNERS_FOR_SLATE' as const;
export const SET_WEEKLY_WINNERS_FOR_SLATE = 'weekly-winners/SET_WEEKLY_WINNERS_FOR_SLATE' as const;

/* eslint-disable max-len */
export const GET_USER_DRAFTS_FOR_WEEKLY_WINNER =
  'weekly-winners/GET_USER_DRAFTS_FOR_WEEKLY_WINNER' as const;
export const SET_USER_DRAFTS_FOR_WEEKLY_WINNER =
  'weekly-winners/SET_USER_DRAFTS_FOR_WEEKLY_WINNER' as const;

export const GET_USER_ENTRIES_FOR_WEEKLY_WINNER_WEEK =
  'weekly-winners/GET_USER_ENTRIES_FOR_WEEKLY_WINNER_WEEK' as const;
export const SET_USER_ENTRIES_FOR_WEEKLY_WINNER_WEEK =
  'weekly-winners/SET_USER_ENTRIES_FOR_WEEKLY_WINNER_WEEK' as const;

export const GET_USER_LEADERBOARD_MARKERS_FOR_WEEKLY_WINNER_WEEK =
  'weekly-winners/GET_USER_LEADERBOARD_MARKERS_FOR_WEEKLY_WINNER' as const;
export const SET_USER_LEADERBOARD_MARKERS_FOR_WEEKLY_WINNER_WEEK =
  'weekly-winners/SET_USER_LEADERBOARD_MARKERS_FOR_WEEKLY_WINNER' as const;
export const UPDATE_LEADERBOARD_MARKER_POINTS =
  'weekly-winners/UPDATE_LEADERBOARD_MARKER_POINTS' as const;

export const GET_WEEKLY_WINNER_WEEKS = 'weekly-winners/GET_WEEKLY_WINNER_WEEKS' as const;
export const SET_WEEKLY_WINNER_WEEKS = 'weekly-winners/SET_WEEKLY_WINNER_WEEKS' as const;

export const UPDATE_ENTRIES_FOR_WEEKLY_WINNER_WEEK =
  'weekly-winners/UPDATE_ENTRIES_FOR_WEEKLY_WINNER_WEEK' as const;
/* eslint-enable max-len */

export const getWeeklyWinner = (payload: { weeklyWinnerId: string }) => ({
  type: GET_WEEKLY_WINNER,
  payload,
});

export const setWeeklyWinner = (payload: WeeklyWinnerResponse) => ({
  type: SET_WEEKLY_WINNER,
  payload,
});

export const getWeeklyWinnersForSlate = (payload: { slateId: string }) => ({
  type: GET_WEEKLY_WINNERS_FOR_SLATE,
  payload,
});

export const setWeeklyWinnersForSlate = (payload: {
  data: WeeklyWinnersForSlateResponse;
  slateId: string;
}) => ({ type: SET_WEEKLY_WINNERS_FOR_SLATE, payload });

export const getUserDraftsForWeeklyWinner = (payload: {
  weeklyWinnerId: string;
  page?: number;
}) => ({ type: GET_USER_DRAFTS_FOR_WEEKLY_WINNER, payload });

export const setUserDraftsForWeeklyWinner = (payload: {
  data: DraftsForWeeklyWinnerResponse;
  weeklyWinnerId: string;
}) => ({ type: SET_USER_DRAFTS_FOR_WEEKLY_WINNER, payload });

export const getUserEntriesForWeeklyWinnerWeek = (payload: {
  weeklyWinnerWeekId: string;
  page?: number;
}) => ({ type: GET_USER_ENTRIES_FOR_WEEKLY_WINNER_WEEK, payload });

export const setUserEntriesForWeeklyWinnerWeek = (payload: {
  data: WeeklyWinnerWeekEntriesResponse;
  weeklyWinnerWeekId: string;
}) => ({ type: SET_USER_ENTRIES_FOR_WEEKLY_WINNER_WEEK, payload });

export const getWeeklyWinnerWeeks = (payload: { weeklyWinnerId: string; page?: number }) => ({
  type: GET_WEEKLY_WINNER_WEEKS,
  payload,
});

export const setWeeklyWinnerWeeks = (payload: {
  data: WeeklyWinnerWeeksResponse;
  weeklyWinnerId: string;
}) => ({ type: SET_WEEKLY_WINNER_WEEKS, payload });

export const getUserLeaderboardMarkersForWeeklyWinnerWeek = (payload: {
  weeklyWinnerWeekId: string;
}) => ({ type: GET_USER_LEADERBOARD_MARKERS_FOR_WEEKLY_WINNER_WEEK, payload });

export const setUserLeaderboardMarkersForWeeklyWinnerWeek = (payload: {
  data: UserLeaderboardMarkersResponse;
  weeklyWinnerWeekId: string;
}) => ({ type: SET_USER_LEADERBOARD_MARKERS_FOR_WEEKLY_WINNER_WEEK, payload });

export const updateLeaderboardMarkerPoints = (
  payload: LeaderboardMarkerPointsUpdateForWWWResponse
) => ({
  payload,
  type: UPDATE_LEADERBOARD_MARKER_POINTS,
});

export const updateEntriesForWeeklyWinnerWeek = (payload: {
  data: WeeklyWinnerWeekEntryScoreUpdateResponse;
  draftId: string;
  weeklyWinnerWeekId: string;
}) => ({ type: UPDATE_ENTRIES_FOR_WEEKLY_WINNER_WEEK, payload });
