/* eslint-disable camelcase */
import airdropAdapter from '@/store/modules/airdrops/adapters';
import appearanceAdapter from '@/store/modules/constructed-slates/adapters/appearance';
import playerAdapter from '@/store/modules/constructed-slates/adapters/player';
import matchAdapter from '@/store/modules/matches/adapters/match';
import powerUpAdapter from '@/store/modules/power-ups/adapters/power-up';

import {
  FeaturedLobby,
  FeaturedLobbyResponse,
  PlayerGroupValue,
  RivalLinesResponse,
} from '@/interfaces/pick-em';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import overUnderLineAdapter from './over-under-lines';
import pickEmPackAdapter from './pack';
import rivalLineAdapter from './rival-line';
import soloGameAdapter from './solo-game';

const featuredLobbyAdapter = (
  featuredLobby: FeaturedLobbyResponse,
  rivalLines: RivalLinesResponse['rival_lines']
): FeaturedLobby => {
  const {
    airdrop_offers,
    appearances,
    games,
    over_under_lines,
    pickem_packs,
    player_groups,
    players,
    power_ups = [],
    rival_lines,
    sections,
    solo_games,
  } = featuredLobby;

  const adaptedSections = sections.map((section) => ({
    title: section.title,
    contentType: section.content_type,
    key: section.key,
    sportId: section.sport_id,
    style: section.style,
  }));

  const playerGroupsKeys = Object.keys(player_groups);
  const adaptedPlayerGroups: { [playerGroupKey: string]: PlayerGroupValue[] } = {};

  playerGroupsKeys.forEach((playerGroupKey) => {
    adaptedPlayerGroups[playerGroupKey] = player_groups[playerGroupKey].map((player) => ({
      id: player.id,
      appearanceIds: player.appearance_ids,
    }));
  });

  const adaptedAirdrops = airdrop_offers.map(airdropAdapter);
  const adaptedAppearances = appearances.map(appearanceAdapter);
  const adaptedGames = games.map(matchAdapter);
  const adaptedOverUnderLines = over_under_lines.map(overUnderLineAdapter);
  const adaptedRivalLines = rival_lines.map(rivalLineAdapter);
  const adaptedPickemPacks = pickem_packs.map(pickEmPackAdapter);
  const adaptedPlayers = arrayToObjectIdKeys(players.map(playerAdapter));
  const adaptedPowerUps = power_ups.map(powerUpAdapter);
  const adaptedSoloGames = solo_games.map(soloGameAdapter);

  // ranked by # of overUnderLines per appearance
  const adaptedFavorites = adaptedPlayerGroups.favorites
    ? adaptedPlayerGroups.favorites
        .map((favoritePlayer) => {
          // if no appearances, return favorite player with 0 active lines
          if (favoritePlayer.appearanceIds.length === 0) {
            return {
              ...favoritePlayer,
              numberOfActiveLines: 0,
              adaptedPlayer: adaptedPlayers[favoritePlayer.id],
            };
          }

          const activeOverUnderLines = adaptedOverUnderLines.filter((oUL) =>
            favoritePlayer.appearanceIds.includes(oUL.overUnder?.appearanceStat.appearanceId)
          );

          const numberOfActiveRivalLines = rivalLines.filter(
            (rivalLine) =>
              favoritePlayer.appearanceIds.includes(
                rivalLine.options[0].appearance_stat.appearance_id
              ) ||
              favoritePlayer.appearanceIds.includes(
                rivalLine.options[1].appearance_stat.appearance_id
              )
          )?.length;

          const numberOfActiveLines = activeOverUnderLines.length + numberOfActiveRivalLines;

          return {
            ...favoritePlayer,
            numberOfActiveLines,
            adaptedPlayer: adaptedPlayers[favoritePlayer.id],
          };
        })
        // check if adaptedPlayer exists, it may not if player isn't available in that state/region
        .filter((adaptedFavorite) => adaptedFavorite.adaptedPlayer)
        // sort by # of active lines
        .sort((a, b) => b.numberOfActiveLines - a.numberOfActiveLines)
    : []; // empty array if no favorites

  return {
    airdrops: adaptedAirdrops,
    appearances: adaptedAppearances,
    // constructedAppearances and constructedRivalLines will be set in reducer
    constructedAppearances: {},
    constructedPromoAppearances: [],
    constructedRivalLines: [],
    favoritePlayers: adaptedFavorites,
    games: adaptedGames,
    overUnderLines: adaptedOverUnderLines,
    pickemPacks: adaptedPickemPacks,
    playerGroups: adaptedPlayerGroups,
    players: adaptedPlayers,
    powerUps: adaptedPowerUps,
    rivalLines: adaptedRivalLines,
    sections: adaptedSections,
    soloGames: adaptedSoloGames,
  };
};

export default featuredLobbyAdapter;
