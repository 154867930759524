import { TransactionsResponse } from '@/interfaces/transactions';

export const GET_TRANSACTIONS = 'user/GET_TRANSACTIONS' as const;
export const SET_TRANSACTIONS = 'user/SET_TRANSACTIONS' as const;

export const getTransactions = (payload: { page: number }) => ({ type: GET_TRANSACTIONS, payload });

export const setTransactions = (payload: TransactionsResponse) => ({
  type: SET_TRANSACTIONS,
  payload,
});
