import React from 'react';

import DialogModal from '@/components/molecules/dialog-modal';
import { AppErrorResponse } from '@/interfaces/error';
import { AppError, contactSupport } from '@/utilities/errors';
import { ERROR_CODES } from '@/utilities/errors/constants';

import styles from './styles.scss';

export type ErrorModalProps = {
  customMessage?: string;
  customTitle?: string;
  error: AppErrorResponse<typeof ERROR_CODES> | AppError;
  handleCloseModal: () => void;
};

const ErrorModal = ({ customMessage, customTitle, error, handleCloseModal }: ErrorModalProps) => {
  const { ctaText, ctaUrl, message, secondaryCtaText, secondaryCtaUrl, title } = error;

  const confirmText = ctaUrl ? ctaText || 'Contact customer support' : null;
  const confirmAction = () => {
    contactSupport(ctaUrl);
  };

  const dismissText = secondaryCtaUrl ? secondaryCtaText || 'Cancel' : null;
  const dismissAction = () => {
    contactSupport(secondaryCtaUrl);
  };

  const modalMessage =
    customMessage ?? (message || 'Something went wrong, please reach out to support.');
  const modalTitle = customTitle ?? (title || 'Error');

  return (
    <DialogModal
      confirmAction={confirmAction}
      confirmText={confirmText}
      content={
        <div className={styles.errorMessage}>
          {modalMessage.split('\n\n').map((m, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={i}>{m}</p>
          ))}
        </div>
      }
      dismissAction={dismissAction}
      dismissText={dismissText}
      handleCloseModal={handleCloseModal}
      title={modalTitle}
    />
  );
};

export default ErrorModal;
