/* eslint-disable camelcase */
import { StatLine, StatLineResponse } from '@/interfaces/constructed-interfaces/constructed-slates';

const statLineAdapter = (statLine: StatLineResponse): StatLine => {
  const { id, data, scores: scoresResponse } = statLine;

  const scores = scoresResponse.map((score) => ({
    id: score.id,
    points: score.points,
    scoringTypeId: score.scoring_type_id,
  }));

  return {
    id,
    data,
    scores,
  };
};

export default statLineAdapter;
