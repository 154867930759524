import { EntryCollection } from 'contentful';

import client from '@/api-contentful/contentful-request';
import { IPowerUpInfoFields } from '@/interfaces/contentful';

export const getContentfulPowerUpInfoById = async ({
  contentfulInfoId,
}: {
  contentfulInfoId: string;
}): Promise<EntryCollection<IPowerUpInfoFields>> => {
  const response = await client.getEntries<IPowerUpInfoFields>({
    content_type: 'powerUpInfo',
    'fields.contentfulInfoId': contentfulInfoId, // unique field
  });

  return response;
};
