import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Icon from '@/components/atoms/icon';
import { LatestNewsItem } from '@/interfaces/appearance-stats';

import styles from './styles.scss';

dayjs.extend(relativeTime);

export interface NewsModalProps {
  handleCloseModal: () => void;
  newsItem: LatestNewsItem;
}

const NewsModal = (props: NewsModalProps) => {
  const { handleCloseModal, newsItem } = props;

  return (
    <div className={styles.dialogModal}>
      <div className={styles.headerSection}>
        <h1 className={styles.title}>{newsItem.title}</h1>
        <button className={styles.closeButton} onClick={handleCloseModal} aria-label="Close">
          <Icon name="clear" />
        </button>
      </div>
      {newsItem?.source && <p className={styles.source}>Source: {newsItem.source}</p>}
      <div className={styles.labelRow}>
        <Icon
          // eslint-disable-next-line max-len
          name={
            dayjs(newsItem.updatedAt).isAfter(dayjs().subtract(1, 'day')) ? 'newsRecent' : 'news'
          }
          className={styles.newsIcon}
        />
        <p className={styles.fromNow}>{dayjs(newsItem.updatedAt).fromNow()}</p>
      </div>
      <div className={styles.contentScrollable}>{newsItem.content}</div>
    </div>
  );
};

export default NewsModal;
