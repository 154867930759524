import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/live';

import {
  getLiveDraftsForSlate,
  getLiveSlatesForBestBallSport,
  getLiveSlatesForDailySport,
  setLiveDraftsForSlate,
  setLiveSlatesForBestBallSport,
  setLiveSlatesForDailySport,
} from '../actions';

export const getLiveSlatesForDailySportSaga = function* getLiveSlatesForDailySportSaga(
  action: ReturnType<typeof getLiveSlatesForDailySport>
): SagaIterator {
  try {
    const response = yield call(api.getLiveSlatesForSport, {
      ...action.payload,
      isBestBall: false,
    });

    yield put(setLiveSlatesForDailySport({ data: response.data, sportId: action.payload.sportId }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getLiveSlatesForBestBallSportSaga = function* getLiveSlatesForBestBallSportSaga(
  action: ReturnType<typeof getLiveSlatesForBestBallSport>
): SagaIterator {
  try {
    const response = yield call(api.getLiveSlatesForSport, { ...action.payload, isBestBall: true });

    yield put(
      setLiveSlatesForBestBallSport({ data: response.data, sportId: action.payload.sportId })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getLiveDraftsForSlateSaga = function* getLiveDraftsForSlateSaga(
  action: ReturnType<typeof getLiveDraftsForSlate>
): SagaIterator {
  try {
    const response = yield call(api.getLiveDraftsForSlate, action.payload);

    yield put(setLiveDraftsForSlate({ data: response.data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
