import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';
import {
  getUserDraftsForWeeklyWinner,
  getUserEntriesForWeeklyWinnerWeek,
  getUserLeaderboardMarkersForWeeklyWinnerWeek,
  getWeeklyWinner,
  getWeeklyWinnersForSlate,
  getWeeklyWinnerWeeks,
  setUserDraftsForWeeklyWinner,
  setUserEntriesForWeeklyWinnerWeek,
  setUserLeaderboardMarkersForWeeklyWinnerWeek,
  setWeeklyWinner,
  setWeeklyWinnersForSlate,
  setWeeklyWinnerWeeks,
} from '@/store/modules/weekly-winners/actions';

import * as api from '@/api/weekly-winners';
import { WeeklyWinnerResponse } from '@/interfaces/weekly-winners';
import { AppError } from '@/utilities/errors';
import errorLogger from '@/utilities/errors/logger';

export const getWeeklyWinnerSaga = function* getWeeklyWinnerSaga(
  action: ReturnType<typeof getWeeklyWinner>
): SagaIterator {
  try {
    const response = yield call(api.getWeeklyWinner, action.payload);
    const { data }: { data: WeeklyWinnerResponse } = response;

    yield put(setWeeklyWinner(data));
  } catch (e) {
    errorLogger(true, `getWeeklyWinnerSaga: ${e}`);
  }
};

export const getWeeklyWinnersForSlateSaga = function* getWeeklyWinnersForSlateSaga(
  action: ReturnType<typeof getWeeklyWinnersForSlate>
): SagaIterator {
  try {
    const response = yield call(api.getWeeklyWinnersForSlate, action.payload);

    if (!response.data.weekly_winners) {
      throw new AppError({
        title: 'No Weekly Winners returned',
        message: 'Invalid slateId suspected',
        apiCode: 'noDrafts',
      });
    }

    yield put(
      setWeeklyWinnersForSlate({
        data: response.data,
        slateId: action.payload.slateId,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getUserDraftsForWeeklyWinnersSaga = function* getUserDraftsForWeeklyWinnersSaga(
  action: ReturnType<typeof getUserDraftsForWeeklyWinner>
): SagaIterator {
  try {
    const response = yield call(api.getUserDraftsForWeeklyWinner, action.payload);

    yield put(
      setUserDraftsForWeeklyWinner({
        data: response.data,
        weeklyWinnerId: action.payload.weeklyWinnerId,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getUserEntriesForWeeklyWinnerSaga = function* getUserEntriesForWeeklyWinnerSaga(
  action: ReturnType<typeof getUserEntriesForWeeklyWinnerWeek>
): SagaIterator {
  try {
    const response = yield call(api.getUserEntriesForWeeklyWinner, action.payload);

    yield put(
      setUserEntriesForWeeklyWinnerWeek({
        data: response.data,
        weeklyWinnerWeekId: action.payload.weeklyWinnerWeekId,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getWeeklyWinnerWeeksSaga = function* getWeeklyWinnerWeeksSaga(
  action: ReturnType<typeof getWeeklyWinnerWeeks>
): SagaIterator {
  try {
    const response = yield call(api.getWeeklyWinnerWeeks, action.payload);

    yield put(
      setWeeklyWinnerWeeks({
        data: response.data,
        weeklyWinnerId: action.payload.weeklyWinnerId,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

// eslint-disable-next-line max-len
export const getUserLeaderboardMarkersForWeeklyWinnerWeekSaga =
  function* getUserLeaderboardMarkersForWeeklyWinnerWeekSaga(
    action: ReturnType<typeof getUserLeaderboardMarkersForWeeklyWinnerWeek>
  ): SagaIterator {
    try {
      const response = yield call(api.getUserLeaderboardMarkersForWeeklyWinnerWeek, action.payload);

      yield put(
        setUserLeaderboardMarkersForWeeklyWinnerWeek({
          data: response.data,
          weeklyWinnerWeekId: action.payload.weeklyWinnerWeekId,
        })
      );
    } catch (e) {
      yield put(setError({ ...e, type: 'toast' }));
    }
  };
