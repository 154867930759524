import { StateConfig, StateConfigResponse } from '@/interfaces/drafting-config';

/* eslint-disable camelcase */
const stateConfigAdapter = (stateConfigResponse: StateConfigResponse): StateConfig => {
  const {
    state_config: {
      pick_em: {
        maximum_selection_size,
        minimum_selection_size,
        pickem_pools_enabled,
        pickem_pools_free_entry,
        pickem_pools_visible,
        payout_modifiers,
        visible_sports,
        visible_stats,
      },
    },
  } = stateConfigResponse;

  return {
    pickEm: {
      visibleSports: visible_sports,
      visibleStats: visible_stats,
      pickemPoolsVisible: pickem_pools_visible,
      pickemPoolsFreeEntry: pickem_pools_free_entry,
      pickemPoolsEnabled: pickem_pools_enabled,
      maximumSelectionSize: maximum_selection_size,
      minimumSelectionSize: minimum_selection_size,
      payoutModifier: payout_modifiers,
    },
  };
};

export default stateConfigAdapter;
