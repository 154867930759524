import { Entry } from 'contentful';

import { GeneralRules, IGeneralRulesFields } from '@/interfaces/general-rules';

const generalRules = (generalRule: Entry<IGeneralRulesFields>): GeneralRules => {
  const { id } = generalRule.sys;
  const { title, slug, contentAccordion, contentBullet, flag } = generalRule.fields;

  return {
    id,
    title,
    slug,
    contentAccordion,
    contentBullet,
    flag,
  };
};

export default generalRules;
