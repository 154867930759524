/* eslint-disable camelcase */

import { badgeAdapter } from '@/store/modules/user/adapters/user';

import { NotCurrentUserResponse, User } from '@/interfaces/drafts';

export const draftUserAdapter = (user: NotCurrentUserResponse): User => {
  const { id, badges: badgesResponse, profile_color, profile_icon_url, username } = user;

  const badges = badgesResponse?.map(badgeAdapter);

  return {
    id,
    badges,
    profileColor: profile_color,
    profileIconUrl: profile_icon_url,
    username,
  };
};
