import React, { useCallback, useEffect, useRef } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { useUIContext } from '@/components/contexts/ui';
import { OpenModalFn } from '@/interfaces/modal';

import styles from './styles.scss';

export type HandleCloseModal = {
  handleCloseModal: () => void;
};

export const useModal = () => {
  const { setModal } = useUIContext();

  const openModal = useCallback<OpenModalFn>(
    (component, config) =>
      setModal({
        element: component,
        config,
      }),
    [setModal]
  );

  return openModal;
};

const Modal = () => {
  const { modal, setModal } = useUIContext();
  const modalContentRef = useRef<HTMLDivElement>(null);

  const ModalElement = modal.element;

  const handleCloseModal = useCallback(() => {
    if (modal.config?.customClose) {
      modal.config.customClose();
      if (modal.config?.ignoreClose) {
        return;
      }
    }
    setModal({});
  }, [modal, setModal]);

  const handleClickAway = useCallback(() => {
    if (modal.config?.customClickAway) {
      setModal({});
      modal.config.customClickAway();
      return;
    }

    if (modal.config?.customClose) {
      modal.config.customClose();
      if (modal.config?.ignoreClose) {
        return;
      }
    }
    if (!modal.config?.disableClickAway) {
      setModal({});
    }
  }, [modal.config, setModal]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        handleClickAway();
      }
    },
    [handleClickAway]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    if (modalContentRef) {
      modalContentRef.current?.focus();
    }
  }, [modalContentRef, ModalElement]);

  return ModalElement ? (
    <div className={styles.modalBackground}>
      <div className={styles.modalWrapper} data-testid="modal-container">
        <ClickAwayListener onClickAway={handleClickAway}>
          <div
            /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
            tabIndex={0}
            ref={modalContentRef}
            role="dialog"
          >
            <ModalElement handleCloseModal={handleCloseModal} />
          </div>
        </ClickAwayListener>
      </div>
    </div>
  ) : null;
};

export default Modal;
