import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '@/store';

import Icon from '@/components/atoms/icon';
import FavoriteButton from '@/components/molecules/favorite-button';
import ManualScoringIcon from '@/components/molecules/manual-scoring-icon';
import PlayerDetailedAvatar from '@/components/molecules/player-detailed-avatar';
import { ConstructedPickEmOverUnderLineAppearance } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { ConstructedPickEmRivalLine } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { Teams } from '@/interfaces/drafting-config';
import { Boost, PickLocation } from '@/interfaces/pick-em';
import langHelper from '@/utilities/lang-helper';

import RemoveSelectionButton from './components/remove-selection-button';

import styles from './styles.scss';

export interface PlayerAppearanceInfoProps {
  constructedPickEmLineAppearance: ConstructedPickEmOverUnderLineAppearance;
  pickLocation: PickLocation;
  appearanceInfoLocation?: 'overUnderCell' | 'altsModal';
  teams: Teams;
  boost: Boost;
  constructedRivalLines: ConstructedPickEmRivalLine[];
}

const PlayerAppearanceInfo = (props: PlayerAppearanceInfoProps) => {
  const {
    constructedPickEmLineAppearance: cPEOULA,
    teams,
    pickLocation,
    appearanceInfoLocation,
    boost,
    constructedRivalLines,
  } = props;

  const playerRivalLines = useMemo(
    () =>
      constructedRivalLines?.filter((cRLA: ConstructedPickEmRivalLine) =>
        cRLA.appearanceOptions.find((appOption) => appOption.id === cPEOULA.id)
      ),
    [cPEOULA, constructedRivalLines]
  );

  const { appearanceLines, match, soloGame, team, player, sport, isActive, hasLiveLines } = cPEOULA;

  if (appearanceLines.length === 0) return null;

  const isSportScoredManually = cPEOULA.match?.manuallyCreated || cPEOULA.soloGame?.manuallyCreated;

  const warningLabelEl = isSportScoredManually ? <ManualScoringIcon /> : null;

  const matchInfoText = hasLiveLines
    ? langHelper.matchTextWithScore({
        match,
        team,
        teams,
        soloGame,
        sport,
      })
    : langHelper.matchText({
        gameType: sport.gameType,
        match,
        team,
        teams,
        soloGame,
      });

  const numberOfActiveOverUnderLines = cPEOULA.appearanceLines?.length ?? 0;
  const numberOfActiveRivalLines = playerRivalLines?.length ?? 0;
  const totalActiveLinesForPlayer = numberOfActiveOverUnderLines + numberOfActiveRivalLines;

  return (
    <div
      className={clsx(styles.actualTopRow, {
        [styles.inactive]: !isActive,
      })}
    >
      <div className={clsx(styles.playerWrapper, { [styles.inactive]: !isActive })}>
        <PlayerDetailedAvatar
          team={team}
          player={player}
          sport={sport}
          boost={boost || null}
          borderType="none"
          backgroundStripes
          size="regular"
        />
        <div className={styles.infoContainer}>
          <div className={styles.nameAndbuttonsContainer}>
            <div className={styles.playerName} data-testid="player-name">
              <span>{langHelper.getPlayerFullName(player)}</span>
            </div>
            <div className={styles.playerInfoButtons}>
              {warningLabelEl}
              {pickLocation === 'review' && appearanceInfoLocation !== 'altsModal' ? (
                <RemoveSelectionButton constructedAppearanceId={cPEOULA.id} disabled={!isActive} />
              ) : (
                <FavoriteButton
                  player={player}
                  appearanceId={cPEOULA.id}
                  numberOfActiveLines={totalActiveLinesForPlayer}
                />
              )}
            </div>
          </div>
          <div className={styles.matchInfo} data-testid="match-info">
            <Icon className={styles.sportIcon} name={sport?.icon} />
            <p className={styles.matchInfoText}>
              {matchInfoText}
              {!hasLiveLines ? (
                <span data-chromatic="ignore">
                  {` - ${langHelper.timeToDayOrDate(match?.scheduledAt || soloGame?.scheduledAt)}`}
                </span>
              ) : null}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state: RootState) => ({
  constructedRivalLines: state.pickEmRivals.constructedPickEmRivals,
  teams: state.draftingConfig.teams,
}))(PlayerAppearanceInfo);
