/* eslint-disable camelcase */
import { SitAndGo, SitAndGoResponse, SitAndGos } from '@/interfaces/lobbies';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

const sitAndGosAdapter = (sitAndGos: SitAndGoResponse[]): SitAndGos => {
  const adaptedSitAndGos = sitAndGos.map((sitAndGo): SitAndGo => {
    const { id, clock, contest_style_id, draft_type, entry_role, entry_style_id } = sitAndGo;

    return {
      id,
      clock,
      contestStyleId: contest_style_id,
      draftType: draft_type,
      entryRole: entry_role,
      entryStyleId: entry_style_id,
    };
  });

  return arrayToObjectIdKeys(adaptedSitAndGos);
};

export default sitAndGosAdapter;
