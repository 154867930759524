import { type UIMatch, useMatches as useMatchesBase } from 'react-router-dom';

/**
 * This hook is a typed wrapper around `useMatches` from `react-router-dom`.
 */
export function useMatches<TData = unknown, THandle = unknown>() {
  // For whatever reason, `useMatches` isn't generically typed and doesn't
  // allow us to specify the type of the data and handle. Therefore, we
  // need to forcibly cast it to the correct type. This is not ideal
  // or safe, but the best we can do until (if) `react-router-dom` provides
  // the necessary typings.
  return useMatchesBase() as UIMatch<TData, THandle>[];
}
