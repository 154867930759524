import { WeeklyAppearances, WeeklyAppearancesResponse } from '@/interfaces/weekly-appearances';

import {
  GET_WEEKLY_APPEARANCES,
  getWeeklyAppearances,
  SET_WEEKLY_APPEARANCES,
  setWeeklyAppearances,
} from '../actions';
import { weeklyAppearanceAdapter } from '../adapters/weekly-appearance';

type State = WeeklyAppearances;

type WeeklyAppearancesActions = ReturnType<
  typeof setWeeklyAppearances | typeof getWeeklyAppearances
>;

const initialWeeklyAppearancesState: WeeklyAppearances = { isLoading: false };

const set = (
  state: WeeklyAppearances,
  { data, weekId }: { data: WeeklyAppearancesResponse; weekId: number }
): WeeklyAppearances => {
  const weeklyAppearances = weeklyAppearanceAdapter(data);

  return {
    ...state,
    isLoading: false,
    [weekId]: weeklyAppearances,
  };
};

export const weeklyAppearancesReducer = (
  state: State = initialWeeklyAppearancesState,
  action: WeeklyAppearancesActions = {} as WeeklyAppearancesActions
): State => {
  switch (action.type) {
    case GET_WEEKLY_APPEARANCES: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SET_WEEKLY_APPEARANCES:
      return set(state, action.payload);
    default:
      return state;
  }
};
