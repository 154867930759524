import React from 'react';
import clsx from 'clsx';

import Icon from '@/components/atoms/icon';

import styles from './styles.scss';

export interface FieldWrapperProps {
  children?: any;
  error?: string | boolean | JSX.Element;
  label?: string | JSX.Element;
  maxLength?: number;
  note?: string;
  inlineNote?: string;
  value?: string | number;
  classNames?: {
    wrapper?: string;
    label?: string;
    labelWrapper?: string;
    field?: string;
    error?: string;
  };
  infoOnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

// FieldWrapper is like a helper component that displays the UI for the field -
// like error, character count and labels. It's pretty much a dummy component
// though, with most information being passed into it.

const FieldWrapper = (props: FieldWrapperProps): JSX.Element => {
  const {
    children,
    error,
    label,
    maxLength,
    note,
    inlineNote,
    value,
    classNames = {},
    infoOnClick,
    disabled,
  } = props;

  const currentLength = typeof value === 'string' ? value.length : 0;
  const atMax = maxLength ? currentLength >= maxLength : false;
  const fieldClasses = clsx(styles.field, classNames.field, {
    [styles.hasError]: error,
    [styles.hasErrorBorder]: error === '',
    [styles.disabled]: disabled,
  });

  const errorClasses = clsx(styles.error, classNames.error);
  const wrapperClassNames = clsx(styles.fieldWrapper, classNames.wrapper);
  const labelClassNames = clsx(styles.label, classNames.label);
  const labelWrapperClassNames = clsx(styles.labelWrapper, classNames.labelWrapper);

  const infoIconEl = infoOnClick && (
    <button className={styles.labelInfoIcon} onClick={infoOnClick} aria-label="Info">
      <Icon name="info" />
    </button>
  );

  const renderError = (err: string | boolean | JSX.Element | undefined) => {
    if (err && (typeof err === 'string' || typeof err === 'object')) {
      return <div className={errorClasses}>{err}</div>;
    }
    return null;
  };

  return (
    <div className={wrapperClassNames}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={labelWrapperClassNames}>
        {label && (
          <div className={labelClassNames}>
            {label}
            {infoIconEl}
            {maxLength && (
              <span className={styles.lengthCounter}>
                <span className={clsx({ [styles.atMax]: atMax })}>{currentLength}</span>/{maxLength}
              </span>
            )}
            {note && <span className={styles.note}>{note}</span>}
          </div>
        )}
        <div className={fieldClasses}>
          {children}
          {inlineNote && <span className={styles.inlineNote}>{inlineNote}</span>}
        </div>
      </label>
      {renderError(error)}
    </div>
  );
};

export default FieldWrapper;
