import { EntryCollection } from 'contentful';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import * as api from '@/api-contentful/state-configuration';
import { IStateConfigurationFields } from '@/interfaces/contentful';
import errorLogger from '@/utilities/errors/logger';

import { setStateConfigurations } from '../actions';

export const getStateConfigurationSaga = function* getStateConfigurationSaga(): SagaIterator {
  try {
    const stateConfigurations: EntryCollection<IStateConfigurationFields> = yield call(
      api.getContentfulStateConfiguration
    );
    yield put(
      setStateConfigurations({
        stateConfigurations,
      })
    );
  } catch (e) {
    // If there's a network error from Contentful, we should use a hardcoded value as a fallback for responsible gaming
    // instead of throwing the user an error toast
    // The reducer checks for an empty object and returns the fallback value accordingly
    errorLogger(true, `getStateConfigurationSaga: ${e}`);
    yield put(setStateConfigurations({ stateConfigurations: {} }));
  }
};
