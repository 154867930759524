import {
  CosmeticsAndAchievements,
  CosmeticsAndAchievementsResponse,
} from '@/interfaces/constructed-interfaces/constructed-cosmetics-and-achievements';

import { SET_COSMETICS_AND_ACHIEVEMENTS, setCosmeticsAndAchievements } from '../actions';
import { accessoriesAdapter } from '../adapters/accessories';
import { constructedCosmeticsAdapter } from '../adapters/constructed-cosmetics';

type State = CosmeticsAndAchievements | null;

type CosmeticsAndAchievementsActions = ReturnType<typeof setCosmeticsAndAchievements>;

const initialState: CosmeticsAndAchievements = {
  avatars: null,
  accessories: null,
  meta: null,
};

const set = (data: CosmeticsAndAchievementsResponse): CosmeticsAndAchievements => {
  const { cosmeticsResponse, achievementsResponse } = data;

  const avatars = constructedCosmeticsAdapter(data);

  const accessories = accessoriesAdapter(achievementsResponse.achievements);

  const meta = {
    colors: cosmeticsResponse.meta.colors,
    extension: cosmeticsResponse.meta.extension,
    basePath: cosmeticsResponse.meta.base_path,
  };

  return {
    avatars,
    accessories,
    meta,
  };
};

export const cosmeticsAndAchievementsReducer = (
  state: State = initialState,
  action: CosmeticsAndAchievementsActions = {} as CosmeticsAndAchievementsActions
): State => {
  switch (action.type) {
    case SET_COSMETICS_AND_ACHIEVEMENTS:
      return set(action.payload);
    default:
      return state;
  }
};
