import React from 'react';

import type { SvgSpriteSheetTypes } from '@/assets/generated/svg-sprite-sheet-types';

export type SvgSpriteProps = {
  spriteName: SvgSpriteSheetTypes;
  title?: string;
};

export function SvgSprite({ spriteName, title, ...svgProps }: SvgSpriteProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg data-testid={`svg-sprite-${spriteName}`} {...svgProps}>
      {title ? <title>{title}</title> : null}
      <use href={`#${spriteName}__gen`} />
    </svg>
  );
}
