import { Entry } from 'contentful';

import { ISweepstakeTermsAndConditionsFields } from '@/interfaces/contentful';
import { SweepstakesTermsAndConditions } from '@/interfaces/sweepstakes-terms-and-conditions';

// eslint-disable-next-line max-len
const sweepstakesTermsAndConditionsAdapter = (
  sweepstakesTermsAndConditions: Entry<ISweepstakeTermsAndConditionsFields>
): SweepstakesTermsAndConditions => {
  const { id } = sweepstakesTermsAndConditions.sys;
  const { title, slug, linkText, order, content } = sweepstakesTermsAndConditions.fields;

  return {
    id,
    title,
    slug,
    linkText,
    order,
    content,
  };
};

export default sweepstakesTermsAndConditionsAdapter;
