import { UPDATE_TOURNAMENT_COUNT, updateTournamentCount } from '@/store/modules/lobbies/actions';

import { EntryCountUpdate } from '@/interfaces/lobbies';
import { TournamentResponse, Tournaments } from '@/interfaces/tournaments';

import { SET_TOURNAMENT, setTournament } from '../actions';
import tournamentAdapter from '../adapters/tournament';

type State = Tournaments;

type TournamentsActions = ReturnType<typeof setTournament | typeof updateTournamentCount>;

const initialTournamentsState: Tournaments = {};

const set = (state: Tournaments, tournamentResponse: TournamentResponse): Tournaments => {
  const tournament = tournamentAdapter(tournamentResponse);
  return {
    ...state,
    [tournament.id]: tournament,
  };
};

const updateCount = (
  state: Tournaments,
  { tournamentId, entryCount }: EntryCountUpdate
): Tournaments => {
  if (!state[tournamentId]) {
    return state;
  }
  return {
    ...state,
    [tournamentId]: {
      ...state[tournamentId],
      entryCount,
    },
  };
};

export const tournamentsReducer = (
  state: State = initialTournamentsState,
  action: TournamentsActions = {} as TournamentsActions
): State => {
  switch (action.type) {
    case SET_TOURNAMENT:
      return set(state, action.payload);
    case UPDATE_TOURNAMENT_COUNT:
      return updateCount(state, action.payload);
    default:
      return state;
  }
};
