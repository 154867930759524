/* eslint-disable camelcase */
import { WeeklyWinnerForSlate, WeeklyWinnerForSlateResponse } from '@/interfaces/weekly-winners';

export const weeklyWinnerForSlateAdapter = (
  weeklyWinner: WeeklyWinnerForSlateResponse
): WeeklyWinnerForSlate => {
  const {
    id,
    additional_info,
    contest_style_id,
    current_weekly_winner_week_id,
    cutoff_at,
    description,
    draft_entry_style_id,
    entry_count,
    entry_role,
    entry_style_id,
    image_url,
    max_entries,
    prize_breakdown_info,
    rules_url,
    slate_id,
    status,
    title,
    user_weekly_winner_entry_count,
    user_weekly_winner_entry_payouts,
  } = weeklyWinner;

  return {
    id,
    additionalInfo: additional_info,
    contestStyleId: contest_style_id,
    currentWeeklyWinnerWeekId: current_weekly_winner_week_id,
    cutoffAt: cutoff_at,
    description,
    draftEntryStyleId: draft_entry_style_id,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    imageUrl: image_url,
    maxEntries: max_entries,
    prizeBreakdownInfo: prize_breakdown_info,
    rulesUrl: rules_url,
    slateId: slate_id,
    status,
    title,
    userWeeklyWinnerEntryCount: user_weekly_winner_entry_count,
    userWeeklyWinnerEntryPayouts: user_weekly_winner_entry_payouts,
  };
};
