import { Transactions, TransactionsResponse } from '@/interfaces/transactions';

const transactionsAdapter = (transactionsResponse: TransactionsResponse): Transactions => {
  const { transactions: transactionsArray, meta } = transactionsResponse;

  const transactions = transactionsArray.map((transaction) => ({
    id: transaction.id,
    action: transaction.action,
    amount: transaction.amount,
    createdAt: transaction.created_at,
    itemId: transaction.item_id,
    itemType: transaction.item_type,
  }));

  return {
    transactions,
    meta, // for now this is the same shape as the response
  };
};

export default transactionsAdapter;
