import { LiveSlatesForBestBallSport, LiveSlatesForSportResponse } from '@/interfaces/live';

import { SET_LIVE_SLATES_FOR_BEST_BALL_SPORT, setLiveSlatesForBestBallSport } from '../actions';
import liveSlateOverviewsAdapter from '../adapters/live-slate-overviews';
import liveSlatesAdapter from '../adapters/live-slates';

type State = LiveSlatesForBestBallSport | null;

type LiveSlatesForSportActions = ReturnType<typeof setLiveSlatesForBestBallSport>;

const initialState: LiveSlatesForBestBallSport = {};

const set = (
  state: LiveSlatesForBestBallSport,
  { data, sportId }: { data: LiveSlatesForSportResponse; sportId: string }
): LiveSlatesForBestBallSport => {
  const slates = liveSlatesAdapter(data.slates);
  const slateOverviews = data.slate_overviews && liveSlateOverviewsAdapter(data.slate_overviews);

  return {
    ...state,
    [sportId]: {
      slates,
      slateOverviews,
    },
  };
};

export const liveSlatesForBestBallSportReducer = (
  state: State = initialState,
  action: LiveSlatesForSportActions = {} as LiveSlatesForSportActions
): State => {
  switch (action.type) {
    case SET_LIVE_SLATES_FOR_BEST_BALL_SPORT:
      return set(state, action.payload);
    default:
      return state;
  }
};
