import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { RootState } from '@/store';
import {
  UpdateSelectedArgs,
  updateSelectedRivals as updateSelectedRivalsAction,
} from '@/store/modules/pick-em/actions';

import ExternalLink from '@/components/atoms/external-link';
import Icon from '@/components/atoms/icon';
import { ScoringSection } from '@/components/atoms/info-modal-components';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import PickEmCellMessage from '@/components/atoms/pick-em-cell-message';
import { useToast } from '@/components/atoms/toast';
import DialogModal from '@/components/molecules/dialog-modal';
import ManualScoringIcon from '@/components/molecules/manual-scoring-icon';
import RivalPlayerCell from '@/components/molecules/pick-em-player-cells/rival-lines/rival-player-cell';
// eslint-disable-next-line import/no-cycle
import PickEmPlayerModal from '@/components/molecules/pick-em-player-modal';
import {
  ConstructedPickEmRivalLine,
  ConstructedPickEmRivalLineAppearance,
  SelectedRival,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { ScoringTypes } from '@/interfaces/drafting-config';
import { FeatureFlags } from '@/interfaces/feature-flags';
import { PickLocation, RivalOption } from '@/interfaces/pick-em';
import { SportId } from '@/interfaces/types';
import { User } from '@/interfaces/user';
import { MIN_INSURED_PICKS } from '@/utilities/constants';
import langHelper, { LOW_SCORE_WINS_TEXT } from '@/utilities/lang-helper';

import { LobbyDividingContainer } from '../rival-dividing-container';

import styles from './styles.scss';

export interface RivalLineCellProps {
  constructedPickEmRivalLine: ConstructedPickEmRivalLine;
  constructedRivalLines: ConstructedPickEmRivalLine[];
  pickLocation: PickLocation;
  scoringTypes: ScoringTypes;
  selectedRivals: SelectedRival[];
  updateSelectedRivals: ({
    action,
    selection,
    isPickEmFeatureEnabled,
    lobbyName,
    pickLocation,
    minInsuredPicks,
    maxUserPicks,
  }: UpdateSelectedArgs<SelectedRival>) => void;
  isPickEmFeatureEnabled: FeatureFlags['pickEm'];
  user: User;
  correlationBlockers?: string[];
}

type ParamTypes = {
  sportId?: SportId;
};

const RivalLineCell = (props: RivalLineCellProps) => {
  const {
    constructedPickEmRivalLine,
    constructedRivalLines,
    isPickEmFeatureEnabled,
    pickLocation,
    scoringTypes,
    selectedRivals,
    updateSelectedRivals,
    user,
    correlationBlockers,
  } = props;
  const openToast = useToast();
  const openModal = useModal();
  const { sportId } = useParams<ParamTypes>();

  const selectedOptionId = selectedRivals?.find(
    (sR) =>
      // needs to be component scoped
      sR.id === constructedPickEmRivalLine.id
  )?.option?.id;

  const rivalLine = useMemo(
    () =>
      constructedRivalLines?.find(
        (rival) =>
          // needs to be component scoped
          rival.rivalLineId === constructedPickEmRivalLine.id
      ),
    [constructedPickEmRivalLine, constructedRivalLines]
  );

  if (!rivalLine) return null;

  const isRemoved = constructedPickEmRivalLine.status === 'removed';

  const handleOptionClick = ({
    id,
    option,
    constructedPickEmRivalAppearance,
  }: {
    id: string;
    option: RivalOption;
    constructedPickEmRivalAppearance: ConstructedPickEmRivalLineAppearance;
  }) => {
    const selection: SelectedRival = {
      id,
      option,
      constructedPickEmRivalAppearance,
      constructedPickEmRivalLine: rivalLine,
    };
    const action = selectedOptionId === option.id ? 'remove' : 'add';

    updateSelectedRivals({
      action: isRemoved ? null : action,
      selection,
      isPickEmFeatureEnabled,
      lobbyName: sportId,
      pickLocation,
      minInsuredPicks: Math.max(MIN_INSURED_PICKS, user.stateConfig.pickEm.minimumSelectionSize),
      maxUserPicks: user.stateConfig.pickEm.maximumSelectionSize,
    });
  };

  const handleScoringModalClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const scoringType = scoringTypes[rivalLine?.rival?.scoringTypeId];
    if (scoringType) {
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <DialogModal
          handleCloseModal={handleCloseModal}
          title="Scoring"
          content={<ScoringSection scoringType={scoringType} displayTitle={false} />}
        />
      ));
    }
  };

  const handleLowScoreWinsModalClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <DialogModal
        handleCloseModal={handleCloseModal}
        title="Lowest score wins"
        content={<p>{LOW_SCORE_WINS_TEXT}</p>}
      />
    ));
  };

  const handlePlayerClick = (appearanceId: string) => {
    const cA = rivalLine.appearanceOptions.find((appearance) => appearance.id === appearanceId);

    if (cA.sport?.manual) {
      openToast({
        open: true,
        message: (
          <div className={styles.noPlayerCardToast}>
            <Icon name="recentActors" className={styles.toastIcon} />
            <p className={styles.noPlayerCardText}>Player card not available</p>
          </div>
        ),
      });
    } else {
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <PickEmPlayerModal
          constructedPickEmRivalLine={rivalLine}
          constructedAppearance={cA}
          handleCloseModal={handleCloseModal}
        />
      ));
    }
  };

  const handleLearnMoreClick = () => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <DialogModal
        handleCloseModal={handleCloseModal}
        title="Locked projections"
        content={
          <>
            <h2 className={styles.infoHeader}>Why are some projections locked?</h2>
            <p className={styles.infoText}>
              In order for us to provide you with the widest range of offerings, certain projections
              may be unavailable based off of your picks.&ensp;
              <ExternalLink
                href={`${process.env.APP_ENDPOINT}/rules`}
                text="Learn more"
                showArrow
              />
            </p>
            <h2 className={styles.infoHeader}>How does it work?</h2>
            <p className={styles.infoText}>
              Picking one player projection may make other player projections and/or Rivals
              unavailable.
            </p>
          </>
        }
      />
    ));
  };

  const [leftCA, rightCA] = rivalLine.appearanceOptions;

  const isSportScoredManually =
    leftCA.match?.manuallyCreated ||
    leftCA.soloGame?.manuallyCreated ||
    rightCA.match?.manuallyCreated ||
    rightCA.soloGame?.manuallyCreated;

  const warningLabelEl = isSportScoredManually ? <ManualScoringIcon /> : null;

  return (
    <div
      className={clsx(styles.rivalLineCell, {
        [styles.removed]: isRemoved,
      })}
    >
      <div className={styles.header}>
        <div className={styles.stat}>
          <span>{leftCA.rivalOption.appearanceStat.displayStat}</span>
          {rivalLine.rival.scoringTypeId && (
            <button
              className={styles.scoringModalButton}
              onClick={handleScoringModalClick}
              aria-label="Scoring info"
            >
              <Icon name="info" className={styles.scoringModalIcon} />
            </button>
          )}
          {leftCA.rivalOption?.appearanceStat?.gradedBy === 'lowScore' && (
            <button
              className={styles.scoringModalButton}
              onClick={handleLowScoreWinsModalClick}
              aria-label="Low score wins info"
            >
              <Icon name="info" className={styles.scoringModalIcon} />
            </button>
          )}
        </div>
        {warningLabelEl}
      </div>
      <div className={styles.playersCell}>
        <RivalPlayerCell
          handleOptionClick={handleOptionClick}
          constructedAppearance={leftCA}
          selectedOptionId={selectedOptionId}
          handlePlayerClick={handlePlayerClick}
          liveRivalLine={{
            liveEvent: rivalLine.liveEvent,
            liveEventStat: rivalLine.liveEventStats?.[leftCA.id],
          }}
        />
        <LobbyDividingContainer
          selectedOptionId={selectedOptionId}
          selectedLeft={selectedOptionId === leftCA.rivalOption.id}
          selectedRight={selectedOptionId === rightCA.rivalOption.id}
        />
        <RivalPlayerCell
          handleOptionClick={handleOptionClick}
          constructedAppearance={rightCA}
          selectedOptionId={selectedOptionId}
          handlePlayerClick={handlePlayerClick}
          liveRivalLine={{
            liveEvent: rivalLine.liveEvent,
            liveEventStat: rivalLine.liveEventStats?.[rightCA.id],
          }}
        />
      </div>
      {correlationBlockers?.length > 0 && (
        <PickEmCellMessage
          classNames={{
            message: styles.correlationLabel,
            icon: styles.lockOverlay,
          }}
          message={
            <div>
              Remove {langHelper.formatListToString(correlationBlockers)} to unlock pick.{' '}
              <span className={styles.learnMoreText}>Learn&nbsp;more</span>
            </div>
          }
          icon="lock"
          onClick={() => {
            handleLearnMoreClick();
          }}
        />
      )}
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    constructedRivalLines: state.pickEmRivals.constructedPickEmRivals,
    isPickEmFeatureEnabled: state.featureFlags.pickEm,
    scoringTypes: state.draftingConfig.scoringTypes,
    selectedRivals: state.pickEmEntries.selected.selectedRivals,
    user: state.user,
  }),
  (dispatch) => ({
    updateSelectedRivals: ({
      action,
      selection,
      isPickEmFeatureEnabled,
      lobbyName,
      pickLocation,
      minInsuredPicks,
      maxUserPicks,
    }: UpdateSelectedArgs<SelectedRival>) =>
      dispatch(
        updateSelectedRivalsAction({
          action,
          selection,
          isPickEmFeatureEnabled,
          lobbyName,
          pickLocation,
          minInsuredPicks,
          maxUserPicks,
        })
      ),
  })
)(RivalLineCell);
