import { EntryCollection } from 'contentful';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import * as api from '@/api-contentful/text-pages';
import { ITextPageFields } from '@/interfaces/contentful';
import errorLogger from '@/utilities/errors/logger';

import { getTextPages, setTextPages } from '../actions';

export const getTextPagesSaga = function* getTextPagesSaga(
  action: ReturnType<typeof getTextPages>
): SagaIterator {
  try {
    const textPages: EntryCollection<ITextPageFields> = yield call(
      api.getContentfulTextPages,
      action.payload
    );
    yield put(
      setTextPages({
        id: action.payload.id,
        textPages,
      })
    );
  } catch (e) {
    errorLogger(true, `getTextPagesSaga: ${e}`);
  }
};
