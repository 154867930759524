import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/tournament-rounds';
import { AppError } from '@/utilities/errors';

import {
  getPaginatedDraftEntriesForDraft,
  getTournamentRoundsForSlate,
  getUserDraftsForTournamentRounds,
  getUserPaginatedDraftEntriesForDraft,
  setPaginatedDraftEntriesForDraft,
  setTournamentRoundsForSlate,
  setUserDraftsForTournamentRound,
  setUserPaginatedDraftEntriesForDraft,
} from '../actions';

export const getTournamentRoundsForSlateSaga = function* getTournamentRoundsForSlateSaga(
  action: ReturnType<typeof getTournamentRoundsForSlate>
): SagaIterator {
  try {
    const response = yield call(api.getTournamentRoundsForSlate, action.payload);

    if (!response.data.tournament_rounds) {
      throw new AppError({
        title: 'No drafts returned',
        message: 'Invalid slateId suspected',
        apiCode: 'noDrafts',
      });
    }

    yield put(setTournamentRoundsForSlate(response.data));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getUserDraftsForTournamentRoundsSaga = function* getUserDraftsForTournamentRoundsSaga(
  action: ReturnType<typeof getUserDraftsForTournamentRounds>
): SagaIterator {
  try {
    const response = yield call(api.getUserDraftsForTournamentRound, action.payload);

    yield put(
      setUserDraftsForTournamentRound({
        data: response.data,
        tournamentRoundId: action.payload.tournamentRoundId,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getPaginatedDraftEntriesForDraftSaga = function* getPaginatedDraftEntriesForDraftSaga(
  action: ReturnType<typeof getPaginatedDraftEntriesForDraft>
): SagaIterator {
  try {
    const response = yield call(api.getPaginatedDraftEntriesForDraft, action.payload);

    yield put(
      setPaginatedDraftEntriesForDraft({
        data: response.data,
        user: action.payload.user,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

// eslint-disable-next-line max-len
export const getUserPaginatedDraftEntriesForDraftSaga =
  function* getUserPaginatedDraftEntriesForDraftSaga(
    action: ReturnType<typeof getUserPaginatedDraftEntriesForDraft>
  ): SagaIterator {
    try {
      const response = yield call(api.getUserPaginatedDraftEntriesForDraft, action.payload);

      yield put(
        setUserPaginatedDraftEntriesForDraft({
          data: response.data,
          draftId: action.payload.draftId,
        })
      );
    } catch (e) {
      yield put(setError({ ...e, type: 'toast' }));
    }
  };
