/* eslint-disable camelcase */
import { CompletedDraft, CompletedDraftResponse } from '@/interfaces/completed-drafts';
import { toCamel } from '@/utilities/helpers';

export const completedDraftAdapter = (draftResponse: CompletedDraftResponse): CompletedDraft => {
  const {
    id,
    clock,
    contest_style_id,
    draft_at,
    draft_type,
    entry_count,
    entry_role,
    entry_style_id,
    slate_id,
    source,
    source_id,
    status,
    title,
    source_entry_style_id,
  } = draftResponse;

  return {
    id,
    clock,
    contestStyleId: contest_style_id,
    draftAt: draft_at,
    draftType: draft_type,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    slateId: slate_id,
    source: toCamel(source) as CompletedDraft['source'],
    sourceId: source_id,
    status,
    title,
    sourceEntryStyleId: source_entry_style_id,
  };
};
