import {
  AppearancesResponse,
  ConstructedSlateResponse,
  GetConstructedSlateRequest,
} from '@/interfaces/constructed-interfaces/constructed-slates';
import { StatLineUpdateResponse } from '@/interfaces/live';

export const GET_CONSTRUCTED_SLATE = 'slates/GET_CONSTRUCTED_SLATE' as const;
export const SET_CONSTRUCTED_SLATE = 'slates/SET_CONSTRUCTED_SLATE' as const;

export const UPDATE_LIVE_SCORE = 'slates/UPDATE_LIVE_SCORE' as const;

export const GET_SLATE_APPEARANCES = 'slates/GET_SLATE_APPEARANCES' as const;
export const SET_SLATE_APPEARANCES = 'slates/SET_SLATE_APPEARANCES' as const;

export const getConstructedSlate = (payload: GetConstructedSlateRequest) => ({
  type: GET_CONSTRUCTED_SLATE,
  payload,
});

export const setConstructedSlate = (payload: ConstructedSlateResponse) => ({
  type: SET_CONSTRUCTED_SLATE,
  payload,
});

export const updateLiveScore = (payload: {
  data: StatLineUpdateResponse;
  slateId: string;
  scoringTypeId: string;
}) => ({ type: UPDATE_LIVE_SCORE, payload });

export const getSlateAppearances = (payload: { slateId: string; scoringTypeId: string }) => ({
  type: GET_SLATE_APPEARANCES,
  payload,
});

export const setSlateAppearances = (payload: {
  appearancesResponse: AppearancesResponse;
  slateId: string;
  scoringTypeId: string;
}) => ({ type: SET_SLATE_APPEARANCES, payload });
