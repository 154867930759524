import { EntryCollection } from 'contentful';

import { IPromoInfoFields } from '@/interfaces/contentful';
import { ContentfulPromoInfo, ContentfulPromoInfos } from '@/interfaces/contentful-promo-info';

import { SET_CONTENTFUL_PROMO_INFO, setContentfulPromoInfo } from '../actions';
import { contentfulPromoInfoAdapter } from '../adapters';

type State = ContentfulPromoInfos;

type PromoInfoActions = ReturnType<typeof setContentfulPromoInfo>;

const initialContentfulPromoInfosState: ContentfulPromoInfos = {};

const set = (
  state: ContentfulPromoInfos,
  data: {
    promoInfoResponse: EntryCollection<IPromoInfoFields>;
    contentfulInfoId: string;
  }
): ContentfulPromoInfos => {
  if (!data.promoInfoResponse.items.length) {
    return {
      ...state,
      [data.contentfulInfoId]: {} as ContentfulPromoInfo,
    };
  }
  const promoInfo: ContentfulPromoInfo = contentfulPromoInfoAdapter(data.promoInfoResponse);

  return {
    ...state,
    [promoInfo.contentfulInfoId]: promoInfo,
  };
};

export const contentfulPromoInfosReducer = (
  state: State = initialContentfulPromoInfosState,
  action: PromoInfoActions = {} as PromoInfoActions
): State => {
  switch (action.type) {
    case SET_CONTENTFUL_PROMO_INFO:
      return set(state, action.payload);
    default:
      return state;
  }
};
