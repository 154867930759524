/* eslint-disable camelcase */
import { UserLeaderboardMarkers, UserLeaderboardMarkersResponse } from '@/interfaces/leaderboard';

export const userLeaderboardAdapter = (
  data: UserLeaderboardMarkersResponse
): UserLeaderboardMarkers => {
  const { leaderboard: leaderboardResponse, payout_min_points } = data;

  const leaderboard = leaderboardResponse.map((l) => ({
    id: l.id,
    draftId: l.draft_id,
    place: l.place,
    tied: l.tied,
  }));

  return {
    leaderboard,
    payoutMinPoints: payout_min_points,
  };
};
