import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';

import {
  getConstructedSlate,
  getSlateAppearances,
  setConstructedSlate,
  setSlateAppearances,
} from '@/store/modules/constructed-slates/actions';
import { setError } from '@/store/modules/error/actions';

import * as matchesApi from '@/api/matches';
import * as api from '@/api/slates';
import {
  AppearancesResponse,
  PlayersResponse,
  SlateInfoResponse,
} from '@/interfaces/constructed-interfaces/constructed-slates';
import { MatchesResponse } from '@/interfaces/matches';
import errorLogger from '@/utilities/errors/logger';

export const getConstructedSlateSaga = function* getConstructedSlateSaga(
  action: ReturnType<typeof getConstructedSlate>
): SagaIterator {
  try {
    const [slateInfoData, slatePlayersData, slateAppearancesData, slateMatchesData] = yield all([
      call(api.getSlateInfo, action.payload),
      call(api.getSlatePlayers, action.payload),
      call(api.getSlateAppearances, action.payload),
      call(matchesApi.getSlateMatches, action.payload),
    ]);

    const { data: slateInfoResponse }: { data: SlateInfoResponse } = slateInfoData;
    const { data: playersResponse }: { data: PlayersResponse } = slatePlayersData;
    const { data: appearanceResponse }: { data: AppearancesResponse } = slateAppearancesData;
    const { data: matchesResponse }: { data: MatchesResponse } = slateMatchesData;

    yield put(
      setConstructedSlate({
        slateInfoResponse,
        playersResponse,
        appearanceResponse,
        matchesResponse,
        scoringTypeId: action.payload.scoringTypeId,
      })
    );
  } catch (e) {
    errorLogger(true, `getConstructedSlateSaga: ${e}`);
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getSlateAppearancesSaga = function* getSlateAppearancesSage(
  action: ReturnType<typeof getSlateAppearances>
): SagaIterator {
  try {
    const slateAppearancesData = yield call(api.getSlateAppearances, action.payload);

    const { data: appearancesResponse }: { data: AppearancesResponse } = slateAppearancesData;

    yield put(
      setSlateAppearances({
        appearancesResponse,
        scoringTypeId: action.payload.scoringTypeId,
        slateId: action.payload.slateId,
      })
    );
  } catch (e) {
    errorLogger(true, `getSlateAppearancesSaga: ${e}`);
    yield put(setError({ ...action, type: 'toast' }));
  }
};
