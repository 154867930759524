import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import TextSlideUpAnimation from '@/components/molecules/animations/text-slide-up';
import { OverUnderLine } from '@/interfaces/pick-em';

import styles from './styles.scss';

export interface InGameProjectionProps {
  overUnderLine: OverUnderLine;
}

const InGameProjection = (props: InGameProjectionProps) => {
  const { overUnderLine } = props;
  const isSuspended = overUnderLine.status === 'suspended';
  const isRemoved = overUnderLine.status === 'removed';
  const currentText = isRemoved ? 'Expired' : `${overUnderLine.liveEventStat}`;

  const [currentSuspendedState, setCurrentSuspendedState] = useState<'suspended' | 'active'>(
    isSuspended ? 'suspended' : 'active'
  );

  useEffect(() => {
    setCurrentSuspendedState(isSuspended ? 'suspended' : 'active');
  }, [currentSuspendedState, isSuspended]);

  return (
    <div data-testid="in-game-projection">
      {overUnderLine.liveEvent && overUnderLine.liveEventStat && (
        <div className={styles.inGameProjectionWrapper}>
          <div
            className={clsx(styles.inGameProjectionStatIcon, {
              [styles.suspendedIcon]: isSuspended,
            })}
          />
          <div
            className={clsx(styles.inGameProjectionStat, {
              [styles.suspendedStat]: isSuspended,
            })}
          >
            <TextSlideUpAnimation
              defaultContent={
                <p className={clsx(styles.stat, { [styles.expired]: isSuspended || isRemoved })}>
                  {currentText}
                </p>
              }
              incomingContent={
                <p className={styles.projectionRefreshingLabel}>Projection refreshing...</p>
              }
              swapCondition={overUnderLine.liveEvent && currentSuspendedState === 'suspended'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InGameProjection;
