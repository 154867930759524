import { EntryCollection } from 'contentful';

import { ICareersFaqFields } from '@/interfaces/contentful';

export const GET_UNDERDOG_CAREERS_FAQS = 'careers/GET_UNDERDOG_CAREERS_FAQS' as const;
export const SET_UNDERDOG_CAREERS_FAQS = 'careers/SET_UNDERDOG_CAREERS_FAQS' as const;

export const getUnderdogCareersFaqs = () => ({ type: GET_UNDERDOG_CAREERS_FAQS });

export const setUnderdogCareersFaqs = (payload: {
  contentfulCareersFaqs: EntryCollection<ICareersFaqFields>;
}) => ({ type: SET_UNDERDOG_CAREERS_FAQS, payload });
