import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/draft-pools';
import { DraftPoolResponse } from '@/interfaces/draft-pools';
import { AppError } from '@/utilities/errors';
import errorLogger from '@/utilities/errors/logger';

import {
  getDraftPool,
  getDraftPoolsForSlate,
  getUserEntriesForDraftPool,
  setDraftPool,
  setDraftPoolsForSlate,
  setUserEntriesForDraftPool,
} from '../actions';

export const getDraftPoolsForSlateSaga = function* getDraftPoolsForSlateSaga(
  action: ReturnType<typeof getDraftPoolsForSlate>
): SagaIterator {
  try {
    const response = yield call(api.getUserDraftPoolsBySlate, action.payload);

    if (!response.data.draft_pools) {
      throw new AppError({
        title: 'No draft pools returned',
        message: 'Invalid slateId suspected',
        apiCode: 'noDraftPools',
      });
    }
    yield put(
      setDraftPoolsForSlate({
        data: response.data,
        slateId: action.payload.slateId,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getUserEntriesForDraftPoolSaga = function* getUserEntriesForDraftPoolSaga(
  action: ReturnType<typeof getUserEntriesForDraftPool>
): SagaIterator {
  try {
    const response = yield call(api.getUserEntriesForDraftPool, action.payload);

    yield put(
      setUserEntriesForDraftPool({
        data: response.data,
        draftPoolId: action.payload.draftPoolId,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getDraftPoolSaga = function* getDraftPoolSaga(
  action: ReturnType<typeof getDraftPool>
): SagaIterator {
  try {
    const response = yield call(api.getDraftPool, action.payload);
    const { data }: { data: DraftPoolResponse } = response;

    yield put(setDraftPool({ data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
    errorLogger(true, `getDraftPoolSaga: ${e}`);
  }
};
