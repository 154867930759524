/* eslint-disable camelcase */
import { WeeklyWinner, WeeklyWinnerResponse } from '@/interfaces/weekly-winners';

export const weeklyWinnerAdapter = (
  weeklyWinner: WeeklyWinnerResponse['weekly_winner']
): WeeklyWinner => {
  const {
    id,
    additional_info,
    contest_style_id,
    current_weekly_winner_week_id,
    cutoff_at,
    description,
    draft_entry_style_id,
    entry_count,
    entry_role,
    entry_style_id,
    image_url,
    max_entries,
    prize_breakdown_info,
    rules_url,
    slate_id,
    status,
    title,
    weekly_entry_style_id,
  } = weeklyWinner;

  return {
    id,
    additionalInfo: additional_info,
    contestStyleId: contest_style_id,
    currentWeeklyWinnerWeekId: current_weekly_winner_week_id,
    cutoffAt: cutoff_at,
    description,
    draftEntryStyleId: draft_entry_style_id,
    entryCount: entry_count,
    entryRole: entry_role,
    entryStyleId: entry_style_id,
    imageUrl: image_url,
    maxEntries: max_entries,
    prizeBreakdownInfo: prize_breakdown_info,
    rulesUrl: rules_url,
    slateId: slate_id,
    status,
    title,
    weeklyEntryStyleId: weekly_entry_style_id,
  };
};
