import { EntryCollection } from 'contentful';

import { IStateConfigurationFields } from '@/interfaces/contentful';
import { AllStateConfigurations } from '@/interfaces/state-configuration';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import { SET_STATE_CONFIGURATION, setStateConfigurations } from '../actions';
import stateConfigurationAdapter from '../adapters/state-configuration';

import { fallbackStateConfiguration } from './state-configuration-data';

type State = AllStateConfigurations;

type StateConfigurationActions = ReturnType<typeof setStateConfigurations>;

const initialState: AllStateConfigurations = {};

const set = ({
  stateConfigurations,
}: {
  stateConfigurations: EntryCollection<IStateConfigurationFields> | Record<string, never>;
}): AllStateConfigurations => {
  if (stateConfigurations?.items?.length > 0) {
    const stateConfigs = stateConfigurations.items.map(stateConfigurationAdapter);
    const formattedStateConfigs = arrayToObjectIdKeys(stateConfigs, 'state');
    return formattedStateConfigs;
  }
  return fallbackStateConfiguration;
};

export const stateConfigurationReducer = (
  state: State = initialState,
  action: StateConfigurationActions = {} as StateConfigurationActions
): State => {
  switch (action.type) {
    case SET_STATE_CONFIGURATION:
      return set(action.payload);
    default:
      return state;
  }
};
