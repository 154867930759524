/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';

import request from '@request';
import { DraftResponse, UnfilledDraftsResponse } from '@/interfaces/drafts';
import { TournamentResponse } from '@/interfaces/tournaments';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getTournamentInfo = async ({
  tournamentId,
  draftId,
}: {
  tournamentId?: string;
  draftId?: string;
}): Promise<AxiosResponse<TournamentResponse>> => {
  const url = tournamentId ? `/tournaments/${tournamentId}` : `/drafts/${draftId}/tournament`;
  try {
    const response = await request({
      method: 'GET',
      url,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUnfilledTournament = async ({
  tournamentId,
}: {
  tournamentId?: string;
}): Promise<AxiosResponse<UnfilledDraftsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/tournaments/${tournamentId}/unfilled_drafts`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const enterTournament = async ({
  tournamentId,
  confirmed,
  draft,
  entryCount,
}: {
  tournamentId: string;
  confirmed?: boolean;
  draft: { clock: number };
  entryCount: string;
}): Promise<AxiosResponse<DraftResponse>> => {
  try {
    const response = await request({
      method: 'POST',
      url: `/tournaments/${tournamentId}/entries`,
      params: {
        ...(confirmed ? { confirmed: true } : {}),
      },
      data: {
        draft,
        entry_count: entryCount,
      },
      requiresGeoComply: true,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
