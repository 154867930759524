/* eslint-disable camelcase */
import { DraftEntry, DraftEntryResponse } from '@/interfaces/drafts';

export const draftEntryAdapter = (draftEntry: DraftEntryResponse): DraftEntry => {
  const {
    id,
    auto_pick,
    payout,
    payout_text,
    pick_order,
    place,
    points,
    share_link,
    title,
    user_id,
  } = draftEntry;

  return {
    id,
    autoPick: auto_pick,
    payout,
    payoutText: payout_text,
    pickOrder: pick_order,
    place,
    points,
    shareLink: share_link,
    title,
    userId: user_id,
  };
};
