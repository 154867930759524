/* eslint-disable camelcase */
import { Slate, SlateResponse } from '@/interfaces/slates';

const slateAdapter = (slateResponse: SlateResponse): Slate => {
  const {
    id,
    description,
    contest_style_ids,
    game_count,
    sport_id,
    start_at,
    title,
    lobby_hidden,
  } = slateResponse;

  return {
    id,
    contestStyleIds: contest_style_ids,
    description,
    gameCount: game_count,
    sportId: sport_id,
    startAt: start_at,
    title,
    lobbyHidden: lobby_hidden,
  };
};

export default slateAdapter;
