import React from 'react';
import clsx from 'clsx';

import styles from './styles.scss';

export interface PickEmButtonProps {
  className?: string;
  disabled?: boolean;
  optionText: string | JSX.Element;
  selected: boolean;
  onClick?: (val?: any) => any;
  testId?: string;
}

const PickEmButton = (props: PickEmButtonProps): JSX.Element => {
  const { className, disabled, selected, optionText, onClick, testId } = props;

  const buttonClassNames = clsx(styles.pickEmButton, { [styles.selected]: selected }, className);

  return (
    <button
      className={buttonClassNames}
      disabled={disabled}
      onClick={onClick}
      data-testid={testId ?? 'option-button'}
    >
      {optionText}
    </button>
  );
};

export default PickEmButton;
