/* eslint-disable camelcase */

import { EntryStyle, EntryStyleResponse, Payout, RoundPayout } from '@/interfaces/drafting-config';

const entryStyleAdapter = (entryStyle: EntryStyleResponse): EntryStyle => {
  const {
    id,
    display_rake,
    entry_count,
    fee,
    guaranteed,
    payouts: payoutsResponse,
    prize,
    rake,
    special_prize,
    special_prize_total,
    tournament_round_payouts,
  } = entryStyle;

  const payouts: Payout[] = payoutsResponse.map((payout) => ({
    placeEnd: payout.place_end,
    placeStart: payout.place_start,
    prize: payout.prize,
    prizeItem: {
      id: payout.prize_item.id,
      amount: payout.prize_item.amount,
      description: payout.prize_item.description,
      displayText: payout.prize_item.display_text,
      status: payout.prize_item.status,
      ticketType: payout.prize_item.ticket_type,
    },
  }));

  const roundPayouts: RoundPayout[] = tournament_round_payouts?.map((roundPayout) => ({
    placeEnd: roundPayout.place_end,
    placeStart: roundPayout.place_start,
    prize: roundPayout.prize,
    round: roundPayout.round,
  }));

  return {
    id,
    displayRake: display_rake,
    entryCount: entry_count,
    fee,
    guaranteed,
    payouts,
    prize,
    rake,
    specialPrize: special_prize,
    specialPrizeTotal: special_prize_total,
    ...(tournament_round_payouts?.length && { tournamentRoundPayouts: roundPayouts }),
  };
};

export default entryStyleAdapter;
