import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/completed';
import { AppError } from '@/utilities/errors';

import { getCompletedDrafts, setCompletedDrafts } from '../actions';

export const getCompletedDraftsSaga = function* getCompletedDraftsSaga(
  action: ReturnType<typeof getCompletedDrafts>
): SagaIterator {
  try {
    const response = yield call(api.getCompletedDrafts, action.payload);

    if (!response.data.drafts || response.data.drafts.length === 0) {
      throw new AppError({
        title: 'No drafts returned',
        message: 'Invalid slateId suspected',
        apiCode: 'noDrafts',
      });
    }

    yield put(setCompletedDrafts(response.data));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
