import {
  allGamesStates as allGamesStatesFallback,
  draftStates as draftStatesFallback,
  pickEmChampionsStates as pickEmChampionsStatesFallback,
  pickEmOnlyStates as pickEmOnlyStatesFallback,
} from '@/components/pages/landing/landing-page-data';
import { LandingPageConfigs, LandingPageConfigsResponse } from '@/interfaces/landing-page-configs';

const landingPageConfigsAdapter = (data: LandingPageConfigsResponse): LandingPageConfigs => {
  if (data?.landingPageConfigs?.items?.length > 0) {
    const config = data.landingPageConfigs.items[0];
    const {
      allGamesStates,
      draftStates,
      numberOfAvailableStates,
      pickEmChampionsStates,
      pickEmOnlyStates,
    } = config?.fields?.stateConfigs ?? {};
    return {
      id: config?.sys?.id,
      landingPageConfigs: {
        allGamesStates,
        draftStates,
        numberOfAvailableStates,
        pickEmChampionsStates,
        pickEmOnlyStates,
      },
    };
  }

  // Return fallback values in the case that the Contentful API call errors out
  return {
    id: 'landing_page_fallback',
    landingPageConfigs: {
      allGamesStates: allGamesStatesFallback,
      draftStates: draftStatesFallback,
      numberOfAvailableStates: '40',
      pickEmChampionsStates: pickEmChampionsStatesFallback,
      pickEmOnlyStates: pickEmOnlyStatesFallback,
    },
  };
};

export default landingPageConfigsAdapter;
