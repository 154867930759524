import { EntryCollection } from 'contentful';

import { AllGeneralRules, IGeneralRulesFields } from '@/interfaces/general-rules';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import { SET_GENERAL_RULES, setGeneralRules } from '../actions';
import generalRulesAdapter from '../adapters/general-rules';

type State = AllGeneralRules;

type GeneralRulesActions = ReturnType<typeof setGeneralRules>;

const initialState: AllGeneralRules = {
  navItems: null,
  generalRules: {},
};

const setGeneral = (
  state: State,
  {
    generalRules,
  }: {
    generalRules: EntryCollection<IGeneralRulesFields>;
  }
): AllGeneralRules => {
  const allGeneralRules = generalRules.items.map(generalRulesAdapter);

  const navItems = allGeneralRules.map((generalRule) => ({
    label: generalRule.title,
    slug: generalRule.slug,
    flag: generalRule.flag,
  }));

  const allGeneralRulesObject = arrayToObjectIdKeys(allGeneralRules, 'slug'); // object with the slug as the key

  return {
    navItems,
    generalRules: allGeneralRulesObject,
  };
};

export const generalRulesReducer = (
  state: State = initialState,
  action: GeneralRulesActions = {} as GeneralRulesActions
): State => {
  switch (action.type) {
    case SET_GENERAL_RULES:
      return setGeneral(state, action.payload);
    default:
      return state;
  }
};
