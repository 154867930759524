import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { RootState } from '@/store';
import {
  clearAlternateProjectionsFromLine as clearAlternateProjectionsFromLineAction,
  getAlternateProjections as getAlternateProjectionsAction,
} from '@/store/modules/pick-em/actions';

import Button from '@/components/atoms/button';
import Icon from '@/components/atoms/icon';
import Loader from '@/components/atoms/loader';
import AltProjectionsCarouselItem from '@/components/molecules/alt-projections-carousel-item';
import CarouselVertical from '@/components/molecules/carousel-vertical';
import OptionsWrapper from '@/components/molecules/options-wrapper';
import PlayerAppearanceInfo from '@/components/molecules/player-appearance-info';
import { SelectedOverUnder } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { PickLocation } from '@/interfaces/pick-em';

import styles from './styles.scss';

export interface AltProjectionsModalProps {
  overUnderLineId: string;
  pickLocation: PickLocation;
  selectedOverUnders: SelectedOverUnder[];
  getAlternateProjections: ({ overUnderId }: { overUnderId: string }) => void;
  clearAlternateProjectionsFromLine: ({ overUnderLineId }: { overUnderLineId: string }) => void;
  handleCloseModal: () => void;
}

const AltProjectionsModal = (props: AltProjectionsModalProps) => {
  const {
    overUnderLineId,
    pickLocation,
    selectedOverUnders,
    getAlternateProjections,
    clearAlternateProjectionsFromLine,
    handleCloseModal,
  } = props;

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const overUnderLine = useSelector((state: RootState) => {
    return state.pickEmOverUnder.overUnderLines[overUnderLineId];
  });

  const mainLineId = overUnderLine.isMain ? overUnderLine.id : overUnderLine.mainLineId;

  const projections = useSelector((state: RootState) =>
    overUnderLine ? state.pickEmOverUnder.overUnderLines[mainLineId].alternateProjections : []
  );

  const cPEOULA =
    useSelector((state: RootState) => {
      return (
        state.pickEmOverUnder.featuredLobby.constructedAppearances[
          overUnderLine?.overUnder?.appearanceStat.appearanceId
        ] ??
        state.pickEmOverUnder.constructedAppearances[
          overUnderLine?.overUnder?.appearanceStat.appearanceId
        ]
      );
    }) ?? null;

  useEffect(() => {
    /** We clear the alt projections from the over under line here
     * so that the next time the alt modal is opened, the lines that
     * are displayed aren't "stale". Since we fetch the alt projections
     * every time the modal is opened, we want to make sure that the
     * lines displayed are the latest ones. This also helps preventing
     * unnecessary bulking up of the store since we store these projections
     * as over under lines also anyway.
     */
    return () => {
      if (projections && projections.length > 0) {
        clearAlternateProjectionsFromLine({ overUnderLineId: mainLineId });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!cPEOULA) {
      handleCloseModal();
    }
  }, [cPEOULA, handleCloseModal]);

  useEffect(() => {
    getAlternateProjections({ overUnderId: overUnderLine.overUnder.id });
    // Disabling the eslint rule here as adding overUnderLine to the
    // dependency array will cause the projections to be fetched several
    // times. This is not necessary. However, we do want to fetch the
    // projections every time the modal is opened.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAlternateProjections]);

  const selectedIndex =
    projections?.findIndex((projection) => projection.id === overUnderLineId) > -1
      ? projections?.findIndex((projection) => projection.id === overUnderLineId)
      : 0;

  return (
    <div className={styles.altProjectionsModal}>
      <h3 className={styles.title}>
        <span>{overUnderLine.overUnder.appearanceStat.displayStat}</span>
        <button onClick={handleCloseModal}>
          <Icon name="clear" />
        </button>
      </h3>
      <div className={styles.playerAppearanceInfoWrapper}>
        <PlayerAppearanceInfo
          constructedPickEmLineAppearance={cPEOULA}
          pickLocation={pickLocation}
          boost={overUnderLine.overUnder.boost}
          appearanceInfoLocation="altsModal"
        />
      </div>
      <div className={styles.altProjectionsContainer}>
        {!projections?.length && <Loader className={styles.loader} />}
        {projections?.length > 0 && (
          <CarouselVertical
            data={projections}
            customRowComponent={<AltProjectionsCarouselItem />}
            selectedIndex={selectedIndex}
            onSelectIndex={(index) => {
              setActiveIndex(index);
            }}
          />
        )}
        {projections?.length > 0 && (
          <div className={styles.selectionBox}>
            <OptionsWrapper
              allAltIds={projections.map((projection) => projection.id)}
              pickLocation={pickLocation}
              optionsLocation="altsModal"
              constructedPickEmLineAppearance={cPEOULA}
              overUnderLine={{
                ...overUnderLine,
                id: projections[activeIndex].id,
                options: projections[activeIndex].options,
                statValue: projections[activeIndex].statValue,
                isMain: projections[activeIndex].isMain,
              }}
              selectedOptionId={
                selectedOverUnders?.find(
                  (sOU) => sOU.overUnderLineId === projections[activeIndex].id
                )?.option.id
              }
              classNames={{
                wrapper: styles.overUnderOptionsWrapper,
                alignment:
                  projections[activeIndex].options.length === 1 &&
                  projections[activeIndex].options[0].choice === 'lower'
                    ? styles.alignEnd
                    : '',
                selectedOverride: styles.selectedOverride,
              }}
              onSelectOption={handleCloseModal}
            />
          </div>
        )}
      </div>
      <Button
        classNames={{ button: styles.cancelButton }}
        onClick={handleCloseModal}
        size="large"
        type="text"
        width="full"
        color="grey"
        text="Cancel"
      />
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    selectedOverUnders: state.pickEmEntries.selected.selectedOverUnders,
  }),
  (dispatch) => ({
    getAlternateProjections: ({ overUnderId }: { overUnderId: string }) =>
      dispatch(getAlternateProjectionsAction({ overUnderId })),
    clearAlternateProjectionsFromLine: ({ overUnderLineId }: { overUnderLineId: string }) =>
      dispatch(clearAlternateProjectionsFromLineAction({ overUnderLineId })),
  })
)(AltProjectionsModal);
