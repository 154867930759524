import { EntryCollection } from 'contentful';

import { IMiscSportsRulesFields, ISportsRulesFields } from '@/interfaces/contentful';
import { AllSportsRules } from '@/interfaces/sport-rules';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import {
  SET_MISC_SPORTS_RULES,
  SET_SPORTS_RULES,
  setMiscSportsRules,
  setSportsRules,
} from '../actions';
import miscSportsRulesAdapter from '../adapters/misc-sport-rules';
import sportRulesAdapter from '../adapters/sport-rules';

type State = AllSportsRules;

type SportRulesActions = ReturnType<typeof setSportsRules | typeof setMiscSportsRules>;

const initialState: AllSportsRules = {
  navItems: null,
  sportRules: {},
};

const setMiscSports = (
  state: State,
  {
    miscSportsRules,
  }: {
    miscSportsRules: EntryCollection<IMiscSportsRulesFields>;
  }
): AllSportsRules => {
  const allMiscSportsRules = miscSportsRules.items.map(miscSportsRulesAdapter);

  return {
    navItems: state.navItems,
    miscSportsRules: allMiscSportsRules,
    sportRules: {
      ...state.sportRules,
    },
  };
};

const setSports = (
  state: State,
  {
    sportsRules,
  }: {
    sportsRules: EntryCollection<ISportsRulesFields>;
  }
): AllSportsRules => {
  const allSportsRules = sportsRules.items.map(sportRulesAdapter);

  const navItems = allSportsRules
    .map((sportRule) => ({
      label: sportRule.navLinkText,
      slug: sportRule.slug,
      navRank: sportRule.navRank,
    }))
    .sort((a, b) => b.navRank - a.navRank);

  const allSportsRulesObject = arrayToObjectIdKeys(allSportsRules, 'slug'); // object with the slug as the key

  return {
    navItems,
    sportRules: allSportsRulesObject,
    miscSportsRules: state.miscSportsRules,
  };
};

export const sportsRulesReducer = (
  state: State = initialState,
  action: SportRulesActions = {} as SportRulesActions
): State => {
  switch (action.type) {
    case SET_MISC_SPORTS_RULES:
      return setMiscSports(state, action.payload);
    case SET_SPORTS_RULES:
      return setSports(state, action.payload);
    default:
      return state;
  }
};
