import Cookies from 'js-cookie';

import { ampli } from '@/ampli';
import { getDeviceId } from '@/api/request';
import { RegistrationSource } from '@/interfaces/user';
import { CONSENT_PREFERENCES } from '@/utilities/constants';

import { formatRegistrationSource } from './helpers';

const environment = process.env.APP_ENV === 'production' ? 'production' : 'development';

function canTrackUser() {
  const consentPreferences = Cookies.get(CONSENT_PREFERENCES);
  let canTrack = true;
  if (consentPreferences) {
    // The cookie is a string formatted like:
    // "dg-category-functional:1|dg-category-analytics:1|dg-category-advertising:1"
    // 1 means the user has consented to that category, 0 means they haven't.
    // We are only interested in the functional category
    const parts = consentPreferences.split('|');
    const part = parts.find((p) => p.split(':')[0] === 'dg-category-functional');
    canTrack = part?.split(':')[1] !== '0';
  }

  return canTrack;
}

export const loadAmplitude = () => {
  ampli.load({
    client: {
      configuration: {
        defaultTracking: { sessions: true },
        trackingOptions: { ipAddress: canTrackUser() },
      },
    },
    environment,
  });
};

export const identifyAmplitudeUser = ({
  userId,
  registrationSource,
}: {
  userId: string;
  registrationSource: RegistrationSource;
}) => {
  const formattedRegistrationSource = formatRegistrationSource(registrationSource);
  const trackUser = canTrackUser();
  const uid = trackUser ? userId : undefined;

  ampli.client.reset();

  ampli.identify(uid, formattedRegistrationSource, {
    device_id: getDeviceId(!trackUser),
    user_id: uid,
  });
};

export const batchAmplitudeEvents = () => {
  ampli.client.flush();
};

export default ampli;
