import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/results';

import {
  getSettledDraftsForSlate,
  getSettledSlates,
  setSettledDraftsForSlate,
  setSettledSlates,
} from '../actions';

export const getSettledSlatesSaga = function* getSettledSlatesSaga(
  action: ReturnType<typeof getSettledSlates>
): SagaIterator {
  try {
    const response = yield call(api.getSettledSlates, action.payload);

    yield put(setSettledSlates({ data: response.data, sportId: action.payload.sportId }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getSettledDraftsForSlateSaga = function* getSettledDraftsForSlateSaga(
  action: ReturnType<typeof getSettledDraftsForSlate>
): SagaIterator {
  try {
    const response = yield call(api.getSettledDraftsForSlate, action.payload);

    yield put(setSettledDraftsForSlate({ data: response.data, slateId: action.payload.slateId }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
