/* eslint-disable camelcase */
import { AlternateProjection, AlternateProjectionResponse } from '@/interfaces/pick-em';

import overUnderOptionAdapter from './over-under-option';

const alternateProjectionAdapter = (
  altProjection: AlternateProjectionResponse
): AlternateProjection => {
  const { is_main, id, options: optionsResponse, stat_value } = altProjection;

  const adaptedOptions = optionsResponse ? optionsResponse?.map(overUnderOptionAdapter) : null;

  return {
    id,
    isMain: is_main,
    options: adaptedOptions,
    statValue: stat_value,
  };
};

export default alternateProjectionAdapter;
