export const statesArray = [
  'AL',
  'AK',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const provincesArray = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'NT',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
];

export const otherArray = ['AA', 'AE', 'AP', 'AS', 'GU', 'MP', 'PR', 'UM', 'VI'];

export const statesProvincesArray = [...statesArray, ...provincesArray, ...otherArray];

export const stateOptions = [
  {
    label: 'AL',
    value: 'AL',
  },
  {
    label: 'AK',
    value: 'AK',
  },
  {
    label: 'AR',
    value: 'AR',
  },
  {
    label: 'AZ',
    value: 'AZ',
  },
  {
    label: 'CA',
    value: 'CA',
  },
  {
    label: 'CO',
    value: 'CO',
  },
  {
    label: 'CT',
    value: 'CT',
  },
  {
    label: 'DC',
    value: 'DC',
  },
  {
    label: 'DE',
    value: 'DE',
  },
  {
    label: 'FL',
    value: 'FL',
  },
  {
    label: 'GA',
    value: 'GA',
  },
  {
    label: 'HI',
    value: 'HI',
  },
  {
    label: 'ID',
    value: 'ID',
  },
  {
    label: 'IL',
    value: 'IL',
  },
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: 'IA',
    value: 'IA',
  },
  {
    label: 'KS',
    value: 'KS',
  },
  {
    label: 'KY',
    value: 'KY',
  },
  {
    label: 'LA',
    value: 'LA',
  },
  {
    label: 'ME',
    value: 'ME',
  },
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MI',
    value: 'MI',
  },
  {
    label: 'MN',
    value: 'MN',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MO',
    value: 'MO',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'NC',
    value: 'NC',
  },
  {
    label: 'ND',
    value: 'ND',
  },
  {
    label: 'NE',
    value: 'NE',
  },
  {
    label: 'NH',
    value: 'NH',
  },
  {
    label: 'NJ',
    value: 'NJ',
  },
  {
    label: 'NM',
    value: 'NM',
  },
  {
    label: 'NV',
    value: 'NV',
  },
  {
    label: 'NY',
    value: 'NY',
  },
  {
    label: 'OH',
    value: 'OH',
  },
  {
    label: 'OK',
    value: 'OK',
  },
  {
    label: 'OR',
    value: 'OR',
  },
  {
    label: 'PA',
    value: 'PA',
  },
  {
    label: 'RI',
    value: 'RI',
  },
  {
    label: 'SC',
    value: 'SC',
  },
  {
    label: 'SD',
    value: 'SD',
  },
  {
    label: 'TN',
    value: 'TN',
  },
  {
    label: 'TX',
    value: 'TX',
  },
  {
    label: 'UT',
    value: 'UT',
  },
  {
    label: 'VA',
    value: 'VA',
  },
  {
    label: 'VT',
    value: 'VT',
  },
  {
    label: 'WA',
    value: 'WA',
  },
  {
    label: 'WI',
    value: 'WI',
  },
  {
    label: 'WV',
    value: 'WV',
  },
  {
    label: 'WY',
    value: 'WY',
  },
];

export const provincesOptions = [
  {
    label: 'AB',
    value: 'AB',
  },
  {
    label: 'BC',
    value: 'BC',
  },
  {
    label: 'MB',
    value: 'MB',
  },
  {
    label: 'NB',
    value: 'NB',
  },
  {
    label: 'NL',
    value: 'NL',
  },
  {
    label: 'NS',
    value: 'NS',
  },
  {
    label: 'NT',
    value: 'NT',
  },
  {
    label: 'NU',
    value: 'NU',
  },
  {
    label: 'ON',
    value: 'ON',
  },
  {
    label: 'PE',
    value: 'PE',
  },
  {
    label: 'QC',
    value: 'QC',
  },
  {
    label: 'SK',
    value: 'SK',
  },
  {
    label: 'YT',
    value: 'YT',
  },
];

export const otherOptions = [
  {
    label: 'AA',
    value: 'AA',
  },
  {
    label: 'AE',
    value: 'AE',
  },
  {
    label: 'AP',
    value: 'AP',
  },
  {
    label: 'AS',
    value: 'AS',
  },
  {
    label: 'GU',
    value: 'GU',
  },
  {
    label: 'MP',
    value: 'MP',
  },
  {
    label: 'PR',
    value: 'PR',
  },
  {
    label: 'UM',
    value: 'UM',
  },
  {
    label: 'VI',
    value: 'VI',
  },
];

export const statesProvincesOptions = [...stateOptions, ...provincesOptions, ...otherOptions];

export const canadianPostalCodeRegex = /[A-Z]\d[A-Z] \d[A-Z]\d$/;
export const usZipRegEx = /^\d{5}-\d{4}$|^\d{9}$|^\d{5}$/;
