import { EntryCollection } from 'contentful';

import { IPowerUpInfoFields } from '@/interfaces/contentful';

export const GET_CONTENTFUL_POWER_UP_INFO =
  'contentful-power-up-infos/GET_CONTENTFUL_POWER_UP_INFO' as const;
export const SET_CONTENTFUL_POWER_UP_INFO =
  'contentful-power-up-infos/SET_CONTENTFUL_POWER_UP_INFO' as const;

export const getContentfulPowerUpInfo = (payload: { contentfulInfoId: string }) => ({
  type: GET_CONTENTFUL_POWER_UP_INFO,
  payload,
});

export const setContentfulPowerUpInfo = (payload: {
  powerUpInfoResponse: EntryCollection<IPowerUpInfoFields>;
  contentfulInfoId: string;
}) => ({ type: SET_CONTENTFUL_POWER_UP_INFO, payload });
