import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Cookies from 'js-cookie';
import debounce from 'lodash.debounce';

import { RootState } from '@/store';
import { clearError as clearErrorAction } from '@/store/modules/error/actions';

import { ampli, RegistrationReferralCodeEnteredSuccessfulProperties } from '@/ampli';
import {
  getPromoCodePromotion as getPromoCodePromotionApi,
  getUserReferralPromotion as getUserReferralPromotionApi,
} from '@/api/promotions';
import { checkUsername as checkUsernameApi } from '@/api/usernames';
import GamblingAdvice from '@/components/atoms/gambling-advice';
import Icon from '@/components/atoms/icon';
import LinkWrapper from '@/components/atoms/link-wrapper';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import ProgressBarStepped from '@/components/atoms/progress-bar-stepped';
import SignUpButton from '@/components/atoms/sign-up-button';
import TextField from '@/components/atoms/text-field';
import { useToast } from '@/components/atoms/toast';
import { useAuth } from '@/components/contexts/auth';
import BranchContext from '@/components/contexts/branch';
/* eslint-disable-next-line import/no-cycle */
import LoginModal from '@/components/molecules/login-modal';
import { AppErrorRedux } from '@/interfaces/error';
import { ReferralPromotionResponse } from '@/interfaces/promotions';
import eventLogger from '@/utilities/analytics';
import { UD_REF } from '@/utilities/constants';
import { ERROR_MESSAGES } from '@/utilities/errors/constants';
import { validEmail, validPassword } from '@/utilities/helpers';
import { useDobField } from '@/utilities/hooks/fields/use-dob-field';
import useQuery from '@/utilities/hooks/use-query';

import styles from './styles.scss';

dayjs.extend(customParseFormat);

export interface RegisterFormProps {
  clearError: () => void;
  error: AppErrorRedux;
  isModal?: boolean;
  prefilledPromo?: string;
}

const RegisterForm = (props: RegisterFormProps): JSX.Element => {
  const { clearError, error, isModal, prefilledPromo } = props;

  const openToast = useToast();
  const branch = useContext(BranchContext);
  const openModal = useModal();
  const navigate = useNavigate();
  const location = useLocation();

  const query = useQuery();
  const initialPromo = prefilledPromo || query.get('promo');

  const { registerUser } = useAuth();
  const { dob, onDobBlur, onDobChange, dobError, dobValid } = useDobField({
    eventType: 'registration',
  });

  // I could consider pulling all of these out into hooks,
  // like const [username, usernameValid, usernameError] = useUsername();
  const [username, setUsername] = useState<string>('');
  const [usernameValid, setUsernameValid] = useState<boolean>(false);
  const [usernameError, setUsernameError] = useState<string | false>(false);

  const [email, setEmail] = useState<string>('');
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | false>(false);

  const [password, setPassword] = useState<string>('');
  const [passwordValid, setPasswordValid] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string | false>(false);
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);

  const [promoCode, setPromoCode] = useState<string>('');
  const [promoCodeValid, setPromoCodeValid] = useState<boolean>(false);
  const [promoCodeError, setPromoCodeError] = useState<string | false>(false);
  const [promoCodeId, setPromoCodeId] = useState<string>(null);
  const [promoCodeReferral, setPromoCodeReferral] = useState<string>('');

  const [ironCladSignerId, setIronCladSignerId] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [ampliPromotionType, setAmpliPromotionType] =
    useState<RegistrationReferralCodeEnteredSuccessfulProperties['promotion_type']>(null);

  useEffect(() => {
    if (error.type === 'toast') {
      ampli.registrationUnsuccessfulRegistrationDialogShown({
        error_text: error.message || '',
        promo_code: ampliPromotionType === 'referral' ? promoCodeReferral : promoCode,
        promotion_type: ampliPromotionType,
      });
      openToast({
        message: error.message,
        open: true,
        variant: 'red',
        customClose: clearError,
      });
    }
  }, [
    ampliPromotionType,
    clearError,
    error,
    openToast,
    prefilledPromo,
    promoCode,
    promoCodeReferral,
  ]);

  useEffect(() => {
    eventLogger({
      gtm: {
        eventName: 'view_registration_page',
      },
    });
    eventLogger({
      gtm: {
        eventName: 'start_registration',
      },
    });
  }, []);

  useEffect(() => {
    if (initialPromo) {
      getPromoCodePromotionApi({ code: initialPromo })
        .then((res: { data: ReferralPromotionResponse }) => {
          setPromoCode(initialPromo.toUpperCase());
          setPromoCodeId(res.data.promotion.id);
          setPromoCodeReferral(res.data.meta.referral);
          setPromoCodeValid(true);
          setAmpliPromotionType('code');
          Cookies.set(UD_REF, res.data.meta.referral, { expires: 2 / 24 }); // expire in 2 hours
        })
        .catch(() => {
          setPromoCode(initialPromo.toUpperCase());
          setPromoCodeError('There was an error with your promo code');
          setPromoCodeValid(false);
          Cookies.remove(UD_REF);
        })
        .finally(() => {
          navigate(location.pathname, { replace: true });
        });
    }
  }, [location.pathname, navigate, initialPromo]);

  useEffect(() => {
    if (branch?.branchData?.data?.username) {
      getUserReferralPromotionApi({ username: branch.branchData.data.username })
        .then((res: { data: ReferralPromotionResponse }) => {
          setPromoCode(branch.branchData.data.username);
          setPromoCodeId(res.data.promotion.id);
          setPromoCodeReferral(res.data.meta.referral);
          setPromoCodeValid(true);
          setAmpliPromotionType('referral');
        })
        .catch(() => {
          setPromoCode(branch.branchData.data.username);
          setPromoCodeError('There was an error with your promo code');
          setPromoCodeValid(false);
        });
    }
    if (!branch?.branchData?.data?.username && branch?.branchData?.data?.promoCode) {
      getPromoCodePromotionApi({ code: branch.branchData.data.promoCode })
        .then((res: { data: ReferralPromotionResponse }) => {
          setPromoCode(branch.branchData.data.promoCode);
          setPromoCodeId(res.data.promotion.id);
          setPromoCodeReferral(res.data.meta.referral);
          setPromoCodeValid(true);
          setAmpliPromotionType('referral');
        })
        .catch(() => {
          setPromoCode(branch.branchData.data.promoCode);
          setPromoCodeError('There was an error with your promo code');
          setPromoCodeValid(false);
        });
    }
  }, [branch?.branchData?.data?.promoCode, branch?.branchData?.data?.username]);

  useEffect(() => {
    if (promoCodeError) {
      ampli.registrationReferralCodeEnteredUnsuccessful({
        promo_code: promoCode.toUpperCase(),
      });
    }
  }, [promoCode, promoCodeError]);

  const progressBarCount = [usernameValid, emailValid, passwordValid, dobValid].reduce(
    (acc, curr) => {
      if (curr) {
        const newCount = acc + 1;
        return newCount;
      }
      return acc;
    },
    0
  );

  /**
   * Username validation ***************
   */
  const validUsername = (name: string) => name.length >= 2 && name.length <= 24;

  const checkUsername = (value: string) => {
    checkUsernameApi(value)
      .then(() => {
        setUsernameValid(false);
        setUsernameError('This username is taken');
        ampli.registrationUsernameEnteredUnsuccessful({
          username_failure_reason: 'Already Taken',
        });
      })
      .catch(() => {
        if (validUsername(value)) {
          setUsernameValid(true);
          setUsernameError(false);
        } else {
          setUsernameError(
            "Your username must be between 2 and 24 characters long and can't start with a number"
          );
          setUsernameValid(false);
          ampli.registrationUsernameEnteredUnsuccessful({
            username_failure_reason: 'Invalid Format',
          });
        }
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUsernameCheck = useCallback(debounce(checkUsername, 300), []);

  const onUsernameChange = ({ value }: { value: string }): void => {
    if (/^[A-Za-z][A-Za-z0-9]*$/.test(value) || value === '') {
      setUsername(value);
      debouncedUsernameCheck(value);
    }
  };

  /**
   * Email validation ***************
   */

  const onEmailChange = ({ value }: { value: string }): void => {
    setEmail(value);
    setEmailValid(validEmail(value));
  };

  const onEmailBlur = () => {
    if (!validEmail(email)) {
      setEmailError('Please enter a valid email address');
      ampli.registrationEmailEnteredUnsuccessful();
    } else {
      setEmailError(false);
    }
  };

  /**
   * Password validation ***************
   */

  const onPasswordChange = ({ value }: { value: string }): void => {
    setPassword(value);
    setPasswordValid(validPassword(value));
  };

  const onPasswordBlur = () => {
    if (password.length < 8 || password.length > 128 || !validPassword(password)) {
      setPasswordError(ERROR_MESSAGES.WEAK_PASSWORD);
    } else {
      setPasswordError(false);
    }
  };

  const passwordEyeIcon = (
    <button
      type="button"
      className={styles.passwordVisibleIcon}
      onClick={(e) => {
        e.preventDefault();
        setPasswordVisibility(!passwordVisibility);
      }}
      aria-label={passwordVisibility ? 'Hide password' : 'Show password'}
    >
      <Icon name={passwordVisibility ? 'visibilityCrossedOut' : 'visibility'} />
    </button>
  );

  /**
   * Promocode validation ***************
   */

  const checkPromoCode = (value: string) => {
    getPromoCodePromotionApi({ code: value })
      .then((res: { data: ReferralPromotionResponse }) => {
        setPromoCodeId(res.data.promotion.id);
        setPromoCodeReferral(res.data.meta.referral);
        setPromoCodeValid(true);
        setPromoCodeError(false);
        setAmpliPromotionType('code');
        Cookies.set(UD_REF, res.data.meta.referral, { expires: 2 / 24 }); // expire in 2 hours
      })
      .catch(() => {
        // if the promo errors check the username
        getUserReferralPromotionApi({ username: value })
          .then((res: { data: ReferralPromotionResponse }) => {
            setPromoCodeId(res.data.promotion.id);
            setPromoCodeReferral(res.data.meta.referral);
            setPromoCodeValid(true);
            setPromoCodeError(false);
            setAmpliPromotionType('referral');
            Cookies.set(UD_REF, res.data.meta.referral, { expires: 2 / 24 }); // expire in 2 hours
          })
          .catch(() => {
            setPromoCodeId(null);
            setPromoCodeValid(false);
            Cookies.remove(UD_REF);
          });
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPromoCodeCheck = useCallback(debounce(checkPromoCode, 300), []);

  const onPromoCodeChange = ({ value }: { value: string }): void => {
    setPromoCode(value);
    debouncedPromoCodeCheck(value.toUpperCase());
  };

  const onPromoCodeBlur = () => {
    if (promoCodeValid || promoCode === '') {
      setPromoCodeError(null);
    } else {
      setPromoCodeError('Invalid promo code');
    }
  };

  /**
   * Submission validation ***************
   */

  const canSubmit =
    validUsername(username) &&
    validPassword(password) &&
    validEmail(email) &&
    dobValid &&
    !usernameError &&
    (promoCodeValid || promoCode === '') &&
    termsAccepted &&
    !isSubmitting;

  /**
   * On submit ***************
   */

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIronCladSignerId(username.toLowerCase());
    setIsSubmitting(true);

    if (!promoCodeId) {
      getPromoCodePromotionApi({ code: 'NOPROMO' })
        .then((res: { data: ReferralPromotionResponse }) => {
          Cookies.set(UD_REF, res.data.meta.referral, { expires: 2 / 24 }); // expire in 2 hours
          registerUser({
            userRegistrationObject: {
              user: {
                username: username.toLowerCase(),
                email,
                password,
                birthdate: dayjs(dob, 'MM/DD/YYYY').format('YYYY-MM-DD'),
                promotion_id: res.data.promotion.id,
                referring_link: promoCodeReferral,
              },
              event_payload: null,
            },
            promoCode: 'NOPROMO',
            ampliPromotionType: 'code',
          }).then(() => {
            setIsSubmitting(false);
          });
          // swallow the error, I don't want this to be blocking
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    } else {
      if (!Cookies.get(UD_REF)) {
        getPromoCodePromotionApi({ code: promoCode }).then(
          (res: { data: ReferralPromotionResponse }) => {
            Cookies.set(UD_REF, res.data.meta.referral, { expires: 2 / 24 });
          }
        );
      }
      registerUser({
        userRegistrationObject: {
          user: {
            username: username.toLowerCase(),
            email,
            password,
            birthdate: dayjs(dob, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            promotion_id: promoCodeId,
            referring_link: promoCodeReferral,
          },
          event_payload: null,
        },
        promoCode,
        ampliPromotionType,
      }).then(() => {
        setIsSubmitting(false);
      });
    }
  };

  const openLoginModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <LoginModal handleCloseModal={handleCloseModal} />
    ));
  };

  const progressBar = (
    <div className={styles.progressBarWrapper}>
      <ProgressBarStepped totalCount={4} currentCount={progressBarCount} />
    </div>
  );

  const loginLink = (
    <LinkWrapper className={styles.loginLink} to="/login">
      Log in
    </LinkWrapper>
  );

  const loginModalLink = (
    <button className={styles.loginLink} onClick={openLoginModal}>
      Log in
    </button>
  );

  return (
    <div className={styles.formContent}>
      <h1 className={styles.title}>Sign up</h1>
      {isModal ? null : progressBar}
      <form onSubmit={onSubmit} className={styles.form}>
        <TextField
          label="Username"
          name="username"
          value={username}
          valid={usernameValid}
          error={usernameError}
          inputClassName={styles.userNameInput}
          placeholder="Only numbers and letters, min 2 characters"
          onChange={onUsernameChange}
          classNames={{ wrapper: styles.fieldWrapper }}
          maxLength={24}
          giveFocus
        />
        <TextField
          label="Email"
          name="email"
          value={email}
          valid={emailValid}
          error={emailError}
          placeholder="Enter a valid email address"
          onChange={onEmailChange}
          onBlur={onEmailBlur}
          classNames={{ wrapper: styles.fieldWrapper }}
          autoComplete="email"
        />
        <TextField
          label="Password"
          name="password"
          value={password}
          valid={passwordValid}
          error={passwordError}
          type={passwordVisibility ? 'text' : 'password'}
          placeholder="Enter a strong password"
          onChange={onPasswordChange}
          onBlur={onPasswordBlur}
          classNames={{ wrapper: styles.fieldWrapper }}
          autoComplete="new-password"
          customRightElement={passwordEyeIcon}
        />
        <TextField
          label="Date of birth"
          name="dob"
          value={dob}
          valid={dobValid}
          error={dobError}
          placeholder="MM/DD/YYYY"
          onChange={onDobChange}
          onBlur={onDobBlur}
          classNames={{ wrapper: styles.fieldWrapper }}
        />
        <TextField
          label="Promo code or username"
          name="promocode"
          value={promoCode}
          valid={promoCodeValid}
          error={promoCodeError}
          placeholder="Enter a promo code or friend's username"
          inputClassName={styles.promoCodeInput}
          onChange={onPromoCodeChange}
          onBlur={onPromoCodeBlur}
          classNames={{ wrapper: styles.fieldWrapper }}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            setPromoCode('BESTPROMO');
            checkPromoCode('BESTPROMO');
            ampli.registrationDefaultReferralCodeInserted();
          }}
          className={styles.noPromoButton}
          type="button"
        >
          Don&apos;t have one?
        </button>
        {/* Ironclad */}
        <PSClickWrap
          accessId={process.env.PACTSAFE_ACCESS_ID}
          groupKey="sign-up"
          signerId={ironCladSignerId}
          customData={{
            username,
            email,
          }}
          onChecked={() => setTermsAccepted(true)}
          onUnchecked={() => setTermsAccepted(false)}
        />
        <p className={styles.signupConditions}>
          I also confirm that I am at least 18 years old (or 19+ in Alabama and Nebraska, 19+ in
          Colorado for some games, and 21+ in Arizona and Massachusetts). You&apos;ll also get
          offers and messages from Underdog.
        </p>
        <SignUpButton
          classNames={{
            button: styles.button,
          }}
          disabled={!canSubmit}
          size="small"
          type="solid"
          width="intrinsic"
        />
      </form>
      <p className={styles.login}>
        Already have an account?&nbsp;
        {isModal ? loginModalLink : loginLink}
      </p>
      <GamblingAdvice />
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    error: state.error,
  }),
  (dispatch) => ({
    clearError: () => dispatch(clearErrorAction()),
  })
)(RegisterForm);
