import { Entry } from 'contentful';

import { IMiscSportsRulesFields } from '@/interfaces/contentful';
import { MiscSportsRules } from '@/interfaces/sport-rules';

export const miscSportsRules = (miscSportRule: Entry<IMiscSportsRulesFields>): MiscSportsRules => {
  const { id } = miscSportRule.sys;
  const { displayName, slug, content, statTables } = miscSportRule.fields;

  return {
    id,
    displayName,
    slug,
    content,
    statTables,
  };
};

export default miscSportsRules;
