import { AxiosResponse } from 'axios';

import request from '@request';
import { CosmeticsResponse } from '@/interfaces/cosmetics';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getCosmetics = async (): Promise<AxiosResponse<CosmeticsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/cosmetics',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
