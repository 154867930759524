/* eslint-disable camelcase */
import { weeklyWinnerAdapter } from '@/store/modules/weekly-winners/adapters/weekly-winner';

import { Contests, ContestsResponse } from '@/interfaces/lobbies';

import draftPoolAdapter from './lobby-draft-pool';
import lobbyTournamentAdapter from './lobby-tournaments';
import slateSitAndGosAdapter from './slate-sit-and-gos';

const contestsAdapter = ({ contests }: { contests: ContestsResponse['contests'] }): Contests => {
  const {
    slate_sit_and_gos,
    draft_pools,
    tournaments: tournamentsResponse,
    weekly_winners,
  } = contests;

  const slateSitAndGos = slate_sit_and_gos ? slateSitAndGosAdapter(slate_sit_and_gos) : [];
  const tournaments = tournamentsResponse ? lobbyTournamentAdapter(tournamentsResponse) : [];
  const draftPools = draft_pools ? draft_pools.map(draftPoolAdapter) : [];
  const weeklyWinners = weekly_winners ? weekly_winners.map(weeklyWinnerAdapter) : [];

  return {
    draftPools,
    slateSitAndGos,
    tournaments,
    weeklyWinners,
  };
};

export default contestsAdapter;
