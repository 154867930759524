/* eslint-disable camelcase */
import {
  RENAME_DRAFT_ENTRY,
  renameDraftEntry,
  UPDATE_DRAFT_ENTRIES_SCORING,
  updateDraftEntriesScoring,
} from '@/store/modules/drafts/actions';

import { RenameDraftEntry } from '@/interfaces/drafts';
import {
  EntryScoringUpdateResponse,
  LiveDraftsForSlate,
  LiveDraftsForSlateResponse,
} from '@/interfaces/live';

import { SET_LIVE_DRAFTS_FOR_SLATE, setLiveDraftsForSlate } from '../actions';
import liveDraftForSlateAdapter from '../adapters/live-draft-for-slate';

type State = LiveDraftsForSlate | null;

type LiveDraftsForSlateActions = ReturnType<
  typeof setLiveDraftsForSlate | typeof updateDraftEntriesScoring | typeof renameDraftEntry
>;

const initialState: LiveDraftsForSlate = {};

const set = (
  state: LiveDraftsForSlate,
  { data }: { data: LiveDraftsForSlateResponse }
): LiveDraftsForSlate => {
  const slateId = data.drafts[0]?.slate_id; // this is a little fragile

  if (!slateId) return state;

  const newDrafts = liveDraftForSlateAdapter(data.drafts);

  // filter out duplicates just in case
  const drafts = state?.[slateId]?.drafts
    ? [...state[slateId].drafts, ...newDrafts].filter(
        (draft, ind, arr) => arr.findIndex((d) => d.id === draft.id) === ind
      )
    : newDrafts;

  return {
    ...state,
    [slateId]: {
      drafts,
      meta: data.meta,
    },
  };
};

const updateScoring = (
  state: LiveDraftsForSlate,
  {
    data,
    draftId,
    slateId,
  }: { data: EntryScoringUpdateResponse[]; draftId: string; slateId: string }
): LiveDraftsForSlate => {
  if (
    !slateId ||
    !draftId ||
    !state[slateId] ||
    !state[slateId].drafts?.find((draft) => draft.id === draftId)
  )
    return state;

  const selectedDraft = state[slateId].drafts?.find((draft) => draft.id === draftId);
  const userDraftEntry = data.find((dE) => dE.id === selectedDraft.userDraftEntryId);

  if (!userDraftEntry) return state;

  const updatedDrafts = state[slateId].drafts.map((draft) => {
    if (draft.id === draftId) {
      return {
        ...draft,
        userDraftEntryPayout: userDraftEntry.payout || draft.userDraftEntryPayout,
        userDraftEntryPlace: userDraftEntry.place || draft.userDraftEntryPlace,
        userDraftEntryPayoutText: userDraftEntry.payout_text || draft.userDraftEntryPayoutText,
        userDraftEntryPoints: userDraftEntry.points || draft.userDraftEntryPoints,
      };
    }

    return draft;
  });

  return {
    ...state,
    [slateId]: {
      ...state[slateId],
      drafts: updatedDrafts,
    },
  };
};

const renameDraft = (state: LiveDraftsForSlate, data: RenameDraftEntry) => {
  const slate = state[data.slateId];
  if (!slate) return state;

  const drafts = slate.drafts.map((draftEntry) => {
    if (draftEntry.id === data.id) {
      return {
        ...draftEntry,
        title: data.draftEntryTitle,
      };
    }
    return draftEntry;
  });

  return {
    ...state,
    [data.slateId]: {
      drafts,
      meta: slate.meta,
    },
  };
};

export const liveDraftsForSlateReducer = (
  state: State = initialState,
  action: LiveDraftsForSlateActions = {} as LiveDraftsForSlateActions
): State => {
  switch (action.type) {
    case SET_LIVE_DRAFTS_FOR_SLATE:
      return set(state, action.payload);
    case UPDATE_DRAFT_ENTRIES_SCORING:
      return updateScoring(state, action.payload);
    case RENAME_DRAFT_ENTRY:
      return renameDraft(state, action.payload);
    default:
      return state;
  }
};
