import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/promotions';

import { setUserPromotions } from '../actions';

export const getUserPromotionsSaga = function* getUserPromotionsSaga(): SagaIterator {
  try {
    const response = yield call(api.getUserPromotions);

    yield put(setUserPromotions({ data: response.data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
