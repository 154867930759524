import React from 'react';

import { StatsTable } from '@/interfaces/appearance-stats';

import styles from './styles.scss';

export interface StatsTableProps {
  statsTable: StatsTable;
}

const StatTable = (props: StatsTableProps) => {
  const { statsTable } = props;
  if (!statsTable?.rows?.length) return null;

  return (
    <table className={styles.statsTable}>
      {statsTable.titles && (
        <thead>
          <tr className={styles.titleRow}>
            {statsTable.titles.reduce((acc, curr, index) => {
              const elementsToAdd = [];
              if (index === 0 && curr.colStart > 0) {
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < curr.colStart; i++) {
                  elementsToAdd.push(<td key={`titleBefore${i}`} aria-label="empty cell" />);
                }
              }
              elementsToAdd.push(
                <th
                  key={curr.text}
                  className={styles.titleHeader}
                  colSpan={curr.colEnd - curr.colStart + 1}
                >
                  {curr.text}
                </th>
              );
              if (
                index === statsTable.titles.length - 1 &&
                statsTable.headers.length - (curr.colEnd + 1) > 0
              ) {
                const remainingCols = statsTable.headers.length - (curr.colEnd + 1);
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < remainingCols; i++) {
                  elementsToAdd.push(<td key={`titleAfter${i}`} aria-label="empty cell" />);
                }
              }
              return [...acc, ...elementsToAdd];
            }, [])}
          </tr>
        </thead>
      )}
      <thead>
        <tr className={styles.headerRow}>
          {statsTable.headers.map((header, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <th key={`${header}${index}`}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {statsTable.rows.map((row, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={`row${index}`} className={styles.statRow}>
            {row.map((stat, i) => {
              if (i === 0) {
                // eslint-disable-next-line react/no-array-index-key
                return <th key={`${index}${i}`}>{stat}</th>;
              }
              if (i === row.length - 1) {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <td key={`${index}${i}`} className={styles.totalStat}>
                    {stat}
                  </td>
                );
              }
              return (
                // eslint-disable-next-line react/no-array-index-key
                <td key={`${index}${i}`} className={styles.statCell}>
                  {stat}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default StatTable;
