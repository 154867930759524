import React from 'react';
import clsx from 'clsx';

import Icon, { IconTypes } from '@/components/atoms/icon';

import styles from './styles.scss';

export interface LabelProps {
  classNames?: {
    label?: string;
    text?: string;
  };
  color: 'dark' | 'light' | 'gold';
  iconNameLeft?: IconTypes;
  iconNameRight?: IconTypes;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  text: string;
  type: 'error' | 'feedbackBrand' | 'info' | 'neutral' | 'primary' | 'success';
}

const Label = (props: LabelProps) => {
  const { classNames, color, iconNameLeft, iconNameRight, onClick, text, type } = props;

  return (
    <div className={clsx(styles.label, styles[type], styles[color], classNames?.label)}>
      {iconNameLeft && (
        <Icon
          className={clsx(styles.icon, styles.leftIcon)}
          name={iconNameLeft}
          title={iconNameLeft}
        />
      )}
      {onClick ? (
        <button className={clsx(styles.text, styles.clickable, classNames?.text)} onClick={onClick}>
          {text}
        </button>
      ) : (
        // ignore this span change in chromatic, it's often a timer
        <span data-chromatic="ignore" className={clsx(styles.text, classNames?.text)}>
          {text}
        </span>
      )}
      {iconNameRight && (
        <Icon
          className={clsx(styles.icon, styles.rightIcon)}
          name={iconNameRight}
          title={iconNameRight}
        />
      )}
    </div>
  );
};

export default Label;
