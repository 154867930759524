import { EntryCollection } from 'contentful';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import * as api from '@/api-contentful/configurations';
import { IStateConfigsFields } from '@/interfaces/contentful';
import errorLogger from '@/utilities/errors/logger';

import { getLandingPageConfigs, setLandingPageConfigs } from '../actions';

export const getLandingPageConfigsSaga = function* getLandingPageConfigsSaga(
  action: ReturnType<typeof getLandingPageConfigs>
): SagaIterator {
  try {
    const landingPageConfigs: EntryCollection<IStateConfigsFields> = yield call(
      api.getContentfulConfiguration,
      action.payload
    );
    yield put(setLandingPageConfigs({ landingPageConfigs }));
  } catch (e) {
    // If there's a network error from Contentful, we should use a hardcoded value as a fallback for our landing page data
    // instead of throwing the user an error toast
    errorLogger(true, `getLandingPageConfigsSaga: ${e}`);
    yield put(
      setLandingPageConfigs({
        landingPageConfigs: {},
      })
    );
  }
};
