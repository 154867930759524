import { Entry } from 'contentful';

import { ICareersFaqFields } from '@/interfaces/contentful';
import { UnderdogCareersFaq } from '@/interfaces/underdog-careers-faqs';

const careersFaqAdapter = (careersFaq: Entry<ICareersFaqFields>): UnderdogCareersFaq => {
  const id = careersFaq?.sys.id;
  const answer = careersFaq?.fields.answer;
  const displayOrder = careersFaq?.fields.displayOrder;
  const question = careersFaq?.fields.question;

  return {
    id,
    answer,
    displayOrder,
    question,
  };
};

export default careersFaqAdapter;
