import { useSelector } from 'react-redux';

import { RootState } from '@/store';

export const usePromoCardBoostData = ({ boostedLineId }: { boostedLineId: string }) => {
  const overUnderLine = useSelector(
    (state: RootState) => state.pickEmOverUnder.overUnderLines?.[boostedLineId]
  );

  const selectedOverUnders = useSelector(
    (state: RootState) => state.pickEmEntries.selected.selectedOverUnders
  );

  const teams = useSelector((state: RootState) => state.draftingConfig.teams);

  return {
    overUnderLine,
    selectedOverUnders,
    teams,
  };
};
