/* eslint-disable camelcase */
import { OverUnderLineSwap, OverUnderLineSwapResponse } from '@/interfaces/pick-em';

import overUnderOptionAdapter from './over-under-option';

const overUnderLineSwapAdapter = (
  overUnderLineSwap: OverUnderLineSwapResponse
): OverUnderLineSwap => {
  const {
    old_id,
    old_stat,
    new_id,
    new_stat,
    options: optionsResponse,
    multiplier_swap,
    old_line_options,
  } = overUnderLineSwap;

  const options = optionsResponse.map(overUnderOptionAdapter);
  const oldLineOptions = old_line_options.map(overUnderOptionAdapter);

  return {
    oldId: old_id,
    oldStat: old_stat,
    newId: new_id,
    newStat: new_stat,
    options,
    multiplierSwap: multiplier_swap,
    oldLineOptions,
  };
};

export default overUnderLineSwapAdapter;
