/* eslint-disable camelcase */
import { SoloGame, SoloGameResponse } from '@/interfaces/pick-em';

const soloGameAdapter = (soloGame: SoloGameResponse): SoloGame => {
  const {
    id,
    competition_id,
    manually_created,
    match_progress,
    period,
    scheduled_at,
    score,
    sport_id,
    sport_tournament_round_id,
    status,
    title,
    type,
  } = soloGame;

  return {
    id,
    competitionId: competition_id,
    manuallyCreated: manually_created,
    matchProgress: match_progress,
    period,
    scheduledAt: scheduled_at,
    score,
    sportId: sport_id,
    sportTournamentRoundId: sport_tournament_round_id,
    status,
    title,
    type,
  };
};

export default soloGameAdapter;
