import { EntryCollection } from 'contentful';

import client from '@/api-contentful/contentful-request';
import { IGeneralRulesFields } from '@/interfaces/general-rules';

export const getContentfulGeneralRules = async (): Promise<
  EntryCollection<IGeneralRulesFields>
> => {
  const response = await client.getEntries<IGeneralRulesFields>({
    content_type: 'generalRules',
  });

  return response;
};
