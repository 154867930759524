/* eslint-disable camelcase */
import { RivalOption, RivalOptionResponse } from '@/interfaces/pick-em';

import appearanceStatAdapter from './appearance-stat';

const rivalOptionAdapter = (rivalOption: RivalOptionResponse): RivalOption => {
  const { id, appearance_stat, rival_line_id, spread, type } = rivalOption;

  const appearanceStat = appearanceStatAdapter(appearance_stat);

  return {
    id,
    appearanceStat,
    rivalLineId: rival_line_id,
    spread,
    type,
  };
};

export default rivalOptionAdapter;
