import { EntryCollection } from 'contentful';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api-contentful/sport-rules';
import { IMiscSportsRulesFields, ISportsRulesFields } from '@/interfaces/contentful';

import { setMiscSportsRules, setSportsRules } from '../actions';

export const getSportsRulesSaga = function* getSportsRulesSaga(): SagaIterator {
  try {
    // eslint-disable-next-line max-len
    const sportsRules: EntryCollection<ISportsRulesFields> = yield call(
      api.getContentfulSportsRules
    );
    yield put(setSportsRules({ sportsRules }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getMiscSportsRulesSaga = function* getMiscSportsRulesSaga(): SagaIterator {
  try {
    // eslint-disable-next-line max-len
    const miscSportsRules: EntryCollection<IMiscSportsRulesFields> = yield call(
      api.getContentfulMiscSportsRules
    );
    yield put(setMiscSportsRules({ miscSportsRules }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};
