import React from 'react';
import { motion } from 'framer-motion';

import Icon from '@/components/atoms/icon';
import { OverUnderLine } from '@/interfaces/pick-em';

import styles from './styles.scss';

export interface StatChangeIconProps {
  overUnderLine: OverUnderLine;
}

const StatChangeIcon = (props: StatChangeIconProps) => {
  const { overUnderLine } = props;

  return (
    <div>
      {overUnderLine.oldOverUnderLineId &&
        overUnderLine.oldStatValue !== overUnderLine.statValue && (
          <motion.div
            initial={{ opacity: 0, y: 2 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 2 }}
            transition={{
              type: 'spring',
              stiffness: 100,
              mass: 10,
              damping: 1,
              duration: 0.1,
            }}
          >
            <Icon
              name={
                parseFloat(overUnderLine?.statValue) > parseFloat(overUnderLine.oldStatValue)
                  ? 'statChangeUp'
                  : 'statChangeDown'
              }
              className={styles.statValueChangeIcon}
            />
          </motion.div>
        )}
    </div>
  );
};

export default StatChangeIcon;
