/* eslint-disable camelcase */
import { CompletedSlate, CompletedSlateResponse } from '@/interfaces/completed-slates';

export const completedSlateAdapter = (slateResponse: CompletedSlateResponse): CompletedSlate => {
  const {
    id,
    contest_style_ids,
    cutoff_at,
    description,
    draft_count,
    draft_pool_draft_count,
    game_count,
    lobby_hidden,
    sport_id,
    start_at,
    title,
    tournament_draft_count,
    weekly_winner_draft_count,
  } = slateResponse;

  return {
    id,
    contestStyleIds: contest_style_ids,
    cutoffAt: cutoff_at,
    description,
    draftCount: draft_count,
    draftPoolDraftCount: draft_pool_draft_count,
    gameCount: game_count,
    lobbyHidden: lobby_hidden,
    sportId: sport_id,
    startAt: start_at,
    title,
    tournamentDraftCount: tournament_draft_count,
    weeklyWinnerDraftCount: weekly_winner_draft_count,
  };
};
