import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import {
  getAppearanceStatsWkAvg,
  setAppearanceStatsWkAvg,
} from '@/store/modules/appearance-stats-wk-avg/actions';

import * as api from '@/api/appearance-stats';
import { AppearanceStatsResponse } from '@/interfaces/appearance-stats';
import errorLogger from '@/utilities/errors/logger';

export const getAppearanceStatsWkAvgSaga = function* getAppearanceStatsSagaWkAvg(
  action: ReturnType<typeof getAppearanceStatsWkAvg>
): SagaIterator {
  try {
    const response = yield call(api.getAppearanceStats, { ...action.payload, isWkAvg: true });
    const { data }: { data: AppearanceStatsResponse } = response;

    yield put(setAppearanceStatsWkAvg(data));
  } catch (e) {
    errorLogger(true, `getAppearanceStatsSaga: ${e}`);
  }
};
