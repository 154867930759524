import { EntryCollection } from 'contentful';

import { ITournamentRulesFields } from '@/interfaces/contentful';

export const GET_TOURNAMENT_RULES = 'rules/GET_TOURNAMENT_RULES' as const;
export const SET_TOURNAMENT_RULES = 'rules/SET_TOURNAMENT_RULES' as const;

export const GET_ACTIVE_TOURNAMENTS_RULES = 'rules/GET_ACTIVE_TOURNAMENTS_RULES' as const;
export const SET_ACTIVE_TOURNAMENTS_RULES = 'rules/SET_ACTIVE_TOURNAMENTS_RULES' as const;

export const getTournamentRules = (payload: { tournamentSlug: string }) => ({
  type: GET_TOURNAMENT_RULES,
  payload,
});

export const setTournamentRules = (payload: {
  tournamentRules: EntryCollection<ITournamentRulesFields>;
  slug: string;
}) => ({ type: SET_TOURNAMENT_RULES, payload });

export const getActiveTournamentsRules = () => ({ type: GET_ACTIVE_TOURNAMENTS_RULES });

export const setActiveTournamentsRules = (payload: {
  tournamentsRules: EntryCollection<ITournamentRulesFields>;
}) => ({ type: SET_ACTIVE_TOURNAMENTS_RULES, payload });
