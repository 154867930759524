/* eslint-disable camelcase */
import { UserLeaderboardMarkersResponse } from '@/interfaces/leaderboard';
import {
  LeaderboardMarkerPointsUpdateForWWWResponse,
  UserLeaderboardMarkersForWeeklyWinnerWeeks,
  WeeklyWinnerWeekEntryScoreUpdateResponse,
} from '@/interfaces/weekly-winners';

import { userLeaderboardAdapter } from '../../leaderboard/adapters/user-leaderboard';
import {
  SET_USER_LEADERBOARD_MARKERS_FOR_WEEKLY_WINNER_WEEK,
  setUserLeaderboardMarkersForWeeklyWinnerWeek,
  UPDATE_ENTRIES_FOR_WEEKLY_WINNER_WEEK,
  UPDATE_LEADERBOARD_MARKER_POINTS,
  updateEntriesForWeeklyWinnerWeek,
  updateLeaderboardMarkerPoints,
} from '../actions';

type State = UserLeaderboardMarkersForWeeklyWinnerWeeks;

type UserLeaderboardMarkersForWeeklyWinnerWeeksActions = ReturnType<
  | typeof setUserLeaderboardMarkersForWeeklyWinnerWeek
  | typeof updateEntriesForWeeklyWinnerWeek
  | typeof updateLeaderboardMarkerPoints
>;

const initialState: UserLeaderboardMarkersForWeeklyWinnerWeeks = null;

const set = (
  state: UserLeaderboardMarkersForWeeklyWinnerWeeks,
  { data, weeklyWinnerWeekId }: { data: UserLeaderboardMarkersResponse; weeklyWinnerWeekId: string }
): UserLeaderboardMarkersForWeeklyWinnerWeeks => {
  const { leaderboard, payoutMinPoints } = userLeaderboardAdapter(data);

  return {
    ...state,
    [weeklyWinnerWeekId]: {
      leaderboard,
      payoutMinPoints,
    },
  };
};

const updateMarkers = (
  state: UserLeaderboardMarkersForWeeklyWinnerWeeks,
  {
    data,
    draftId,
    weeklyWinnerWeekId,
  }: {
    data: WeeklyWinnerWeekEntryScoreUpdateResponse;
    weeklyWinnerWeekId: string;
    draftId: string;
  }
): UserLeaderboardMarkersForWeeklyWinnerWeeks => {
  if (!state[weeklyWinnerWeekId]) return state;
  const updatedMarkers = state[weeklyWinnerWeekId].leaderboard.map((currentMarker) => {
    if (currentMarker.draftId !== draftId && currentMarker.id !== data.id) return currentMarker;
    const { place, tied } = data;

    return {
      ...currentMarker,
      place,
      tied,
    };
  });

  return {
    ...state,
    [weeklyWinnerWeekId]: {
      ...state[weeklyWinnerWeekId],
      leaderboard: updatedMarkers,
    },
  };
};

const updatePoints = (
  state: UserLeaderboardMarkersForWeeklyWinnerWeeks,
  {
    payout_min_points: payoutMinPoints,
    weekly_winner_week_id: weeklyWinnerWeekId,
  }: LeaderboardMarkerPointsUpdateForWWWResponse
) => ({
  ...state,
  [weeklyWinnerWeekId]: {
    ...state[weeklyWinnerWeekId],
    payoutMinPoints,
  },
});

export const userLeaderboardMarkersForWeeklyWinnerWeeksReducer = (
  state: State = initialState,
  // eslint-disable-next-line max-len
  action: UserLeaderboardMarkersForWeeklyWinnerWeeksActions = {} as UserLeaderboardMarkersForWeeklyWinnerWeeksActions
): State => {
  switch (action.type) {
    case SET_USER_LEADERBOARD_MARKERS_FOR_WEEKLY_WINNER_WEEK:
      return set(state, action.payload);
    case UPDATE_ENTRIES_FOR_WEEKLY_WINNER_WEEK:
      return updateMarkers(state, action.payload);
    case UPDATE_LEADERBOARD_MARKER_POINTS:
      return updatePoints(state, action.payload);
    default:
      return state;
  }
};
