import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as api from '@/api/matches';
import errorLogger from '@/utilities/errors/logger';

import { getWeeklyMatches, setWeeklyMatches } from '../actions';

export const getWeeklyMatchesSaga = function* getWeeklyMatchesSaga(
  action: ReturnType<typeof getWeeklyMatches>
): SagaIterator {
  try {
    const response = yield call(api.getWeeklyMatches, action.payload);

    yield put(setWeeklyMatches({ data: response.data, weekId: action.payload.weekId }));
  } catch (e) {
    errorLogger(true, `getConstructedSlateSaga: ${e}`);
    yield put(setError({ ...e, type: 'toast' }));
  }
};
