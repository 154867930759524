import React, { useRef } from 'react';
import { clsx } from 'clsx';

import Button from '@/components/atoms/button';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import ContentfulNoVulturesInfo from '@/components/molecules/contentful-no-vultures-info';
import ModalWrapper from '@/components/molecules/modal-wrapper';
import { CDN_HOST } from '@/utilities/constants';

import commonStyles from '../../common-styles.scss';
import styles from './styles.scss';

const PromoCardNoVultures = () => {
  const openModal = useModal();
  const containerRef = useRef<HTMLDivElement>(null);

  const openInfoModal = () => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <ModalWrapper handleCloseModal={handleCloseModal} hideCloseButton>
        <ContentfulNoVulturesInfo handleCloseModal={handleCloseModal} />
      </ModalWrapper>
    ));
  };

  const keyboardSelect = (e: React.KeyboardEvent<HTMLElement>) => {
    if (containerRef.current === e.target) {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();
        openInfoModal();
      }
    }
  };

  return (
    <div
      ref={containerRef}
      className={clsx(commonStyles.promoCardWrapper, styles.promoCardNoVultures)}
      style={{
        backgroundImage: `url(${CDN_HOST}/pickem/promo-bg.png)`,
      }}
      onClick={openInfoModal}
      onKeyDown={keyboardSelect}
      role="button"
      tabIndex={0}
    >
      <img
        alt="No vultures icon"
        className={styles.noVulturesIcon}
        src={`${CDN_HOST}/pickem/no-vultures-icon.png`}
      />
      <div className={clsx(commonStyles.infoSection, styles.infoSection)}>
        <div className={styles.content}>
          <h2 className={clsx(commonStyles.header, styles.header)}>Vulture Protection</h2>
          <p className={clsx(commonStyles.text, styles.text)}>Protect your RB</p>
          <div className={styles.buttonWrapper}>
            <Button
              classNames={{
                button: styles.button,
              }}
              color="grey"
              onClick={openInfoModal}
              size="regular"
              text="Learn more"
              type="solid"
              width="full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PromoCardNoVultures;
