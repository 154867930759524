import { ConstructedEntrySlipPickEmOverUnderAppearance } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { POWER_UP_SPECIAL_STAT_VALUE } from '@/utilities/constants';

export const getStatValues = ({
  cA,
}: {
  cA: ConstructedEntrySlipPickEmOverUnderAppearance;
}): { prevStatValue: string | null; statValue: string } => {
  if (cA.selection.powerUp?.type === 'special') {
    // Power up special
    return {
      prevStatValue: cA.overUnderLineInfo.statValue,
      statValue: POWER_UP_SPECIAL_STAT_VALUE,
    };
  }

  if (cA.selection.discountedLineValue) {
    // Power up discount
    return {
      prevStatValue: cA.overUnderLineInfo.statValue,
      statValue: cA.selection.discountedLineValue,
    };
  }

  if (cA.overUnderLineInfo.nonDiscountedStatValue) {
    // Legacy discount
    return {
      prevStatValue: cA.overUnderLineInfo.nonDiscountedStatValue,
      statValue: cA.overUnderLineInfo.statValue,
    };
  }

  // default
  return {
    prevStatValue: null,
    statValue: cA.overUnderLineInfo.statValue,
  };
};
