/* eslint-disable camelcase */
import { WeeklyWinnersForSlate, WeeklyWinnersForSlateResponse } from '@/interfaces/weekly-winners';

import { SET_WEEKLY_WINNERS_FOR_SLATE, setWeeklyWinnersForSlate } from '../actions';
import { weeklyWinnerForSlateAdapter } from '../adapters/weekly-winner-for-slate';

type State = WeeklyWinnersForSlate;

type WeeklyWinnersActions = ReturnType<typeof setWeeklyWinnersForSlate>;

const initialState: WeeklyWinnersForSlate = {};

const set = (
  state: WeeklyWinnersForSlate,
  { data, slateId }: { data: WeeklyWinnersForSlateResponse; slateId: string }
): WeeklyWinnersForSlate => {
  const weeklyWinners = data.weekly_winners.map(weeklyWinnerForSlateAdapter);
  return {
    ...state,
    [slateId]: weeklyWinners,
  };
};

export const weeklyWinnersForSlateReducer = (
  state: State = initialState,
  action: WeeklyWinnersActions = {} as WeeklyWinnersActions
): State => {
  switch (action.type) {
    case SET_WEEKLY_WINNERS_FOR_SLATE:
      return set(state, action.payload);
    default:
      return state;
  }
};
