import { EntryCollection } from 'contentful';

import client from '@/api-contentful/contentful-request';
import { IPromoInfoFields } from '@/interfaces/contentful';

export const getContentfulPromoInfoById = async ({
  contentfulInfoId,
}: {
  contentfulInfoId: string;
}): Promise<EntryCollection<IPromoInfoFields>> => {
  const response = await client.getEntries<IPromoInfoFields>({
    content_type: 'promoInfo',
    'fields.contentfulInfoId': contentfulInfoId, // unique field
  });

  return response;
};
