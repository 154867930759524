import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { Modal } from '@/interfaces/modal';

export interface UIContextValue {
  modal: Modal;
  setModal: React.Dispatch<React.SetStateAction<Modal>>;
}

const UIContext = createContext<UIContextValue | undefined>(undefined);

export type UIProviderProps = PropsWithChildren<unknown>;

export const useUIContext = () => {
  const context = useContext(UIContext);
  if (!context) {
    throw new Error(
      // eslint-disable-next-line max-len
      'UIContext value is undefined, ensure that your `useUIContext` call is a descendant of a `<UIContextProvider />` element.'
    );
  }

  return context;
};

export const UIContextProvider = ({ children }: UIProviderProps) => {
  const [modal, setModal] = useState<Modal>({});

  const value = useMemo(
    () => ({
      modal,
      setModal,
    }),
    [modal]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export default UIContext;
