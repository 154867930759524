/* eslint-disable camelcase */
import { RENAME_DRAFT_ENTRY, renameDraftEntry } from '@/store/modules/drafts/actions';

import { RenameDraftEntry } from '@/interfaces/drafts';
import { DraftsForWeeklyWinnerResponse, DraftsForWeeklyWinners } from '@/interfaces/weekly-winners';

import { SET_USER_DRAFTS_FOR_WEEKLY_WINNER, setUserDraftsForWeeklyWinner } from '../actions';
import { draftForWeeklyWinnerAdapter } from '../adapters/draft-for-weekly-winners';

type State = DraftsForWeeklyWinners | null;

type DraftForWeeklyWinnersActions = ReturnType<
  typeof setUserDraftsForWeeklyWinner | typeof renameDraftEntry
>;

const initialState: DraftsForWeeklyWinners = {};

const set = (
  state: DraftsForWeeklyWinners,
  {
    data,
    weeklyWinnerId,
  }: {
    data: DraftsForWeeklyWinnerResponse;
    weeklyWinnerId: string;
  }
): DraftsForWeeklyWinners => {
  const newDraftsForWeeklyWinner = data.drafts && data.drafts.map(draftForWeeklyWinnerAdapter);

  const drafts = state[weeklyWinnerId]?.drafts
    ? [...state[weeklyWinnerId].drafts, ...newDraftsForWeeklyWinner].filter(
        (draft, ind, arr) => arr.findIndex((d) => d.id === draft.id) === ind
      )
    : newDraftsForWeeklyWinner;

  return {
    ...state,
    [weeklyWinnerId]: {
      drafts,
      meta: data.meta,
    },
  };
};

const renameDraft = (state: DraftsForWeeklyWinners, data: RenameDraftEntry) => {
  const weeklyWinner = state[data.weeklyWinnerId];
  if (!state[data.weeklyWinnerId]) return state;

  const drafts = state[data.weeklyWinnerId].drafts.map((draft) => {
    if (draft.id === data.id) {
      return {
        ...draft,
        title: data.draftEntryTitle,
      };
    }
    return draft;
  });

  return {
    ...state,
    [data.weeklyWinnerId]: {
      drafts: [...drafts],
      meta: { ...weeklyWinner.meta },
    },
  };
};

export const draftsForWeeklyWinnersReducer = (
  state: State = initialState,
  action: DraftForWeeklyWinnersActions = {} as DraftForWeeklyWinnersActions
): State => {
  switch (action.type) {
    case SET_USER_DRAFTS_FOR_WEEKLY_WINNER:
      return set(state, action.payload);
    case RENAME_DRAFT_ENTRY:
      return renameDraft(state, action.payload);
    default:
      return state;
  }
};
