import { useSelector } from 'react-redux';

import { RootState } from '@/store';

import { User } from '@/interfaces/user';

const useIsPoolsEnabled = () => {
  const user = useSelector<RootState, User>((state) => state.user);

  const isPoolsEnabled = Boolean(user.stateConfig?.pickEm.pickemPoolsVisible);

  return isPoolsEnabled;
};

export default useIsPoolsEnabled;
