/* eslint-disable camelcase */
import { Exposure, ExposureResponse } from '@/interfaces/exposure';

import { exposureAppearanceAdapter } from './exposure-appearance';
import { exposureDraftPoolAdapter } from './exposure-draft-pool';
import { exposureTournamentAdapter } from './exposure-tournament';
import { exposureWeeklyWinnerAdapter } from './exposure-weekly-winner';

export const exposureAdapter = ({ ownership }: ExposureResponse): Exposure => {
  const {
    appearances: appearancesResponse,
    draft_count,
    draft_pools: draftPoolsResponse,
    entry_counts,
    entry_fees,
    tournaments: tournamentsResponse,
    updated_at,
    weekly_winners: weeklyWinnersResponse,
  } = ownership;

  const appearances = exposureAppearanceAdapter(appearancesResponse);
  const draftPools = draftPoolsResponse && draftPoolsResponse.map(exposureDraftPoolAdapter);
  const tournaments = tournamentsResponse && tournamentsResponse.map(exposureTournamentAdapter);
  // eslint-disable-next-line max-len
  const weeklyWinners =
    weeklyWinnersResponse && weeklyWinnersResponse.map(exposureWeeklyWinnerAdapter);

  return {
    appearances,
    draftCount: draft_count,
    draftPools,
    entryCounts: entry_counts,
    entryFees: entry_fees,
    tournaments,
    updatedAt: updated_at,
    weeklyWinners,
  };
};
