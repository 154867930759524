import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '@/store';
import { clearError as clearErrorAction } from '@/store/modules/error/actions';

import { ampli } from '@/ampli';
import Button from '@/components/atoms/button';
import GamblingAdvice from '@/components/atoms/gambling-advice';
import Icon from '@/components/atoms/icon';
import LinkWrapper from '@/components/atoms/link-wrapper';
import Loader from '@/components/atoms/loader';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import ProgressBarStepped from '@/components/atoms/progress-bar-stepped';
import SignUpButton from '@/components/atoms/sign-up-button';
import SupportLink from '@/components/atoms/support-link';
import TextField from '@/components/atoms/text-field';
import { useToast } from '@/components/atoms/toast';
import { useAuth } from '@/components/contexts/auth';
import DialogModal from '@/components/molecules/dialog-modal';
/* eslint-disable import/no-cycle */
import ForgotPasswordModal from '@/components/molecules/forgot-password-modal';
import RegisterModal from '@/components/molecules/register-modal';
import { AppErrorRedux } from '@/interfaces/error';
import { AUTH0_ERRORS } from '@/utilities/errors/constants';
import { askForLocation } from '@/utilities/location';

/* eslint-enable import/no-cycle */
import styles from './styles.scss';

export interface LoginFormProps {
  clearError: () => void;
  error: AppErrorRedux;
  isModal?: boolean;
  // Needed only for login page
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginForm = (props: LoginFormProps): JSX.Element => {
  const { clearError, error, isModal, setLoading } = props;
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | false>(false);
  const [password, setPassword] = useState<string>('');
  const [showLoginState, setShowLoginState] = useState<boolean>(false);
  const [locationMessage, setLocationMessage] = useState<string>(null);
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);

  const store = useStore();
  const openToast = useToast();
  const timeoutRef = useRef(null);
  const openModal = useModal();
  const { loginUser } = useAuth();

  // return a function to clear the timeout
  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    []
  );

  const getFormattedError = useCallback((): JSX.Element | string => {
    switch (error.title) {
      case AUTH0_ERRORS.INVALID_GRANT:
        return (
          <>
            <p className={styles.toastTextBold}>
              We don&apos;t recognize this email and password combination.
            </p>
            <p className={styles.toastText}>
              For your security, we will lock your account after 3 failed attempts.
            </p>
            <p className={styles.toastText}>
              Try again or{' '}
              <LinkWrapper className={styles.toastLink} to="/forgot-password">
                reset your password
              </LinkWrapper>
              .
            </p>
            <p className={styles.toastText}>
              If you are having problems, please{' '}
              <SupportLink className={styles.toastLink} label="contact support" />.
            </p>
          </>
        );
      case AUTH0_ERRORS.TOO_MANY_ATTEMPTS:
        return (
          <>
            <p className={styles.toastTextBold}>You&apos;ve been locked out.</p>
            <p className={styles.toastText}>
              Due to multiple failed login attempts, we&apos;ve taken the precaution of locking your
              account.
            </p>
            <p className={styles.toastText}>
              To unlock your account,{' '}
              <LinkWrapper className={styles.toastLink} to="/forgot-password">
                reset your password
              </LinkWrapper>
              .
            </p>
            <p className={styles.toastText}>
              If you are having problems, please{' '}
              <SupportLink label="contact support" className={styles.toastLink} />.
            </p>
          </>
        );
      case AUTH0_ERRORS.MFA_REQUIRED:
        return (
          <p className={styles.toastText}>
            We don&apos;t recognize this device or location. We have sent you an email to confirm
            your identity. Please follow the instructions to proceed. You will be blocked after 3
            failed login attempts.
          </p>
        );
      default:
        // in certain cases, mobile includes line breaks within the error message
        // we need to clear these out, web does not require line breaks
        return <p className={styles.toastText}>{error.message?.replace(/\/n\/n/g, ' ')}</p>;
    }
  }, [error]);

  useEffect(() => {
    if (error.type === 'toast' && error.message) {
      // prevent user from immediately logging in again
      // password manager may immediately reset this with users pw
      setPassword('');

      openToast({
        message: getFormattedError(),
        showCloseButton: true,
        open: true,
        autoHideDuration: Object.values(AUTH0_ERRORS).includes(error.title) ? null : 6000,
        customClose: () => {
          clearError();
          setShowLoginState(false);
        },
        variant: 'red',
      });
    }

    if (error.type === 'modal') {
      openModal(
        ({ handleCloseModal }: HandleCloseModal) => (
          <DialogModal
            title={error.title}
            content={<p>{error.message}</p>}
            handleCloseModal={handleCloseModal}
            dismissText="Ok"
          />
        ),
        {
          customClose: () => {
            clearError();
            setShowLoginState(false);
          },
        }
      );
    }
  }, [
    clearError,
    error.message,
    error.title,
    error.type,
    getFormattedError,
    openModal,
    openToast,
    store,
  ]);

  const onEmailChange = ({ value }: { value: string }): void => {
    setEmail(value);
  };

  const onEmailBlur = () => {
    if (!email.includes('@') && email.length > 1) {
      return setEmailError('Please use a valid email');
    }
    return setEmailError(false);
  };

  const onPasswordChange = ({ value }: { value: string }): void => {
    setPassword(value);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (setLoading) {
      setLoading(true);
    }
    askForLocation({
      onAsk: () => setShowLoginState(true),
      onSuccess: () => loginUser({ user: { email, password } }),
      onFailure: (err) => {
        setShowLoginState(null);
        if (!err?.code && err?.message) {
          // denied permission
          openToast({
            message: err.message,
            open: true,
          });
        }
        if (err?.code === 1) {
          // denied permission
          openToast({
            // eslint-disable-next-line max-len
            message:
              'You denied us location permission, we need this per state regulations to log you in.',
            open: true,
          });
        }
        if (err.code === 2) {
          // couldn't get location
          openToast({
            message: 'Could not get location, please try again.',
            open: true,
          });
        }
        if (err.code === 3) {
          // timed out
          openToast({
            message: "Location timed out, we weren't able to get your location.",
            open: true,
          });
        }
      },
      errorContext: {
        email,
        location: 'Login page',
      },
    });
    timeoutRef.current = setTimeout(() => {
      // eslint-disable-next-line max-len
      setLocationMessage(
        "If this spins for a while and you haven't seen another error message you may not have your location settings turned on, try reaching out to support@underdogfantasy.com"
      );
    }, 10000);
  };

  const openRegisterModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    clearError();
    ampli.registrationSignUpButtonPressed();
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <RegisterModal handleCloseModal={handleCloseModal} />
    ));
  };

  const openForgotPasswordModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <ForgotPasswordModal handleCloseModal={handleCloseModal} />
    ));
  };

  const canSubmit = !!email && !!password;

  const forgotPasswordLink = (
    <LinkWrapper className={styles.forgotPassword} to="/forgot-password">
      Forgot password?
    </LinkWrapper>
  );

  const forgotPasswordModalLink = (
    <button className={styles.forgotPassword} onClick={openForgotPasswordModal} type="button">
      Forgot password?
    </button>
  );

  const progressBar = (
    <div className={styles.progressBarWrapper}>
      <ProgressBarStepped totalCount={1} currentCount={1} />
    </div>
  );

  if (showLoginState && !error.message) {
    return (
      <div className={styles.formContent}>
        <div className={styles.loggingInState}>
          <p>Securely logging you in</p>
          {locationMessage && <p>{locationMessage}</p>}
          <Loader />
        </div>
      </div>
    );
  }

  const passwordEyeIcon = (
    <button
      type="button"
      className={styles.passwordVisibleIcon}
      onClick={(e) => {
        e.preventDefault();
        setPasswordVisibility(!passwordVisibility);
      }}
      aria-label={passwordVisibility ? 'Hide password' : 'Show password'}
    >
      <Icon name={passwordVisibility ? 'visibilityCrossedOut' : 'visibility'} />
    </button>
  );

  return (
    <div className={styles.formContent}>
      <h1 className={styles.title}>Log in</h1>
      {isModal ? null : progressBar}
      <form onSubmit={onSubmit} className={styles.form}>
        <TextField
          label="Email"
          name="email"
          value={email}
          error={emailError}
          onChange={onEmailChange}
          onBlur={onEmailBlur}
          classNames={{ wrapper: styles.fieldWrapper }}
          autoComplete="email"
          giveFocus
          testId="email_input"
          placeholder="Email"
        />
        <TextField
          label="Password"
          name="password"
          type={passwordVisibility ? 'text' : 'password'}
          value={password}
          onChange={onPasswordChange}
          classNames={{ wrapper: styles.fieldWrapper }}
          autoComplete="current-password"
          customRightElement={passwordEyeIcon}
          testId="password_input"
          placeholder="Password"
        />
        {isModal ? forgotPasswordModalLink : forgotPasswordLink}
        <Button
          text="Log in"
          color="green"
          size="small"
          width="intrinsic"
          type="solid"
          disabled={!canSubmit}
          classNames={{ button: styles.button }}
          testId="sign-in-button"
        />
      </form>
      <p className={styles.register}>
        Don&apos;t have an account?&nbsp;
        <SignUpButton
          classNames={{
            button: styles.signUpLink,
          }}
          eventType="initiate"
          onClick={
            isModal
              ? openRegisterModal
              : () => {
                  clearError();
                  navigate('/register');
                }
          }
          type="text"
          size="small"
          width="intrinsic"
        />
      </p>
      <GamblingAdvice />
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    error: state.error,
  }),
  (dispatch) => ({
    clearError: () => dispatch(clearErrorAction()),
  })
)(LoginForm);
