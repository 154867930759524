import React from 'react';

import { Outlet } from '@/services/router/service';

import styles from './styles.scss';

const WebviewSampleLayout = () => {
  return (
    <div className={styles.container}>
      <Outlet />
    </div>
  );
};

export default WebviewSampleLayout;
