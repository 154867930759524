/* eslint-disable camelcase */
import {
  AppliedPowerUp,
  AppliedPowerUpResponse,
  PowerUpRestrictions,
  PowerUpType,
  RedeemablePowerUp,
  RedeemablePowerUpResponse,
} from '@/interfaces/power-ups';
import { toCamel } from '@/utilities/helpers';

// Here T extends RedeemablePowerUpResponse instead of extending AppliedPowerUp because
// the RedeemablePowerUpResponse is more specific and therefore TypeScript can only
// correctly determine and narrowdown the type of T if RedeemablePowerUp is first. Don't reverse these.
type PowerUpAdapterReturnType<T extends AppliedPowerUpResponse | RedeemablePowerUpResponse> =
  T extends RedeemablePowerUpResponse ? RedeemablePowerUp : AppliedPowerUp;

const powerUpAdapter = <T extends AppliedPowerUpResponse | RedeemablePowerUpResponse>(
  powerUp: T
): PowerUpAdapterReturnType<T> => {
  const {
    id,
    auto_applies,
    background_color,
    background_image,
    contentful_info_id,
    cta_text,
    cta_url,
    description,
    fee_amount,
    header_contentful_info_id,
    header_image,
    hero_image,
    icon,
    percentage,
    primary_color_dark_mode,
    primary_color_light_mode,
    secondary_color_dark_mode,
    secondary_color_light_mode,
    tag,
    title,
    type,
  } = powerUp;

  const adaptedPowerUpType = toCamel(type) as PowerUpType;

  let adaptedHighlights = null;
  if ('highlights' in powerUp) {
    const { highlights } = powerUp;
    adaptedHighlights = highlights;
  }

  let adaptedRestrictions = null;
  if ('restrictions' in powerUp) {
    const { restrictions } = powerUp;
    adaptedRestrictions = {
      appliesTo: toCamel(powerUp.restrictions.applies_to) as PowerUpRestrictions['appliesTo'],
      entrySlip: {
        boostable: restrictions.entry_slip.boostable,
        flexible: restrictions.entry_slip.flexible,
        inverseScorchable: restrictions.entry_slip.inverse_scorchable,
        maxFeeAmount: restrictions.entry_slip.max_fee_amount,
        maxSelectionCount: restrictions.entry_slip.max_selection_count,
        minSelectionCount: restrictions.entry_slip.min_selection_count,
        scorchable: restrictions.entry_slip.scorchable,
        streak: restrictions.entry_slip.streak,
      },
      entrySlipSelection: restrictions.entry_slip_selection
        ? {
            boostable: restrictions.entry_slip_selection.boostable,
            eligibleAppearanceIds: restrictions.entry_slip_selection.eligible_appearance_ids,
            eligiblePickemStatIds: restrictions.entry_slip_selection.eligible_pickem_stat_ids,
            eligibleOverUnderIds: restrictions.entry_slip_selection.eligible_over_under_ids,
            eligibleSportIds: restrictions.entry_slip_selection.eligible_sport_ids,
            inverseScorchable: restrictions.entry_slip_selection.inverse_scorchable,
            overOptionSelectable: restrictions.entry_slip_selection.over_option_selectable,
            rivalOptionSelectable: restrictions.entry_slip_selection.rival_option_selectable,
            scorchable: restrictions.entry_slip_selection.scorchable,
            underOptionSelectable: restrictions.entry_slip_selection.under_option_selectable,
          }
        : null,
      filterableSelections: restrictions.filterable_selections,
    };
  }

  let adaptedUses = null;
  if ('uses' in powerUp) {
    const { uses } = powerUp;
    adaptedUses = uses?.map((use) => ({
      id,
      expiresAt: use.expires_at,
    }));
  }

  const response = {
    id,
    autoApplies: auto_applies,
    backgroundColor: background_color,
    backgroundImage: background_image,
    contentfulInfoId: contentful_info_id,
    ctaText: cta_text,
    ctaUrl: cta_url,
    description,
    ...('expires_at' in powerUp ? { expiresAt: powerUp.expires_at } : {}),
    feeAmount: fee_amount,
    headerContentfulInfoId: header_contentful_info_id,
    headerImage: header_image,
    heroImage: hero_image,
    ...(adaptedHighlights ? { highlights: adaptedHighlights } : {}),
    icon,
    ...('number_of_uses_remaining' in powerUp
      ? { numberOfUsesRemaining: powerUp.number_of_uses_remaining }
      : {}),
    percentage,
    primaryColorDarkMode: primary_color_dark_mode,
    primaryColorLightMode: primary_color_light_mode,
    ...(adaptedRestrictions ? { restrictions: adaptedRestrictions } : {}),
    secondaryColorDarkMode: secondary_color_dark_mode,
    secondaryColorLightMode: secondary_color_light_mode,
    tag,
    title,
    type: adaptedPowerUpType,
    ...(adaptedUses ? { uses: adaptedUses } : {}),
    ...('unlimited' in powerUp ? { unlimited: powerUp.unlimited } : {}),
  };

  return response as PowerUpAdapterReturnType<T>;
};

export default powerUpAdapter;
