import React from 'react';
import { connect } from 'react-redux';

import { Navigate } from '@/services/router/service';
import type { RootState } from '@/store';
import { ALTERNATIVE_HOME_ROUTE, DEFAULT_ROUTE } from '@/utilities/constants';

export interface UnknownRoutePageProps {
  redirectRoute: string;
}

function UnknownRoutePage({ redirectRoute }: UnknownRoutePageProps) {
  return <Navigate to={redirectRoute} replace state="redirected" />;
}

export default connect((state: RootState) => {
  return {
    redirectRoute: state.featureFlags.lobbySwitch ? ALTERNATIVE_HOME_ROUTE : DEFAULT_ROUTE,
  };
})(UnknownRoutePage);
