import React, { useState } from 'react';
import clsx from 'clsx';
import Cookies from 'js-cookie';

import Icon from '@/components/atoms/icon';
import { UD_MAX_PAYOUT_MULTIPLIER_MODAL_DISMISSED } from '@/utilities/constants';

import styles from './styles.scss';

export interface MaxPayoutMultiplierModalContent {
  updateSelectedOverUnders: () => void;
  handleCloseModal: () => void;
}

const MaxPayoutMultiplierModal = (props: MaxPayoutMultiplierModalContent) => {
  const { updateSelectedOverUnders, handleCloseModal } = props;

  const [dismissMaxPayoutMessage, setDismissMaxPayoutMessage] = useState<boolean>(false);

  const slipType = localStorage.getItem('max_payout_modifier_reached');

  let warningText: string;
  const dismissCheckBox = (
    <button
      className={styles.checkBoxButton}
      onClick={() => {
        if (!dismissMaxPayoutMessage) {
          Cookies.set(UD_MAX_PAYOUT_MULTIPLIER_MODAL_DISMISSED, 'true', { expires: 400 });
          setDismissMaxPayoutMessage(true);
        } else if (dismissMaxPayoutMessage) {
          Cookies.set(UD_MAX_PAYOUT_MULTIPLIER_MODAL_DISMISSED, 'false');
          setDismissMaxPayoutMessage(false);
        }
      }}
    >
      <Icon
        name={dismissMaxPayoutMessage ? 'checkBox' : 'checkBoxOutline'}
        className={styles.checkBoxIcon}
      />
      <p>Don&apos;t show this message again</p>
    </button>
  );

  if (slipType === 'default') {
    warningText = 'Your entry is at the maximum payout of 100x.';
  }
  if (slipType === 'insurance') {
    warningText = 'Your entry is at the maximum flexed payout of 50x.';
  }

  return (
    <div className={styles.modal}>
      <div className={styles.headerSection}>
        <h1 className={styles.title}>Max payout reached</h1>
      </div>
      <div className={styles.contentScrollable}>
        <p className={styles.contentText}>{warningText}</p>
        <p>Adding this pick will not increase your potential payout.</p>
      </div>
      {dismissCheckBox}
      <div className={styles.buttonSection}>
        <button className={clsx(styles.button, styles.confirmButton)} onClick={handleCloseModal}>
          Don&apos;t add this pick
        </button>
        <button
          className={clsx(styles.button, styles.dismissButton)}
          onClick={() => {
            updateSelectedOverUnders();
            handleCloseModal();
          }}
        >
          Add this pick anyway
        </button>
      </div>
    </div>
  );
};

export default MaxPayoutMultiplierModal;
