import { EntryCollection } from 'contentful';

import client from '@/api-contentful/contentful-request';
import { IMiscSportsRules, ISportsRulesFields } from '@/interfaces/contentful';

export const getContentfulSportsRules = async (): Promise<EntryCollection<ISportsRulesFields>> => {
  const response = await client.getEntries<ISportsRulesFields>({
    content_type: 'sportsRules',
  });

  return response;
};

export const getContentfulMiscSportsRules = async (): Promise<
  EntryCollection<IMiscSportsRules>
> => {
  const response = await client.getEntries<IMiscSportsRules>({
    content_type: 'miscSportsRules',
  });

  return response;
};
