import { AppearanceStatsRequest, AppearanceStatsResponse } from '@/interfaces/appearance-stats';

export const GET_APPEARANCE_STATS_WK_AVG = 'drafts/GET_APPEARANCE_STATS_WK_AVG' as const;
export const SET_APPEARANCE_STATS_WK_AVG = 'drafts/SET_APPEARANCE_STATS_WK_AVG' as const;

export const getAppearanceStatsWkAvg = (payload: AppearanceStatsRequest) => ({
  type: GET_APPEARANCE_STATS_WK_AVG,
  payload,
});

export const setAppearanceStatsWkAvg = (payload: AppearanceStatsResponse) => ({
  type: SET_APPEARANCE_STATS_WK_AVG,
  payload,
});
