import React from 'react';

import Icon from '@/components/atoms/icon';
import ToolTip from '@/components/atoms/tool-tip';

import styles from './styles.scss';

export const MANUAL_SCORING_MESSAGE = "This pick won't be scored live.";

const ManualScoringIcon = () => {
  return (
    <ToolTip
      align="left"
      classNames={{
        toolTip: styles.toolTip,
        message: styles.toolTipMessage,
      }}
      labelEl={<Icon name="live" className={styles.icon} />}
      messageEl={<p>{MANUAL_SCORING_MESSAGE}</p>}
    />
  );
};

export default ManualScoringIcon;
