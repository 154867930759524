import { SitAndGosResponse } from '@/interfaces/lobbies';

export const GET_ALL_SIT_AND_GOS = 'lobbies/GET_ALL_SIT_AND_GOS' as const;
export const SET_ALL_SIT_AND_GOS = 'lobbies/SET_ALL_SIT_AND_GOS' as const;

export const getAllSitAndGos = () => ({ type: GET_ALL_SIT_AND_GOS });

export const setAllSitAndGos = (payload: { data: SitAndGosResponse }) => ({
  type: SET_ALL_SIT_AND_GOS,
  payload,
});
