/* eslint-disable camelcase */
import dayjs from 'dayjs';

import {
  ConstructedUserAchievement,
  CosmeticsAndAchievementsResponse,
} from '@/interfaces/constructed-interfaces/constructed-cosmetics-and-achievements';

export const constructedCosmeticsAdapter = (
  data: CosmeticsAndAchievementsResponse
): ConstructedUserAchievement[] => {
  const { cosmeticsResponse, achievementsResponse } = data;

  const cosmeticAvatars: ConstructedUserAchievement[] = cosmeticsResponse.cosmetics.map((cos) => ({
    cosmetic: cos,
    description: null,
    openedAt: null,
    rarity: null,
    isAchievement: false,
    isNew: false,
    isNewAchievement: false,
    userAchievement: null,
    isLocked: false,
  }));

  const filteredAchievementAvatars = achievementsResponse.achievements.filter(
    (ach) => ach.cosmetic.style === 'avatar'
  );

  const achievementAvatars: ConstructedUserAchievement[] = filteredAchievementAvatars.map(
    (achievement) => {
      const { cosmetic, description, opened_at, rarity, user_achievement } = achievement;

      const isNew = dayjs(opened_at).isAfter(dayjs().subtract(3, 'day'));
      const isNewAchievement =
        user_achievement?.achieved_at &&
        dayjs(user_achievement?.achieved_at).isAfter(dayjs().subtract(3, 'day'));

      return {
        cosmetic,
        description,
        openedAt: opened_at,
        rarity,
        isAchievement: true,
        isNew,
        isNewAchievement,
        isLocked: !user_achievement?.achieved_at,
        userAchievement: user_achievement
          ? {
              progress: user_achievement.progress,
              achievedAt: user_achievement.achieved_at,
            }
          : null,
      };
    }
  );

  return [...cosmeticAvatars, ...achievementAvatars];
};
