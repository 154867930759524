/* eslint-disable max-len */
import {
  DraftEntriesForDraftResponse,
  DraftsForTournamentRoundResponse,
  TournamentRoundsForSlateResponse,
  UserDraftEntriesForDraftResponse,
} from '@/interfaces/tournament-rounds';
import { User } from '@/interfaces/user';

export const GET_TOURNAMENT_ROUNDS_FOR_SLATE =
  'tournament-rounds/GET_TOURNAMENT_ROUNDS_FOR_SLATE' as const;
export const SET_TOURNAMENT_ROUNDS_FOR_SLATE =
  'tournament-rounds/SET_TOURNAMENT_ROUNDS_FOR_SLATE' as const;

export const GET_USER_DRAFTS_FOR_TOURNAMENT_ROUNDS =
  'tournament-rounds/GET_USER_DRAFTS_FOR_TOURNAMENT_ROUNDS' as const;
export const SET_USER_DRAFTS_FOR_TOURNAMENT_ROUNDS =
  'tournament-rounds/SET_USER_DRAFTS_FOR_TOURNAMENT_ROUNDS' as const;

export const GET_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT =
  'tournament-rounds/GET_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT' as const;
export const SET_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT =
  'tournament-rounds/SET_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT' as const;

export const GET_USER_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT =
  'tournament-rounds/GET_USER_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT' as const;
export const SET_USER_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT =
  'tournament-rounds/SET_USER_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT' as const;

export const getTournamentRoundsForSlate = (payload: { slateId: string }) => ({
  type: GET_TOURNAMENT_ROUNDS_FOR_SLATE,
  payload,
});

export const setTournamentRoundsForSlate = (payload: TournamentRoundsForSlateResponse) => ({
  type: SET_TOURNAMENT_ROUNDS_FOR_SLATE,
  payload,
});

export const getUserDraftsForTournamentRounds = (payload: {
  tournamentRoundId: string;
  page?: number;
}) => ({ type: GET_USER_DRAFTS_FOR_TOURNAMENT_ROUNDS, payload });

export const setUserDraftsForTournamentRound = (payload: {
  data: DraftsForTournamentRoundResponse;
  tournamentRoundId: string;
}) => ({ type: SET_USER_DRAFTS_FOR_TOURNAMENT_ROUNDS, payload });

export const getPaginatedDraftEntriesForDraft = (payload: {
  draftId: string;
  page?: number;
  user?: User;
}) => ({ type: GET_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT, payload });

export const setPaginatedDraftEntriesForDraft = (payload: {
  data: DraftEntriesForDraftResponse;
  user?: User;
}) => ({ type: SET_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT, payload });

export const getUserPaginatedDraftEntriesForDraft = (payload: {
  draftId: string;
  page?: number;
}) => ({ type: GET_USER_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT, payload });

export const setUserPaginatedDraftEntriesForDraft = (payload: {
  data: UserDraftEntriesForDraftResponse;
  draftId: string;
}) => ({ type: SET_USER_PAGINATED_DRAFT_ENTRIES_FOR_DRAFT, payload });
