import { ContentTypeCollection, EntryCollection } from 'contentful';

import client from '@/api-contentful/contentful-request';
import { ITeamFields, ITeamMemberFields } from '@/interfaces/contentful';

export const getContentfulTeams = async (): Promise<EntryCollection<ITeamFields>> => {
  const response = await client.getEntries<ITeamFields>({
    content_type: 'team',
  });

  return response;
};

export const getContentfulTeamMembers = async (): Promise<EntryCollection<ITeamMemberFields>> => {
  const response = await client.getEntries<ITeamMemberFields>({
    content_type: 'teamMember',
  });

  return response;
};

export const getContentTypes = async (): Promise<ContentTypeCollection> => {
  const response = await client.getContentTypes();

  return response;
};
