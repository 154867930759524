import React, { ChangeEvent, MouseEvent, useEffect, useRef } from 'react';
import clsx from 'clsx';

import FieldWrapper, { FieldWrapperProps } from '@/components/atoms/field-wrapper';
import Icon from '@/components/atoms/icon';

import styles from './styles.scss';

export type TextFieldCallback = ({ value, name }: { value: string; name: string }) => any;

export interface TextFieldProps extends FieldWrapperProps {
  // classNames is from FieldWrapperProps and passed straight into it
  autoComplete?: string;
  customClearAction?: (...value: any) => void;
  customLeftElement?: JSX.Element;
  customRightElement?: JSX.Element;
  giveFocus?: boolean; // will be overridden by the modal focus if used in a modal
  infoOnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  inputClassName?: string;
  inputMode?: 'text' | 'decimal' | 'numeric' | 'tel' | 'url' | 'search' | 'email';
  name: string;
  onBlur?: TextFieldCallback;
  onChange: TextFieldCallback;
  onFocus?: TextFieldCallback;
  onSubmit?: (val?: any) => any;
  placeholder?: string;
  readOnly?: boolean;
  selectTextOnClick?: boolean;
  type?: 'text' | 'password';
  valid?: boolean;
  disabled?: boolean;
  testId?: string;
}

const TextField = (props: TextFieldProps): JSX.Element => {
  const {
    autoComplete,
    classNames,
    customClearAction,
    customLeftElement,
    customRightElement,
    error,
    giveFocus,
    infoOnClick,
    inlineNote,
    inputClassName,
    inputMode,
    label,
    maxLength,
    name,
    note,
    onBlur,
    onChange,
    onFocus,
    onSubmit,
    placeholder,
    readOnly,
    selectTextOnClick,
    type = 'text',
    valid,
    value,
    disabled,
    testId,
  } = props;
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (giveFocus) {
      inputEl.current?.focus();
    }
  }, [giveFocus]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!maxLength || e.target.value.length <= maxLength) {
      onChange({
        value: e.target.value,
        name,
      });
    }
  };

  const handleOnClick = (e: MouseEvent<HTMLInputElement>): void => {
    if (!selectTextOnClick) return;
    e.currentTarget.select();
  };

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!onBlur) return;
    onBlur({
      value: e.target.value,
      name,
    });
  };

  const handleOnFocus = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!onFocus) return;
    onFocus({
      value: e.target.value,
      name,
    });
  };

  const handleOnSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!onSubmit) return;
    if (e.key === 'Enter') onSubmit();
  };

  const handleClearAction = (): void => {
    if (!customClearAction) return;
    customClearAction({
      name,
    });
  };

  const validClasses = clsx(styles.valid, valid ? styles.isValid : styles.notValid);
  const inputClasses = clsx(styles.field, inputClassName, { [styles.disabled]: disabled });

  return (
    <FieldWrapper
      error={error}
      label={label}
      maxLength={maxLength}
      note={note}
      value={value}
      classNames={classNames}
      inlineNote={inlineNote}
      infoOnClick={infoOnClick}
      disabled={disabled}
    >
      {customLeftElement}
      <input
        ref={inputEl}
        className={inputClasses}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onClick={handleOnClick}
        onFocus={handleOnFocus}
        onKeyUp={handleOnSubmit}
        placeholder={placeholder}
        type={type}
        inputMode={inputMode}
        value={value}
        autoComplete={autoComplete}
        readOnly={readOnly}
        disabled={disabled}
        data-testid={testId ?? 'text-field'}
      />
      {customRightElement}
      {valid !== undefined && <Icon name="checkCircle" className={validClasses} />}
      {value && customClearAction && (
        <button onClick={handleClearAction} data-testid="clear-button" aria-label="Clear text">
          <Icon name="cancel" className={styles.customClear} />
        </button>
      )}
    </FieldWrapper>
  );
};

export default TextField;
