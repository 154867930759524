/* eslint-disable camelcase */
import { FeatureFlags, FeatureResponse, UserFeatureResponseKeys } from '@/interfaces/feature-flags';

const featureResponseToObject = (
  features: FeatureResponse['features']
): Record<UserFeatureResponseKeys, boolean> =>
  features.reduce(
    (acc, { key, status }) => {
      acc[key] = status;

      return acc;
    },
    {} as Record<UserFeatureResponseKeys, boolean>
  );

export const featuresAdapter = (features: FeatureResponse['features']): FeatureFlags => {
  const {
    lobby_switch,
    pick_em,
    secondary_pusher_env,
    sift,
    sportsbook_pre_registration,
    tertiary_pusher_env,
    web_alternate_projections,
    web_no_vultures,
    web_powerups,
    web_pick_8,
    ui_deposit_redesign,
  } = featureResponseToObject(features);

  return {
    lobbySwitch: lobby_switch,
    pickEm: pick_em,
    secondaryPusherEnv: secondary_pusher_env,
    sift,
    sportsbookPreRegistration: sportsbook_pre_registration,
    tertiaryPusherEnv: tertiary_pusher_env,
    webAlternateProjections: web_alternate_projections,
    webNoVultures: web_no_vultures,
    webPowerUps: web_powerups,
    webPick8: web_pick_8,
    webDepositRedesign: ui_deposit_redesign,
  };
};
