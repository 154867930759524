/* eslint-disable camelcase */

import { PickMadePick, PickMadePickResponse } from '@/interfaces/drafts';

export const pickMadeAdapter = (pick: PickMadePickResponse): PickMadePick => {
  const { id, appearance_id, draft_entry_id, number, pick_slot_id } = pick;

  return {
    id,
    appearanceId: appearance_id,
    draftEntryId: draft_entry_id,
    number,
    pickSlotId: pick_slot_id,
  };
};
