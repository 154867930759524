import { AppearanceStats, AppearanceStatsResponse } from '@/interfaces/appearance-stats';

import {
  CLEAR_APPEARANCE_STATS,
  clearAppearanceStats,
  SET_APPEARANCE_STATS,
  SET_WEEKLY_APPEARANCE_STATS,
  setAppearanceStats,
  setWeeklyAppearanceStats,
} from '../actions';
import appearanceStatsAdapter from '../adapters/appearance-stats';

type State = AppearanceStats;

type AppearanceStatsActions = ReturnType<
  typeof setAppearanceStats | typeof setWeeklyAppearanceStats | typeof clearAppearanceStats
>;

const initialAppearanceStatsState: AppearanceStats = {};

const set = (
  state: AppearanceStats,
  appearanceStatsResponse: AppearanceStatsResponse
): AppearanceStats => {
  const appearanceStat = appearanceStatsAdapter(appearanceStatsResponse);

  return {
    ...state,
    [appearanceStat.id]: appearanceStat,
  };
};

const clear = () => ({});

export const appearanceStatsReducer = (
  state: State = initialAppearanceStatsState,
  action: AppearanceStatsActions = {} as AppearanceStatsActions
): State => {
  switch (action.type) {
    case SET_APPEARANCE_STATS:
      return set(state, action.payload);
    case SET_WEEKLY_APPEARANCE_STATS:
      return set(state, action.payload);
    case CLEAR_APPEARANCE_STATS:
      return clear();
    default:
      return state;
  }
};
