import React from 'react';
import { connect, useSelector } from 'react-redux';

import { RootState } from '@/store';
import { updateSelectedOverUnders as updateSelectedOverUndersAction } from '@/store/modules/pick-em/actions';

import Icon from '@/components/atoms/icon';
import { SelectedOverUnder } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { PickLocation } from '@/interfaces/pick-em';

import styles from './styles.scss';

interface RemoveSelectionButtonProps {
  constructedAppearanceId: string;
  disabled: boolean;
  minInsuredPicks: number;
  removeOverUnder: ({
    selection,
    minInsuredPicks,
  }: {
    selection: SelectedOverUnder;
    minInsuredPicks: number;
  }) => void;
}

const RemoveSelectionButton = (props: RemoveSelectionButtonProps) => {
  const { constructedAppearanceId, disabled, minInsuredPicks, removeOverUnder } = props;

  const selectedOverUnder = useSelector((state: RootState) =>
    state.pickEmEntries.selected.selectedOverUnders.find(
      (sOU) => sOU.constructedOverUnderAppearance.id === constructedAppearanceId
    )
  );

  return (
    <button
      aria-label="Remove selection"
      data-testid="remove-button"
      className={disabled ? styles.inactive : ''}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        removeOverUnder({
          selection: selectedOverUnder,
          minInsuredPicks,
        });
      }}
    >
      <Icon name="clear" className={styles.removeIcon} />
    </button>
  );
};

export default connect(
  (state: RootState) => ({
    minInsuredPicks: state.pickEmEntries.payoutOutcome.insurance.minSelectionCount,
  }),
  (dispatch) => ({
    removeOverUnder: ({
      selection,
      minInsuredPicks,
    }: {
      selection: SelectedOverUnder;
      minInsuredPicks: number;
    }) =>
      dispatch(
        updateSelectedOverUndersAction({
          action: 'remove',
          selection,
          isPickEmFeatureEnabled: true,
          pickLocation: PickLocation.REVIEW,
          minInsuredPicks,
          maxUserPicks: Infinity, // doesn't matter on remove
        })
      ),
  })
)(RemoveSelectionButton);
