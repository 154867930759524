import { useMatches } from './use-matches';

/**
 * This hook returns the last match in the current route `matches` that satisfies
 * the given test for the `match.handle` property.
 * Example: You have a route defined with a `handle` property
 * ```
 * <Route element={<LayoutRouteComponent />}>
 *   <Route handle={{ layoutProps: { layout: 'narrow' } }} path="/tax" element={<TaxPage />}>
 * </Route>
 * ```
 *
 * When browsing to the `/tax` route, react-router will provide "matches" containing
 * all the `Route` objects that match the current URL via the `useMatches` hook.
 * We can then iterate over these matches to find the last/final one that has a `handle` property
 * that has the desired "shape" we're looking for.
 * Example:
 * ```
 * const match = useLastMatchWithHandle<LayoutRouteHandle>((handle) => Boolean(handle.layoutProps));
 * ```
 * This will return the last match that has a `handle` property with a `layoutProps` property, if any.
 *
 * This approach is primarily useful for "layout routes", where a parent Route component is assigned
 * a layout component that should be used for all child routes - but the child routes can individually
 * control the prop values for the layout and determine how it will render. All of this without
 * needing to fully remount the layout component when navigating between routes that share the layout, nor
 * needing to use useEffect or similar to update the layout component props when the child/page component renders.
 *
 */
export function useLastMatchWithHandle<THandle>(test: (handle: THandle) => boolean) {
  const matches = useMatches<unknown, THandle>();
  const match = matches.findLast((m) => test(m.handle));

  return match;
}
