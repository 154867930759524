type ServiceFactory<TService, TServiceFactoryContext> = (
  ctx: TServiceFactoryContext
) => Promise<TService>;

type InstanceServiceConfig<TName, TService> = {
  name: TName;
  instance: TService;
};

type FactoryServiceConfig<TName, TService, TServiceFactoryContext> = {
  name: TName;
  factory: ServiceFactory<TService, Prettify<TServiceFactoryContext>>;
};

// This is a helper type that is used to simplify / prettify complex object types
// into a more readable format.
type Prettify<T> = T extends infer U ? { [K in keyof U]: U[K] } : never;

type InstanceServiceConfigRegistry = {
  [key: string]: InstanceServiceConfig<string, unknown>;
};

type FactoryServiceConfigRegistry = {
  [key: string]: FactoryServiceConfig<string, unknown, unknown>;
};

type ExtractInstanceServiceType<TInstanceServiceConfig> =
  TInstanceServiceConfig extends InstanceServiceConfig<any, infer TServiceType>
    ? TServiceType
    : never;

type ExtractFactoryServiceType<TFactoryServiceConfig> =
  TFactoryServiceConfig extends FactoryServiceConfig<any, infer TServiceType, any>
    ? TServiceType
    : never;

type ServiceFactoryContext<TInstanceServiceConfigRegistry extends InstanceServiceConfigRegistry> = {
  [K in keyof TInstanceServiceConfigRegistry]: ExtractInstanceServiceType<
    TInstanceServiceConfigRegistry[K]
  >;
};

type InstanceServiceName<TInstanceServiceConfigRegistry extends InstanceServiceConfigRegistry> =
  keyof TInstanceServiceConfigRegistry & string;

type FactoryServiceName<TFactoryServiceConfigRegistry extends FactoryServiceConfigRegistry> =
  keyof TFactoryServiceConfigRegistry & string;

/**
 * This is a helper type to derive a mapped type of service names to their respective
 * service types from a concrete `ServiceContainer` instance. This makes it
 * easier to reference service types by name without having to do TS gymnastics
 * around the `ServiceContainer.get` method parameters and return type.
 */
export type ServiceMap<T> = T extends ServiceContainer<
  infer TInstanceServiceConfigRegistry,
  infer TFactoryServiceConfigRegistry
>
  ? {
      [K in keyof TInstanceServiceConfigRegistry]: ExtractInstanceServiceType<
        TInstanceServiceConfigRegistry[K]
      >;
    } & {
      [K in keyof TFactoryServiceConfigRegistry]: ExtractFactoryServiceType<
        TFactoryServiceConfigRegistry[K]
      >;
    }
  : never;

type MergeConfigRegistry<TOld, TNew> = TOld & TNew;

export class ServiceContainer<
  // We disable the ban-types rule for the "empty object" type `{}` because
  // if we use Record<string, never> instead, or some other variant of Record<string, xxxx>,
  // then the `InstanceServiceName` and `FactoryServiceName` helpers used by the `getService`
  // and `getServiceAsync` methods will widen the type of the service names to `string` instead
  // of providing a union of the service names.
  // I'm not sure how to fix that, so we allow the empty object type - which is
  // fine in this case because we're only using it as an initializer and does
  // exactly what we want.
  // eslint-disable-next-line @typescript-eslint/ban-types
  TInstanceServiceConfigRegistry extends InstanceServiceConfigRegistry = {},
  // eslint-disable-next-line @typescript-eslint/ban-types
  TFactoryServiceConfigRegistry extends FactoryServiceConfigRegistry = {},
> {
  private instanceServiceConfigRegistry = {} as TInstanceServiceConfigRegistry;

  private factoryServiceConfigRegistry = {} as TFactoryServiceConfigRegistry;

  private factoryServiceCache = new Map();

  private factoryServiceContext = {} as ServiceFactoryContext<TInstanceServiceConfigRegistry>;

  registerInstance = <TServiceName extends string, TService>(
    config: InstanceServiceConfig<TServiceName, TService>
  ): ServiceContainer<
    MergeConfigRegistry<TInstanceServiceConfigRegistry, { [K in TServiceName]: typeof config }>,
    TFactoryServiceConfigRegistry
  > => {
    // We freeze the instance object to try to prevent changes to it after registration.
    const readonlyInstance = Object.freeze(config.instance);

    // We create a new config object to prevent re-assignment of the `instance` property
    // after the service is registered.
    this.instanceServiceConfigRegistry[
      config.name as keyof typeof this.instanceServiceConfigRegistry
    ] = {
      name: config.name,
      instance: readonlyInstance,
    } as any;

    this.factoryServiceContext[config.name as keyof typeof this.factoryServiceContext] =
      readonlyInstance as any;

    return this as any;
  };

  registerFactory = <TServiceName extends string, TService>(
    config: FactoryServiceConfig<TServiceName, TService, typeof this.factoryServiceContext>
  ): ServiceContainer<
    TInstanceServiceConfigRegistry,
    MergeConfigRegistry<TFactoryServiceConfigRegistry, { [K in TServiceName]: typeof config }>
  > => {
    // We create a new config object to prevent re-assignment of the `factory` property
    // after the service is registered.
    this.factoryServiceConfigRegistry[
      config.name as keyof typeof this.factoryServiceConfigRegistry
    ] = {
      name: config.name,
      factory: config.factory,
    } as any;

    return this as any;
  };

  getService = <
    TServiceName extends InstanceServiceName<TInstanceServiceConfigRegistry>,
    TServiceType extends ExtractInstanceServiceType<TInstanceServiceConfigRegistry[TServiceName]>,
  >(
    name: TServiceName
  ): TServiceType => {
    const serviceConfig = this.instanceServiceConfigRegistry[name];

    // This is a relatively safe cast because we freeze the service instance object
    // when the service is registered, so it should be immutable to an extent and
    // align with TServiceType.
    return serviceConfig.instance as TServiceType;
  };

  getServiceAsync = async <
    TServiceName extends FactoryServiceName<TFactoryServiceConfigRegistry>,
    TServiceType extends ExtractFactoryServiceType<TFactoryServiceConfigRegistry[TServiceName]>,
  >(
    name: TServiceName
  ): Promise<Prettify<TServiceType>> => {
    const cachedService = this.factoryServiceCache.get(name);
    if (cachedService) {
      return cachedService as Prettify<TServiceType>;
    }

    const serviceConfig = this.factoryServiceConfigRegistry[name];

    const createdInstance = Object.freeze(await serviceConfig.factory(this.factoryServiceContext));
    this.factoryServiceCache.set(name, createdInstance);
    return createdInstance as Prettify<TServiceType>;
  };
}
