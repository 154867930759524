import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';

import { setCosmeticsAndAchievements } from '@/store/modules/cosmetics/actions';
import { setError } from '@/store/modules/error/actions';

import * as cosmeticsApi from '@/api/cosmetics';
import * as usersApi from '@/api/users';
import { CosmeticsResponse, UserAchievementsResponse } from '@/interfaces/cosmetics';
import errorLogger from '@/utilities/errors/logger';

export const getCosmeticsAndAchievementsSaga =
  function* getCosmeticsAndAchievementsSaga(): SagaIterator {
    try {
      const [cosmeticsData, achievementsData] = yield all([
        call(cosmeticsApi.getCosmetics),
        call(usersApi.getUserAchievements),
      ]);

      const { data: cosmeticsResponse }: { data: CosmeticsResponse } = cosmeticsData;
      const { data: achievementsResponse }: { data: UserAchievementsResponse } = achievementsData;

      yield put(
        setCosmeticsAndAchievements({
          cosmeticsResponse,
          achievementsResponse,
        })
      );
    } catch (e) {
      errorLogger(true, `getCosmeticsAndAchievementsSaga: ${e}`);
      yield put(setError({ ...e, type: 'toast' }));
    }
  };
