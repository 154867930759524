import React from 'react';
import { useLocation } from 'react-router-dom';

import { ampli } from '@/ampli';
import Button, { ButtonProps } from '@/components/atoms/button';

interface SignUpButtonProps extends ButtonProps {
  eventType?: 'initiate';
}

const SignUpButton = (props: SignUpButtonProps) => {
  const { classNames, disabled, eventType, onClick, size, text, type, width } = props;

  const location = useLocation();

  const onSignUpClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
    if (eventType === 'initiate') {
      ampli.registrationSignUpInitiated({
        sign_up_path_web: location.pathname,
      });
    }
  };

  return (
    <Button
      classNames={{
        button: classNames?.button,
      }}
      color="green"
      disabled={disabled}
      text={text || 'Sign up'}
      size={size}
      width={width}
      onClick={onSignUpClick}
      type={type}
    />
  );
};

export default SignUpButton;
