import { EntryCollection } from 'contentful';

import client from '@/api-contentful/contentful-request';
import { ITextPageFields } from '@/interfaces/contentful';

export const getContentfulTextPages = async ({
  id,
}: {
  id: string;
}): Promise<EntryCollection<ITextPageFields>> => {
  const response = await client.getEntries<ITextPageFields>({
    content_type: 'textPage',
    'fields.id': id,
  });

  return response;
};
