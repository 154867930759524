import { WeeklyAppearancesResponse } from '@/interfaces/weekly-appearances';

export const GET_WEEKLY_APPEARANCES = 'drafts/GET_WEEKLY_APPEARANCES' as const;
export const SET_WEEKLY_APPEARANCES = 'drafts/SET_WEEKLY_APPEARANCES' as const;

export const getWeeklyAppearances = (payload: { weekId: number; scoringTypeId: string }) => ({
  type: GET_WEEKLY_APPEARANCES,
  payload,
});

export const setWeeklyAppearances = (payload: {
  data: WeeklyAppearancesResponse;
  weekId: number;
}) => ({ type: SET_WEEKLY_APPEARANCES, payload });
