/* eslint-disable camelcase */
import { projectionAdapter } from '@/store/modules/appearance-stats/adapters/appearance-stats';

import {
  Appearance,
  AppearanceResponse,
} from '@/interfaces/constructed-interfaces/constructed-slates';

import scoreAdapter from './score';
import statLineAdapter from './stat-line';

const appearanceAdapter = (appearance: AppearanceResponse): Appearance => {
  const {
    id,
    badges,
    latest_news_item_updated_at,
    lineup_status_id,
    match_id,
    match_type,
    player_id,
    position_id,
    projection,
    score: scoreResponse,
    stat_line,
    team_id,
  } = appearance;

  const adaptedProjection = projection && projectionAdapter(projection);
  const adaptedStatLine = stat_line && statLineAdapter(stat_line);
  const score = scoreResponse && scoreAdapter(scoreResponse);

  return {
    id,
    badges,
    ...(latest_news_item_updated_at && {
      latestNewsItemUpdatedAt: latest_news_item_updated_at,
    }),
    lineupStatusId: lineup_status_id,
    matchId: match_id,
    matchType: match_type,
    playerId: player_id,
    positionId: position_id,
    ...(projection && { projection: adaptedProjection }),
    ...(score && { score }),
    ...(stat_line && { statLine: adaptedStatLine }),
    teamId: team_id,
  };
};

export default appearanceAdapter;
