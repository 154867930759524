import { AxiosResponse } from 'axios';

import request from '@request';
import {
  ContestStylesResponse,
  EntryStylesResponse,
  LineupStatusesResponse,
  ScoringTypesResponse,
  SlotsResponse,
  SportsResponse,
  StateConfigResponse,
  TeamsResponse,
} from '@/interfaces/drafting-config';
import { apiErrorTracking, AppError } from '@/utilities/errors';

// Get sports
export const getSports = async (): Promise<AxiosResponse<SportsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      underdogAPIVersion: 'v2',
      url: '/sports',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get contest styles
 *
 * Contest styles are the name, if it's best ball or normal and the slot
 * and pick slot information
 */
export const getContestStyles = async (): Promise<AxiosResponse<ContestStylesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: '/contest_styles',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get entry styles
 *
 * Entry styles are the contest size, the entry fee and the prize
 */
export const getEntryStyles = async (): Promise<AxiosResponse<EntryStylesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: '/entry_styles',
      underdogAPIVersion: 'v2',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get lineup statuses
 *
 * Lineup statuses are in, out, doubtful etc
 */
export const getLineupStatuses = async (): Promise<AxiosResponse<LineupStatusesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: '/lineup_statuses',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get teams for all sports
 *
 * Returns the information for teams in a given league, information like
 * team colors, name, abbr
 */
export const getTeams = async (): Promise<AxiosResponse<TeamsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: '/teams',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get slots
 *
 * Returns the information for positions in a given league, information like
 * position name, color and sport
 */
export const getSlots = async (): Promise<AxiosResponse<SlotsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: '/slots',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get scoring types
 *
 * Returns the information for positions in a given league, information like
 * position name, color and sport
 */
export const getScoringTypes = async (): Promise<AxiosResponse<ScoringTypesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: '/scoring_types',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get pick_em state configs
 *
 * Returns the allowed sports and stats in a particular state
 */
export const getStateConfigs = async (): Promise<AxiosResponse<StateConfigResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      requiresLatLong: true,
      url: '/user/state_configs',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};
