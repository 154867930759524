/* eslint-disable camelcase */
import { LobbySlate, LobbySlateResponse } from '@/interfaces/lobbies';

const lobbySlateAdapter = (slateResponse: LobbySlateResponse): LobbySlate => {
  const { id, cutoff_at, description, game_count, lobby_hidden, sport_id, start_at, title } =
    slateResponse;

  return {
    id,
    cutoffAt: cutoff_at,
    description,
    gameCount: game_count,
    lobbyHidden: lobby_hidden,
    sportId: sport_id,
    startAt: start_at,
    title,
  };
};

export default lobbySlateAdapter;
