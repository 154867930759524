/* eslint-disable camelcase */
import { RENAME_DRAFT_ENTRY, renameDraftEntry } from '@/store/modules/drafts/actions';

import { CompletedDraftsBySlate, CompletedDraftsResponse } from '@/interfaces/completed-drafts';
import { RenameDraftEntry } from '@/interfaces/drafts';

import { SET_COMPLETED_DRAFTS, setCompletedDrafts, setCompletedDraftsError } from '../actions';
import { completedDraftAdapter } from '../adapters/completed-draft';

type State = CompletedDraftsBySlate | null;

type CompletedDraftsActions = ReturnType<
  typeof setCompletedDrafts | typeof setCompletedDraftsError | typeof renameDraftEntry
>;

const initialState: CompletedDraftsBySlate = {};

const set = (
  state: CompletedDraftsBySlate,
  data: CompletedDraftsResponse
): CompletedDraftsBySlate => {
  const slateId = data.drafts?.[0].slate_id; // this is a little fragile
  const newDrafts = data.drafts && data.drafts.map(completedDraftAdapter);

  // filter out duplicates just in case
  const drafts = state[slateId]?.drafts
    ? [...state[slateId].drafts, ...newDrafts].filter(
        (draft, ind, arr) => arr.findIndex((d) => d.id === draft.id) === ind
      )
    : newDrafts;

  return {
    ...state,
    [slateId]: {
      drafts,
      meta: data.meta,
    },
  };
};

const rename = (state: CompletedDraftsBySlate, data: RenameDraftEntry) => {
  const slate = state[data.slateId];
  if (!slate) return state;

  const drafts = slate.drafts.map((draft) => {
    if (draft.id === data.id) {
      return {
        ...draft,
        title: data.draftEntryTitle,
      };
    }
    return draft;
  });
  return {
    ...state,
    [data.slateId]: {
      drafts: [...drafts],
      meta: slate.meta,
    },
  };
};

export const completedDraftsReducer = (
  state: State = initialState,
  action: CompletedDraftsActions = {} as CompletedDraftsActions
): State => {
  switch (action.type) {
    case SET_COMPLETED_DRAFTS:
      return set(state, action.payload);
    case RENAME_DRAFT_ENTRY:
      return rename(state, action.payload);
    default:
      return state;
  }
};
