import React, { useEffect, useMemo, useState } from 'react';
import { To, useLocation } from 'react-router-dom';

interface INavigationContext {
  getNextRoute: ({ path, end }: { path: To; end?: boolean }) => To;
}

export const NavigationContext = React.createContext<INavigationContext | null>(null);

interface NavigationProviderProps {
  children: React.ReactNode;
}

export const useNavigation = () => {
  const location = useLocation();
  const [routeHistory, setRouteHistory] = useState<string[]>([]);

  useEffect(() => {
    // clear route history on bad url
    if (location.state === 'redirected') {
      setRouteHistory([]);
    }
  }, [location.state]);

  useEffect(() => {
    // set route history if url is good and not currently on page
    if (location.state !== 'redirected') {
      if (location.pathname !== routeHistory[0]) {
        // filter out duplicates
        const newRouteHistory = [location.pathname, ...routeHistory].filter(
          (existingPath, ind, arr) => arr.findIndex((eP) => eP === existingPath) === ind
        );
        setRouteHistory(newRouteHistory);
      }
    }
  }, [location.state, location.pathname, routeHistory]);

  const getNextRoute = ({ path, end }: { path: To; end?: boolean }) => {
    // handle navLink 'end' prop
    if (end) {
      return path;
    }

    // `path` is not guaranteed to be a string, so we need to be more
    // exhaustive in resolving the path value we use later in the function.
    const pathToTest = typeof path === 'string' ? path : path.pathname;
    if (!pathToTest) {
      return path;
    }

    // allow top level links to reset page while on the page
    if (
      location.pathname.includes(pathToTest) ||
      (location.pathname.match(/\/active|\/completed/) && pathToTest === '/lobby')
    ) {
      return path;
    }

    // look for 'exact match' or 'current path followed by forward slash'
    const routePattern = new RegExp(`(^${pathToTest}$|${pathToTest}/)`);
    const foundPath = routeHistory.find((oldPath) => {
      const routeExists = oldPath.match(routePattern);
      return !!routeExists;
    });

    return foundPath || path;
  };

  return { getNextRoute };
};

export const NavigationProvider = (props: NavigationProviderProps) => {
  const { getNextRoute } = useNavigation();

  const value = useMemo(() => ({ getNextRoute }), [getNextRoute]);

  return <NavigationContext.Provider value={value}>{props.children}</NavigationContext.Provider>;
};

export function useNavigationContext() {
  const context = React.useContext(NavigationContext);
  if (!context) {
    throw new Error(
      'useNavigationContext can only be used as a descendant of <NavigationProvider />'
    );
  }
  return context;
}
