/* eslint-disable camelcase */
import { Boost, OverUnder, OverUnderResponse } from '@/interfaces/pick-em';

import appearanceStatAdapter from './appearance-stat';
import boostAdapter from './boost';

const overUnderAdapter = (overUnder: OverUnderResponse): OverUnder => {
  const { id, scoring_type_id, title, appearance_stat, boost, option_priority } = overUnder;

  const appearanceStat = appearanceStatAdapter(appearance_stat);

  let adaptedBoost: Boost | null = null;
  if (boost) {
    adaptedBoost = boostAdapter(boost);
  }

  return {
    id,
    scoringTypeId: scoring_type_id,
    title,
    appearanceStat,
    boost: adaptedBoost,
    optionPriority: option_priority,
  };
};

export default overUnderAdapter;
