import {
  AppearancesResponse,
  ConstructedSlateResponse,
  ConstructedSlates,
  LiveScores,
} from '@/interfaces/constructed-interfaces/constructed-slates';
import { StatLineUpdateResponse } from '@/interfaces/live';
import { arrayToObjectIdKeys, getConstructedSlateKey } from '@/utilities/helpers';

import matchAdapter from '../../matches/adapters/match';
import {
  SET_CONSTRUCTED_SLATE,
  SET_SLATE_APPEARANCES,
  setConstructedSlate,
  setSlateAppearances,
  UPDATE_LIVE_SCORE,
  updateLiveScore,
} from '../actions';
import appearanceAdapter from '../adapters/appearance';
import playerAdapter from '../adapters/player';
import scoreAdapter from '../adapters/score';
import slateInfoAdapter from '../adapters/slate-info';

type State = ConstructedSlates;

type ConstructedSlateAction = ReturnType<
  typeof setConstructedSlate | typeof updateLiveScore | typeof setSlateAppearances
>;

const initialSlates: ConstructedSlates = {};

const set = (
  state: ConstructedSlates,
  slateResponse: ConstructedSlateResponse
): ConstructedSlates => {
  const { slateInfoResponse, playersResponse, appearanceResponse, matchesResponse, scoringTypeId } =
    slateResponse;

  const slateInfo = slateInfoAdapter(slateInfoResponse);

  const players = arrayToObjectIdKeys(
    playersResponse.players.map((player) => playerAdapter(player))
  );

  // appearances are actually an array, because I don't usually have to access them
  // individually and they're more likely to be looped through
  const appearances = appearanceResponse.appearances.map(appearanceAdapter);

  const adaptedMatchesArr = matchesResponse.matches && matchesResponse.matches.map(matchAdapter);
  const matches = adaptedMatchesArr && arrayToObjectIdKeys(adaptedMatchesArr);

  const liveScores: LiveScores = appearances.reduce((acc: LiveScores, curr) => {
    acc[curr.id] = curr.score;
    return acc;
  }, {});

  // combine the slateId and the scoringTypeId for the id, as either one is not enough
  const id = `${slateInfoResponse.slate.id}-${scoringTypeId}`;

  return {
    ...state,
    [id]: {
      slateInfo,
      players,
      appearances,
      matches,
      liveScores,
      lastUpdated: new Date(),
    },
  };
};

const updateScore = (
  state: ConstructedSlates,
  {
    data,
    slateId,
    scoringTypeId,
  }: {
    data: StatLineUpdateResponse;
    slateId: string;
    scoringTypeId: string;
  }
): ConstructedSlates => {
  // TODO [FAN-2617]: this is way too intensive for how often this updates, it should just be an id lookup
  // I may have to completely redo how I construct live appearances
  const key = getConstructedSlateKey({ slateId, scoringTypeId });

  const scoreData = data.stat_line.scores.find((score) => score.scoring_type_id === scoringTypeId);
  if (!scoreData) return state;

  const newScore = scoreAdapter(scoreData);

  return {
    ...state,
    [key]: {
      ...state[key],
      liveScores: {
        ...state[key].liveScores,
        [data.stat_line.owner_id]: newScore,
      },
    },
  };
};

const updateAppearances = (
  state: ConstructedSlates,
  {
    appearancesResponse,
    slateId,
    scoringTypeId,
  }: {
    appearancesResponse: AppearancesResponse;
    slateId: string;
    scoringTypeId: string;
  }
) => {
  const key = getConstructedSlateKey({ slateId, scoringTypeId });

  const appearances = appearancesResponse.appearances.map(appearanceAdapter);

  return {
    ...state,
    [key]: {
      ...state[key],
      appearances,
      lastUpdated: new Date(),
    },
  };
};

export const constructedSlatesReducer = (
  state: State = initialSlates,
  action: ConstructedSlateAction = {} as ConstructedSlateAction
): State => {
  switch (action.type) {
    case SET_CONSTRUCTED_SLATE:
      return set(state, action.payload);
    case UPDATE_LIVE_SCORE:
      return updateScore(state, action.payload);
    case SET_SLATE_APPEARANCES:
      return updateAppearances(state, action.payload);
    default:
      return state;
  }
};
